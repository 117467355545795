/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BannerPageResponseView } from '../models/BannerPageResponseView';
import type { BannerPosition } from '../models/BannerPosition';
import { request as __request } from '../core/request';

export class AppBannerService {

    /**
     * Get list of banners based on position
     * @param position
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns BannerPageResponseView OK
     * @throws ApiError
     */
    public static async appBannerList(
        position: BannerPosition,
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<BannerPageResponseView> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/banners/${position}`,
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
        });
        return result.body;
    }

}