/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlogDetailsView } from '../models/BlogDetailsView';
import type { BlogPageResponseView } from '../models/BlogPageResponseView';
import { request as __request } from '../core/request';

export class AppBlogService {

    /**
     * Get list of blogs
     * @param keyword keyword
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns BlogPageResponseView OK
     * @throws ApiError
     */
    public static async appBlogList(
        keyword?: string,
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<BlogPageResponseView> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/blogs`,
            query: {
                'keyword': keyword,
                'page': page,
                'size': size,
                'sort': sort,
            },
        });
        return result.body;
    }

    /**
     * Show blog
     * @param blogId blogId
     * @returns BlogDetailsView OK
     * @throws ApiError
     */
    public static async appBlogShow(
        blogId: number,
    ): Promise<BlogDetailsView> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/blogs/${blogId}`,
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Show blog by slug
     * @param blogSlug blogSlug
     * @returns BlogDetailsView OK
     * @throws ApiError
     */
    public static async appBlogShowBySlug(
        blogSlug: string,
    ): Promise<BlogDetailsView> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/blogs/slug-${blogSlug}`,
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

}