/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FeatureType } from './FeatureType';
import type { PostType } from './PostType';

export type PayAndRequestReviewBicycleVM = {
    transition: PayAndRequestReviewBicycleVM.transition;
    description?: string;
    deliveryInstructions?: string;
    postType?: PostType;
    featureType?: FeatureType;
    mediaIds?: Array<number>;
    mainMediaId?: number;
}

export namespace PayAndRequestReviewBicycleVM {

    export enum transition {
        PAY_AND_REQUEST_REVIEW = 'PAY_AND_REQUEST_REVIEW',
    }


}
