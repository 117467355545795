import { AppAuthService, ResendCodeVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const resendRequestAsync: AsyncThunk<void, ResendCodeVM, any> = createAsyncThunk(
  'auth/resendRequest',
  async (body: ResendCodeVM, { rejectWithValue }) => {
    try {
      return await AppAuthService.appAuthResendCode({ email: body.email })
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
