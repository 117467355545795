import { AdminNewsletterService, NewsletterPageResponseView } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { GetALlNewslettersAdminRequest } from '../Interfaces'

export const getAllNewsletterRequestAsync: AsyncThunk<NewsletterPageResponseView, GetALlNewslettersAdminRequest, any> =
  createAsyncThunk(
    'admin/newsletter/getAll',
    async (requestBody: GetALlNewslettersAdminRequest, { rejectWithValue }) => {
      try {
        return await AdminNewsletterService.adminNewsletterList(
          requestBody.keyword,
          requestBody.page - 1,
          requestBody.size
        )
      } catch (error) {
        return rejectWithValue(error)
      }
    }
  )
