import { AdminBicycleService, SaveBicycleVMSaveFromAdminView } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const adminCreateBicycleRequest: AsyncThunk<SaveBicycleVMSaveFromAdminView, any, any> = createAsyncThunk(
  'admin/bicycle/create',
  async (requestBody: SaveBicycleVMSaveFromAdminView, { rejectWithValue }) => {
    try {
      return await AdminBicycleService.adminBicycleCreate(requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
