import { AppMediaService, CreateMediaVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const createMediaRequest: AsyncThunk<CreateMediaVM, any, any> = createAsyncThunk(
  'bicycle/media',
  async (requestBody: CreateMediaVM, { rejectWithValue }) => {
    try {
      return await AppMediaService.appMediaCreate(requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
