import { AdminBicycleService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { SingleBicycleType } from '../Interfaces'

export const getAdminBicyclesRequestAsync: AsyncThunk<SingleBicycleType, number, any> = createAsyncThunk(
  'admin/bicycles/get',
  async (bicycleId: number, { rejectWithValue }) => {
    try {
      return await AdminBicycleService.adminBicycleShow(bicycleId)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
