import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { loginRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { setToken } from 'utils/storage'
import { AuthStatus } from 'constants/index'
import { ErrorResponse } from 'interfaces/Error'
import jwt_decode from 'jwt-decode'

export enum AuthRole {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
}

interface JWTInterface {
  auth: string
  exp: number
  sub: string
  name: string
}

export interface AuthResponse {
  id_token: string
  role: AuthRole
  email: string
  name: string
}

interface AdminUserStatus {
  status: AuthStatus
}
export interface AuthState extends AdminUserStatus {
  is_fetching: boolean
  data: AuthResponse
  errors: ErrorResponse
}

const initialState: AuthState = {
  is_fetching: false,
  data: {
    id_token: null,
    role: null,
    email: null,
    name: null,
  },
  status: AuthStatus.none,
  errors: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<AdminUserStatus>) => {
      state.status = action.payload.status
    },
    setLoggedIn: (state, action: PayloadAction<{ token: string }>) => {
      const decodedJwt: JWTInterface = jwt_decode(action.payload.token)
      state.data = {
        id_token: action.payload.token,
        email: decodedJwt?.sub,
        role:
          decodedJwt.auth === AuthRole.ROLE_ADMIN || decodedJwt.auth === AuthRole.ROLE_SUPER_ADMIN
            ? AuthRole.ROLE_ADMIN
            : AuthRole.ROLE_USER,
        name: decodedJwt.name,
      }
      state.status = AuthStatus.loggedIn
    },
    deleteStatus: state => {
      state.status = AuthStatus.none
      state.data = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginRequestAsync.pending, state => {
        state.status = AuthStatus.none
        state.is_fetching = true
      })
      .addCase(loginRequestAsync.fulfilled, (state, { payload }) => {
        setToken(payload?.id_token)
        state.is_fetching = false
        const decodedJwt: JWTInterface = jwt_decode(payload?.id_token)
        state.data = {
          id_token: payload?.id_token,
          email: decodedJwt?.sub,
          role: AuthRole.ROLE_ADMIN,
          // decodedJwt.auth === AuthRole.ROLE_ADMIN || decodedJwt.auth === AuthRole.ROLE_SUPER_ADMIN
          //   ? AuthRole.ROLE_ADMIN
          //   : AuthRole.ROLE_USER,
          name: decodedJwt.name,
        }
        state.status = AuthStatus.loggedIn
      })
      .addCase(loginRequestAsync.rejected, (state, action: any) => {
        state.status = AuthStatus.rejected
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = null
        }
      })
  },
})

export const { setStatus, deleteStatus, setLoggedIn } = authSlice.actions

export const selectAuthDetails = (state: RootState): AuthState => state.auth.auth
export const selectAuthStatus = (state: RootState): AuthStatus => state.auth.auth.status

export default authSlice.reducer
