import { AppBicycleService, SaveBicycleVMSaveFromAppView } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const createBicycleRequest: AsyncThunk<SaveBicycleVMSaveFromAppView, any, any> = createAsyncThunk(
  'bicycle/create',
  async (requestBody: SaveBicycleVMSaveFromAppView, { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleCreate1(requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
