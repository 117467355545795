import { Col, Row } from 'antd'
import { colors } from 'constants/colors'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { UserBar } from '../Header/UserBar/UserBar'
import { Link } from 'react-router-dom'
import email from '../../../assets/images/email.png'

export const Footer: FunctionComponent = () => {
  const { t } = useTranslation<string>()

  return (
    <FooterContainer>
      <Col xs={{ span: 24 }} sm={{ span: 12, push: 1 }} md={{ span: 7, push: 1 }} lg={{ span: 6, push: 1 }}>
        <LinkLabel>
          <Link to="/sell-on-nettifillari">{t('sellOnNet')}</Link>
        </LinkLabel>
        <LinkLabel>
          <Link to="/blog">{t('blog')}</Link>
        </LinkLabel>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12, push: 0 }} md={{ span: 7, push: 0 }} lg={{ span: 6, push: 0 }}>
        <LinkLabel>
          <Link to="/terms-and-conditions">{t('termsAndConditions')}</Link>
        </LinkLabel>
        <LinkLabel>
          <Link to="/privacy-and-policy">{t('privacyAndPolicy')}</Link>
        </LinkLabel>
        <LinkLabel>
          <Link to="/faq">{t('faq')}</Link>
        </LinkLabel>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24, push: 0 }} md={{ span: 10, push: 0 }} lg={{ span: 8, push: 0 }}>
        <LinkLabel>
          <Link to="#">{t('contactUs')}</Link>
        </LinkLabel>
        <LinkLabel>
          <FooterIcon>
            <img className={'footer-image'} src={email} alt={''} />
          </FooterIcon>
          <a href="mailto:&#097;&#115;&#105;&#097;&#107;&#097;&#115;&#112;&#097;&#108;&#118;&#101;&#108;&#117;&#064;&#110;&#101;&#116;&#116;&#105;&#102;&#105;&#108;&#108;&#097;&#114;&#105;&#046;&#102;&#105;">
            Email
          </a>
        </LinkLabel>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24, push: 0 }} md={{ span: 24, push: 0 }} lg={{ span: 4, push: 0 }}>
        <UserBar noAuth sidebar showDropDown={false} />
      </Col>
    </FooterContainer>
  )
}

const FooterIcon = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 12px;
`

const FooterContainer = styled(Row)`
  color: ${colors.WHITE};
  min-height: 270px;
  background: ${colors.BRAND_GREEN};
  padding: 30px 10px;
  margin: 0 -20px;
  overflow: hidden;
`
const LinkLabel = styled(Row)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 20px;

  & a {
    color: ${colors.WHITE};
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`
