/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Feature Type
 */
export enum FeatureType {
    TOP_FIVE = 'TOP_FIVE',
    TOP_TEN = 'TOP_TEN',
}