import { createSlice } from '@reduxjs/toolkit'
import { ErrorResponse } from 'interfaces/Error'
import { RootState } from 'store'
import { languageChangeRequestAsync } from './asyncActions'

export interface LanguageResponse {
  id: number
  name: string
}

export interface LanguageState {
  is_fetching: boolean
  done: boolean
  errors: ErrorResponse
}

const initialState: LanguageState = {
  is_fetching: false,
  done: false,
  errors: null,
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(languageChangeRequestAsync.pending, state => {
        state.done = null
        state.is_fetching = true
      })
      .addCase(languageChangeRequestAsync.fulfilled, state => {
        state.is_fetching = false
        state.done = false
      })
      .addCase(languageChangeRequestAsync.rejected, (state, { payload }) => {
        state.done = null
        state.is_fetching = false
        state.errors = payload
      })
  },
})

export const selectLanguage = (state: RootState): LanguageState => state.auth.language

export default languageSlice.reducer
