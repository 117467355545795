import { Button, Col, message, Row, Space, Switch, Tabs } from 'antd'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { SaveBlogVM } from 'api/main'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { MediaResponse, selectCreatedMedia } from 'store/bicycle/media'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { adminCreatePostRequest } from 'store/admin/blog/create/asyncActions'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'
import {
  BICYCLE_MAIN_MEDIA_LABEL,
  BLOG_MEDIA_LABEL,
  CLOUDINARY_ID,
  CLOUDINARY_UPLOAD_PRESET,
  CREATE_MEDIA_MEDIA_TYPE_IMAGE,
  SLUG_PATTERN,
} from 'constants/index'
import { CloudinaryResponse } from 'interfaces/Cloudinary'
import { createMediaRequest } from 'store/bicycle/media/asyncActions'
import { UploadImage } from 'Components/Profile/Bicyles/UploadImage/UploadImage'
import { selectAdminCreatedPost } from 'store/admin/blog/create'
import { resetState } from 'store/admin/blog/create'
import { resetState as resetMediaState } from 'store/bicycle/media'
import { Indicator } from 'Components/UiKit/Indicator'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'
import { SelectBicycleInput, SelectBicycleWrapper } from '../../../Shared/Components/Components'

const { TabPane } = Tabs

export const NewPost: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const [mediaIds, setMediaIds] = useState<string[]>([])
  const dispatch = useAppDispatch()
  const modules = useMemo(() => {
    return {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image'],
        ['clean'],
        [{ color: [] }],
      ],
    }
  }, [])
  const { quill, quillRef } = useQuill({ modules, placeholder: t('blogPostBody') })
  const { quill: quillFinish, quillRef: quillFinishRef } = useQuill({ modules, placeholder: t('blogPostBody') })
  const { data: uploadedMediaIds, errors: UploadErrors } = useAppSelector(selectCreatedMedia)
  const { done, errors: PostErrors, is_fetching: createFetching } = useAppSelector(selectAdminCreatedPost)

  const newPostSchema = (t: TFunction): Yup.ObjectSchema<ObjectShape> =>
    Yup.object().shape({
      slug: Yup.string().required(t('required')).matches(SLUG_PATTERN, t('enterBasedOnPattern')),
      title: Yup.string().required(t('required')),
      body: Yup.string().required(t('required')),
      metaTag: Yup.string().required(t('required')),
      finnishTitle: Yup.string(),
      finnishBody: Yup.string(),
      finnishMetaTag: Yup.string(),
      enabled: Yup.boolean().required(t('required')),
      photoId: Yup.number(),
    })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<SaveBlogVM>({
    defaultValues: { enabled: true },
    resolver: yupResolver(newPostSchema(t)),
  })

  const goBack = () => history.goBack()

  useEffect(() => {
    dispatch(resetMediaState())
  }, [dispatch])

  const onSubmit = handleSubmit((requestBody: SaveBlogVM) => {
    if (UploadErrors) return
    if (!uploadedMediaIds || uploadedMediaIds.length === 0) return
    requestBody.bodyPhotoIds = uploadedMediaIds
      .filter((img: MediaResponse) => mediaIds.indexOf(img.cloudId) > -1)
      .map((img: MediaResponse) => img.id)
    const bodyImage = uploadedMediaIds.filter((img: MediaResponse) => mediaIds.indexOf(img.cloudId) === -1)
    requestBody.photoId = bodyImage[bodyImage.length - 1]?.id
    dispatch(adminCreatePostRequest(requestBody))
  })

  const insertToEditor = useCallback(
    (url: string) => {
      const range = quill.getSelection()
      quill.insertEmbed(range.index, 'image', url)
    },
    [quill]
  )

  useEffect(() => {
    if (UploadErrors) {
      UploadErrors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }
  }, [t, UploadErrors, dispatch])

  useEffect(() => {
    if (Array.isArray(PostErrors?.fieldErrors)) {
      PostErrors?.fieldErrors?.forEach((err: FieldError) => message.error(err.message))
    }
    if (!PostErrors && done) {
      message.success(t('postCreated'))
      history.push('/admin/blog')
    }
    return () => {
      dispatch(resetState())
      dispatch(resetMediaState())
    }
  }, [t, PostErrors, done, history, dispatch])

  const saveToServer = useCallback(
    async (file: any) => {
      const data = new FormData()
      data.append('file', file)
      data.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      data.append('cloud_name', 'breellz')
      fetch('https://api.cloudinary.com/v1_1/' + CLOUDINARY_ID + '/image/upload', {
        method: 'post',
        body: data,
      })
        .then(resp => resp.json())
        .then((response: CloudinaryResponse) => {
          dispatch(
            createMediaRequest({
              cloudId: response.public_id,
              mediaType: CREATE_MEDIA_MEDIA_TYPE_IMAGE,
              targetType: BLOG_MEDIA_LABEL,
            })
          )
          setMediaIds([...mediaIds, response.public_id])
          insertToEditor(response.url)
        })
    },
    [insertToEditor, dispatch, mediaIds]
  )

  const selectLocalImage = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = () => {
      const file = input.files[0]
      saveToServer(file)
    }
  }, [saveToServer])

  useEffect(() => {
    if (quill) {
      quill.getModule('toolbar').addHandler('image', selectLocalImage)
      quill.on('text-change', () => {
        setValue('body', quillRef.current.firstChild.innerHTML)
      })
    }
  }, [quill, selectLocalImage, quillRef, setValue])

  useEffect(() => {
    if (quillFinish) {
      quillFinish.getModule('toolbar').addHandler('image', selectLocalImage)
      quillFinish.on('text-change', () => {
        setValue('finnishBody', quillFinishRef.current.firstChild.innerHTML)
      })
    }
  }, [quillFinish, selectLocalImage, quillFinishRef, setValue])

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'newPost' }} />
      {createFetching && <Indicator />}
      <form onSubmit={onSubmit}>
        <Topbar justify="space-between">
          <Space>
            <Button type="text" onClick={goBack} icon={<ArrowLeftOutlined />} />
            {t('newBlogPost')}
          </Space>
          <Controller
            control={control}
            name={'enabled'}
            render={({ field: { onChange } }) => (
              <GreenSwitch
                checkedChildren={t('active')}
                unCheckedChildren={t('inactive')}
                defaultChecked={true}
                onChange={onChange}
              />
            )}
          />
        </Topbar>
        <TabsList defaultActiveKey="english">
          <TabPane tab={t('english')} key="english" forceRender>
            <RowPadding justify="space-between" gutter={20}>
              <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
                <SelectBicycleInput label={t('slug')} name={'slug'} control={control} error={errors.slug} />
                <SelectBicycleInput label={t('title')} name={'title'} control={control} error={errors.title} />
                <UploadImage hint={t('postHint')} singleMode targetType={BICYCLE_MAIN_MEDIA_LABEL} images={[]} />
              </InputCol>
              <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
                <SelectBicycleInput label={t('metaTag')} name={'metaTag'} control={control} error={errors.metaTag} />
                <SelectBicycleWrapper label={t('body')} name={'body'} control={control} error={errors.body}>
                  <Quill ref={quillRef} />
                </SelectBicycleWrapper>
              </InputCol>
            </RowPadding>
          </TabPane>
          <TabPane tab={t('suomi')} key="suomi" forceRender>
            <RowPadding justify="space-between" gutter={20}>
              <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
                <SelectBicycleInput
                  label={t('finnishTitle')}
                  name={'finnishTitle'}
                  control={control}
                  error={errors.finnishTitle}
                />
              </InputCol>
              <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
                <SelectBicycleInput
                  label={t('finnishMetaTag')}
                  name={'finnishMetaTag'}
                  control={control}
                  error={errors.finnishMetaTag}
                />
                <SelectBicycleWrapper
                  label={t('finnishBody')}
                  name={'finnishBody'}
                  control={control}
                  error={errors.finnishBody}
                >
                  <Quill ref={quillFinishRef} />
                </SelectBicycleWrapper>
              </InputCol>
            </RowPadding>
          </TabPane>
        </TabsList>
        <Row justify="end">
          <SubmitButton htmlType="submit">{t('post')}</SubmitButton>
        </Row>
      </form>
    </Container>
  )
}
const Quill = styled.div`
  height: 85%;
`
const Container = styled.div`
  margin: 16px 24px;
  background-color: ${colors.WHITE};
  font-family: 'Roboto';
  font-style: normal;
  color: ${colors.BLACK_TR};
`
const Topbar = styled(Row)`
  padding: 16px 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 1px solid ${colors.HEADER_BORDER};
`
const TabsList = styled(Tabs)`
  margin: 16px 24px;
`
const InputCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const SubmitButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  background: ${colors.BLUE};
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: ${colors.WHITE};
`
const RowPadding = styled(Row)`
  padding: 0 0 50px 0;
`
const GreenSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${colors.BRAND_GREEN};
  }
`
