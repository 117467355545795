import { ContentPosition } from '../../../../store/admin/contents/interfaces'
import { ContentDetailsView } from '../../../../api/main'
import { useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { useEffect } from 'react'
import { getContentRequestAsync } from '../../../../store/admin/contents/get/asyncActions'
import { selectAdminContent } from '../../../../store/admin/contents/get'

export function useContent(): { content: ContentDetailsView; isFetchingContent: boolean } {
  const { contentPosition } = useParams<{ contentPosition: ContentPosition }>()
  const dispatch = useAppDispatch()
  const { data, is_fetching } = useAppSelector(selectAdminContent)

  useEffect(() => {
    dispatch(getContentRequestAsync(contentPosition))
  }, [contentPosition, dispatch])

  return {
    content: data,
    isFetchingContent: is_fetching,
  }
}
