import { createSlice } from '@reduxjs/toolkit'
import { adminDeletePostRequest } from './asyncActions'

import { RootState } from '../../../index'
import { ErrorResponse } from 'interfaces/Error'
import { SaveBlogVM } from 'api/main'

export interface DeletePostInterface extends SaveBlogVM {
  id: number
}

export interface AdminDeletePostState {
  is_fetching: boolean
  done: boolean
  errors: ErrorResponse
}

const initialState: AdminDeletePostState = {
  is_fetching: false,
  done: false,
  errors: null,
}

export const adminDeletePostSlice = createSlice({
  name: 'adminDeletePost',
  initialState,
  reducers: {
    resetState: state => {
      state.is_fetching = false
      state.done = false
      state.errors = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(adminDeletePostRequest.pending, state => {
        state.is_fetching = true
      })
      .addCase(adminDeletePostRequest.fulfilled, state => {
        state.is_fetching = false
        state.done = true
      })
      .addCase(adminDeletePostRequest.rejected, (state, action: any) => {
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = null
        }
      })
  },
})

export const selectAdminDeletePost = (state: RootState): any => state.admin.blog.delete
export const { resetState, resetError } = adminDeletePostSlice.actions

export default adminDeletePostSlice.reducer
