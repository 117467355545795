/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BannerDetailsView } from '../models/BannerDetailsView';
import type { BannerPageResponseView } from '../models/BannerPageResponseView';
import type { BannerPosition } from '../models/BannerPosition';
import type { SaveBannerVM } from '../models/SaveBannerVM';
import { request as __request } from '../core/request';

export class AdminBannerService {

    /**
     * Get list of banners
     * @param startDateFrom
     * @param startDateTo
     * @param endDateFrom
     * @param endDateTo
     * @param active
     * @param position
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns BannerPageResponseView OK
     * @throws ApiError
     */
    public static async adminBannerList(
        startDateFrom?: string,
        startDateTo?: string,
        endDateFrom?: string,
        endDateTo?: string,
        active?: boolean,
        position?: BannerPosition,
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<BannerPageResponseView> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/banners`,
            query: {
                'startDateFrom': startDateFrom,
                'startDateTo': startDateTo,
                'endDateFrom': endDateFrom,
                'endDateTo': endDateTo,
                'active': active,
                'position': position,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
        return result.body;
    }

    /**
     * Create Banner By Admin
     * @param requestBody
     * @returns BannerDetailsView OK
     * @throws ApiError
     */
    public static async adminBannerCreate(
        requestBody?: SaveBannerVM,
    ): Promise<BannerDetailsView> {
        const result = await __request({
            method: 'POST',
            path: `/api/administration/banners`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
        return result.body;
    }

    /**
     * Show banner
     * @param bannerId bannerId
     * @returns BannerDetailsView OK
     * @throws ApiError
     */
    public static async adminBannerShow(
        bannerId: number,
    ): Promise<BannerDetailsView> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/banners/${bannerId}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Delete banner
     * @param bannerId bannerId
     * @returns void
     * @throws ApiError
     */
    public static async adminBannerDelete(
        bannerId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/administration/banners/${bannerId}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Update banner
     * @param bannerId bannerId
     * @param requestBody
     * @returns BannerDetailsView OK
     * @throws ApiError
     */
    public static async adminBannerUpdate(
        bannerId: number,
        requestBody?: SaveBannerVM,
    ): Promise<BannerDetailsView> {
        const result = await __request({
            method: 'PUT',
            path: `/api/administration/banners/${bannerId}`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}