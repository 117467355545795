import { ActivateUserVM, AppAuthService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const activateUserRequestAsync: AsyncThunk<void, ActivateUserVM, any> = createAsyncThunk(
  'auth/activateUser',
  async (body: ActivateUserVM, { rejectWithValue }) => {
    try {
      return await AppAuthService.appAuthActivate(body)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
