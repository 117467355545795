import { combineReducers } from 'redux'
import getReducer from './get'
import createReducer from './create'
import createMedia from './media'
import getOwnReducer from './getown'
import singleBicycle from './getSingle'
import editBicycle from './edit'
import capacityReducer from './capacity'
import statusReducer from './status'
import topReducer from './getTop'

export const bicyclesReducers = combineReducers({
  get: getReducer,
  create: createReducer,
  media: createMedia,
  own: getOwnReducer,
  current: singleBicycle,
  edit: editBicycle,
  capacity: capacityReducer,
  status: statusReducer,
  top: topReducer,
})
