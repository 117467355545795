/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Banner Position
 */
export enum BannerPosition {
    HOME = 'HOME',
    BLOG = 'BLOG',
    BICYCLE = 'BICYCLE',
}