import { ColumnsType } from 'antd/lib/table/interface'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useEffect, useState } from 'react'
import { getAllContentRequestAsync } from '../../../store/admin/contents/getAll/asyncActions'
import { selectAdminContents } from '../../../store/admin/contents/getAll'
import { ContentListView } from '../../../api/main'
import * as React from 'react'
import { RenderedCell } from 'rc-table/lib/interface'
import { useTranslation } from 'react-i18next'

interface ContentDataTable {
  columns: ColumnsType<Record<string, unknown>>
  data: any
  changePage: (page: number) => void
  currentPage: number
  totalElements: number
  pageSize: number
}

export function useContentDataTable(
  action: (
    value: any,
    record: Record<string, unknown>,
    index: number
  ) => React.ReactNode | RenderedCell<ContentListView>
): ContentDataTable {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const contentsData = useAppSelector(selectAdminContents)
  const changePage = (page: number) => setCurrentPage(page)
  const pageSize = 10

  useEffect(() => {
    dispatch(
      getAllContentRequestAsync({
        page: currentPage,
        size: pageSize,
        sort: 'contentPosition,asc',
      })
    )
  }, [dispatch, currentPage, pageSize])

  return {
    columns: [
      {
        title: t('contentPosition'),
        dataIndex: 'contentPosition',
        render: value => t(value),
      },
      {
        title: t('action'),
        key: 'action',
        render: action,
      },
    ],
    data: contentsData.content.map(value => ({ ...value, key: value.id })),
    changePage,
    currentPage,
    totalElements: contentsData.totalElements,
    pageSize,
  }
}
