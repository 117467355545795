import { createSlice } from '@reduxjs/toolkit'
import { ErrorResponse } from 'interfaces/Error'
import { resetPasswordRequestAsync } from './asyncActions'

export interface ResetPasswordRequest {
  email: string
}

export enum ResetPasswordStatus {
  requestSuccess,
  requestFailed,
  none,
}

export interface ResetPasswordState {
  is_fetching: boolean
  status: ResetPasswordStatus
  errors: ErrorResponse
}

const initialState: ResetPasswordState = {
  is_fetching: false,
  status: ResetPasswordStatus.none,
  errors: null,
}

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    deleteStatus: state => {
      state.status = ResetPasswordStatus.none
    },
  },

  extraReducers: builder => {
    builder
      .addCase(resetPasswordRequestAsync.pending, state => {
        state.status = ResetPasswordStatus.none
        state.is_fetching = true
      })
      .addCase(resetPasswordRequestAsync.fulfilled, state => {
        state.is_fetching = false
        state.status = ResetPasswordStatus.requestSuccess
      })
      .addCase(resetPasswordRequestAsync.rejected, (state, { payload }) => {
        state.status = ResetPasswordStatus.requestFailed
        state.is_fetching = false
        state.errors = payload
      })
  },
})

export const { deleteStatus } = resetPasswordSlice.actions

export default resetPasswordSlice.reducer
