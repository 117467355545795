/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentDetailsView } from '../models/ContentDetailsView';
import { request as __request } from '../core/request';

export class AppContentService {

    /**
     * Show content
     * @param contentPosition contentPosition
     * @returns ContentDetailsView OK
     * @throws ApiError
     */
    public static async appContentShow(
        contentPosition: 'HOME_HEADING' | 'BLOG_HEADING' | 'BRANDS_HEADING' | 'SELL_HEADING',
    ): Promise<ContentDetailsView> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/contents/${contentPosition}`,
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

}