import { createSlice } from '@reduxjs/toolkit'
import { changePasswordRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { ErrorResponse } from 'interfaces/Error'

export interface ChangePasswordState {
  is_fetching: boolean
  done: boolean
  errors: ErrorResponse
}

const initialState: ChangePasswordState = {
  is_fetching: false,
  done: false,
  errors: null,
}

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    resetState: state => {
      state.is_fetching = false
      state.done = false
    },
  },

  extraReducers: builder => {
    builder
      .addCase(changePasswordRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(changePasswordRequestAsync.fulfilled, state => {
        state.is_fetching = false
        state.done = true
      })
      .addCase(changePasswordRequestAsync.rejected, (state, action: any) => {
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
          if (Object.keys(state.errors).length === 0) {
            throw 'Error'
          }
        } catch (e) {
          state.errors = {
            fieldErrors: [
              {
                message: 'incorrectPassword',
                field: 'passwordChange',
                objectName: 'passwordChange',
              },
            ],
          }
        }
      })
  },
})

export const selectChangePassword = (state: RootState): ChangePasswordState => state.auth.changePassword
export const { resetState } = changePasswordSlice.actions

export default changePasswordSlice.reducer
