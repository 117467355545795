import { AppContentService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { ContentPosition } from '../interfaces'

export const getContentRequestAsync: AsyncThunk<any, ContentPosition, any> = createAsyncThunk(
  'app/content/get',
  async (contentPosition: ContentPosition, { rejectWithValue }) => {
    try {
      return await AppContentService.appContentShow(contentPosition)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
