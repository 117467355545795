import { AdminBlogService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { EditPostInterface } from '.'

export const adminEditPostRequest: AsyncThunk<any, EditPostInterface, any> = createAsyncThunk(
  'admin/blog/edit',
  async (requestBody: EditPostInterface, { rejectWithValue }) => {
    try {
      return await AdminBlogService.adminBlogUpdate(requestBody.id, requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
