import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { getPostsRequestAsync } from './asyncActions'
import { ErrorResponse } from 'interfaces/Error'
import { BlogListView } from 'api/main'

export interface AdminPostsGetState {
  is_fetching: boolean
  data: BlogListView
  errors: ErrorResponse
}

const initialState: AdminPostsGetState = {
  is_fetching: false,
  data: null,
  errors: null,
}

export const getPostsSlice = createSlice({
  name: 'adminPost',
  initialState,
  reducers: {
    resetState: state => {
      state.is_fetching = false
      state.data = null
      state.errors = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getPostsRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(getPostsRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload
      })
      .addCase(getPostsRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.data = null
        state.is_fetching = false
      })
  },
})

export const selectAdminPost = (state: RootState): AdminPostsGetState => state.admin.blog.get
export const { resetState, resetError } = getPostsSlice.actions

export default getPostsSlice.reducer
