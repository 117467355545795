import { AdminBlogService, BlogPageResponseView } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { GetALlPostsAdminRequest } from '../Interfaces'

export const getAllPostsRequestAsync: AsyncThunk<BlogPageResponseView, GetALlPostsAdminRequest, any> = createAsyncThunk(
  'admin/bicycles/getAll',
  async (requestBody: GetALlPostsAdminRequest, { rejectWithValue }) => {
    try {
      return await AdminBlogService.adminBlogList(requestBody.keyword, requestBody.page - 1, requestBody.size)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
