/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateUserVM } from '../models/UpdateUserVM';
import { request as __request } from '../core/request';

export class AdminUserService {

    /**
     * Get list of users
     * @param keyword keyword
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns any OK
     * @throws ApiError
     */
    public static async adminUserList(
        keyword?: string,
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/users`,
            query: {
                'keyword': keyword,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Update user
     * @param userId userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async adminUserUpdate(
        userId: string,
        requestBody?: UpdateUserVM,
    ): Promise<any> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/administration/users/${userId}`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
        return result.body;
    }

}