import { Button, Col, Divider, message, Radio, Row } from 'antd'
import { colors } from 'constants/colors'
import React, { useEffect, useMemo, useState } from 'react'
import { FunctionComponent, useCallback } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ArrowLeftOutlined, PhoneOutlined } from '@ant-design/icons'
import {
  CustomTextArea,
  SelectBicycleInput,
  SelectBicycleItems,
  SelectBicycleWrapper,
} from '../../../Shared/Components/Components'
import styled from 'styled-components'
import {
  BicycleDetailsView,
  BicycleTransition,
  FeatureType,
  MediaNestedView,
  PostType,
  RequestReviewBicycleVM,
  SaveBicycleVMSaveFromAppView,
} from 'api/main'
import { Indicator } from 'Components/UiKit/Indicator'
import { MediaResponse, selectCreatedMedia, resetState as resetMediaState, addMedia } from 'store/bicycle/media'
import { BICYCLE_MEDIA_LABEL } from 'constants/index'
import { selectEditedBicycle, UpdateBicycleRequest, resetError, resetState } from 'store/bicycle/edit'
import { editBicycleRequest } from 'store/bicycle/edit/asyncActions'
import { getSingleBicycleRequestAsync } from 'store/bicycle/getSingle/asyncActions'
import { selectCurrentBicycle, resetState as resetBicycleState } from 'store/bicycle/getSingle'
import { OptionInterface } from './Options'
import { UploadImage } from '../UploadImage/UploadImage'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'
import {
  batteryLocation,
  bikeTypes,
  brakes,
  brands,
  cityCategory,
  colorsBikes,
  eBikesCategories,
  frameSizes,
  framesMaterial,
  gears,
  genders,
  hybridsCategory,
  kidsCategories,
  motors,
  mountainCategories,
  otherCategories,
  powers,
  roadCategories,
  suspensionTypes,
  tireSizes,
} from '../NewBicycle/Options'
import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import { ObjectShape } from 'yup/lib/object'
import * as Yup from 'yup'
import { IconWrapper } from '../../../Auth/Shared/TinyComponents/TinyComponents'
import { NotFound } from '../../../Shared/NotFound/NotFound'

const eBikeId = bikeTypes.find(item => item.name === 'E-bikes').id

interface ValueWithLang {
  id: number
  name: string
  finnishName: string
}

interface BicycleDetailsViewWithExtra extends BicycleDetailsView {
  category: ValueWithLang
  suspension: any
  whatsappNumber: string
  mainMedia: MediaNestedView
  bicycleBattery: any
}

export const EditBicycle: FunctionComponent = () => {
  const history = useHistory()
  const { t } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()
  const conditions = useMemo(
    () =>
      Object.entries(PostType).map(([key, value]) => {
        return {
          id: key,
          name: t(value),
          name_fi: t(value),
        } as OptionInterface
      }),
    [t]
  )
  const { errors: editBicycleErrors, done, is_fetching } = useAppSelector(selectEditedBicycle)
  const {
    errors: getBicycleErrors,
    data: bicycleWithoutType,
    getFetching,
    rejected: isRejected,
  } = useAppSelector(selectCurrentBicycle)
  const { data: mediaIds } = useAppSelector(selectCreatedMedia)
  const bicycle: BicycleDetailsViewWithExtra = bicycleWithoutType
  const newBicycleSchema = (t: TFunction): Yup.ObjectSchema<ObjectShape> =>
    Yup.object().shape({
      bicycleTypeId: Yup.number().required(t('required')),
      brakeId: Yup.number().required(t('required')),
      brandId: Yup.number().required(t('required')),
      categoryId: Yup.number().required(t('required')),
      colorId: Yup.number().required(t('required')),
      deliveryInstructions: Yup.string().nullable(),
      description: Yup.string().required(t('required')),
      frameMaterialId: Yup.number(),
      frameSizeId: Yup.number().required(t('required')),
      gearId: Yup.number().required(t('required')),
      genderId: Yup.number().required(t('required')),
      model: Yup.string().nullable(),
      postType: Yup.string().required(t('required')),
      price: Yup.string().required(t('required')),
      suspensionId: Yup.string().nullable(),
      tireId: Yup.number().required(t('required')),
      year: Yup.string().required(t('required')),
      mainMediaId: Yup.number().nullable(),
      whatsappNumber: Yup.string()
        .min(7, t('invalidPhone'))
        .matches(/^[1-9][0-9]*$/, t('invalidPhone')),
      batteryLocationId: Yup.number().when('bicycleTypeId', {
        is: (val: number) => val === eBikeId,
        then: Yup.number().required('Required'),
      }),
      motorId: Yup.number().when('bicycleTypeId', {
        is: (val: number) => val === eBikeId,
        then: Yup.number().required('Required'),
      }),
      powerId: Yup.number().when('bicycleTypeId', {
        is: (val: number) => val === eBikeId,
        then: Yup.number().required('Required'),
      }),
    })
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SaveBicycleVMSaveFromAppView>({
    resolver: yupResolver(newBicycleSchema(t)),
  })

  useEffect(() => {
    if (bicycle) {
      const defaultValues = {
        bicycleTypeId: bicycle?.bicycleType.id,
        brakeId: bicycle?.brake.id,
        ownerId: bicycle?.owner.id,
        brandId: bicycle?.brand.id,
        colorId: bicycle?.color.id,
        batteryLocationId: bicycle?.bicycleBattery?.batteryLocation?.id,
        motorId: bicycle?.bicycleBattery?.motor?.id,
        powerId: bicycle?.bicycleBattery?.power?.id,
        deliveryInstructions: bicycle?.deliveryInstructions,
        description: bicycle?.description,
        featureType: bicycle?.featureType,
        frameMaterialId: bicycle?.frameMaterial?.id,
        frameSizeId: bicycle?.frameSize.id,
        gearId: bicycle?.gear.id,
        genderId: bicycle?.gender.id,
        model: bicycle?.model,
        postType: bicycle?.postType,
        price: bicycle?.price,
        suspensionId: bicycle?.suspension?.id,
        tireId: bicycle?.tire.id,
        year: bicycle?.year,
        whatsappNumber: bicycle?.whatsappNumber?.slice(4),
        categoryId: bicycle?.category?.id,
        mainMediaId: bicycle?.mainMedia?.id,
      }
      reset(defaultValues)
    }
  }, [bicycle, dispatch, reset])
  useEffect(() => {
    return () => {
      dispatch(resetBicycleState())
    }
  }, [dispatch])
  const [showEbikeOptions, setShowEbikeOptions] = useState(false)
  useEffect(() => {
    const subscription = watch(value => {
      value.bicycleTypeId === eBikeId ? setShowEbikeOptions(true) : setShowEbikeOptions(false)
    })
    return () => subscription.unsubscribe()
  }, [watch])
  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  useEffect(() => {
    if (editBicycleErrors) {
      editBicycleErrors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
      dispatch(resetError())
    }
    if (getBicycleErrors) {
      getBicycleErrors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
      dispatch(resetError())
    }
  }, [editBicycleErrors, dispatch, getBicycleErrors])

  useEffect(() => {
    if (done && !editBicycleErrors) {
      message.success(t('bicycleIsUpdated'))
      history.push('/admin/bicycles')
    }
    return () => {
      dispatch(resetState())
      dispatch(resetMediaState())
    }
  }, [dispatch, done, history, editBicycleErrors, t])

  useEffect(() => {
    dispatch(getSingleBicycleRequestAsync(+id))

    return () => {
      dispatch(resetState())
    }
  }, [dispatch, id])
  const watchbicycleTypeId = watch('bicycleTypeId')
  const categories = useMemo(() => {
    switch (watchbicycleTypeId) {
      case 1:
        return cityCategory
      case 2:
        return hybridsCategory
      case 3:
        return mountainCategories
      case 4:
        return eBikesCategories
      case 5:
        return kidsCategories
      case 6:
        return roadCategories
      case 7:
        return otherCategories
      default:
        return []
    }
  }, [watchbicycleTypeId])

  const [isBicycleMediasCopied, setIsBicycleMediasCopied] = useState(false)
  useEffect(() => {
    if (bicycle && !isBicycleMediasCopied) {
      bicycle.medias.forEach(media => {
        dispatch(addMedia(media))
      })
      setIsBicycleMediasCopied(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bicycle])
  useEffect(() => {
    if (mediaIds.length === 0) {
      setValue('mainMediaId', null)
    }
  }, [mediaIds, setValue])
  const mainMediaId = watch('mainMediaId')

  const onSubmit = handleSubmit((data: RequestReviewBicycleVM & { whatsappNumber: string }) => {
    let mediaIdList: any[] = []
    if (data.whatsappNumber) data.whatsappNumber = '+358' + data.whatsappNumber
    if (mediaIds && mediaIds.length > 0) mediaIdList = [...mediaIdList, ...mediaIds]
    mediaIdList = mediaIdList.filter(item => item !== null)
    const request: UpdateBicycleRequest = {
      ...data,
      transition: BicycleTransition.REQUEST_REVIEW,
      mediaIds: mediaIdList.map((mediaID: MediaResponse) => mediaID.id),
      id: +id,
    }
    dispatch(editBicycleRequest(request))
  })

  const imageSrc = useMemo(() => {
    return mediaIds.find((item: { id: number }) => item.id === mainMediaId)?.url
  }, [mediaIds, mainMediaId])

  return (
    <Background>
      <HelmetWrapper parentData={{ titleKey: 'editBicycle' }} />
      {(is_fetching || getFetching) && <Indicator />}
      {bicycle ? (
        <Container>
          <form onSubmit={onSubmit}>
            <Row justify="space-between">
              <Col>
                <BackButton onClick={goBack} icon={<ArrowLeftOutlined />} />
                <H1>{t('editBicycle')}</H1>
              </Col>
            </Row>
            <Divider />
            <Row justify="space-between" gutter={20}>
              <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
                <SelectBicycleItems
                  control={control}
                  required
                  label={t('condition')}
                  options={conditions}
                  error={errors.postType}
                  name={'postType'}
                />
                <SelectBicycleWrapper
                  label={t('description')}
                  required
                  name={'description'}
                  control={control}
                  error={errors.description}
                >
                  <CustomTextArea />
                </SelectBicycleWrapper>
                <SelectBicycleWrapper
                  label={t('deliveryInstruction')}
                  name={'deliveryInstructions'}
                  control={control}
                  error={errors.deliveryInstructions}
                >
                  <CustomTextArea />
                </SelectBicycleWrapper>
                <SelectBicycleWrapper
                  label={t('featureIn')}
                  name={'featureType'}
                  control={control}
                  error={errors.featureType}
                >
                  <Radio.Group>
                    <Radio value={FeatureType.TOP_FIVE}>{t('top5Euro')}</Radio>
                    <Radio value={FeatureType.TOP_TEN}>{t('top10Euro')}</Radio>
                  </Radio.Group>
                </SelectBicycleWrapper>
                {isBicycleMediasCopied ? (
                  <>
                    <UploadImage
                      hint={t('bicycleSizeHint')}
                      targetType={BICYCLE_MEDIA_LABEL}
                      images={mediaIds.map((media: { id: any }) => {
                        return { ...media, uid: media.id }
                      })}
                    />
                    <SelectBicycleItems
                      label={t('mainMediaId')}
                      options={mediaIds.map((media: { id: any; url: string }) => {
                        return {
                          id: media.id,
                          name: media.url.split('/').pop(),
                          name_fi: media.url.split('/').pop(),
                        } as OptionInterface
                      })}
                      control={control}
                      error={errors.mainMediaId}
                      name={'mainMediaId'}
                    />
                    {imageSrc && <MainImage alt="" src={imageSrc} />}
                  </>
                ) : null}
              </InputCol>
              <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
                <SelectBicycleItems
                  label={t('productType')}
                  options={bikeTypes}
                  required
                  control={control}
                  error={errors.bicycleTypeId}
                  name={'bicycleTypeId'}
                />
                <SelectBicycleItems
                  label={t('category')}
                  options={categories}
                  required
                  control={control}
                  error={errors.categoryId}
                  name={'categoryId'}
                />
                {showEbikeOptions && (
                  <>
                    <SelectBicycleItems
                      label={t('batteryLocation')}
                      options={batteryLocation}
                      required
                      control={control}
                      error={errors.batteryLocationId}
                      name={'batteryLocationId'}
                    />
                    <SelectBicycleItems
                      label={t('motor')}
                      required
                      options={motors}
                      control={control}
                      error={errors.motorId}
                      name={'motorId'}
                    />
                    <SelectBicycleItems
                      label={t('powers')}
                      required
                      options={powers}
                      control={control}
                      error={errors.powerId}
                      name={'powerId'}
                    />
                  </>
                )}
                <SelectBicycleItems
                  label={t('gender')}
                  required
                  options={genders}
                  error={errors.genderId}
                  name={'genderId'}
                  control={control}
                />
                <SelectBicycleItems
                  label={t('brand')}
                  options={brands}
                  required
                  error={errors.brandId}
                  name={'brandId'}
                  control={control}
                />
                <SelectBicycleInput label={t('model')} name={'model'} control={control} error={errors.model} />
                <SelectBicycleInput label={t('year')} required name={'year'} control={control} error={errors.year} />
                <SelectBicycleInput label={t('price')} required name={'price'} control={control} error={errors.price} />
              </InputCol>
              <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
                <SelectBicycleItems
                  label={t('tireSize')}
                  required
                  options={tireSizes}
                  error={errors.tireId}
                  name={'tireId'}
                  control={control}
                />
                <SelectBicycleItems
                  label={t('frameSize')}
                  options={frameSizes}
                  required
                  error={errors.frameSizeId}
                  name={'frameSizeId'}
                  control={control}
                />
                <SelectBicycleItems
                  label={t('gears')}
                  options={gears}
                  required
                  error={errors.gearId}
                  name={'gearId'}
                  control={control}
                />
                <SelectBicycleItems
                  label={t('framesMaterial')}
                  options={framesMaterial}
                  error={errors.frameMaterialId}
                  name={'frameMaterialId'}
                  control={control}
                />
                <SelectBicycleItems
                  label={t('brakes')}
                  options={brakes}
                  required
                  error={errors.brakeId}
                  name={'brakeId'}
                  control={control}
                />
                <SelectBicycleItems
                  label={t('colors')}
                  options={colorsBikes}
                  error={errors.colorId}
                  required
                  name={'colorId'}
                  control={control}
                />
                <SelectBicycleItems
                  label={t('suspension')}
                  // isMulti
                  options={suspensionTypes}
                  error={errors.suspensionId}
                  name={'suspensionId'}
                  control={control}
                />
                <SelectBicycleInput
                  label={t('whatsappNumber')}
                  name={'whatsappNumber'}
                  control={control}
                  error={errors.whatsappNumber}
                  prefix={
                    <IconWrapper>
                      <PhoneOutlined />
                      {t('phonePrefix')}
                    </IconWrapper>
                  }
                />
              </InputCol>
            </Row>
            <Row justify="end">
              <RequestButton htmlType="submit">{t('update')}</RequestButton>
            </Row>
          </form>
        </Container>
      ) : (
        <NotFound isRejected={isRejected} />
      )}
    </Background>
  )
}

const Background = styled.div`
  background-color: ${colors.SELL_BACKGROUND};
`
const MainImage = styled.img`
  border: 3px dashed gray;
`
const Container = styled.div`
  font-family: 'Roboto', 'Poppins';
  line-height: 28px;
  font-style: normal;
  background-color: ${colors.WHITE};
  padding: 16px;
`
const InputCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const RequestButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  background: ${colors.BLUE};
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: ${colors.WHITE};
`
const H1 = styled.h1`
  font-weight: bold;
  font-size: 20px;
  color: ${colors.BLACK_TR};
  display: inline-block;
`
const BackButton = styled(Button)`
  background: ${colors.WHITE};
  border: none;
`
