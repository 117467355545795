import { Button, Input, message, Pagination, Row, Space, Table } from 'antd'
import { colors } from 'constants/colors'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AdminPageTitle } from 'Components/Admin/Shared/Components/Components'
import { PlusOutlined, PaperClipOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Indicator } from 'Components/UiKit/Indicator'
import { FieldError } from 'interfaces/Error'
import { ellipsis, formatDate } from 'constants/functions'
import { selectAdminNewsletters } from 'store/admin/newsletter/getAll'
import { getAllNewsletterRequestAsync } from 'store/admin/newsletter/getAll/asyncActions'
import { GetALlNewslettersAdminRequest } from 'store/admin/newsletter/Interfaces'
import { ADMIN_NEWSLETTER_PAGE_SIZE } from 'constants/index'
import { Emails, MediaNestedView, NewsletterListView } from 'api/main'
import { useHistory } from 'react-router'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

export const NewsletterList: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const [pageNumber, setPageNumber] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const { is_fetching, data, errors, totalElements } = useAppSelector(selectAdminNewsletters)
  const newsletters: NewsletterListView[] = data

  const changePage = (page: number) => setPageNumber(page)
  const userActivateIsFetching = false
  const history = useHistory()

  useEffect(() => {
    if (errors) {
      errors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }
  }, [errors, dispatch])

  const columns = useMemo(
    () => [
      {
        title: t('title'),
        dataIndex: 'title',
        width: 400,
        render: (content: string) => ellipsis(content, 300),
      },
      {
        title: t('receiversEmail'),
        dataIndex: 'emails',
        width: 400,
        render: (content: Emails) => ellipsis(content?.join(','), 150),
      },
      {
        title: t('startDate'),
        dataIndex: 'created',
        render: (date: string) => formatDate(date),
      },
      {
        title: t('attachedFile'),
        dataIndex: 'media',
        render: (media: MediaNestedView) => (
          <a href={media.url}>
            <PaperClipOutlined /> {media.cloudId}
          </a>
        ),
      },
    ],
    [t]
  )
  useEffect(() => {
    const request: GetALlNewslettersAdminRequest = {
      page: pageNumber,
      size: ADMIN_NEWSLETTER_PAGE_SIZE,
      keyword: searchQuery,
    }
    dispatch(getAllNewsletterRequestAsync(request))
  }, [pageNumber, searchQuery, dispatch])

  const onSearch = (query: string) => {
    setSearchQuery(query)
  }

  const gotoNewEmail = () => history.push('/admin/new-email')

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'newsletter' }} />
      <ActionBar>
        <Row justify="space-between">
          <AdminPageTitle>{t('newsletter')}</AdminPageTitle>
          <Space>
            <Input.Search
              placeholder={t('searchByUserOrModel')}
              onSearch={onSearch}
              className={'three-hundred-px-width'}
            />
            <Button icon={<PlusOutlined />} type="primary" onClick={gotoNewEmail}>
              {t('newEmail')}
            </Button>
          </Space>
        </Row>
      </ActionBar>
      {(is_fetching || userActivateIsFetching) && <Indicator />}
      <Table
        expandable={{
          expandedRowRender: (record: NewsletterListView) => <p className={'zero-margin'}>{record.emails.join(',')}</p>,
          rowExpandable: record => record.emails.length < 4,
        }}
        columns={columns}
        dataSource={newsletters}
        pagination={false}
      />
      <Row justify="end">
        <Pagination defaultCurrent={1} onChange={changePage} current={pageNumber} total={totalElements} />
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 24px;
`
const ActionBar = styled.div`
  padding: 16px 24px;
  background: ${colors.WHITE};
`
