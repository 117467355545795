/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Transition
 */
export enum BicycleTransition {
    ACTIVATE = 'ACTIVATE',
    APPROVE = 'APPROVE',
    DEACTIVATE = 'DEACTIVATE',
    EXPIRE = 'EXPIRE',
    MARK_AS_FOUND = 'MARK_AS_FOUND',
    MARK_AS_SOLD = 'MARK_AS_SOLD',
    MARK_AS_RENTED = 'MARK_AS_RENTED',
    MARK_AS_LEASED = 'MARK_AS_LEASED',
    REJECT = 'REJECT',
    REQUEST_REVIEW = 'REQUEST_REVIEW',
}