import { combineReducers } from 'redux'
import loginReducer from './login'
import resetPasswordReducer from './reset-password'
import setPasswordReducer from './set-password'
import registerReducer from './register'
import updateReducer from './update'
import activateUserReducer from './activate'
import meReducer from './me'
import cityReducer from './city'
import changePasswordReducer from './change-password'
import languageReducer from './language'

export const authReducers = combineReducers({
  auth: loginReducer,
  resetPassword: resetPasswordReducer,
  setPassword: setPasswordReducer,
  register: registerReducer,
  update: updateReducer,
  activateUser: activateUserReducer,
  me: meReducer,
  city: cityReducer,
  changePassword: changePasswordReducer,
  language: languageReducer,
})
