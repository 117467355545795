import { AdminBannerService, BannerPageResponseView } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { GetALlBannersAdminRequest } from '../Interfaces'

export const getAllBannerRequestAsync: AsyncThunk<BannerPageResponseView, GetALlBannersAdminRequest, any> =
  createAsyncThunk('admin/banner/getAll', async (request: GetALlBannersAdminRequest, { rejectWithValue }) => {
    try {
      return await AdminBannerService.adminBannerList(
        request.startDateFrom,
        request.startDateTo,
        request.endDateFrom,
        request.endDateTo,
        request.active,
        request.position,
        request.page - 1,
        request.size,
        request.sort
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  })
