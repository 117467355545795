import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors } from 'constants/colors'

interface Props {
  image: string
  priceForDay: string
  priceForWeek: string
}

export const SellCol: FunctionComponent<Props> = ({ image, priceForDay, priceForWeek }) => {
  const { t } = useTranslation<string>()
  return (
    <ColMaxWidth>
      <Row>
        <img alt={t('buyANotice')} src={image} />
      </Row>
      <RowStart justify="start">{t('buyANotice')}</RowStart>
      <br />
      <Row>
        <Bold>
          {priceForDay} {t('EUR')}
        </Bold>
        <PriceText>{t('aDay')} </PriceText>
        <Or>{t('or')} </Or>
        <Bold>
          {priceForWeek} {t('EUR')}
        </Bold>
        <PriceText>{t('aWeek')}</PriceText>
      </Row>
    </ColMaxWidth>
  )
}

const PriceText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: ${colors.GRAY_TEXT};
  white-space: pre;
  margin-left: 5px;
`

const Bold = styled(PriceText)`
  font-weight: 600;
`
const Or = styled(PriceText)`
  font-weight: 500;
  color: ${colors.BRAND_GREEN};
`
const ColMaxWidth = styled(Col)`
  width: 396px;
`
const RowStart = styled(Row)`
  text-align: start;
`
