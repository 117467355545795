import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { paths as authPaths } from './Auth'
import { LoginGuardRoute } from './Auth/guard'
import MainRoutes, { paths as mainPaths } from './Main/index'
import { paths as adminPaths } from './Admin/index'
import { AdminGuardRoute } from './Admin/guard'
import { PaymentComponent } from 'Components/Payment/Payment'
import { NotFound } from '../Components/Shared/NotFound/NotFound'

const AuthRoutes = lazy(() => import('./Auth'))
const AdminRoutes = lazy(() => import('./Admin'))

const Routes: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route key={'successful'} exact={true} path={'/successful'}>
          <PaymentComponent isSuccessful={true} />
        </Route>
        <Route key={'failed'} exact={true} path={'/failed'}>
          <PaymentComponent isSuccessful={false} />
        </Route>
        <Route exact path="/">
          <Redirect to={{ pathname: '/home' }} />
        </Route>
        <Route exact path="/admin">
          <Redirect to={{ pathname: '/admin/users' }} />
        </Route>
        <AdminGuardRoute path={adminPaths}>
          <AdminRoutes />
        </AdminGuardRoute>
        <Route path={mainPaths}>
          <MainRoutes />
        </Route>
        <LoginGuardRoute path={authPaths}>
          <AuthRoutes />
        </LoginGuardRoute>
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
}

export default Routes
