import { createSlice } from '@reduxjs/toolkit'
import { getSingleBicycleRequestAsync } from './asyncActions'
import { RootState } from '../../index'
import { ErrorResponse } from 'interfaces/Error'
import { BicycleDetailsView, MediaNestedView } from 'api/main'

export interface BicycleState {
  is_fetching: boolean
  data: BicycleDetailsView & { whatsappNumber: string; mainMedia: MediaNestedView }
  errors: ErrorResponse
  rejected: boolean
}

const initialState: BicycleState = {
  is_fetching: false,
  data: null,
  errors: null,
  rejected: false,
}

export const bicycleSlice = createSlice({
  name: 'bicycle',
  initialState,
  reducers: {
    resetState: state => {
      state.data = null
      state.errors = null
      state.is_fetching = null
      state.rejected = false
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getSingleBicycleRequestAsync.pending, state => {
        state.is_fetching = true
        state.rejected = false
      })
      .addCase(getSingleBicycleRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.rejected = false
        state.data = payload
      })
      .addCase(getSingleBicycleRequestAsync.rejected, (state, action: any) => {
        state.rejected = true
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
      })
  },
})

export const { resetState } = bicycleSlice.actions
export const selectCurrentBicycle = (state: RootState): any => state.bicycles.current

export default bicycleSlice.reducer
