/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BicycleStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    FOUND = 'FOUND',
    INACTIVE = 'INACTIVE',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    SOLD = 'SOLD',
    RENTED = 'RENTED',
    LEASED = 'LEASED',
}