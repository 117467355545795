import { AppAuthService, ForgetPasswordVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const resetPasswordRequestAsync: AsyncThunk<void, ForgetPasswordVM, any> = createAsyncThunk(
  'auth/resetPasswordRequest',
  async (body: ForgetPasswordVM, { rejectWithValue }) => {
    try {
      return await AppAuthService.appAuthForgetPassword({ email: body.email })
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
