import { OpenAPI } from 'api/main'
import { API_BASE_URL } from 'constants/index'
import { getToken } from 'utils/storage'

export const apiConfig = async (): Promise<void> => {
  OpenAPI.BASE = API_BASE_URL
  OpenAPI.TOKEN = async (): Promise<string | null> => {
    return getToken()
  }
}
