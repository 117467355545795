import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { BlogListRequest } from '.'
import { AppBlogService, BlogPageResponseView } from 'api/main'
import { BLOG_SORT_QUERY } from 'constants/index'

export const getBlogPostsRequestAsync: AsyncThunk<BlogPageResponseView, BlogListRequest, any> = createAsyncThunk(
  'blog/get',
  async (query: BlogListRequest, { rejectWithValue }) => {
    try {
      return AppBlogService.appBlogList(query.keyword, query.page - 1, query.size, BLOG_SORT_QUERY)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
