const en = Object.freeze({
  hello: 'Hello',
  login: 'Login',
  required: 'Required',
  invalidEmail: 'Invalid email',
  tooShort: 'Too Short!',
  tooLong: 'Too Long!',
  submit: 'Submit',
  email: 'Email',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  or: 'or',
  loginWith: 'Login with:',
  mainMediaId: 'Main Media',
  whatsappNumber: 'WhatsApp',
  signUp: 'Sign Up',
  newHere: 'New here?',
  admin: 'Admin',
  user: 'User',
  firstName: 'First name',
  lastName: 'Last name',
  signUpWith: 'Sign Up with:',
  PleaseEnterYourRegisteredEmailSoWeCanSendYouACodeToResetYourPassword:
    'Please, enter your registered email, so we can send you a code to reset your password.',
  resetLinkSent: 'Reset password request is sent. check your inbox',
  resetPassword: 'Reset Password',
  pleaseEnterAndConfirmYourNewPassword: 'Please, enter and confirm your new password.',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  confirm: 'Confirm',
  invalidToken: 'Token is invalid',
  welcomeToNettiFillari: 'Welcome to NettiFillari',
  slogan: 'Creating bike selling posts and searching for bikes is free for you',
  passwordMatch: 'Password and confirm password does not match',
  invalidCredentials: 'Username or password is wrong',
  brands: 'Brands',
  sellOnNet: 'Sell on NettiFillari',
  blog: 'Blog',
  backToBrands: 'Back to brands',
  sortBy: 'Sort by',
  filters: 'Filters',
  men: 'Men',
  women: 'Women',
  unisex: 'Unisex',
  kids: 'Kids and juniors',
  postedOn: 'Posted on:',
  noBic: 'Not bicycle has found',
  productType: 'Product Type',
  city: 'City',
  hybrid: 'Hybrid',
  mountain: 'Mountain',
  readAndGravel: 'Read and Gravel',
  ebike: 'E-Bikes',
  kidsAndJuniors: 'Kids & Juniors',
  other: 'Other',
  bsa: 'BSA',
  bsaLadyBird: 'BSA Ladybird',
  hercules: 'Hercules',
  machCity: 'Mach City',
  montra: 'Montra',
  juniors: 'Juniors',
  xs14: 'XS (14‴)',
  s16: 'S (16‴)',
  m18: 'M (18‴)',
  aluminum: 'Aluminum',
  carbon: 'Carbon',
  steel: 'Steel',
  foot: 'Foot',
  footAndFront: 'Foot & Front',
  disc: 'Disc',
  vBrak: 'V',
  red: 'Red',
  golden: 'Golden',
  blue: 'Blue',
  purple: 'Purple',
  loadMore: 'Load more',
  framesMaterial: 'Frames Material',
  BecomeSellerOnNettiFillari: 'Become a seller on NettiFillari',
  startSelling: 'START SELLING',
  SellerTopQuestion: 'Sell your used bicycle easily online on NettiFillari',
  whoCanUse: 'What type of bikes can be sold on NettiFillari?',
  youCanSearch: 'You can search for your stolen bike on NettiFillari!',
  longTextSell: `If you get such bad luck that your bike is stolen, you can post pictures and badges of it in Nettifillar’s open Stolen Bikes section. From here, anyone buying a used bike can easily check if the bike they bought have been stolen or not.

  The announcement is posted in the same way as for tha purpose of selling a bike, but with the difference that there will be no price for it, and you will have to mentioned the place, from where your bike has been stolen from. 
  
  As soon as the bike is found (we truly hope so), you can take your announcement away, and volunteer to leave tips on our system from 4 to 10 EUR.
  
  `,
  weOfferNoCommission: 'We offer you our platform with NO commission fee',
  termsAndConditions: 'Terms & Conditions',
  privacyAndPolicy: 'Privacy Policy',
  contactUs: 'Contact Us',
  noPostFound: 'No post is found',
  verification: 'Verification',
  privacyPolicy: 'Privacy Policy',
  privacyPolicyBody: `
   <p>
    <strong>1 Yleistä</strong>
   </p>
<p>
    NettiFillari Oy on sitoutunut huolehtimaan sen hallussa olevien
    henkilötietojen luottamuksellisuudesta ja tietosuojasta. Tämä
    tietosuojaseloste soveltuu henkilötietoihin, joita keräämme verkkosivujen
    vierailijoista. Lisätietoja henkilötietojen käsittelystä saat:
    asiakaspalvelu@nettifillari.fi
</p>
<p>
    <strong>2 Rekisterinpitäjä</strong>
</p>
<p>
    Nimi: NettiFillari Oy
</p>
<p>
    Osoite: c/o Angelma&amp;Mäkelä
</p>
<p>
    Eteläkatu 14 C 3, 13100 Hämeenlinna
</p>
<p>
    Y-tunnus: 3234194-2
</p>
<p>
    <strong>3 Mitä henkilötietojasi kerätään ja miten?</strong>
</p>
<p>
    Keräämme tietoja kun käytät nettifillari.fi verkkosivuja ja/tai käytät
    palveluitamme. Näihin henkilötietoihin sisältyvät tiedot koskien
    verkkosivuston hyödyntämistä ja sen sisältöä, selaimesi lähettämät tekniset
    tiedot (esim. IP-osoite, selain, selainversio, verkkosivu) sekä evästeet,
    jotka lähetetään selaimellesi ja näihin liittyvät tiedot. (ks.
    Evästeet-osio)
</p>
<ul>
    <li>Nimi</li>
    <li>Osoite</li>
    <li>Sähköpostiosoite</li>
    <li>Puhelinnumero</li>
    <li>Laskutustiedot</li>
    <li>Asiakkaan ilmoittamat asiakassuhteen hoitoon ja/tai markkinointiin
        liittyvät tiedot</li>
</ul>
<p>
    <strong>4 Henkilötietojesi käsittelyn peruste ja käyttötarkoitukset</strong>
</p>
<p>
    NettiFillari Oy voi lähettää sinulle myös sähköisiä
    suoramarkkinointiviestejä, jos olet kirjautunut uutiskirjeen tilaajaksi tai
    tilannut meiltä palveluita. Emme lähetä suoramarkkinointiviestejä muille.
    Henkilötietoja käytetään asiakassuhteen hoitamiseen ja kehittämiseen,
    laskutukseen ja maksujen perintään, markkinointiin sekä tilastollisiin
    tarkoituksiin. NettiFillari saattaa välittää asiakasrekisterin tietoja
    yhteistyökumppaneille. Asiakkaalla on oikeus kieltää tietojen välittäminen
    yhteistyökumppaneille ilmoittamalla siitä NettiFillarille.
</p>
<p>
    Käsittelemme henkilötietojasi voidaksemme:
</p>
<ul>
    <li>käsitellä pyyntöjäsi ja vastata niihin;</li>
    <li>näyttää verkkosivustonsa sisällön sopivalla tavalla laitteessasi;</li>
    <li>seurata verkkosivustonsa käyttöä ja parantaa sivustonsa toimintoja sekä
        käyttökokemusta;
    </li>
    <li>seurata markkinointikampanjoidensa tehokuutta;
    </li>
    <li>mainostaa ja tarjota palvelujaan sinulle voimassaolevan lainsäädännön
        sallimissa rajoissa; ja parantaa asiakasviestintäänsä ja tunnistaa
        mahdolliset asiakkaat.
    </li>
</ul>
<p>
    Emme käsittele henkilötietojasi muihin kuin tässä tietosuojaselosteessa
    kuvattuihin tarkoituksiin.
</p>
<p>
    <strong>5 Henkilötietojesi säännönmukaiset luovutukset ja siirrot kolmansille
        osapuolille
    </strong>
</p>
<p>
    Käsittelemme henkilötietojasi vain tässä tietosuojaselosteessa määriteltyjä
    tarkoituksia varten.
</p>
<p>
    <strong>
        6 Henkilötietojesi siirrot EU:n tai Euroopan talousalueen ulkopuolelle
    </strong>
</p>
<p>
    Pääsääntöisesti emme siirrä henkilötietojasi EU:n tai ETA:n ulkopuolelle.
    Tietoja voidaan kuitenkin siirtää GDPR asetusten sallimissa rajoissa EU:n
    tai ETA:n ulkopuolelle.
    <br/>
    <br/>
    <strong>7 Henkilötietojen säilyttämisen periaatteet</strong>
</p>
<p>
    Säilytämme henkilötietojasi tämän tietosuojaselosteen mukaisten
    käsittelytarkoitusten vaatiman ajan.
</p>
<p>
    <strong>8 Henkilötietojesi käsittelyyn liittyvät oikeudet</strong>
</p>
<p>
    Sinulla on oikeus koska tahansa vastustaa henkilötietojesi käsittelyä
    suoramarkkinointitarkoituksiin.
</p>
<p>
    Lisäksi sinulla on oikeus, soveltuvan tietosuojalainsäädännön mukaisesti,
    milloin tahansa:
</p>
<ul>
    <li>saada tieto henkilötietojesi käsittelystä;
    </li>
    <li>saada pääsy omiin tietoihisi ja tarkastaa itseäsi koskevat
        henkilötietosi;
    </li>
    <li>vaatia epätarkan ja virheellisen henkilötietosi oikaisua sekä tietojesi
        täydentämistä;
    </li>
    <li>peruuttaa suostumuksesi ja vastustaa henkilötietojesi käsittelyä siltä
        osin, kuin henkilötietojesi käsittely perustuu antamaasi suostumukseen;
    </li>
    <li>vastustaa henkilötietojesi käsittelyä henkilökohtaiseen erityiseen
        tilanteeseesi liittyvällä perusteella
    </li>
</ul>
<p>
    Sinun tulee esittää yllä mainitun oikeuden toteuttamista koskeva pyyntösi
    NettiFillari Oy:lle tämän tietosuojaselosteen mukaisesti. Voimme pyytää
    sinua tarkentamaan pyyntöäsi kirjallisesti ja varmentamaan
    henkilöllisyytesi ennen pyynnön käsittelemistä.
</p>
<p>
    <strong>9 Rekisterin suojauksen periaatteet</strong>
</p>
<p>
    Kunnioitamme henkilötietojesi luottamuksellisuutta. Digitaalisesti
    käsiteltävät henkilötiedot on suojattu ja tallennettu järjestelmiin, johon
    on rajattu pääsy vain sellaisilla henkilöillä, jotka tarvitsevat kyseisiä
    tietoja työtehtäviensä hoitamiseksi. Tietojärjestelmät ja tiedostot on
    suojattu yritystoiminnassa normaalisti käytössä olevilla teknisillä
    suojausmenetelmillä. Rekisterin käyttöoikeus edellyttää henkilökohtaista
    käyttäjätunnusta ja salasanaa, jotka myönnetään vain rekisterinpitäjän
    henkilökuntaan kuuluvalle, jonka asemaan ja tehtäviin mainittu käyttöoikeus
    liittyy.
</p>
<p>
    <strong>
        10 Evästeet
        <br/>
        <br/>
    </strong>
    Eväste on yleisesti käytetty pieni tekstitiedosto, jonka internetselain
    tallentaa tietokoneellesi tai muulle päätelaitteellesi, kun vierailet
    verkkosivulla. Selain lähettää tiedon vierailustasi takaisin verkkosivulle,
    kun vierailet siellä uudestaan. Kaikki nykyaikaiset verkkosivut käyttävät
    evästeitä tarjotakseen sinulle personoidumman selainkokemuksen. Evästeitä
    tarvitaan esimerkiksi siihen, kun teet ostoksia verkkokaupassa, ostoskori
    muistaa evästeiden avulla, mitä olet koriin laittanut.
    <br/>
    <br/>
</p>
<p>
    Kukin eväste on erikseen asetettu kullekin päätelaitteellesi ja evästeitä
    voi lukea ainoastaan sillä palvelimella, jolla eväste on asetettu. Koska
    eväste on sidottu selaimeen, eikä lähtökohtaisesti ole jaettavissa eri
    selainten tai laitteiden välillä (ellei jokin selain, lisäosa tai muu
    sovellus erikseen mahdollista tätä), evästeiden hallinnoimiseksi tekemät
    valintasi soveltuvat lähtökohtaisesti vain kyseiseen yksittäiseen
    selaimeen. Eväste ei voi ajaa ohjelmistoja, eikä sitä voida käyttää
    virusten tai muun haitallisen koodin toimittamiseen, eikä vahingoittamaan
    päätelaitettasi tai tiedostojasi. Yksittäistä käyttäjää ei voida tunnistaa
    pelkästään evästeen tai vastaavien teknologioiden käytön kautta.
    <br/>
    <br/>
</p>
<p>
    Sivustollamme käytetään Google Analytics -palvelua, joka on Googlen
    verkkoanalyysipalvelu. Google Analytics käyttää evästeitä
    tekstitiedoissaan. Tämä eväste tallennetaan tietokoneellesi, mikä
    mahdollistaa sivuston käyttöanalyysin. Evästeen antama tieto sivuston
    käytöstä (vain IP-osoite, joka on tehty tunnistamattomaksi) lähetetään ja
    tallennetaan Googlen palvelimelle Yhdysvaltoihin. Niissä tapauksissa,
    joissa Google siirtää henkilötietoja EU:n ja ETA-alueen ulkopuolelle,
    tietoja käsitellään Standard contractual clauses (SCCs) asetusten
    mukaisilla tavoilla. Jos haluat poistaa Google Analyticsin käytöstä, voit
    tehdä sen asentamalla Google Analytics Opt-Out Browser -lisäosan tai
    lopettaa tämän sivuston käytön. Google käyttää näitä tietoja arvioidakseen
    sivuston käyttöä, raportoimalla käyttötiheydestä ja internetin käyttöön
    liittyvistä palveluista. Voit lukea tarkemmin Google Analyticsin
    tietosuojapolitiikasta
    <a href="https://support.google.com/analytics/answer/6004245?hl=fi">
        tästä linkistä.
    </a>
</p>
<p>
    <strong>DoubleClick</strong>
</p>
<p>
    Käytämme (Googlen) DoubleClick-alustaa mainontaan ulkoisessa
    verkkomediassa.
    <br/>
    DoubleClick automatisoi mainosten ostamisprosessin ja määrittelemien
    kriteerien mukaisen mainostilan. Ulkoinen mainonta on yksilöllistä ja
    suunnattu tietyille kävijöille.
    <a href="https://support.google.com/faqs/answer/2727482?hl=en">
        Lue lisää.
    </a>
</p>
<p>
    <br/>
    DoubleClick-evästeet tallennetaan kävijän laitteeseen, kun kävijä tulee
    jollekin sivulle, jolla käytetään DoubleClick-alustaa tai (Googlen)
    AdWordsin tai YouTuben kaltaista sisältöä, joka asettaa evästeitä
    DoubleClickin puolesta.
    <br/>
    Kävijä ei näe DoubleClick-alustaa millään tavalla. Se toimii taustalla ja
    eväste asetetaan laitteeseen.
    <br/>
    DoubleClickin keräämät tiedot lähetetään Googlelle. Google voi käyttää
    näitä tietoja kaikessa kävijälle suunnatussa verkkomainonnassa.
</p>
<p>
    <strong>11 Yhteydenotot</strong>
</p>
<p>
    Kaikki yllä mainittujen oikeuksiesi käyttämistä koskevat pyyntösi,
    kysymyksesi tästä tietosuojaselosteesta ja muut yhteydenottosi tulee tehdä
    sähköpostitse
    <a href="mailto:asiakaspalvelu@nettifillari.fi">
        asiakaspalvelu@nettifillari.fi
    </a>
</p>
  `,
  termsAndConditionsBody: `Käyttöehdot

Yleistä
Nämä Käyttöehdot koskevat NettiFillari Oy:n (jäljempänä ”Palveluntarjoaja”) ylläpitämää verkkopalvelua www.nettifillari.fi  (jäljempänä ”Palvelu”). Näitä ehtoja sovelletaan kaikkiin NettiFillari Oy:n ylläpitämiin Palveluihin. Verkkopalvelua käyttävän (jäljempänä ”Käyttäjä”) on hyväksyttävä nämä Käyttöehdot itseään sitoviksi, käyttääkseen Palvelua, rekisteröitynä tai rekisteröimättä.
Käyttämällä Palvelua Käyttäjä hyväksyy Käyttöehdot ja vakuuttaa toimivansa näiden Käyttöehtojen, kulloinkin sovellettavan lain ja hyvän tavan mukaisesti. Käyttöehdot sitovat Käyttäjää niin kauan, kun Käyttäjä käyttää Palvelua. Käyttäjä vakuuttaa olevansa vähintään 18-vuotias tai että hänellä on huoltajansa suostumus Palvelun käyttöön ja että hän on oikeustoimikelpoinen.
Palvelu toimii verkkopalveluna, jossa Palveluun rekisteröityneet myyjät ja ostajat voivat ilmoittaa tuotteitaan (jäljempänä ”Kohde”) myyntiin Palvelun välityksellä ja tehdä ostotarjouksia myynnissä olevista Kohteista. Kaupanteko tapahtuu Käyttäjien kesken Käyttäjien sopimien ehtojen mukaisesti, eikä Palveluntarjoaja ole kaupan osapuoli missään suhteessa.
  •	Palveluntarjoajan oikeudet ja velvollisuudet
Palveluntarjoajan tarjoama Palvelu on lähtökohtaisesti käytettävissä 24 tuntia vuorokaudessa, parhaalla mahdollisella tavalla. Palveluntarjoajalla on kuitenkin oikeus ottaa Palvelu tai sen osa tilapäisesti pois käytöstä huollon, laitteistoasennuksen, yleisen järjestyksen ja turvallisuuden, järjestelmän liiallisen kuormituksen tai muun välttämättömän syyn vuoksi.
Palveluntarjoajalla on oikeus parantaa ja kaikin tavoin muuttaa Palvelun sisältöä, rakennetta ja ulkoasua, sen tuote- ja tietokantavalikoimaa sekä muita Palvelun osia.  Palveluntarjoajalla on oikeus muuttaa Palvelun maksuttomia osioita maksullisiksi tai lopettaa Palvelun ylläpitäminen sekä tuottaminen osittain tai kokonaisuudessaan. Palveluntarjoajalla on myös oikeus muuttaa Palvelun eri osioiden hintoja ja hinnoitteluperusteita. Palveluntarjoaja pyrkii tiedottamaan Palvelussa tapahtuvista, Käyttäjän kannalta olennaisista muutoksista sekä katkoksista Käyttäjälle etukäteen mahdollisuuksien mukaan hyvissä ajoin etukäteen Palvelun välityksellä tai muulla sopivaksi katsomallaan tavalla. Muutokset astuvat voimaan heti, kun ne on toteutettu.
Palveluntarjoaja ei takaa suoranaisesti eikä välillisesti, että Palvelu toimii käyttökatkoitta tai täysin virheettömästi.
Palveluntarjoaja ei vastaa Palvelun kautta saatavilla olevien sisältöjen oikeellisuudesta tai lainmukaisuudesta eikä Palvelussa olevilla keskustelu- tai muilla vastaavilla palstoilla esitetyistä näkemyksistä tai mielipiteistä. 
Palveluntarjoajalla on oikeus, mutta ei velvollisuutta tarkastaa ja muuttaa tai poistaa Palvelussa esitettävien kirjoitusten ja julkaisujen sisältöä osittain tai kokonaan, jos Palveluntarjoaja kokee tämän tarpeelliseksi.
Palveluntarjoajalla on oikeus poistaa Palvelusta aineistoa, jonka ilmaisemisen Palvelussa Palveluntarjoaja on kieltänyt tai joka Palveluntarjoajan näkemyksen mukaan on lain tai hyvän tavan vastaista, sopimatonta, virheellistä, haitallista tai vahingollista Palveluntarjoajalle, Käyttäjille tai kolmansille osapuolille. Palveluntarjoajalla ei ole velvollisuutta ilmoittaa aineiston poistamisesta Käyttäjille etu- eikä jälkikäteen.
Palveluntarjoaja ei ole vastuussa mahdollisista Käyttäjien välisen viestinnän tai muun toiminnan aiheuttamista haitoista tai vahingoista, jotka kohdistuvat toisiin Käyttäjiin tai kolmansiin osapuoliin.
Palveluntarjoaja ei vastaa Palvelussa esitettyjen tietojen oikeellisuudesta tai luotettavuudesta tai Palvelussa kaupan pidettävien tai välitettävien tuotteiden ja palvelusten virheettömyydestä tai niitä koskevien tietojen oikeellisuudesta.
Palveluntarjoaja ei ole kaupan osapuoli. Kaupankäynti Palvelussa tapahtuu Käyttäjien kesken ja vastuu kaupasta ja sen toteutumisesta on Palvelun Käyttäjillä. Palveluntarjoaja ei vastaa oikeudettomasti tapahtuneesta myynnistä kolmannelle osapuolelle.
Palveluntarjoaja ei vastaa mistään vahingoista tai muista haitoista, joita virheelliset tai riittämättömät Käyttäjätiedot mahdollisesti aiheuttavat Käyttäjälle tai kolmansille osapuolille.
Palvelu on suojattu tekijänoikeudella Suomen lainsäädännön mukaisesti. Palvelu voi sisältää tekijänoikeudella, tavaramerkkioikeudella tai muulla immateriaalioikeudella suojattua aineistoa. Käyttäjällä ei ole ilman Palveluntarjoajan tai muun oikeudenhaltijan nimenomaista kirjallista lupaa levittää, julkaista, kopioida, saattaa yleisön saataville tai muuten kaupallisesti hyödyntää suojattua materiaalia taikka muodostaa Palvelusta tai sen osasta toista teosta, tietokantaa tai palvelua.
Palveluntarjoaja on vastuussa ainoastaan Palvelua varten tuottamansa aineiston sisällöstä, tekijänoikeuksista ja muista immateriaalioikeuksista. Palveluntarjoaja vastaa ainoastaan itse tuottamiensa Palvelussa olevien tietojen tarkkuudesta, laadusta ja toimivuudesta.”
Palveluntarjoaja ei ole missään tapauksessa vastuussa mistään suorista tai epäsuorista vahingoista, jotka liittyvät millään tavalla Palveluun tai sen käyttöön, Palvelun kautta saatavilla oleviin tietoihin tai ilmoituksiin. Palveluntarjoaja ei myöskään ole vastuussa mistään vahingoista, joita Palvelun käyttö tai sen toimintahäiriöt, tekniset viat, haittaohjelmat, linkit tai keskeytykset Palvelussa aiheuttavat Käyttäjälle tai kolmansille osapuolille.


  •	Käyttäjän oikeudet ja velvollisuudet
Käyttäjän luoma käyttäjätunnus sekä salasana ovat henkilökohtaisia. Käyttäjä saa oikeuden käyttää Palvelua näiden Käyttöehtojen, lainsäädännön ja muiden Palvelua tai sen yksittäisiä osia koskevien ehtojen mukaisesti. Käyttäjä sitoutuu käyttäessään Palvelua toimimaan kaikkien näiden ehtojen, lainsäädännön sekä hyvän tavan mukaisesti. Käyttäjä sitoutuu niin ikään noudattamaan muualla Palvelussa asetettuja sääntöjä ja ohjeita.
Käyttäjä ymmärtää, että Palveluntarjoaja ei ole velvollinen, mutta sillä on kuitenkin oikeus valvoa, miten Käyttäjä käyttää Palvelua, mikäli Palveluntarjoaja katsoo tämän tarpeelliseksi.
Palvelussa ei saa esittää lain tai hyvän tavan vastaista materiaalia. Käyttäjä vastaa siitä, että hänellä on kaikki tarvittavat tekijän- ja muut oikeudet ja/tai oikeudenhaltijoiden suostumukset Palvelussa julkaisemaansa materiaaliin sekä muuhun aineistoon, jonka hän lähettää, välittää tai tallentaa Palveluun. Palvelussa ei saa myydä tuoteväärennöksiä tai piraattituotteita. Käyttäjä vastaa kaikista toiminnallaan aiheuttamistaan vahingoista. 
Käyttäjä vastaa kaikista Palvelun käytöstä itselleen aiheutuvista välittömistä ja välillisistä kuluista.
Käyttäjä sitoutuu Kohteen ilmoittaessaan kuvaamaan sen totuudenmukaisesti ja riittävän laajasti. Käyttäjä vakuuttaa ja vastaa siitä, että hänellä on oikeus myydä ilmoittamansa Kohteet, joita hän tarjoaa myytäväksi Palvelussa. Palveluntarjoaja ei vastaa oikeudettomasti tapahtuneesta myynnistä kolmansille osapuolille.
Mikäli ilmenee, että Käyttäjä rikkoo näitä Käyttöehtoja käyttäessään Palvelua, taikka mikäli Palveluntarjoajalla on perusteltu syy olettaa tällaisen rikkomuksen tapahtuneen, Palveluntarjoaja pidättää oikeuden käyttäjätilin välittömään sulkemiseen sekä käyttäjätilille ja/tai Palveluun kirjautumisen väliaikaiseen rajoittamiseen ilman erillistä ennakkovaroitusta.
  •	Käyttäjätiedot
Palvelun käyttö voi edellyttää rekisteröitymistä. Käyttäjän tulee antaa Palvelun tarjoamista varten tarvittavat tiedot tätä varten. Käyttäjä vakuuttaa tietojen olevan oikein ja ajantasaisia. Käyttäjä vastaa virheistä tai viivästyksistä, jotka johtuvat siitä, että Käyttäjän antamat tiedot ovat puutteellisia tai vanhentuneita.
Käyttäjätunnus ja salasana ovat käyttäjäkohtaisia, eikä niitä saa luovuttaa kolmansille osapuolille. Käyttäjä vastaa kaikesta Käyttäjätunnuksellaan tapahtuneesta Palvelun käytöstä. Salasanan tai Käyttäjätunnuksen joutuessa ulkopuolisen tietoon, Käyttäjän tulee ilmoittaa tästä viipymättä Palveluntarjoajalle. Saatuaan Palveluntarjoajan kuittauksen ilmoituksestaan Käyttäjän vastuu Palvelun käytöstä lakkaa kyseisten tunnusten osalta.

Käyttäjä voi saada kadonneen käyttäjätunnuksen ja salasanan tilalle uudet niin halutessaan. Käyttäjän oikeudet käyttää Palvelua voidaan tällöin siirtää uudelle tunnukselle ja salasanalle, samoin kuin maksullisen Palvelun käyttämättä oleva osa.
Käyttäjätunnus, salasana tai muut Palvelun käytön mahdolliset edellyttämät tunnukset voidaan tarvittaessa muuttaa Palveluntarjoajan toimesta, jos tekniset tai muut hyväksyttävät syyt sitä edellyttävät.
  •	Maksulliset palvelut ja laskutus
Palvelu saattaa sisältää sekä maksuttomia että maksullisia osia. Maksullisten osien hinnat ja maksuehdot ilmoitetaan kunkin osan kohdalla erikseen. Lisäksi Palvelun kautta voi olla tilattavissa erilaisia maksullisia tuotteita ja palveluita.
Palveluntarjoaja voi muuttaa Palvelun hinnoittelua tai laskutusperusteita oman harkintansa mukaan ilmoittamalla siitä Palvelussa tai muulla sopivaksi katsotulla tavalla hyvissä ajoin etukäteen. Jos arvonlisävero tai jokin muu Palveluun liittyvä vero tai julkisoikeudellinen maksu tai niitä koskeva viranomaisten soveltamiskäytäntö muuttuu, Palveluntarjoajalla on oikeus välittömästi muuttaa hintoja vastaavasti.
Käyttäjällä ei ole Palvelun kautta hankittujen maksullisten tuotteiden tai palvelujen peruuttamisoikeutta sen jälkeen, kun kyseessä oleva palvelu tai tuote on Käyttäjän tilauksen perusteella sähköisesti suoritettu.
Mikäli Käyttäjälle toimitetun maksullisen palvelun käyttö estyy Palveluntarjoajan vaikutuspiirissä ja vastuulla olevasta Palvelun häiriöstä johtuen kokonaan tai oleellisin osin, Käyttäjällä on hyvityksenä oikeus maksullisen palvelun käyttöoikeuden pidennykseen keskeytystä vastaavalla ajalla.
  •	Immateriaalioikeudet
Palveluntarjoaja omistavaa kaikki oikeudet Palveluun, mukaan lukien rajoituksetta kaikki Palveluun ja sen sisältämään materiaaliin ja aineistoon liittyvät tavaramerkit, tekijänoikeudet sekä muut immateriaalioikeudet Palveluun sekä teksteihin, kuviin, videoihin, musiikkiin, muotoiluihin, malleihin sekä kaikkeen muuhun materiaaliin ja aineistoon, joka on Palvelussa, lukuun ottamatta Käyttäjän tuottamaa sisältöä.
Palveluntarjoaja myöntää Käyttäjälle oikeuden käyttää Palvelua Käyttöehtojen mukaisesti. Käyttäjällä on oikeus käyttää Palvelun aineistoa ainoastaan sellaisiin tarkoituksiin, jotka kuuluvat Palvelun tavanomaiseen käyttöön. Käyttäjällä ei ole oikeutta siirtää kolmansille osapuolille Palveluntarjoajalta saamaansa käyttöoikeutta Palveluun.
Palveluntarjoajalla on oikeus käyttää Käyttäjän tuottamaa sisältöä vapaasti markkinoinnissa. Käyttäjä luopuu kaikista korvausvaatimuksista, jotka koskevat Käyttäjän tuottaman sisällön käyttöä Palveluntarjoajan toimesta.
Käyttäjän tulee varmistaa, että Käyttäjän tuottama sisältö ei loukkaa mitään kolmannen osapuolen oikeuksia, lakeja tai hyvän tavan mukaisia käytäntöjä.
Käyttäjä vakuuttaa Palvelua käyttäessään ymmärtävänsä oikeutensa Palvelun aineiston ja Käyttäjän tuottaman sisällön käytöstä Palvelussa. 
Palveluntarjoajalla on oikeus muokata, kopioida, tallentaa, säilyttää sekä muuten käyttää Käyttäjän tuottamaa sisältöä. Palveluntarjoajalla on oikeus luovuttaa näitä oikeuksia edelleen mahdollisille yhteistyökumppaneilleen.
  •	Käyttöoikeuden siirtäminen ja lakkaaminen
Käyttäjällä ei ole oikeutta luovuttaa tai siirtää Palvelun käyttöoikeutta kolmannelle henkilölle. 
Palvelun käytön lakkaaminen ei vapauta Käyttäjää palveluiden tai suoritusten maksuista, joiden peruste on syntynyt ennen, kun käyttöoikeus on lakkautettu. 
Palveluntarjoajalla on oikeus olla antamatta Käyttäjälle pääsyä Palveluun, jos sopimus päättyy Käyttäjän sopimusrikkomuksen johdosta. 
  •	Ylivoimainen este
Osapuolet vapautuvat sopimuksen mukaisista velvoitteistaan siksi ajaksi ja siinä laajuudessa, kun tämä johtuu ylivoimaisesta esteestä. Perusteena ylivoimaiselle esteelle pidetään sellaista sopijapuolista riippumatonta asiaa tai tapahtumaa, jonka vaikutusta ei voida kohtuudella välttää. Tällaisia tapahtumia voivat olla mm. sota, tulipalo, lakko tai muu työnseisaus, tulva tai muu luonnonilmiö tai muu vaikutuksiltaan vastaava ja epätavallinen Käyttäjästä tai Palveluntarjoajasta riippumaton syy.
  •	Henkilötietojen käsittely ja evästeet
Käyttäjien henkilötietoja käsitellään luottamuksellisesti tietosuojaselosteen ja yksityisyydensuojaa kuvaavan lainsäädännön mukaan. Palvelussa käytetään evästeitä tietosuojaselosteessa kerrotuin tavoin. Tietosuojaseloste:
(tähän voidaan myös tehdä evästeille oma kohta, jos tietosuojaselosteessa ei ole mainintaa)
  •	Käyttöehtojen muuttaminen
Palveluntarjoajalla on oikeus muuttaa Käyttöehtoja, jos kokee sen tarpeelliseksi. Käyttöehtoja voidaan muuttaa sekä täydentää esim. tarjonnan tai sisällön muuttuessa, lainsäädännön tai viranomaiskäytännön muutoksista johtuen tai ennakoimattomien olosuhteiden vuoksi. Palveluntarjoaja pyrkii ilmoittamaan keskeisistä muutoksista Käyttäjälle mahdollisimman nopeasti sähköpostitse tai Palvelun välityksellä.
  •	Muut ehdot
Käyttäjä sitoutuu myös noudattamaan mahdollisia Palveluntarjoajan erikseen määrittämiä tai sen sopimuskumppanin luomia ohjeita. Kaikki voimassa olevat käyttöehdot ovat Käyttäjän luettavissa.
Palvelu voi sisältää linkkejä ja yhteyksiä kolmannen osapuolen verkkosivustoihin ja palveluihin. Näihin kolmannen osapuolen tarjoamiin palveluihin sovelletaan kyseisten osapuolien käyttö- sekä muita ehtoja, eikä Palveluntarjoaja vastaa sivustojen yksityisyydensuojakäytännöistä, sisällöstä, toimivuudesta tai soveltuvuudesta Käyttäjälle.
Palvelun kautta yksityishenkilöltä ostettuihin tuotteisiin tai palveluihin ei sovelleta kuluttajansuojalain mukaista palautusoikeutta. Jos käytetyt tavarat on ostettu yksityishenkilöltä, kauppaan ei sovelleta kuluttajansuojalakia vaan kauppalakia, jonka säännökset vastaavat suurelta osin kuluttajansuojalakia virheen määritelmän osalta. Jos tuote tai palvelu ei vastaa myyjän antamaa kuvausta, ostajan on otettava yhteyttä myyjään asian selvittämiseksi. Jos asiaa ei saada ratkaistua toivotulla tavalla, esim. kaupan purkamisella, ostaja voi viedä riidan käräjäoikeuteen.
Yksityishenkilöiden lisäksi Palvelussa toimii myyjinä myös yrityksiä, ja heiltä hankittuihin tuotteisiin ja palveluihin kuluttajalla on kuluttajansuojalain mukainen suoja ja palautusoikeus. Palveluntarjoaja ei ole kuitenkaan vastuussa, jos yrityksen ja yksityisen henkilön välisessä kaupassa kuluttajansuojalain mukainen oikeus ei toteudu, vaan tällöin vastuussa on kuluttajansuojalakia noudattamatta jättänyt yritys.
  •	Sovellettava laki ja riitojen ratkaisu
Näihin käyttöehtoihin sekä Palveluun sovelletaan Suomen lakia, pois lukien lainvalintaa koskevat säännökset.
Palveluntarjoajan ja Käyttäjän väliset mahdolliset erimielisyydet pyritään ratkaisemaan osapuolten välisillä neuvotteluilla. Mikäli asiasta ei päästä sovintoon, riita ratkaistaan Suomen yleisissä tuomioistuimissa.
`,
  phone: 'phone',
  buyANotice: `Buy a notice withdrawal on the Nettifillar front page for:`,
  EUR: 'EUR',
  aDay: 'a day',
  aWeek: 'a week.',
  alreadyAUser: 'Already a user?',
  userCreated: 'Sign up was successful. please check your email to confirm your email address.',
  verificationCode: 'Verification code',
  userActivated: 'Your account is activated successfully.',
  enterVerificationCode: 'Please, enter down below the verification code sent to your email.',
  enterBasedOnPattern: 'Only lowercase letters, numbers and dash are permitted',
  resendCode: 'Resend code',
  newPasswordSet: 'You password has been changed',
  newBicycle: 'New Bicycle',
  public: 'Public',
  private: 'Private',
  condition: 'Condition',
  tireSize: 'Tire size',
  description: 'Description',
  gender: 'Gender',
  Gender: 'Gender',
  deliveryInstruction: 'Delivery Instruction',
  frameSize: 'Frame Size',
  urban: 'Urban',
  gears: 'Gears',
  featureIn: 'Feature In',
  request: 'Request',
  top5Euro: 'Top5 (+5 €)',
  top10Euro: 'Top10 (+3 €)',
  brand: 'Brand',
  price: 'Price',
  year: 'Year',
  model: 'Model',
  colors: 'Colors',
  brakes: 'Brakes',
  frameMaterial: 'Frame Material',
  myProfile: 'My Profile',
  dashboard: 'Dashboard',
  signOut: 'Sign out',
  myPosts: 'My Posts',
  profile: 'Profile',
  category: 'Category',
  new: 'New',
  secondHand: 'Second hand',
  stolen: 'Stolen',
  leasing: 'Leasing',
  renting: 'Renting',
  batteryLocation: 'Battery location',
  reaBattery: 'Rear rack battery',
  hubBattery: 'Hub battery ',
  frameBattery: 'Frame battery',
  intubeBattery: 'Intube battery',
  motors: 'Motors',
  users: 'Users',
  bicycles: 'Bicycles',
  banner: 'Banner',
  newsletter: 'Newsletter',
  settings: 'Settings',
  searchByUserOrModel: 'Search by User or Model',
  filter: 'Filter',
  active: 'Active',
  bicycleIsCreated: 'Bicycle is created',
  bicycleApproved: 'Bicycle is approved',
  chooseYourFavorite: 'A digital marketplace for used and new bicycles',
  SearchPlaceHolder: 'Find bicycle by keyword',
  all: 'ALL',
  todaysHighlight: 'Today’s Highlights',
  contactSeller: 'Contact Seller',
  characteristics: 'Characteristics',
  bikType: 'Bicycle type',
  frame: 'Frame',
  shareOn: 'Share on',
  userUpdated: 'User is updated',
  LEASING: 'Leasing',
  NEW: 'New',
  RENTING: 'Renting',
  powers: 'Power',
  SECOND_HAND: 'Second hand',
  STOLEN: 'Stolen',
  rejectReason: 'Rejection Reason',
  pleaseChooseReason: 'Please, choose a reason for rejecting the post. ',
  poorQuality: 'Poor Picture Quality',
  lackOfBike: 'Lack of bike description and information',
  badBike: 'Bad Bike Quality',
  otherReason: 'Other: Please, contact the NettiFillari office',
  accept: 'Accept',
  reject: 'Reject',
  close: 'Close',
  bicycleRejected: 'Bicycle is rejected',
  reviewBicycle: 'Review bicycle',
  edit: 'Edit',
  size: 'Size',
  youMightAlsoLike: 'You might also like',
  title: 'Title',
  date: 'Date',
  searchByTitleOrModel: 'Search by title or keywords',
  searchByTitle: 'Search by Title',
  newBlogPost: 'New Blog Post',
  id: 'ID',
  position: 'Position',
  photos: 'Photos',
  hoverText: 'Hover text',
  url: 'URL',
  startDate: 'Start Date',
  endDate: 'End Date',
  newBannerPost: 'New Banner Post',
  receiversEmail: 'Receivers’ Emails',
  attachedFile: 'Attached File',
  newEmail: 'New Email',
  profileInfo: 'Profile Info',
  uploadPhoto: 'Upload Photo',
  phoneNumber: 'Phone Number',
  save: 'Save',
  changePassowrd: 'Change Password',
  currentPassword: 'Current Password',
  passwordIsChanged: 'Passowrd is changed',
  profileIsUpdated: 'Profile is updated',
  bicycleIsUpdated: 'Bicycle is updated',
  markAsSold: 'Mark as sold',
  markAsFound: 'Mark as found',
  markAsLeased: 'Mark as leased',
  markAsRented: 'Mark as rented',
  english: 'English',
  suomi: 'Suomi',
  slug: 'Slug',
  metaTag: 'Meta tags',
  blogPostBody: 'Post body',
  post: 'Post',
  finnishTitle: 'Finish title',
  finnishBody: 'Finish body',
  finnishMetaTag: 'Finish metatag',
  postCreated: 'Post created',
  inactive: 'Inactive',
  postIsUpdated: 'Post is updated',
  update: 'Update',
  postUpdated: 'Post is updated',
  confirmDeletePost: 'Are you sure to delete this post?',
  yes: 'Yes',
  no: 'No',
  postIsDeleted: 'Post is deleted',
  editBicycle: 'Edit bicycle',
  blogInnerPage: 'Blog Inner Page',
  home: 'Home',
  status: 'Status',
  apply: 'Apply',
  reset: 'Reset',
  selectRejectionReason: 'Please select a rejection reason',
  newsletterCreated: 'Email is created',
  send: 'Send',
  pleaseUploadFile: 'Please upload a file',
  newBanner: 'New banner',
  enterCorrectUrl: 'Enter correct url',
  paymentSuccessFul: 'Payment Successful!',
  yourPaymentWasSuc: 'Your payment was successful! You can now continue using NettiFillari.',
  gotoProfile: 'Go to the Profile',
  yourPaymentFailed: 'Your payment was Failed! It seams we have not received money.',
  paymentFailed: 'Paytment Failed',
  bannerCreated: 'Banner is created',
  bannerUpdated: 'Banner is updated',
  confirmDeleteBanner: 'Confirm delete banner',
  bannerIsDelete: 'Banner is deleted',
  pending: 'Pending',
  pleaseUploadImage: 'Please upload images for your bike',
  ascending: 'Ascending',
  pleaseFillCityAndPhone: 'Please fill city and phone',
  editBanner: 'Edit banner',
  bicyclesList: 'Bicycle list',
  editPost: 'Edit post',
  done: 'Done',
  BAD_BIKE_QUALITY: 'Bad bicyile quality',
  NOT_ENOUGH_INFORMATION: 'Not enough information provided',
  OTHER: 'Other reasosn',
  POOR_PICTURE_QUALITY: 'Poor quality of bicycle image',
  incorrectPassword: 'Your current password is not entered correctly.',
  pleaseFillPhone: 'Please fill your phone number in profile',
  pleaseFillCity: 'Please fill your city in profile',
  bannerSizeHint:
    'Suggested sizes for banner is: 850x417, 630x417, 410x417 and the file size should not be more than 2 MB',
  bicycleSizeHint: 'Suggested size is 290x170 and the maximum file size is 2 MB',
  postHint: 'Suggested size is 850x420 and the maximum file size is 2 MB',
  emailHint: 'File should be in pdf/word format and the maximum file is 2 MB',
  mainPageTitle: 'Your versatile bike selling and buying site online',
  mainPageContent: `Do you dream of a used full suspension mountain bike or is it perhaps time for your child's first bike? On NettiFillari marketplace, you will find men's, women's and children's bicycles for different terrains: off-road, urban, road or gravel! We have a wide selection of bike brands from high-quality road bikes to trendy electric bikes. Find the right bike for your needs using our extensive bike search, and you’ll discover how easy and fast it can be to buy a used bike. Also check out our blog, where you will find tips and tricks for example buying the right size bike.`,
  sellOnNetTitle: 'Sell your used bike online',
  sellOnNetH2: 'Instructions for selling your bike',
  SellerH1: 'Sell your used bicycle easily online on NettiFillari',
  sellerP:
    'Selling on NettiFillari is easy! You can register your bike for sale after registering as a NettiFillari user. Please pay attention to creating an informative bike sales post and take clear pictures of the bike for sale. NettiFillari´s users who are interested in buying bikes search for used and new bikes through a bike search (search area by keyword) or brand search. It is therefore important that the basic information, make and any additional descriptions of the bicycle are correctly mentioned in the sales post. Some of the fields in the sales post are mandatory to make it as easy as possible to sell and buy a bike. The image of the sales post is also mandatory.',
  blogH1: 'NettiFillari´s cycling blog',
  blogH2: 'Tips for cycling and advice on choosing a bike',
  blogTopContent:
    'Cycling is good exercise, ecological and convenient mode of transport and a great hobby. Read on our blog about how to get the most out of cycling: which bike frame size is the right choice? What is the difference between different suspensions? What to consider when choosing a bike? Up-to-date and practical information about cycling is provided by our NettiFillari cycling experts, bike hobbyists and guest writers!',
  blogTitle: 'NettiFillari´s cycling blog and tips for buying or selling a bike',
  blogMeta:
    'NettiFillari is an online marketplace for used bikes. Find bike information and cycling tips from our cycling blog. Read more now!',
  sellMeta:
    'You can sell your bicycle easily online on NettiFillari´s digital marketplace for bikes. Making a sales post is free, start now!',
  phonePrefix: '+358',
  FAQTitle: 'Myy ja osta fillari, usein kysyttyä | NettiFillari.fi',
  FAQdesc:
    'Myy ja osta fillari helposti NettiFillarista. Olemme pyrkineet tekemään käytettyjen pyörien myymisestä ja ostamisesta mahdollisimman helppoa. Tutustu ohjeisiin ja usein kysyttyihin kysymyksiin!',
  FAQPolicy: 'NettiFillarin ohjeet ja usein kysyttyä ',
  FAQh2: 'Vastaamme fillarin myyjän ja ostajan kysymyksiin!',
  FAQBody: `Olemme pyrkineet tekemään käytettyjen pyörien myymisestä ja ostamisesta mahdollisimman helppoa. NettiFillari-sivustomme on suunniteltu tarjoamaan perinteisen nettikaupan miellyttävä asiakaskokemus, jota tukee selkeä tuotelistaus, navigointi sekä tyylikäs ulkoasu. Käytettyjenkin fillareiden myyminen ja ostaminen voi olla helppoa! Tällä sivulla vastaamme usein kysyttyihin kysymyksiin. Kehitämme sivustoa jatkuvasti palautteiden ja kysymysten perusteella. Asiakaspalvelumme palvelee sähköpostitse osoitteella <a href="mailto:&#097;&#115;&#105;&#097;&#107;&#097;&#115;&#112;&#097;&#108;&#118;&#101;&#108;&#117;&#064;&#110;&#101;&#116;&#116;&#105;&#102;&#105;&#108;&#108;&#097;&#114;&#105;&#046;&#102;&#105;">Email</a>.




  Mikä on NettiFillari?
  
  Tavoitteemme on tarjota helppokäyttöinen käytettyjen ja uusien fillarien kauppapaikka netissä kaikille pyöräilystä kiinnostuneille. Olemme kategorisoineet pyörät muun muassa pyörämerkkien, pyörän koon, pyörän tyypin ja käyttötarkoituksen mukaan, jotta pyörien etsiminen olisi sujuvaa. NettiFillari-sivustolla pyörän myynti-ilmoituksen teko vaatii rekisteröitymisen ja kirjautumisen sivuston käyttäjäksi. Pyörän ostajan ei tarvitse rekisteröityä tai kirjautua sivustolle. Pyöräkaupat tehdään suoraan myyjän ja ostajan välillä. 
  
  
  
  
  Maksaako NettiFillari-sivuston käyttö? 
  
  Sivustolle rekisteröityminen, pyörien etsiminen sekä myynti-ilmoituksen tekeminen on käyttäjälle täysin maksutonta. Rekisteröityneenä käyttäjänä voit ostaa maksullisia lisäpalveluita, kuten fillarin myynti-ilmoituksen mainostusta ja ilmoituksen uusimisen.
  
  
  
  
  Voinko palauttaa ostamani fillarin? 
  
  NettiFillari on käytettyjen ja uusien pyörien kauppapaikka, jossa sivuston käyttäjät voivat myydä ja ostaa pyöriä. NettiFillari Oy ei siis myy polkupyöriä kuluttajille, eikä täten voi ottaa vastaan tuotteiden palautuksia. Tutustuthan myynti-ilmoituksiin huolellisesti ja kysy tarvittaessa myyjältä lisätietoja tai lisäkuvia myytävästä fillarista ennen sen ostoa. 
  `,
  faq: 'FAQ',
  frames: 'Frames',
  color: 'Color',
  suspension: 'Suspension',
  upload: 'Upload',
  reason: 'Reason',
  REJECTED: 'Rejected',
  RENTED: 'Rented',
  SOLD: 'Sold',
  FOUND: 'Found',
  LEASED: 'Leased',
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
  ACTIVE: 'Active',
  motor: 'Motor',
  location: 'Location',
  body: 'Body',
  search: 'Search',
  contents: 'Contents',
  editContent: 'Edit Content',
  contentPosition: 'Content Position',
  action: 'Action',
  BLOG_HEADING: 'Blog Heading',
  BRANDS_HEADING: 'Brands Heading',
  HOME_HEADING: 'Home Heading',
  SELL_HEADING: 'Sell Heading',
})

export default en
