import { createSlice } from '@reduxjs/toolkit'
import { getBicyclesRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { ErrorResponse } from 'interfaces/Error'
import { BicycleAppListView, PostType } from 'api/main'

export interface BicycleRequest {
  bicycleTypeIds?: number[]
  categoryIds?: number[]
  brakeIds?: number[]
  brandIds?: number[]
  colorIds?: number[]
  batteryLocationIds?: Array<number>
  powerIds?: Array<number>
  motorIds?: Array<number>
  frameMaterialIds?: number[]
  frameSizeIds?: number[]
  gearIds?: number[]
  genderIds?: number[]
  keyword?: string
  offset?: number
  page?: number
  pageNumber?: number
  pageSize?: number
  paged?: boolean
  postTypes?: PostType[]
  size?: number
  sort?: string
  sortSorted?: boolean
  sortUnsorted?: boolean
  tireIds?: number[]
  unpaged?: boolean
}

export interface BicycleState {
  is_fetching: boolean
  data: BicycleAppListView[]
  isLast: boolean
  errors: ErrorResponse
  page: number
}

const initialState: BicycleState = {
  is_fetching: false,
  data: [],
  errors: null,
  isLast: false,
  page: -1,
}

export const bicyclesSlice = createSlice({
  name: 'bicycles',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getBicyclesRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(getBicyclesRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.isLast = payload?.isLast
        if (state.page !== payload?.currentPageNumber) {
          state.page = payload?.currentPageNumber
        } else {
          state.data = []
        }
        if (Array.isArray(payload?.content)) state.data.push(...payload?.content)
      })
      .addCase(getBicyclesRequestAsync.rejected, (state, action: any) => {
        state.is_fetching = false
        state.isLast = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
      })
  },
})

export const selectBicycles = (state: RootState): any => state.bicycles.get

export default bicyclesSlice.reducer
