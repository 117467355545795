import i18n from 'i18next'

export interface OptionInterface {
  id: number | string
  name: string
  name_fi: string
}

export const conditions = [
  {
    id: 1,
    name: 'New',
    name_fi: i18n.t('new'),
  },
  {
    id: 2,
    name: 'Second hand',
    name_fi: i18n.t('new'),
  },
  {
    id: 3,
    name: 'Stolen',
    name_fi: i18n.t('stolen'),
  },
  {
    id: 4,
    name: 'Leasing',
    name_fi: i18n.t('leasing'),
  },
  {
    id: 5,
    name: 'Renting',
    name_fi: i18n.t('renting'),
  },
]

//   {
//     id: 1,
//     name: 'Men',
//     name_fi: i18n.t('men'),
//   },
//   {
//     id: 1,
//     name: 'Women',
//     name_fi: i18n.t('women'),
//   },
//   {
//     id: 1,
//     name: 'Unisex',
//     name_fi: i18n.t('unisex'),
//   },
//   {
//     id: 1,
//     name: 'Kids',
//     name_fi: i18n.t('kids'),
//   },
// ]
export const genders = [
  {
    id: 1,
    name: 'Men',
    name_fi: 'Miehet',
  },
  {
    id: 2,
    name: 'Women',
    name_fi: 'Naiset',
  },
  {
    id: 3,
    name: 'Unisex',
    name_fi: 'Unisex',
  },
  {
    id: 4,
    name: 'Kids and juniors',
    name_fi: 'Lapset ja nuoret',
  },
]
export const motors = [
  {
    id: 1,
    name: 'Mid-drive motor',
    name_fi: 'Keskiömoottori',
  },
  {
    id: 2,
    name: 'Bottom bracket',
    name_fi: 'Keskiön vaihteet',
  },
  {
    id: 3,
    name: 'Hub motor',
    name_fi: 'Napamoottori',
  },
  {
    id: 4,
    name: 'Other',
    name_fi: 'Muu',
  },
]
export const bikeTypes = [
  {
    id: 1,
    name: 'City bikes',
    name_fi: 'Kaupunkipyörät',
  },
  {
    id: 2,
    name: 'Hybrids',
    name_fi: 'Hybridipyörät',
  },
  {
    id: 3,
    name: 'Mountain bikes',
    name_fi: 'Maastopyörät',
  },
  {
    id: 4,
    name: 'E-bikes',
    name_fi: 'Sähköpyörät',
  },
  {
    id: 5,
    name: 'Kids and juniors',
    name_fi: 'Lasten ja nuorten pyörät',
  },
  {
    id: 6,
    name: 'Road and gravel bikes',
    name_fi: 'Maantiepyörät ja gravel-pyörät',
  },
  {
    id: 7,
    name: 'Other',
    name_fi: 'Muut',
  },
]

export const cityCategory = [
  {
    id: 1,
    name: 'Urban',
    name_fi: 'Urbaanit',
  },
  {
    id: 2,
    name: 'Classics',
    name_fi: 'Klassikot',
  },
  {
    id: 3,
    name: 'Jopo',
    name_fi: 'Jopot',
  },
  {
    id: 4,
    name: 'Folding bikes',
    name_fi: 'Taittopyörät',
  },
  {
    id: 5,
    name: 'Cruisers',
    name_fi: 'Cruiserit',
  },
  {
    id: 6,
    name: 'Other',
    name_fi: 'Muut',
  },
]

export const hybridsCategory = [
  {
    id: 7,
    name: 'General',
    name_fi: 'Yleiskäyttö',
  },
  {
    id: 8,
    name: 'Touring',
    name_fi: 'Retkipyörät',
  },
  {
    id: 9,
    name: 'Other',
    name_fi: 'Muu',
  },
]

export const mountainCategories = [
  {
    id: 10,
    name: 'Hardtail',
    name_fi: 'Etujousto',
  },
  {
    id: 11,
    name: 'Full suspension',
    name_fi: 'Täysjousto',
  },
  {
    id: 12,
    name: 'Fatbike',
    name_fi: 'Fatbike',
  },
  {
    id: 13,
    name: 'Other',
    name_fi: 'Muu',
  },
]

export const eBikesCategories = [
  {
    id: 14,
    name: 'Road',
    name_fi: 'Road',
  },
  {
    id: 15,
    name: 'Triathlon',
    name_fi: 'Triathlon',
  },
  {
    id: 16,
    name: 'Gravel',
    name_fi: 'Gravel',
  },
  {
    id: 17,
    name: 'Other',
    name_fi: 'Muu',
  },
]

export const kidsCategories = [
  {
    id: 18,
    name: 'City',
    name_fi: 'Sähkökaupunkipyörät',
  },
  {
    id: 19,
    name: 'Mountain bikes',
    name_fi: 'Sähkömaastopyörät',
  },
  {
    id: 20,
    name: 'Touring',
    name_fi: 'Sähköretkipyörät',
  },
  {
    id: 21,
    name: 'Kids and juniors',
    name_fi: 'Lasten ja nuorten sähköpyörät',
  },
  {
    id: 22,
    name: 'Other e-bikes',
    name_fi: 'Muut sähköpyörät',
  },
]

export const roadCategories = [
  {
    id: 23,
    name: 'Bicycles',
    name_fi: 'Polkupyörät',
  },
  {
    id: 24,
    name: 'Tricycles',
    name_fi: 'Kolmipyöräiset',
  },
  {
    id: 25,
    name: 'Junior bikes',
    name_fi: 'Nuorten pyörät',
  },
  {
    id: 26,
    name: 'Others',
    name_fi: 'Muut',
  },
]
export const otherCategories = [
  {
    id: 27,
    name: 'BMX',
    name_fi: 'BMX',
  },
  {
    id: 28,
    name: 'Dirt',
    name_fi: 'Dirt',
  },
  {
    id: 29,
    name: 'Custom build',
    name_fi: 'Custom build',
  },
  {
    id: 30,
    name: 'Parts',
    name_fi: 'Osat',
  },
  {
    id: 31,
    name: 'Other (see product description)',
    name_fi: 'Muu (katso tuotekuvaus)',
  },
]
export const batteryLocation = [
  {
    id: 1,
    name: 'Intube battery',
    name_fi: 'intubeBattery',
  },
  {
    id: 2,
    name: 'Frame battery',
    name_fi: 'frameBattery',
  },
  {
    id: 3,
    name: 'Hub battery',
    name_fi: 'hubBattery',
  },
  {
    id: 4,
    name: 'Rear rack battery',
    name_fi: 'reaBattery',
  },

  {
    id: 5,
    name: 'Other',
    name_fi: 'Muut',
  },
]
export const powers = [
  {
    id: 1,
    name: '<50 Nm',
    name_fi: '<50 Nm',
  },
  {
    id: 2,
    name: '50 Nm - 70Nm',
    name_fi: '50 Nm - 70Nm',
  },
  {
    id: 3,
    name: '71 Nm - 84Nm',
    name_fi: '71 Nm - 84Nm',
  },
  {
    id: 4,
    name: '>84 Nm',
    name_fi: '>84 Nm',
  },
]

export const tireSizes = [
  {
    id: 1,
    name: '29',
    name_fi: '29',
  },
  {
    id: 2,
    name: '28',
    name_fi: '28',
  },
  {
    id: 3,
    name: '27.5',
    name_fi: '27.5',
  },
  {
    id: 4,
    name: '26',
    name_fi: '26',
  },
  {
    id: 5,
    name: '24',
    name_fi: '24',
  },
  {
    id: 6,
    name: '20',
    name_fi: '20',
  },
  {
    id: 7,
    name: '18',
    name_fi: '18',
  },
  {
    id: 8,
    name: '16',
    name_fi: '16',
  },
  {
    id: 9,
    name: '14',
    name_fi: '14',
  },
  {
    id: 10,
    name: '12',
    name_fi: '12',
  },
  {
    id: 11,
    name: 'Other',
    name_fi: 'Muu',
  },
]
export const frameSizes = [
  {
    id: 1,
    name: 'Kids',
    name_fi: 'Lapset',
  },
  {
    id: 2,
    name: 'Junior',
    name_fi: 'Junior',
  },
  {
    id: 3,
    name: 'XS (14")',
    name_fi: 'XS (14")',
  },
  {
    id: 4,
    name: 'S (16")',
    name_fi: 'S (16")',
  },
  {
    id: 5,
    name: 'M (18")',
    name_fi: 'M (18")',
  },
  {
    id: 6,
    name: 'L (20")',
    name_fi: 'L (20")',
  },
  {
    id: 7,
    name: 'XL (22")',
    name_fi: 'XL (22")',
  },
  {
    id: 8,
    name: 'XXL (24")',
    name_fi: 'XXL (24")',
  },
  {
    id: 9,
    name: 'Other',
    name_fi: 'Muu',
  },
]
export const gears = [
  {
    id: 1,
    name: '1',
    name_fi: '1',
  },
  {
    id: 2,
    name: '3',
    name_fi: '3',
  },
  {
    id: 3,
    name: '6',
    name_fi: '6',
  },
  {
    id: 4,
    name: '7',
    name_fi: '7',
  },
  {
    id: 5,
    name: '8',
    name_fi: '8',
  },
  {
    id: 6,
    name: '10',
    name_fi: '10',
  },
  {
    id: 7,
    name: '11',
    name_fi: '11',
  },
  {
    id: 8,
    name: '12',
    name_fi: '12',
  },
  {
    id: 9,
    name: '16',
    name_fi: '16',
  },
  {
    id: 10,
    name: '18',
    name_fi: '18',
  },
  {
    id: 11,
    name: '20',
    name_fi: '20',
  },
  {
    id: 12,
    name: '22',
    name_fi: '22',
  },
  {
    id: 13,
    name: '24',
    name_fi: '24',
  },
  {
    id: 14,
    name: 'Other',
    name_fi: 'Muu',
  },
]
export const framesMaterial = [
  {
    id: 1,
    name: 'Aluminium',
    name_fi: 'Alumiinirunko',
  },
  {
    id: 2,
    name: 'Carbon',
    name_fi: 'Hiilikuiturunko',
  },
  {
    id: 3,
    name: 'Steal',
    name_fi: 'Teräsrunko',
  },
  {
    id: 4,
    name: 'Other',
    name_fi: 'Muu ',
  },
]
export const brakes = [
  {
    id: 1,
    name: 'Foot brakes',
    name_fi: 'Jalkajarrut',
  },
  {
    id: 2,
    name: 'Foot and front brakes',
    name_fi: 'Jalka- ja etujarrut',
  },
  {
    id: 3,
    name: 'Disc brakes',
    name_fi: 'Levyjarrut',
  },
  {
    id: 4,
    name: 'V brakes',
    name_fi: 'V-jarrut',
  },
  {
    id: 5,
    name: 'Other',
    name_fi: 'Muut',
  },
]
export const colorsBikes = [
  {
    id: 1,
    name: 'Red',
    name_fi: 'Punainen',
  },
  {
    id: 2,
    name: 'Blue',
    name_fi: 'Sininen',
  },
  {
    id: 3,
    name: 'Green',
    name_fi: 'Vihreä',
  },
  {
    id: 4,
    name: 'Yellow',
    name_fi: 'Keltainen',
  },
  {
    id: 5,
    name: 'Orange',
    name_fi: 'Oranssi',
  },
  {
    id: 6,
    name: 'Pink',
    name_fi: 'Pinkki',
  },
  {
    id: 7,
    name: 'Purple',
    name_fi: 'Lila/violetti',
  },
  {
    id: 8,
    name: 'Gold',
    name_fi: 'Kultainen',
  },
  {
    id: 9,
    name: 'Silver',
    name_fi: 'Hopea',
  },
  {
    id: 10,
    name: 'Grey',
    name_fi: 'Harmaa',
  },
  {
    id: 11,
    name: 'Black',
    name_fi: 'Musta',
  },
  {
    id: 12,
    name: 'White',
    name_fi: 'Valkoinen',
  },
  {
    id: 13,
    name: 'Brown',
    name_fi: 'Ruskea',
  },
  {
    id: 14,
    name: 'Beige',
    name_fi: 'Beige',
  },
  {
    id: 15,
    name: 'Turquoise',
    name_fi: 'Turkoosi',
  },
  {
    id: 16,
    name: 'Multicolor',
    name_fi: 'Monivärinen',
  },
  {
    id: 17,
    name: 'Other',
    name_fi: 'Muu',
  },
]
export const brands = [
  {
    id: 1,
    name: 'Academy',
    name_fi: 'Academy',
  },
  {
    id: 2,
    name: 'Active',
    name_fi: 'Active',
  },
  {
    id: 3,
    name: 'All-City',
    name_fi: 'All-City',
  },
  {
    id: 4,
    name: 'Arcade',
    name_fi: 'Arcade',
  },
  {
    id: 5,
    name: 'Atala',
    name_fi: 'Atala',
  },
  {
    id: 6,
    name: 'Baana',
    name_fi: 'Baana',
  },
  {
    id: 7,
    name: 'Basso',
    name_fi: 'Basso',
  },
  {
    id: 8,
    name: 'Bianchi',
    name_fi: 'Bianchi',
  },
  {
    id: 9,
    name: 'BMC',
    name_fi: 'BMC',
  },
  {
    id: 10,
    name: 'Bobike',
    name_fi: 'Bobike',
  },
  {
    id: 11,
    name: 'Cannondale',
    name_fi: 'Cannondale',
  },
  {
    id: 12,
    name: 'Canyon',
    name_fi: 'Canyon',
  },
  {
    id: 13,
    name: 'Capriolo',
    name_fi: 'Capriolo',
  },
  {
    id: 14,
    name: 'Cervélo',
    name_fi: 'Cervélo',
  },
  {
    id: 15,
    name: 'Cinelli',
    name_fi: 'Cinelli',
  },
  {
    id: 16,
    name: 'CityGlide',
    name_fi: 'CityGlide',
  },
  {
    id: 17,
    name: 'Coast',
    name_fi: 'Coast',
  },
  {
    id: 18,
    name: 'Creme',
    name_fi: 'Creme',
  },
  {
    id: 19,
    name: 'Crescent',
    name_fi: 'Crescent',
  },
  {
    id: 20,
    name: 'Cube',
    name_fi: 'Cube',
  },
  {
    id: 21,
    name: 'Cult',
    name_fi: 'Cult',
  },
  {
    id: 22,
    name: 'Cyclette',
    name_fi: 'Cyclette',
  },
  {
    id: 23,
    name: 'Dahon',
    name_fi: 'Dahon',
  },
  {
    id: 24,
    name: 'Dartmoor',
    name_fi: 'Dartmoor',
  },
  {
    id: 25,
    name: 'Diamant',
    name_fi: 'Diamant',
  },
  {
    id: 26,
    name: 'Drag Bicycles',
    name_fi: 'Drag Bicycles',
  },
  {
    id: 27,
    name: 'Early Rider',
    name_fi: 'Early Rider',
  },
  {
    id: 28,
    name: 'EcoBike',
    name_fi: 'EcoBike',
  },
  {
    id: 29,
    name: 'Ecorides',
    name_fi: 'Ecorides',
  },
  {
    id: 30,
    name: 'Eightshot',
    name_fi: 'Eightshot',
  },
  {
    id: 31,
    name: 'Electra',
    name_fi: 'Electra',
  },
  {
    id: 32,
    name: 'Excelsior',
    name_fi: 'Excelsior',
  },
  {
    id: 33,
    name: 'Fantic',
    name_fi: 'Fantic',
  },
  {
    id: 34,
    name: 'Felt',
    name_fi: 'Felt',
  },
  {
    id: 35,
    name: 'Fischer',
    name_fi: 'Fischer',
  },
  {
    id: 36,
    name: 'FitNord',
    name_fi: 'FitNord',
  },
  {
    id: 37,
    name: 'Focus',
    name_fi: 'Focus',
  },
  {
    id: 38,
    name: 'Frog Bikes',
    name_fi: 'Frog Bikes',
  },
  {
    id: 39,
    name: 'Frog Road',
    name_fi: 'Frog Road',
  },
  {
    id: 40,
    name: 'Fuji',
    name_fi: 'Fuji',
  },
  {
    id: 41,
    name: 'Fuzion',
    name_fi: 'Fuzion',
  },
  {
    id: 42,
    name: 'Ghost',
    name_fi: 'Ghost',
  },
  {
    id: 43,
    name: 'Giant',
    name_fi: 'Giant',
  },
  {
    id: 44,
    name: 'Globber',
    name_fi: 'Globber',
  },
  {
    id: 45,
    name: 'Gomo',
    name_fi: 'Gomo',
  },
  {
    id: 46,
    name: 'GT Bicycles',
    name_fi: 'GT Bicycles',
  },
  {
    id: 47,
    name: 'Haibike',
    name_fi: 'Haibike',
  },
  {
    id: 48,
    name: 'Helkama',
    name_fi: 'Helkama',
  },
  {
    id: 49,
    name: 'Hudora',
    name_fi: 'Hudora',
  },
  {
    id: 50,
    name: 'Husqvarna',
    name_fi: 'Husqvarna',
  },
  {
    id: 51,
    name: 'Insera',
    name_fi: 'Insera',
  },
  {
    id: 52,
    name: 'Jopo',
    name_fi: 'Jopo',
  },
  {
    id: 53,
    name: 'Kalkhoff',
    name_fi: 'Kalkhoff',
  },
  {
    id: 54,
    name: 'Kinderkraft',
    name_fi: 'Kinderkraft',
  },
  {
    id: 55,
    name: 'Kona',
    name_fi: 'Kona',
  },
  {
    id: 56,
    name: 'KTM',
    name_fi: 'KTM',
  },
  {
    id: 57,
    name: 'Lapierre',
    name_fi: 'Lapierre',
  },
  {
    id: 58,
    name: 'Leg&go',
    name_fi: 'Leg&go',
  },
  {
    id: 59,
    name: 'Lionelo',
    name_fi: 'Lionelo',
  },
  {
    id: 60,
    name: 'Liv',
    name_fi: 'Liv',
  },
  {
    id: 61,
    name: 'Madd Gear',
    name_fi: 'Madd Gear',
  },
  {
    id: 62,
    name: 'Madison',
    name_fi: 'Madison',
  },
  {
    id: 63,
    name: 'Marin Bikes',
    name_fi: 'Marin Bikes',
  },
  {
    id: 64,
    name: 'MBK',
    name_fi: 'MBK',
  },
  {
    id: 65,
    name: 'Merida',
    name_fi: 'Merida',
  },
  {
    id: 66,
    name: 'Micro',
    name_fi: 'Micro',
  },
  {
    id: 67,
    name: 'Milly Mally',
    name_fi: 'Milly Mally',
  },
  {
    id: 68,
    name: 'Monark',
    name_fi: 'Monark',
  },
  {
    id: 69,
    name: 'N1',
    name_fi: 'N1',
  },
  {
    id: 70,
    name: 'Nakamura',
    name_fi: 'Nakamura',
  },
  {
    id: 71,
    name: 'Nishiki',
    name_fi: 'Nishiki',
  },
  {
    id: 72,
    name: 'Nopsa',
    name_fi: 'Nopsa',
  },
  {
    id: 73,
    name: 'Norco Bicycles',
    name_fi: 'Norco Bicycles',
  },
  {
    id: 74,
    name: 'NS Bikes',
    name_fi: 'NS Bikes',
  },
  {
    id: 75,
    name: 'OnlyOne',
    name_fi: 'OnlyOne',
  },
  {
    id: 76,
    name: 'Orbea',
    name_fi: 'Orbea',
  },
  {
    id: 77,
    name: 'Ortler',
    name_fi: 'Ortler',
  },
  {
    id: 78,
    name: 'Puky',
    name_fi: 'Puky',
  },
  {
    id: 79,
    name: 'Qu-Ax',
    name_fi: 'Qu-Ax',
  },
  {
    id: 80,
    name: 'Radio Bikes',
    name_fi: 'Radio Bikes',
  },
  {
    id: 81,
    name: 'Raymon',
    name_fi: 'Raymon',
  },
  {
    id: 82,
    name: 'Rebel Kidz',
    name_fi: 'Rebel Kidz',
  },
  {
    id: 83,
    name: 'Ridley',
    name_fi: 'Ridley',
  },
  {
    id: 84,
    name: 'Ridley Bikes',
    name_fi: 'Ridley Bikes',
  },
  {
    id: 85,
    name: 'Riva',
    name_fi: 'Riva',
  },
  {
    id: 86,
    name: 'Romet',
    name_fi: 'Romet',
  },
  {
    id: 87,
    name: 'Rondo',
    name_fi: 'Rondo',
  },
  {
    id: 88,
    name: 'Rose',
    name_fi: 'Rose',
  },
  {
    id: 89,
    name: 'RoyalBaby',
    name_fi: 'RoyalBaby',
  },
  {
    id: 90,
    name: 'Ruff Cycles',
    name_fi: 'Ruff Cycles',
  },
  {
    id: 91,
    name: "s'cool",
    name_fi: "s'cool",
  },
  {
    id: 92,
    name: 'Salsa',
    name_fi: 'Salsa',
  },
  {
    id: 93,
    name: 'Santa Cruz',
    name_fi: 'Santa Cruz',
  },
  {
    id: 94,
    name: 'Scott',
    name_fi: 'Scott',
  },
  {
    id: 95,
    name: 'SE Bikes',
    name_fi: 'SE Bikes',
  },
  {
    id: 96,
    name: 'Serious',
    name_fi: 'Serious',
  },
  {
    id: 97,
    name: 'Silverback',
    name_fi: 'Silverback',
  },
  {
    id: 98,
    name: 'Six Degrees',
    name_fi: 'Six Degrees',
  },
  {
    id: 99,
    name: 'Solifer',
    name_fi: 'Solifer',
  },
  {
    id: 100,
    name: 'SUP Cycles',
    name_fi: 'SUP Cycles',
  },
  {
    id: 101,
    name: 'Tabou',
    name_fi: 'Tabou',
  },
  {
    id: 102,
    name: 'Tern',
    name_fi: 'Tern',
  },
  {
    id: 103,
    name: 'Toyz by Caretero',
    name_fi: 'Toyz by Caretero',
  },
  {
    id: 104,
    name: 'Trek',
    name_fi: 'Trek',
  },
  {
    id: 105,
    name: 'Tunturi',
    name_fi: 'Tunturi',
  },
  {
    id: 106,
    name: 'VanMoof',
    name_fi: 'VanMoof',
  },
  {
    id: 107,
    name: 'Vermont',
    name_fi: 'Vermont',
  },
  {
    id: 108,
    name: 'wethepeople',
    name_fi: 'wethepeople',
  },
  {
    id: 109,
    name: 'Whistle',
    name_fi: 'Whistle',
  },
  {
    id: 110,
    name: 'White',
    name_fi: 'White',
  },
  {
    id: 111,
    name: 'Willier',
    name_fi: 'Willier',
  },
  {
    id: 112,
    name: 'Winora',
    name_fi: 'Winora',
  },
  {
    id: 113,
    name: 'Other',
    name_fi: 'Other',
  },
]

export const suspensionTypes = [
  {
    id: 1,
    name: 'Front fork: gas / air',
    name_fi: 'Etuhaarukka: kaasu / ilma',
  },
  {
    id: 2,
    name: 'Front fork: spring / coil',
    name_fi: 'Etuhaarukka: metallijousi',
  },
  {
    id: 3,
    name: 'Front fork: other',
    name_fi: 'Etuhaarukka: muu',
  },
  {
    id: 4,
    name: 'Rear suspension: gas / air',
    name_fi: 'Takajousitus: kaasu / ilma',
  },
  {
    id: 5,
    name: 'Rear suspension: spring / coil',
    name_fi: 'Takajousitus: metallijousi',
  },
  {
    id: 6,
    name: 'Rear suspension: other',
    name_fi: 'Takajousitus: muu',
  },
]
