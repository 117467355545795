import { createSlice } from '@reduxjs/toolkit'
import { adminDeleteBannerRequest } from './asyncActions'

import { RootState } from '../../../index'
import { ErrorResponse } from 'interfaces/Error'

export interface AdminDeleteBannerState {
  is_fetching: boolean
  done: boolean
  errors: ErrorResponse
}

const initialState: AdminDeleteBannerState = {
  is_fetching: false,
  done: false,
  errors: null,
}

export const adminDeleteBannerSlice = createSlice({
  name: 'adminDeleteBanner',
  initialState,
  reducers: {
    resetState: state => {
      state.is_fetching = false
      state.done = false
      state.errors = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(adminDeleteBannerRequest.pending, state => {
        state.is_fetching = true
      })
      .addCase(adminDeleteBannerRequest.fulfilled, state => {
        state.is_fetching = false
        state.done = true
      })
      .addCase(adminDeleteBannerRequest.rejected, (state, action: any) => {
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = null
        }
      })
  },
})

export const selectAdminDeletedBanner = (state: RootState): any => state.admin.banner.delete
export const { resetState, resetError } = adminDeleteBannerSlice.actions

export default adminDeleteBannerSlice.reducer
