import { Modal, Upload } from 'antd'
import { CloudinaryContext, Image, Video } from 'cloudinary-react'
import { CLOUDINARY_ID, CLOUDINARY_UPLOAD_PRESET } from 'constants/index'
import { FunctionComponent, useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { createMediaRequest } from 'store/bicycle/media/asyncActions'
import { CloudinaryResponse } from 'interfaces/Cloudinary'
import { deleteMedia, selectCreatedMedia } from 'store/bicycle/media'
import { Indicator } from 'Components/UiKit/Indicator'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface UploadImageInterface {
  images?: any
  viewMode?: boolean
  singleMode?: boolean
  targetType: string
  hint?: string
}

export const UploadImage: FunctionComponent<UploadImageInterface> = ({
  images = [],
  viewMode = false,
  singleMode = false,
  targetType,
  hint,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const [previewTitle, setPreviewTitle] = useState('')
  const [initialImagesSet, setInitialImagesSet] = useState(false)
  const [imagesList, setImagesList] = useState([])
  const { is_fetching } = useAppSelector(selectCreatedMedia)
  const { data: medias } = useAppSelector(selectCreatedMedia)

  const { t } = useTranslation()

  useEffect(() => {
    if (!initialImagesSet) {
      setImagesList(
        images.map((file: any) => {
          return {
            uid: file.id,
            url: file.url,
            responseType: file.type,
          }
        })
      )
      setInitialImagesSet(true)
    }
  }, [images, singleMode, initialImagesSet])

  const dispatch = useAppDispatch()

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file } = options

    const data = new FormData()
    data.append('file', file)
    data.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)
    data.append('cloud_name', 'breellz')
    fetch('https://api.cloudinary.com/v1_1/' + CLOUDINARY_ID + '/upload', {
      method: 'post',
      body: data,
    })
      .then(resp => resp.json())
      .then((response: CloudinaryResponse) => {
        if (singleMode) setImagesList([{ uid: response.public_id, url: response.secure_url, name: response.public_id }])
        else
          setImagesList([
            ...imagesList,
            {
              uid: response.public_id,
              url: response.secure_url,
              name: response.public_id,
              responseType: response.resource_type,
            },
          ])
        dispatch(
          createMediaRequest({
            cloudId: response.public_id,
            mediaType: response.resource_type,
            targetType: targetType,
          })
        )
        onSuccess('Ok')
      })
      .catch(err => {
        onError({ err })
        window.console.log(err)
      })
  }

  const handlePreview = (file: any) => {
    setPreviewVisible(true)
    setPreviewImage(file)
  }
  const handleCancel = () => {
    setPreviewVisible(false)
    setPreviewImage(null)
    setPreviewTitle('')
  }
  return (
    <>
      {is_fetching && <Indicator />}
      <Upload
        action="https://api.cloudinary.com/v1_1/breellz/image/upload"
        listType="picture-card"
        customRequest={uploadImage}
        fileList={imagesList}
        onPreview={handlePreview}
        onChange={handleCancel}
        onRemove={file => {
          setImagesList(imagesList.filter((f: any) => file.uid !== f.uid))
          dispatch(deleteMedia(medias.find((m: any) => m.url === file.url)))
        }}
      >
        {!viewMode && (
          <div>
            <PlusOutlined />
            <div className={'eight-px-top-margin'}>{t('upload')}</div>
          </div>
        )}
      </Upload>
      {hint && <Hint>{hint}</Hint>}
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <CloudinaryContext cloudName={CLOUDINARY_ID}>
          {previewImage?.responseType === 'image' ? (
            <Image publicId={previewImage?.url} width="100%" />
          ) : previewImage?.responseType === 'video' ? (
            <Video publicId={previewImage?.url} controls autoPlay width="100%" />
          ) : (
            previewImage?.fileName
          )}
        </CloudinaryContext>
      </Modal>
    </>
  )
}
const Hint = styled.div``
