import { Button, Col } from 'antd'
import { FormInput, InputAreaInterface } from 'Components/Auth/Shared/InputArea'
import { colors } from 'constants/colors'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Label } from '../../../Shared/Components/Components'

export const ProfileInput: FunctionComponent<InputAreaInterface> = ({
  name,
  title,
  control,
  error,
  defaultValue,
  type,
  addonBefore,
}) => {
  return (
    <ProfileElement xs={{ span: 24 }} md={{ span: 12 }}>
      <Label>{title}</Label>
      <FormInput
        addonBefore={addonBefore}
        defaultValue={defaultValue}
        name={name}
        title={title}
        control={control}
        error={error}
        type={type}
      />
    </ProfileElement>
  )
}
export const ProfileElement = styled(Col)`
  padding: 12px 10px;
`

export const SaveContainer = styled(ProfileElement)`
  display: flex;
  align-items: end;
  justify-content: end;
`
export const SaveButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
`
