import { AppAuthService, RegisterUserVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const registerRequestAsync: AsyncThunk<void, RegisterUserVM, any> = createAsyncThunk(
  'auth/register',
  async (body: RegisterUserVM, { rejectWithValue }) => {
    try {
      return await AppAuthService.appAuthRegister(body)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
