import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AppCityService } from 'api/main'
import { GET_CITY_SIZE } from 'constants/index'
import { CityResponse } from '.'

export const cityRequestAsync: AsyncThunk<CityResponse[], void, any> = createAsyncThunk(
  'auth/city',
  async (_, { rejectWithValue }) => {
    try {
      return await AppCityService.appCityList(0, GET_CITY_SIZE, 'name')
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
