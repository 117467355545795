import { AdminBicycleService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AdminGetAllBicyclesResponse, GetALlBicyclesAdminRequest } from '../Interfaces'

export const getAllBicyclesRequestAsync: AsyncThunk<AdminGetAllBicyclesResponse, GetALlBicyclesAdminRequest, any> =
  createAsyncThunk('admin/bicycles/getAll', async (requestBody: GetALlBicyclesAdminRequest, { rejectWithValue }) => {
    try {
      return await AdminBicycleService.adminBicycleList(
        requestBody.postType,
        requestBody.status,
        requestBody.bicycleTypeId,
        requestBody.brandId,
        requestBody.price,
        requestBody.year,
        requestBody.userId,
        requestBody.keyword,
        requestBody.page - 1,
        requestBody.size,
        requestBody.sort.toString()
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  })
