import { AdminUserService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AdminGetAllUsersResponse, GetALlUsersAdminRequest } from '../Interfaces'

export const getAllUsersRequestAsync: AsyncThunk<AdminGetAllUsersResponse, GetALlUsersAdminRequest, any> =
  createAsyncThunk('admin/users/getAll', async (requestBody: GetALlUsersAdminRequest, { rejectWithValue }) => {
    try {
      return await AdminUserService.adminUserList(requestBody.keyword, requestBody.page - 1, requestBody.size)
    } catch (error) {
      return rejectWithValue(error)
    }
  })
