/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Media type
 */
export enum MediaType {
    IMAGE = 'image',
    VIDEO = 'video',
    RAW = 'raw',
}