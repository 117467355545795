import { Row } from 'antd'
import { H1 } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { colors } from 'constants/colors'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

export const TermsPage: FunctionComponent = () => {
  const { t } = useTranslation<string>()

  return (
    <GrayBackground>
      <HelmetWrapper parentData={{ titleKey: 'termsAndConditions' }} />
      <ContentCenter>
        <Row>
          <H1>{t('termsAndConditions')}</H1>
        </Row>
        <ContentBody>{t('termsAndConditionsBody')}</ContentBody>
      </ContentCenter>
    </GrayBackground>
  )
}

const GrayBackground = styled.div`
  background-color: ${colors.LIGHT_GRAY};
`
const ContentCenter = styled.div`
  margin: auto;
  max-width: 850px;
  padding: 110px 0;
`
const ContentBody = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
`
