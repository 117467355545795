import { PrivateRoutePresentation, PrivateRoutePresentationProps } from '../RoutePresentation'
import { RootState } from 'store'
import { RouteProps } from 'react-router'
import { connect } from 'react-redux'
import { AuthStatus } from 'constants/index'
import { AuthRole } from 'store/auth/login'

export const AdminGuardRoute = connect<PrivateRoutePresentationProps, RecordObject, RouteProps, RootState>(
  (state: RootState): PrivateRoutePresentationProps => {
    const { data, status } = state.auth.auth
    const role = data?.role
    return {
      isAuthorized: status === AuthStatus.loggedIn && role !== AuthRole.ROLE_USER,
      pathname: '/home',
    }
  }
)(PrivateRoutePresentation)

export type RecordObject<T = unknown> = Record<string, T>
