import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { getAllPostsRequestAsync } from './asyncActions'
import { ErrorResponse } from 'interfaces/Error'
import { BlogListView } from 'api/main'

export interface AdminPostsGetAllState {
  is_fetching: boolean
  data: BlogListView[]
  currentPageNumber: number
  isLast?: boolean
  pageSize: number
  totalElements?: number
  errors: ErrorResponse
}

const initialState: AdminPostsGetAllState = {
  is_fetching: false,
  data: null,
  currentPageNumber: null,
  isLast: false,
  pageSize: null,
  totalElements: null,
  errors: null,
}

export const getAllPostsSlice = createSlice({
  name: 'adminAllPosts',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getAllPostsRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(getAllPostsRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload?.content
        state.currentPageNumber = payload?.currentPageNumber
        state.isLast = payload?.isLast
        state.pageSize = payload?.pageSize
        state.totalElements = payload?.totalElements
      })
      .addCase(getAllPostsRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.data = null
        state.currentPageNumber = null
        state.isLast = false
        state.pageSize = null
        state.totalElements = null
        state.totalElements = null
        state.is_fetching = false
      })
  },
})

export const selectAdminPosts = (state: RootState): AdminPostsGetAllState => state.admin.blog.getAll

export default getAllPostsSlice.reducer
