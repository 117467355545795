import { Layout, Menu } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { FunctionComponent } from 'react'
import { UserOutlined, BarsOutlined, FileTextOutlined, FileImageOutlined, MailOutlined } from '@ant-design/icons'
import { AdminHeader } from 'Components/Admin/Shared/Header/Header'
import { Content } from 'antd/lib/layout/layout'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { colors } from 'constants/colors'

const adminSiderBarMenu = [
  {
    label: 'users',
    icon: <UserOutlined />,
    link: '/admin/users',
  },
  {
    label: 'bicycles',
    icon: <BarsOutlined />,
    link: '/admin/bicycles',
  },
  {
    label: 'blog',
    icon: <FileTextOutlined />,
    link: '/admin/blog',
  },
  {
    label: 'banner',
    icon: <FileImageOutlined />,
    link: '/admin/banner',
  },
  {
    label: 'newsletter',
    icon: <MailOutlined />,
    link: '/admin/newsletter',
  },
  {
    label: 'contents',
    icon: <FileTextOutlined />,
    link: '/admin/content',
  },
]

export const AdminLayout: FunctionComponent = ({ children }) => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const gotoPage = (path: string) => history.push(path)

  return (
    <ContentMinHeight>
      <AdminHeader />
      <LayoutMinHeight hasSider>
        <SiderBackground breakpoint="sm" collapsedWidth="0">
          <div className="logo" />
          <Menu mode="inline" defaultSelectedKeys={['1']}>
            {adminSiderBarMenu.map(sideBarItem => (
              <Menu.Item key={sideBarItem.label} icon={sideBarItem.icon} onClick={() => gotoPage(sideBarItem.link)}>
                {t(sideBarItem.label)}
              </Menu.Item>
            ))}
          </Menu>
        </SiderBackground>
        <LayoutGrayBG>{children}</LayoutGrayBG>
      </LayoutMinHeight>
    </ContentMinHeight>
  )
}
const ContentMinHeight = styled(Content)`
  min-height: 90vh;
`
const LayoutMinHeight = styled(Layout)`
  min-height: 90vh;
`
const LayoutGrayBG = styled(Layout)`
  background-color: ${colors.SELL_BACKGROUND} !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
`
const SiderBackground = styled(Sider)`
  background: ${colors.WHITE};
`
