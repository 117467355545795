import { AppAuthService, ResetPasswordVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const setPasswordRequestAsync: AsyncThunk<void, ResetPasswordVM, any> = createAsyncThunk(
  'auth/setPasswordRequest',
  async (body: ResetPasswordVM, { rejectWithValue }) => {
    try {
      return await AppAuthService.appAuthResetPassword(body)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
