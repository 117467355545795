/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseChangeBicycleStatusVM } from '../models/BaseChangeBicycleStatusVM';
import type { FeatureType } from '../models/FeatureType';
import type { PayAndRequestReviewBicycleVM } from '../models/PayAndRequestReviewBicycleVM';
import type { PostType } from '../models/PostType';
import type { RejectBicycleVM } from '../models/RejectBicycleVM';
import type { RequestReviewBicycleVM } from '../models/RequestReviewBicycleVM';
import type { SaveBicycleVMSaveFromAppView } from '../models/SaveBicycleVMSaveFromAppView';
import { request as __request } from '../core/request';

export class AppBicycleService {

    /**
     * Create bicycle
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static async appBicycleCreate1(
        requestBody?: SaveBicycleVMSaveFromAppView,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/bicycle`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Check if feature has capacity or not
     * @param type feature type
     * @returns any OK
     * @throws ApiError
     */
    public static async appBicycleFeatureCapacity(
        type: 'TOP_TEN' | 'TOP_FIVE',
    ): Promise<{
        available: boolean,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/bicycles/feature/capacity`,
            query: {
                'type': type,
            },
        });
        return result.body;
    }

    /**
     * List all user bicycles
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns any OK
     * @throws ApiError
     */
    public static async appBicycleList(
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/bicycles`,
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Search bicycles
     * @param bicycleTypeIds
     * @param brakeIds
     * @param brandIds
     * @param colorIds
     * @param frameMaterialIds
     * @param postTypes
     * @param frameSizeIds
     * @param gearIds
     * @param genderIds
     * @param categoryIds
     * @param batteryLocationIds
     * @param powerIds
     * @param motorIds
     * @param featureType
     * @param keyword keyword
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns any OK
     * @throws ApiError
     */
    public static async appBicycleSearch(
        bicycleTypeIds?: Array<number>,
        brakeIds?: Array<number>,
        brandIds?: Array<number>,
        colorIds?: Array<number>,
        frameMaterialIds?: Array<number>,
        postTypes?: Array<PostType>,
        frameSizeIds?: Array<number>,
        gearIds?: Array<number>,
        genderIds?: Array<number>,
        categoryIds?: Array<number>,
        batteryLocationIds?: Array<number>,
        powerIds?: Array<number>,
        motorIds?: Array<number>,
        featureType?: FeatureType,
        keyword?: string,
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/bicycles/search`,
            query: {
                'bicycleTypeIds': bicycleTypeIds,
                'brakeIds': brakeIds,
                'brandIds': brandIds,
                'colorIds': colorIds,
                'frameMaterialIds': frameMaterialIds,
                'postTypes': postTypes,
                'frameSizeIds': frameSizeIds,
                'gearIds': gearIds,
                'genderIds': genderIds,
                'categoryIds': categoryIds,
                'batteryLocationIds': batteryLocationIds,
                'powerIds': powerIds,
                'motorIds': motorIds,
                'featureType': featureType,
                'keyword': keyword,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Show bicycle
     * @param bicycleId bicycleId
     * @returns any OK
     * @throws ApiError
     */
    public static async appBicycleShow(
        bicycleId: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/bicycles/${bicycleId}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Update bicycle status
     * @param bicycleId bicycleId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async appBicycleStatusUpdate(
        bicycleId: number,
        requestBody?: (RequestReviewBicycleVM | RejectBicycleVM | BaseChangeBicycleStatusVM),
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/app/bicycles/${bicycleId}/status`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Subscribe bicycle
     * @param bicycleId bicycleId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async appBicycleSubscribe(
        bicycleId: number,
        requestBody?: PayAndRequestReviewBicycleVM,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/bicycles/${bicycleId}/subscribe`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}