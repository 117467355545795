import { Row } from 'antd'
import { BannerPosition, BlogListView } from 'api/main'
import { Indicator } from 'Components/UiKit/Indicator'
import { colors } from 'constants/colors'
import { blogPosts } from 'constants/index'
import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectBanners } from 'store/banners/getAll'
import { getAppBannerRequestAsync } from 'store/banners/getAll/asyncActions'
import { selectPosts } from 'store/blog/get'
import { getBlogPostsRequestAsync } from 'store/blog/get/asyncActions'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import styled from 'styled-components'
import { ColPost } from './ColPost/ColPost'
import { TopPost } from './TopPost/TopPost'
import { getContentRequestAsync } from '../../store/contents/get/asyncActions'
import { selectAppContent } from '../../store/contents/get'
import { HelmetWrapper } from '../UiKit/HelmetWrapper/HelmetWrapper'
import TopPanelImage from '../../assets/images/blog/blog_header-min.jpg'

export const Blog: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const [page] = useState(1)
  const { i18n } = useTranslation<string>()

  const { is_fetching, data: posts } = useAppSelector(selectPosts)
  const { blog: blogBanner } = useAppSelector(selectBanners)

  useEffect(() => {
    dispatch(getBlogPostsRequestAsync({ keyword: '', page, size: blogPosts }))
  }, [dispatch, page])

  useEffect(() => {
    dispatch(getAppBannerRequestAsync(BannerPosition.BLOG))
  }, [dispatch])

  useEffect(() => {
    dispatch(getContentRequestAsync('BLOG_HEADING'))
  }, [dispatch])

  const { data } = useAppSelector(selectAppContent)

  return (
    <Container>
      <TopPanel></TopPanel>
      <BlogWrapper>
        <HelmetWrapper parentData={{ descriptionKey: 'blogMeta', titleKey: 'blogTitle' }} />
        {is_fetching && <Indicator />}
        {data && <div dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? data.body : data.finnishBody }} />}
        {posts[0] && <TopPost post={posts[0]} />}
        <MiddlePostsWrapper justify="center">
          {posts[1] && <ColPost md={{ span: 8 }} xs={{ span: 20 }} post={posts[1]}></ColPost>}
          {posts[2] && <ColPost md={{ span: 8 }} xs={{ span: 20 }} post={posts[2]}></ColPost>}
          {posts[3] && <ColPost md={{ span: 8 }} xs={{ span: 20 }} post={posts[3]}></ColPost>}
        </MiddlePostsWrapper>
        {blogBanner && blogBanner.length > 0 && (
          <BlogBanner>
            <a href={blogBanner[0].url}>
              <Img alt={blogBanner[0]?.title} src={blogBanner[0]?.photo?.url} />
            </a>
          </BlogBanner>
        )}
        {posts.length > 4 && (
          <MiddlePostsWrapper justify="center">
            {posts.slice(4).map((post: BlogListView) => (
              <ColPost key={post.id} md={{ span: 12 }} xs={{ span: 20 }} post={post} />
            ))}
          </MiddlePostsWrapper>
        )}
      </BlogWrapper>
    </Container>
  )
}

const MiddlePostsWrapper = styled(Row)`
  margin: 0 auto;
  max-width: fit-content;
`

const BlogWrapper = styled.div`
  padding: 10px;
  margin: auto;
  max-width: 1200px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.GRAY_TEXT};
`
const BlogBanner = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const Img = styled.img`
  max-width: 100%;
`
const Container = styled.div`
  background-color: ${colors.SELL_BACKGROUND};
`
const TopPanel = styled.div`
  background-image: url(${TopPanelImage});
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 0;
  padding-top: 25%;
  background-position: center center;
  background-size: contain;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  width: 100%;
`
