import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AppUserService, UpdateLanguageVM } from 'api/main'

export const languageChangeRequestAsync: AsyncThunk<any, UpdateLanguageVM, any> = createAsyncThunk(
  'auth/language',
  async (request: UpdateLanguageVM, { rejectWithValue }) => {
    try {
      return await AppUserService.appLanguageUpdate(request)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
