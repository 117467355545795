import { FunctionComponent, ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

class HelmetWrapperProps {
  parentData?: {
    titleKey?: string
    title?: string
    finnishTitle?: string
    description?: string
    descriptionKey?: string
    finnishDescription?: string
    keywords?: string
    image?: string
    isArticle?: boolean
    noIndex?: boolean
  }
  children?: ReactNode
}

export const HelmetWrapper: FunctionComponent<HelmetWrapperProps> = ({
  parentData: {
    title = 'A digital marketplace for used and new bicycles',
    titleKey,
    finnishTitle = 'Käytettyjen ja uusien polkupyörien kauppapaikka netissä – valitse suosikkisi!',
    finnishDescription = 'Monipuolinen polkupyöräkauppasi netissä',
    description = 'Your versatile bike selling and buying site online',
    descriptionKey,
    keywords = 'gravel, lasten, maantiepyörät, maastopyörät, muut, nuorten, preview, pyörät, sähköpyörät',
    noIndex = false,
    image = window.location.origin + '/img/ogMainImage.png',
    isArticle = false,
  },
  children,
}: HelmetWrapperProps) => {
  const isFin = i18n.language === 'fi'
  const { t } = useTranslation<string>()

  function getDescription() {
    return descriptionKey ? t(descriptionKey) : isFin ? finnishDescription : description
  }

  function getTitle() {
    return titleKey ? t(titleKey) : isFin ? finnishTitle : title
  }

  return (
    <Helmet>
      {children}
      {noIndex && <meta name="robots" content="noindex" />}
      <title>{getTitle()}</title>
      <meta name="description" content={getDescription()} />
      <meta name="keywords" content={keywords} />
      <meta property={'og:title'} content={getTitle()} />
      <meta property={'og:type'} content={isArticle ? 'article' : 'website'} />
      <meta property={'og:image'} content={image} />
      <meta property={'og:url'} content={window.location.href} />
      <meta property={'og:locale'} content={isFin ? 'fi-FI' : 'en_US'} />
      <meta property={'og:site_name'} content={'Nettifillari'} />
    </Helmet>
  )
}
