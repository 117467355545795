import { PrivateRoutePresentation, PrivateRoutePresentationProps } from '../RoutePresentation'
import { RootState } from 'store'
import { RouteProps } from 'react-router'
import { connect } from 'react-redux'
import { AuthStatus } from 'constants/index'
import { AuthRole } from 'store/auth/login'

export const LoginGuardRoute = connect<PrivateRoutePresentationProps, RecordObject, RouteProps, RootState>(
  (state: RootState): PrivateRoutePresentationProps => {
    const { status, data } = state.auth.auth
    const isAdmin = data?.role !== AuthRole.ROLE_USER
    return {
      isAuthorized: status !== AuthStatus.loggedIn,
      pathname: isAdmin ? '/admin' : '/home',
    }
  }
)(PrivateRoutePresentation)

export type RecordObject<T = unknown> = Record<string, T>
