import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { getAllBannerRequestAsync } from './asyncActions'
import { ErrorResponse } from 'interfaces/Error'
import { BannerListView } from 'api/main'

export interface AdminBannersGetAllState {
  is_fetching: boolean
  data: BannerListView[]
  currentPageNumber: number
  isLast?: boolean
  pageSize: number
  totalElements?: number
  errors: ErrorResponse
}

const initialState: AdminBannersGetAllState = {
  is_fetching: false,
  data: null,
  currentPageNumber: null,
  isLast: false,
  pageSize: null,
  totalElements: null,
  errors: null,
}

export const getAllBannersSlice = createSlice({
  name: 'adminAllBanners',
  initialState,
  reducers: {
    resetState: state => {
      state.is_fetching = false
      state.data = null
      state.errors = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAllBannerRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(getAllBannerRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload?.content
        state.currentPageNumber = payload?.currentPageNumber
        state.isLast = payload?.isLast
        state.pageSize = payload?.pageSize
        state.totalElements = payload?.totalElements
      })
      .addCase(getAllBannerRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.data = null
        state.currentPageNumber = null
        state.isLast = false
        state.pageSize = null
        state.totalElements = null
        state.totalElements = null
        state.is_fetching = false
      })
  },
})

export const selectAdminBanners = (state: RootState): AdminBannersGetAllState => state.admin.banner.getAll
export const { resetState, resetError } = getAllBannersSlice.actions

export default getAllBannersSlice.reducer
