import { createSlice } from '@reduxjs/toolkit'
import { createMediaRequest } from './asyncActions'

import { RootState } from '../../index'
import { ErrorResponse } from 'interfaces/Error'

export interface MediaResponse {
  cloudId?: string
  format?: string
  id?: number
  type?: string
  url?: string
}

export interface MediaState {
  is_fetching: boolean
  data: MediaResponse[]
  errors: ErrorResponse
}

const initialState: MediaState = {
  is_fetching: false,
  data: [],
  errors: null,
}

const removeDuplicates = (arr: MediaResponse[]) => {
  return arr.filter((item, index) => {
    return arr.findIndex(i => i.id === item.id) === index
  })
}

export const createMediaSlice = createSlice({
  name: 'createMedia',
  initialState,
  reducers: {
    addMedia: (state, action) => {
      state.data.push(action.payload)
      state.data = removeDuplicates(state.data)
    },
    deleteMedia: (state, action) => {
      state.data = state.data.filter((f: MediaResponse) => action.payload.id !== f.id)
    },
    resetState: state => {
      state.is_fetching = false
      state.data = []
      state.errors = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(createMediaRequest.pending, state => {
        state.is_fetching = true
      })
      .addCase(createMediaRequest.fulfilled, (state, action) => {
        state.is_fetching = false

        state.data.push(action.payload)
      })
      .addCase(createMediaRequest.rejected, (state, action: any) => {
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
      })
  },
})

export const selectCreatedMedia = (state: RootState): any => state.bicycles.media
export const { resetState, resetError, deleteMedia, addMedia } = createMediaSlice.actions

export default createMediaSlice.reducer
