import { createSlice } from '@reduxjs/toolkit'
import { getOnePostsRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { BlogDetailsView } from 'api/main'

export interface PostState {
  is_fetching: boolean
  data: BlogDetailsView
}

const initialState: PostState = {
  is_fetching: false,
  data: null,
}

export const postSlice = createSlice({
  name: 'currentPost',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getOnePostsRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(getOnePostsRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload
      })
      .addCase(getOnePostsRequestAsync.rejected, state => {
        state.is_fetching = false
      })
  },
})

export const selectPost = (state: RootState): any => state.blog.current

export default postSlice.reducer
