import { AdminBlogService, SaveBlogVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const adminCreatePostRequest: AsyncThunk<any, SaveBlogVM, any> = createAsyncThunk(
  'admin/blog/create',
  async (requestBody: SaveBlogVM, { rejectWithValue }) => {
    try {
      return await AdminBlogService.adminBlogCreate(requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
