import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Divider, message, Radio, Row, Switch } from 'antd'
import { colors } from 'constants/colors'
import React, { useEffect, useMemo, useState } from 'react'
import { FunctionComponent, useCallback } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { ArrowLeftOutlined, PhoneOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { PostType, SaveBicycleVMSaveFromAdminView } from 'api/main'
import { Indicator } from 'Components/UiKit/Indicator'
import { MediaResponse, selectCreatedMedia, resetState as resetMediaState } from 'store/bicycle/media'
import {
  batteryLocation,
  bikeTypes,
  brakes,
  brands,
  cityCategory,
  colorsBikes,
  eBikesCategories,
  frameSizes,
  framesMaterial,
  gears,
  genders,
  hybridsCategory,
  kidsCategories,
  motors,
  mountainCategories,
  OptionInterface,
  otherCategories,
  powers,
  roadCategories,
  suspensionTypes,
  tireSizes,
} from 'Components/Profile/Bicyles/NewBicycle/Options'
import { UploadImage } from 'Components/Profile/Bicyles/UploadImage/UploadImage'
import { resetError, resetState, selectAdminCreatedBicycle } from 'store/admin/bicycles/create'
import { adminCreateBicycleRequest } from 'store/admin/bicycles/create/asyncActions'
import { selectAdminUsers } from 'store/admin/user/getAll'
import { getAllUsersRequestAsync } from 'store/admin/user/getAll/asyncActions'
import { ALL_USERS_SIZE, BICYCLE_MEDIA_LABEL, CAPACITY_TOP_10, CAPACITY_TOP_5 } from 'constants/index'
import { getCapacityRequestAsync } from 'store/bicycle/capacity/asyncActions'
import { selectCapacity } from 'store/bicycle/capacity'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'
import { IconWrapper } from '../../../Auth/Shared/TinyComponents/TinyComponents'
import {
  CustomTextArea,
  SelectBicycleInput,
  SelectBicycleItems,
  SelectBicycleWrapper,
} from 'Components/Shared/Components/Components'

const eBikeId = bikeTypes.find(item => item.name === 'E-bikes').id

export const AdminNewBicycle: FunctionComponent = () => {
  const history = useHistory()
  const { t } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const [showEbikeOptions, setShowEbikeOptions] = useState(false)

  const conditions = useMemo(
    () =>
      Object.entries(PostType).map(([key, value]) => {
        return {
          id: key,
          name: t(value),
          name_fi: t(value),
        } as OptionInterface
      }),
    [t]
  )

  const newBicycleSchema = (t: TFunction): Yup.ObjectSchema<ObjectShape> =>
    Yup.object().shape({
      bicycleTypeId: Yup.number().required(t('required')),
      brakeId: Yup.number().required(t('required')),
      brandId: Yup.number().required(t('required')),
      categoryId: Yup.number().required(t('required')),
      colorId: Yup.number().required(t('required')),
      deliveryInstructions: Yup.string().nullable(),
      description: Yup.string().required(t('required')),
      frameMaterialId: Yup.number(),
      frameSizeId: Yup.number().required(t('required')),
      gearId: Yup.number().required(t('required')),
      genderId: Yup.number().required(t('required')),
      model: Yup.string().nullable(),
      postType: Yup.string().required(t('required')),
      price: Yup.string().required(t('required')),
      suspensionId: Yup.string().nullable(),
      tireId: Yup.number().required(t('required')),
      year: Yup.string().required(t('required')),
      mainMediaId: Yup.number().nullable(),
      batteryLocationId: Yup.number().when('bicycleTypeId', {
        is: (val: number) => val === eBikeId,
        then: Yup.number().required('Required'),
      }),
      whatsappNumber: Yup.string()
        .min(7, t('invalidPhone'))
        .matches(/^[1-9][0-9]*$/, t('invalidPhone')),
      motorId: Yup.number().when('bicycleTypeId', {
        is: (val: number) => val === eBikeId,
        then: Yup.number().required('Required'),
      }),
      powerId: Yup.number().when('bicycleTypeId', {
        is: (val: number) => val === eBikeId,
        then: Yup.number().required('Required'),
      }),
    })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SaveBicycleVMSaveFromAdminView>({
    defaultValues: {},
    resolver: yupResolver(newBicycleSchema(t)),
  })

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const watchbicycleTypeId = watch('bicycleTypeId')
  const { errors: createBicyleErrors, done, is_fetching } = useAppSelector(selectAdminCreatedBicycle)
  const { available_10, available_5 } = useAppSelector(selectCapacity)
  const { data: usersList } = useAppSelector(selectAdminUsers)
  const { data: mediaIds } = useAppSelector(selectCreatedMedia)

  const users: OptionInterface[] = useMemo(() => {
    if (Array.isArray(usersList))
      return usersList.map(user => {
        const name = user.firstName + ' ' + user.lastName
        return { id: user.id, name, name_fi: name }
      })
    return []
  }, [usersList])

  useEffect(() => {
    dispatch(getAllUsersRequestAsync({ keyword: '', page: 1, size: ALL_USERS_SIZE }))
  }, [dispatch])

  useEffect(() => {
    const subscription = watch(value => {
      value.bicycleTypeId === eBikeId ? setShowEbikeOptions(true) : setShowEbikeOptions(false)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (createBicyleErrors) {
      createBicyleErrors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
      dispatch(resetError())
    }
  }, [createBicyleErrors, dispatch])

  useEffect(() => {
    if (done && !createBicyleErrors) {
      message.success(t('bicycleIsCreated'))
      history.push('/admin/bicycles')
    }
    return () => {
      dispatch(resetState())
      dispatch(resetMediaState())
    }
  }, [dispatch, done, history, createBicyleErrors, t])

  const onSubmit = handleSubmit((data: SaveBicycleVMSaveFromAdminView) => {
    if (data.whatsappNumber) data.whatsappNumber = '+358' + data.whatsappNumber
    if (mediaIds) data.mediaIds = mediaIds.map((mediaID: MediaResponse) => mediaID.id)
    if (mediaIds.length === 0) {
      message.warning(t('pleaseUploadImage'))
      return
    }
    dispatch(adminCreateBicycleRequest(data))
  })

  useEffect(() => {
    dispatch(getCapacityRequestAsync(CAPACITY_TOP_10))
    dispatch(getCapacityRequestAsync(CAPACITY_TOP_5))
  }, [dispatch])

  const categories = useMemo(() => {
    switch (watchbicycleTypeId) {
      case 1:
        return cityCategory
      case 2:
        return hybridsCategory
      case 3:
        return mountainCategories
      case 4:
        return eBikesCategories
      case 5:
        return kidsCategories
      case 6:
        return roadCategories
      case 7:
        return otherCategories
      default:
        return []
    }
  }, [watchbicycleTypeId])
  useEffect(() => {
    if (mediaIds.length === 0) {
      setValue('mainMediaId', null)
    }
  }, [mediaIds, setValue])

  const mainMediaId = watch('mainMediaId')

  const imageSrc = useMemo(() => {
    return mediaIds.find((item: { id: number }) => item.id === mainMediaId)?.url
  }, [mediaIds, mainMediaId])

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'newBicycle' }} />
      {is_fetching && <Indicator />}
      <form onSubmit={onSubmit}>
        <Row justify="space-between">
          <Col>
            <BackButton onClick={goBack} icon={<ArrowLeftOutlined />} />
            <H1>{t('newBicycle')}</H1>
          </Col>
          <GreenSwitch checkedChildren={t('public')} unCheckedChildren={t('private')} defaultChecked />
        </Row>
        <Divider />
        <Row justify="space-between" gutter={20}>
          <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
            <SelectBicycleItems
              control={control}
              required
              label={t('user')}
              options={users}
              error={errors.ownerId}
              name={'ownerId'}
            />
            <SelectBicycleItems
              control={control}
              required
              label={t('condition')}
              options={conditions}
              error={errors.postType}
              name={'postType'}
            />
            <SelectBicycleWrapper
              label={t('description')}
              required
              name={'description'}
              control={control}
              error={errors.description}
            >
              <CustomTextArea />
            </SelectBicycleWrapper>
            <SelectBicycleWrapper
              label={t('deliveryInstruction')}
              name={'deliveryInstructions'}
              control={control}
              error={errors.deliveryInstructions}
            >
              <CustomTextArea />
            </SelectBicycleWrapper>
            <SelectBicycleWrapper
              label={t('featureIn')}
              name={'featureType'}
              control={control}
              error={errors.featureType}
            >
              <Radio.Group>
                {available_5 && <Radio value={'top5'}>{t('top5Euro')}</Radio>}
                {available_10 && <Radio value={'top10'}>{t('top10Euro')}</Radio>}
              </Radio.Group>
            </SelectBicycleWrapper>
            <UploadImage hint={t('bicycleSizeHint')} targetType={BICYCLE_MEDIA_LABEL} images={[]} />
            <SelectBicycleItems
              label={t('mainMediaId')}
              options={mediaIds.map((media: { id: any; url: any }) => {
                return {
                  id: media.id,
                  name: media.url.split('/').pop(),
                  name_fi: media.url.split('/').pop(),
                } as OptionInterface
              })}
              control={control}
              error={errors.mainMediaId}
              name={'mainMediaId'}
            />
            {imageSrc && <MainImage alt="" src={imageSrc} />}
          </InputCol>
          <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
            <SelectBicycleItems
              label={t('productType')}
              options={bikeTypes}
              required
              control={control}
              error={errors.bicycleTypeId}
              name={'bicycleTypeId'}
            />
            <SelectBicycleItems
              label={t('category')}
              options={categories}
              required
              control={control}
              error={errors.categoryId}
              name={'categoryId'}
            />
            {showEbikeOptions && (
              <>
                <SelectBicycleItems
                  label={t('batteryLocation')}
                  options={batteryLocation}
                  required
                  control={control}
                  error={errors.batteryLocationId}
                  name={'batteryLocationId'}
                />
                <SelectBicycleItems
                  label={t('motor')}
                  required
                  options={motors}
                  control={control}
                  error={errors.motorId}
                  name={'motorId'}
                />
                <SelectBicycleItems
                  label={t('powers')}
                  required
                  options={powers}
                  control={control}
                  error={errors.powerId}
                  name={'powerId'}
                />
              </>
            )}
            <SelectBicycleItems
              label={t('gender')}
              required
              options={genders}
              error={errors.genderId}
              name={'genderId'}
              control={control}
            />
            <SelectBicycleItems
              label={t('brand')}
              options={brands}
              required
              error={errors.brandId}
              name={'brandId'}
              control={control}
            />
            <SelectBicycleInput label={t('model')} name={'model'} control={control} error={errors.model} />
            <SelectBicycleInput label={t('year')} required name={'year'} control={control} error={errors.year} />
            <SelectBicycleInput label={t('price')} required name={'price'} control={control} error={errors.price} />
          </InputCol>
          <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
            <SelectBicycleItems
              label={t('tireSize')}
              required
              options={tireSizes}
              error={errors.tireId}
              name={'tireId'}
              control={control}
            />
            <SelectBicycleItems
              label={t('frameSize')}
              options={frameSizes}
              required
              error={errors.frameSizeId}
              name={'frameSizeId'}
              control={control}
            />
            <SelectBicycleItems
              label={t('gears')}
              options={gears}
              required
              error={errors.gearId}
              name={'gearId'}
              control={control}
            />
            <SelectBicycleItems
              label={t('framesMaterial')}
              options={framesMaterial}
              error={errors.frameMaterialId}
              name={'frameMaterialId'}
              control={control}
            />
            <SelectBicycleItems
              label={t('brakes')}
              options={brakes}
              required
              error={errors.brakeId}
              name={'brakeId'}
              control={control}
            />
            <SelectBicycleItems
              label={t('colors')}
              options={colorsBikes}
              error={errors.colorId}
              required
              name={'colorId'}
              control={control}
            />
            <SelectBicycleItems
              label={t('suspension')}
              // isMulti
              options={suspensionTypes}
              error={errors.suspensionId}
              name={'suspensionId'}
              control={control}
            />
            <SelectBicycleInput
              label={t('whatsappNumber')}
              name={'whatsappNumber'}
              control={control}
              error={errors.whatsappNumber}
              prefix={
                <IconWrapper>
                  <PhoneOutlined />
                  {t('phonePrefix')}
                </IconWrapper>
              }
            />
          </InputCol>
        </Row>
        <Row justify="end">
          <RequestButton htmlType="submit">{t('request')}</RequestButton>
        </Row>
      </form>
    </Container>
  )
}

const Container = styled.div`
  font-family: 'Roboto', 'Poppins';
  line-height: 28px;
  font-style: normal;
  background-color: ${colors.WHITE};
  padding: 16px;
`
const GreenSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${colors.BRAND_GREEN};
  }
`
const InputCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MainImage = styled.img`
  border: 3px dashed gray;
`
const RequestButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  background: ${colors.BLUE};
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: ${colors.WHITE};
`
const H1 = styled.h1`
  font-weight: bold;
  font-size: 20px;
  color: ${colors.BLACK_TR};
  display: inline-block;
`
const BackButton = styled(Button)`
  background: ${colors.WHITE};
  border: none;
`
