import { FunctionComponent } from 'react'
import logo from 'assets/images/logo.png'
import { Row } from 'antd'
import { Padding } from '../TinyComponents/TinyComponents'
import { Link } from 'react-router-dom'

export const HeaderComponent: FunctionComponent = () => {
  return (
    <Padding>
      <Row>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </Row>
    </Padding>
  )
}
