/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Post Type (Condition)
 */
export enum PostType {
    LEASING = 'LEASING',
    NEW = 'NEW',
    RENTING = 'RENTING',
    SECOND_HAND = 'SECOND_HAND',
    STOLEN = 'STOLEN',
}