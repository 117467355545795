import { FC } from 'react'
import { useContent } from './useContent'
import { useContentForm } from './useContentForm'
import { useQuillField } from './useQuillField'
import styled from 'styled-components'
import { colors } from '../../../../constants/colors'
import { Indicator } from '../../../UiKit/Indicator'
import { Button, Row, Space } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const EditContent: FC = () => {
  const { content, isFetchingContent } = useContent()

  const { errors, handleSubmit, submitting, setValue, getValues } = useContentForm(content)

  const bodyRef = useQuillField(setValue, getValues, 'body', content)
  const finnishBodyRef = useQuillField(setValue, getValues, 'finnishBody', content)
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Container>
      {(isFetchingContent || submitting) && <Indicator />}
      <form onSubmit={e => handleSubmit(e)}>
        <Topbar justify="space-between">
          <Space>
            <Button type="text" onClick={() => history.push('/admin/content')} icon={<ArrowLeftOutlined />} />
            {t('editContent')}
          </Space>
        </Topbar>

        <Quill>
          <label>{t('body')}</label>
          <div ref={bodyRef} />
          <p>{errors.body?.message}</p>
        </Quill>

        <Quill>
          <label>{t('finnishBody')}</label>
          <div ref={finnishBodyRef} />
          <p>{errors.finnishBody?.message}</p>
        </Quill>

        <Row justify="end">
          <SubmitButton htmlType="submit">{t('update')}</SubmitButton>
        </Row>
      </form>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 24px;
  background-color: ${colors.WHITE};
  font-family: 'Roboto';
  font-style: normal;
  color: ${colors.BLACK_TR};
`
const Topbar = styled(Row)`
  padding: 16px 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 1px solid ${colors.HEADER_BORDER};
`
const SubmitButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  background: ${colors.BLUE};
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: ${colors.WHITE};
`
const Quill = styled.div`
  height: 85%;
  padding: 0 10px;
  margin: 20px auto;
`
