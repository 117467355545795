import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AppUserService } from 'api/main/services/AppUserService'
import { MeResponse } from '.'

export const meRequestAsync: AsyncThunk<MeResponse, void, any> = createAsyncThunk(
  'auth/me',
  async (_, { rejectWithValue }) => {
    try {
      return await AppUserService.appProfileShow()
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
