/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateLanguageVM = {
    langKey: UpdateLanguageVM.langKey;
}

export namespace UpdateLanguageVM {

    export enum langKey {
        EN = 'en',
        FI = 'fi',
    }


}
