import { AppUserService, UpdateProfileVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const updateRequestAsync: AsyncThunk<any, UpdateProfileVM, any> = createAsyncThunk(
  'auth/update',
  async (request: UpdateProfileVM, { rejectWithValue }) => {
    try {
      return await AppUserService.appProfileUpdate(request)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
