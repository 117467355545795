import { Button, message, Row, Space, Switch } from 'antd'
import { colors } from 'constants/colors'
import { Bicycle } from 'interfaces/Bicycles'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BicycleStatus, BicycleTransition, PostType } from 'api/main'
import { mapColor } from 'constants/functions'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { changeOwnStatusRequestAsync } from 'store/bicycle/status/asyncActions'
import { BicycleChangeStatus } from 'store/admin/bicycles/status/Status.interface'
import { resetState, SelectOwnChangeStatusBicycle } from 'store/bicycle/status'
import { Indicator } from 'Components/UiKit/Indicator'
import { FieldError } from 'react-hook-form'
import { EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'

interface BicyclePostProps {
  bicycle: Bicycle
}

export const BicyclePost: FunctionComponent<BicyclePostProps> = ({ bicycle }) => {
  const { t } = useTranslation<string>()
  const { model, price, description, status, id, rejectReason } = bicycle
  const isPublic = status === BicycleStatus.ACTIVE
  const isEditable =
    status === BicycleStatus.REJECTED || status === BicycleStatus.EXPIRED || status === BicycleStatus.INACTIVE
  const showStatusBar =
    status === BicycleStatus.REJECTED ||
    status === BicycleStatus.EXPIRED ||
    status === BicycleStatus.INACTIVE ||
    status === BicycleStatus.PENDING
  const underReview = status !== BicycleStatus.ACTIVE && status !== BicycleStatus.INACTIVE
  const dispatch = useAppDispatch()
  const { done, errors, is_fetching, lastRequestStatus } = useAppSelector(SelectOwnChangeStatusBicycle)
  const history = useHistory()

  useEffect(() => {
    if (done && lastRequestStatus === bicycle.id) {
      message.success(t('bicycleIsUpdated'))
    }
    if (errors) {
      errors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }

    return () => {
      dispatch(resetState())
    }
  }, [dispatch, errors, done, t, lastRequestStatus, bicycle.id])

  const markAsAction = useCallback(
    (bicycle: Bicycle) => {
      let status
      switch (bicycle.postType) {
        case PostType.STOLEN:
          status = BicycleTransition.MARK_AS_FOUND
          break
        case PostType.LEASING:
          status = BicycleTransition.MARK_AS_LEASED
          break
        case PostType.RENTING:
          status = BicycleTransition.MARK_AS_RENTED
          break
        case PostType.NEW:
        case PostType.SECOND_HAND:
          status = BicycleTransition.MARK_AS_SOLD
      }
      const request: BicycleChangeStatus = {
        bicycleId: bicycle.id,
        requestBody: {
          transition: status,
        },
      }
      dispatch(changeOwnStatusRequestAsync(request))
    },
    [dispatch]
  )

  const markAsPublicPrivate = useCallback(
    (bicycle: Bicycle, toPublic: boolean) => {
      const request: BicycleChangeStatus = {
        bicycleId: bicycle.id,
        requestBody: {
          transition: toPublic ? BicycleTransition.REQUEST_REVIEW : BicycleTransition.DEACTIVATE,
        },
      }
      dispatch(changeOwnStatusRequestAsync(request))
    },
    [dispatch]
  )

  const gotoEdit = useCallback(
    (id: number) => {
      history.push('/bicycle/edit/' + id.toString())
    },
    [history]
  )

  const mapMarkAsButton = useCallback(
    (postType: string) => {
      switch (postType) {
        case PostType.STOLEN:
          return t('markAsFound')
        case PostType.LEASING:
          return t('markAsLeased')
        case PostType.RENTING:
          return t('markAsRented')
        case PostType.NEW:
        case PostType.SECOND_HAND:
          return t('markAsSold')
      }
    },
    [t]
  )

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'bicycle' }} />
      {is_fetching && <Indicator />}
      <Row justify="space-between">
        <Title>{model}</Title>
        <Space>
          {bicycle.status === BicycleStatus.ACTIVE && (
            <Button type="primary" ghost onClick={() => markAsAction(bicycle)}>
              {mapMarkAsButton(bicycle.postType)}
            </Button>
          )}
          <GreenSwitch
            disabled={underReview}
            checkedChildren={t('public')}
            unCheckedChildren={t('private')}
            defaultChecked={isPublic}
            onChange={checked => markAsPublicPrivate(bicycle, checked)}
          />
          {showStatusBar && (
            <StatusBar>
              {t('status')} : <StatusColor color={mapColor(status)}>{t(status)}</StatusColor>
            </StatusBar>
          )}
        </Space>
      </Row>
      <Row>
        <Price>€{price}</Price>
      </Row>
      <Row>
        <Description>{description}</Description>
      </Row>
      {isEditable && (
        <Row justify="end">
          <Button type="text" onClick={() => gotoEdit(id)}>
            <EditOutlined /> {t('edit')}
          </Button>
        </Row>
      )}
      <Row>
        <Status color={mapColor(status)}>
          {status === BicycleStatus.REJECTED ? t('reason') + ' : ' + t(rejectReason) : t(status)}
        </Status>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin: 10px 42px;
`
const GreenSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${colors.BRAND_GREEN};
  }
`
const Title = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.GRAY_TEXT};
`
const Price = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.GRAY_TEXT};
`
const Description = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 100%;
  word-wrap: break-word;
  line-height: 27px;
  color: ${colors.GRAY_TEXT};
  margin: 12px 0;
`
const Status = styled.div<{ color?: string }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: ${props => (props.color ? props.color : colors.PRIMARY)};
  color: ${colors.WHITE};
  padding: 6px 10px;
  width: 100%;
`
const StatusBar = styled(Row)``
const StatusColor = styled.div<{ color?: string }>`
  color: ${props => (props.color ? props.color : colors.PRIMARY)};
  padding: 0 3px;
`
