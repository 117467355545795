import { Divider, message, Row } from 'antd'
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
// import { CameraOutlined, UploadOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import { ObjectSchema } from 'yup'
import { ObjectShape } from 'yup/lib/object'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { UpdateProfileVM } from 'api/main'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectMe, updateCityIdAndPhone } from 'store/auth/me'
import { cityRequestAsync } from 'store/auth/city/asyncActions'
import { resetState, selectUpdate } from 'store/auth/update'
import { resetState as resetStateChangePassword } from 'store/auth/change-password'
import { ShowValue } from 'Components/Admin/Shared/Components/Components'
import { selectCity } from 'store/auth/city'
import { updateRequestAsync } from 'store/auth/update/asyncActions'
import { ChangePassword } from './ChangePassword/ChangePassword'
import { ProfileElement, ProfileInput, SaveButton, SaveContainer } from './Components/Components'
import { selectChangePassword } from 'store/auth/change-password'
import { Indicator } from 'Components/UiKit/Indicator'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'
import { SelectBicycleItems } from '../../Shared/Components/Components'

export const Profile: FunctionComponent = () => {
  const updateProfileSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
    Yup.object().shape({
      firstName: Yup.string().min(2, t('tooShort')).max(50, t('tooLong')),
      lastName: Yup.string().min(2, t('tooShort')).max(50, t('tooLong')),
      password: Yup.string().min(8, t('tooShort')).max(50, t('tooLong')),
      email: Yup.string().email(t('invalidEmail')),
      mobile: Yup.string()
        .min(7, t('invalidPhone'))
        .matches(/^[1-9][0-9]*$/, t('invalidPhone')),
    })
  const { t } = useTranslation<string>()
  const { data, is_fetching: meFetching } = useAppSelector(selectMe)
  const { data: citiesRaw } = useAppSelector(selectCity)
  const { done, errors: changePasswordErrors, is_fetching } = useAppSelector(selectChangePassword)
  const { done: updateDone, errors: updateErrors, is_fetching: updateIsFetching } = useAppSelector(selectUpdate)
  const dispatch = useAppDispatch()

  const calcMobileInput = useCallback(() => {
    if (!data || !data.mobile) {
      return ''
    }

    if (data.mobile.startsWith('+358')) {
      return data.mobile.substr(4)
    }

    return data.mobile
  }, [data])

  const calcMobileRequestValue = useCallback(value => {
    if (!value) {
      return ''
    }

    if (value.startsWith('+')) {
      return value
    }

    return '+358' + value
  }, [])

  useEffect(() => {
    if (updateErrors) {
      updateErrors.fieldErrors?.forEach(err => message.error(err.message))
      dispatch(resetState())
    }

    if (updateDone) {
      message.success(t('profileIsUpdated'))
      dispatch(resetState())
    }
  }, [dispatch, t, updateErrors, updateDone])

  useEffect(() => {
    if (changePasswordErrors) {
      changePasswordErrors.fieldErrors?.forEach(err => message.error(t(err.message)))
      dispatch(resetStateChangePassword())
    }
    if (done) {
      message.success(t('passwordIsChanged'))
      dispatch(resetStateChangePassword())
    }

    return () => {
      dispatch(resetStateChangePassword())
    }
  }, [dispatch, t, changePasswordErrors, done])

  const cities = useMemo(
    () =>
      Array.isArray(citiesRaw)
        ? citiesRaw.map(c => {
            return { ...c, name_fi: c.name }
          })
        : [],
    [citiesRaw]
  )

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateProfileVM>({
    defaultValues: useMemo(() => {
      return {
        cityId: data?.city?.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        mobile: calcMobileInput(),
        photoId: data?.photo?.id,
      }
    }, [data, calcMobileInput]),
    resolver: yupResolver(updateProfileSchema(t)),
  })

  useEffect(() => {
    dispatch(cityRequestAsync())

    return () => {
      dispatch(resetState())
    }
  }, [dispatch])

  const onSubmit = handleSubmit(async (updatedData: UpdateProfileVM) => {
    if (!updatedData.cityId && !data?.city?.id) {
      message.error(t('pleaseFillCity'))
      return
    }
    if (!updatedData.mobile && !data?.mobile) {
      message.error(t('pleaseFillPhone'))
      return
    }
    if (!updatedData.cityId) updatedData.cityId = data.city?.id
    if (!updatedData.firstName) updatedData.firstName = data.firstName
    if (!updatedData.lastName) updatedData.lastName = data.lastName
    updatedData.mobile = calcMobileRequestValue(!updatedData.mobile ? data?.mobile : updatedData.mobile)

    await dispatch(updateRequestAsync(updatedData))
    dispatch(updateCityIdAndPhone({ id: updatedData.cityId }))
  })

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'profile' }} />
      {(is_fetching || updateIsFetching || meFetching) && <Indicator />}
      <Row>
        <H1>{t('profileInfo')}</H1>
      </Row>
      {/* <RowAlignCenter>
        <Avatar>
          <CameraOutlinedBig />
        </Avatar>
        <UploadOutlinedMedium />
        {t('uploadPhoto')}
      </RowAlignCenter> */}
      {!meFetching && (
        <form onSubmit={onSubmit}>
          <Row>
            <ProfileInput
              name="firstName"
              title={t('firstName')}
              defaultValue={data?.firstName}
              control={control}
              error={errors.firstName}
            />
            <ProfileInput
              name="lastName"
              defaultValue={data?.lastName}
              title={t('lastName')}
              control={control}
              error={errors.lastName}
            />
            <ProfileElement xs={{ span: 24 }} md={{ span: 12 }}>
              <ShowValue styled label={t('email')} value={data?.email} />
            </ProfileElement>
            <ProfileInput
              name="mobile"
              defaultValue={calcMobileInput()}
              title={t('phoneNumber')}
              control={control}
              type="tel"
              error={errors.mobile}
              addonBefore={t('phonePrefix')}
            />
            <ProfileElement xs={{ span: 24 }} md={{ span: 12 }}>
              <SelectBicycleItems
                label={t('city')}
                options={cities}
                control={control}
                error={errors.cityId}
                name={'cityId'}
                value={data?.city?.id}
              />
            </ProfileElement>
            <SaveContainer xs={{ span: 24 }} md={{ span: 12 }}>
              <SaveButton type="primary" htmlType="submit">
                {t('save')}
              </SaveButton>
            </SaveContainer>
          </Row>
        </form>
      )}
      <Divider />
      <Row>
        <H1>{t('changePassowrd')}</H1>
      </Row>
      <ChangePassword />
    </Container>
  )
}

// const UploadOutlinedMedium = styled(UploadOutlined)`
//   font-size: 1.5rem;
//   color: ${colors.GRAY};
// `

// const RowAlignCenter = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 15px;
// `
// const CameraOutlinedBig = styled(CameraOutlined)`
//   font-size: 4rem;
//   color: ${colors.LIGHT_GRAY_2};
// `
// const Avatar = styled.div`
//   background: ${colors.LIGHT_GRAY};
//   border-radius: 50%;
//   width: 150px;
//   height: 150px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `
const H1 = styled.h1`
  font-weight: bold;
  font-size: 20px;
  color: ${colors.BLACK_TR};
  display: inline-block;
`
const Container = styled.div`
  padding: 20px;
`
