import { createSlice } from '@reduxjs/toolkit'
import { getCapacityRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { ErrorResponse } from 'interfaces/Error'
import { CAPACITY_TOP_5, CAPACITY_TOP_10 } from 'constants/index'

export interface CapacityState {
  is_fetching: boolean
  available_10: boolean
  available_5: boolean
  errors: ErrorResponse
  page: number
}

const initialState: CapacityState = {
  is_fetching: false,
  available_10: false,
  available_5: false,
  errors: null,
  page: -1,
}

export const capacitySlice = createSlice({
  name: 'capacity',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getCapacityRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(getCapacityRequestAsync.fulfilled, (state, action) => {
        state.is_fetching = false
        if (action?.meta?.arg === CAPACITY_TOP_10) state.available_10 = action.payload?.available
        if (action?.meta?.arg === CAPACITY_TOP_5) state.available_5 = action.payload?.available
      })
      .addCase(getCapacityRequestAsync.rejected, (state, action: any) => {
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
      })
  },
})

export const selectCapacity = (state: RootState): any => state.bicycles.capacity

export default capacitySlice.reducer
