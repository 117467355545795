import { combineReducers } from '@reduxjs/toolkit'
import { bicycleReducers } from './bicycles/'
import { usersReducers } from './user'
import { blogReducers } from './blog'
import { newsletterReducers } from './newsletter'
import { bannerReducers } from './banners'
import { contentReducers } from './contents'

export const adminReducers = combineReducers({
  bicycles: bicycleReducers,
  users: usersReducers,
  blog: blogReducers,
  newsletter: newsletterReducers,
  banner: bannerReducers,
  contents: contentReducers,
})
