import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Pagination,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Menu,
  Dropdown,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Breakpoint } from 'antd/lib/_util/responsiveObserve'
import { colors } from 'constants/colors'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AdminPageTitle } from 'Components/Admin/Shared/Components/Components'
import {
  FilterOutlined,
  PlusOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectAdminBicycles } from 'store/admin/bicycles/getAll'
import { getAllBicyclesRequestAsync } from 'store/admin/bicycles/getAll/asyncActions'
import {
  BicycleType,
  GetALlBicyclesAdminRequest,
  Bicycles,
  AdminBicyclesFilters,
} from 'store/admin/bicycles/Interfaces'
import { ADMIN_BICYCLES_PAGE_SIZE, BICYCLE_ADMIN_SORT_QUERY } from 'constants/index'
import { Indicator } from 'Components/UiKit/Indicator'
import { changeStatusRequestAsync } from 'store/admin/bicycles/status/asyncActions'
import { BicycleChangeStatus } from 'store/admin/bicycles/status/Status.interface'
import { resetState, SelectChangeStatusBicycle } from 'store/admin/bicycles/status'
import { FieldError } from 'react-hook-form'
import { useHistory } from 'react-router'
import { BicycleStatus, BicycleTransition, PostType, RejectReason } from 'api/main'
import { bikeTypes, OptionInterface, brands } from 'Components/Profile/Bicyles/NewBicycle/Options'
import { useQuery } from 'utils/useQuery'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'
import { useWindowDimensions } from 'utils/hooks/useWindowDimensions'

export const BicyclesList: FunctionComponent = () => {
  const { t, i18n } = useTranslation<string>()
  const conditions = useMemo(
    () =>
      Object.entries(PostType).map(([key, value]) => {
        return {
          id: key,
          name: t(value),
          name_fi: t(value),
        } as OptionInterface
      }),
    [t]
  )
  const dispatch = useAppDispatch()
  const [pageNumber, setPageNumber] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [refresh, setRefresh] = useState(1)
  const history = useHistory()
  const { data, is_fetching, totalElements } = useAppSelector(selectAdminBicycles)
  const { is_fetching: approveIsFetching, done, errors } = useAppSelector(SelectChangeStatusBicycle)
  const [rejectionReason, setRejectionReason] = useState<RejectReason>(null)
  const [rejectModal, setRejectModal] = useState(0)
  const [filterModal, setFilterModal] = useState(false)
  const [productType, setProductType] = useState(null)
  const [brandId, setBrandId] = useState(null)
  const [condition, setCondition] = useState(null)
  const [year, setYear] = useState(null)
  const [price, setPrice] = useState(null)
  const [status, setStatus] = useState(null)
  const [filterQuery, setFilterQuery] = useState(null)
  const [sortQuery, setSortQuery] = useState(null)
  const { userId } = useQuery<{ userId?: string }>()
  const { width } = useWindowDimensions()
  const isMobile = width <= 576

  const approveBicycle = useCallback(
    (bicycleId: number) => {
      const request: BicycleChangeStatus = {
        bicycleId,
        requestBody: {
          transition: BicycleTransition.APPROVE,
        },
      }
      dispatch(changeStatusRequestAsync(request))
    },
    [dispatch]
  )

  const toggleActiveBicycle = useCallback(
    (bicycleId: number, checked: boolean) => {
      const request: BicycleChangeStatus = {
        bicycleId,
        requestBody: {
          transition: checked ? BicycleTransition.ACTIVATE : BicycleTransition.DEACTIVATE,
        },
      }
      dispatch(changeStatusRequestAsync(request))
    },
    [dispatch]
  )

  useEffect(() => {
    if (done) {
      message.success(t('bicycleIsUpdated'))
      setRefresh(refresh + 1)
      setRejectModal(0)
    }
    if (errors) {
      errors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }

    return () => {
      dispatch(resetState())
    }
  }, [dispatch, errors, done, refresh, t])

  const rejectBicycle = useCallback(
    (bicycleId: number) => {
      if (!rejectionReason) {
        message.error(t('selectRejectionReason'))
        return
      }
      const request: BicycleChangeStatus = {
        bicycleId,
        requestBody: {
          transition: BicycleTransition.REJECT,
          rejectReason: rejectionReason,
        },
      }
      dispatch(changeStatusRequestAsync(request))
    },
    [dispatch, rejectionReason, t]
  )

  const changePage = (page: number) => setPageNumber(page)

  const viewBike = useCallback(bikeId => history.push('/admin/bicycle/' + bikeId), [history])
  const editBike = useCallback(bikeId => history.push('/admin/bicycle/edit/' + bikeId), [history])
  const mobileActionMenuItems = useCallback(
    (record: Bicycles) => {
      return (
        <Menu>
          <Menu.Item>
            <ColorButton type="text" color={colors.BLUE} onClick={() => viewBike(record.id)}>
              <EyeOutlined />
            </ColorButton>
          </Menu.Item>
          <Menu.Item>
            <ColorButton type="text" color={colors.BLUE} onClick={() => editBike(record.id)}>
              <EditOutlined />
            </ColorButton>
          </Menu.Item>
          <Menu.Item>
            {record.status === BicycleStatus.PENDING && (
              <>
                <ColorButton type="text" color={colors.POLAR_GREEN} onClick={() => approveBicycle(record.id)}>
                  <CheckOutlined />
                </ColorButton>
                <ColorButton type="text" color={colors.DANGER_RED} onClick={() => setRejectModal(record.id)}>
                  <CloseOutlined />
                </ColorButton>
              </>
            )}
          </Menu.Item>
        </Menu>
      )
    },
    [approveBicycle, editBike, viewBike]
  )
  const actionsDesktop = useCallback(
    (record: Bicycles) => {
      return (
        <Space size="middle">
          <ColorButton type="text" color={colors.BLUE} onClick={() => viewBike(record.id)}>
            <EyeOutlined />
          </ColorButton>
          <ColorButton type="text" color={colors.BLUE} onClick={() => editBike(record.id)}>
            <EditOutlined />
          </ColorButton>
          {record.status === BicycleStatus.PENDING && (
            <>
              <ColorButton type="text" color={colors.POLAR_GREEN} onClick={() => approveBicycle(record.id)}>
                <CheckOutlined />
              </ColorButton>
              <ColorButton type="text" color={colors.DANGER_RED} onClick={() => setRejectModal(record.id)}>
                <CloseOutlined />
              </ColorButton>
            </>
          )}
        </Space>
      )
    },
    [approveBicycle, editBike, viewBike]
  )
  const actionsMobile = useCallback(
    (record: Bicycles) => {
      return (
        <Dropdown overlay={() => mobileActionMenuItems(record)}>
          <Space size="middle">
            Action
            <DownOutlined />
          </Space>
        </Dropdown>
      )
    },
    [mobileActionMenuItems]
  )
  const columns = useMemo(
    () => [
      {
        title: t('user'),
        key: 'user',
        render: (_: any, record: Bicycles) => record?.owner?.firstName + ' ' + record?.owner?.lastName,
      },
      {
        title: t('productType'),
        dataIndex: 'bicycleType',
        key: 'productType',
        render: (bicycleType: BicycleType) => (i18n.language === 'en' ? bicycleType?.name : bicycleType?.finnishName),
      },
      {
        title: t('model'),
        dataIndex: 'model',
        key: 'model',
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: t('year'),
        dataIndex: 'year',
        key: 'year',
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: t('price'),
        dataIndex: 'price',
        key: 'price',
        sorter: true,
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: t('condition'),
        dataIndex: 'postType',
        key: 'condition',
        render: (postType: string) => t(postType),
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: 'active',
        key: 'active',
        dataIndex: 'active',
        render: (_: any, record: Bicycles) => (
          <Switch
            disabled={record.status === BicycleStatus.REJECTED || record.status === BicycleStatus.PENDING}
            defaultChecked={record.status === BicycleStatus.ACTIVE}
            onChange={checked => toggleActiveBicycle(record.id, checked)}
          />
        ),
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: 'Action',
        key: 'action',
        render: (_: any, record: Bicycles) => (isMobile ? actionsMobile(record) : actionsDesktop(record)),
      },
    ],
    [t, i18n.language, toggleActiveBicycle, isMobile, actionsMobile, actionsDesktop]
  )
  useEffect(() => {
    const request: GetALlBicyclesAdminRequest = {
      ...filterQuery,
      pageNumber: pageNumber,
      page: pageNumber,
      size: ADMIN_BICYCLES_PAGE_SIZE,
      keyword: searchQuery,
      sort: sortQuery || BICYCLE_ADMIN_SORT_QUERY,
      userId: userId,
    }
    dispatch(getAllBicyclesRequestAsync(request))
  }, [pageNumber, searchQuery, dispatch, refresh, filterQuery, sortQuery, userId])
  const onSearch = (query: string) => {
    setSearchQuery(query)
  }

  const gotoNewBicycle = useCallback(() => {
    history.push('/admin/new-bicycle')
  }, [history])

  const resetFilters = useCallback(() => {
    setProductType(null)
    setBrandId(null)
    setCondition(null)
    setYear(null)
    setPrice(null)
    setStatus(null)
    setFilterQuery(null)
    setFilterModal(false)
  }, [])

  const setFilters = useCallback(() => {
    const filters: AdminBicyclesFilters = {}
    if (condition) filters.postType = condition
    if (productType) filters.bicycleTypeId = productType
    if (brandId) filters.brandId = brandId
    if (year) filters.year = year
    if (price) filters.price = price
    if (status) filters.status = status
    setFilterQuery(filters)
    setFilterModal(false)
  }, [condition, price, productType, brandId, status, year])

  const changeSort = (_: any, __: any, sortObj: any) => {
    if (!sortObj.order) setSortQuery(null)
    else {
      if (sortObj.order === 'ascend') setSortQuery('price,asc')
      if (sortObj.order === 'descend') setSortQuery('price,desc')
    }
  }

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'bicyclesList' }} />
      <ActionBar>
        <Row justify="space-between">
          <AdminPageTitle>{t('bicycles')}</AdminPageTitle>
          <Space direction={isMobile ? 'vertical' : 'horizontal'} align={isMobile ? 'end' : 'center'}>
            <Input.Search
              placeholder={t('searchByUserOrModel')}
              onSearch={onSearch}
              className={'three-hundred-px-width'}
            />
            <Button icon={<FilterOutlined />} onClick={() => setFilterModal(true)}>
              {t('filter')}
            </Button>
            <Button icon={<PlusOutlined />} type="primary" onClick={gotoNewBicycle}>
              {t('newBicycle')}
            </Button>
          </Space>
        </Row>
      </ActionBar>
      {(is_fetching || approveIsFetching) && <Indicator />}
      <Table columns={columns} dataSource={data} pagination={false} onChange={changeSort} />
      <Row justify="end">
        <Pagination defaultCurrent={1} onChange={changePage} current={pageNumber} total={totalElements} />
      </Row>
      <Modal
        title={<Row>{t('rejectReason')}</Row>}
        visible={rejectModal > 0}
        onOk={() => rejectBicycle(rejectModal)}
        onCancel={() => {
          setRejectionReason(null)
          setRejectModal(0)
        }}
      >
        <>
          <Row>{t('pleaseChooseReason')}</Row>
          <Row>
            <Radio.Group
              onChange={(value: RadioChangeEvent) => setRejectionReason(value.target.value)}
              name="radiogroup"
            >
              <Row>
                <Radio value={RejectReason.POOR_PICTURE_QUALITY}>{t('poorQuality')}</Radio>
              </Row>
              <Row>
                <Radio value={RejectReason.NOT_ENOUGH_INFORMATION}>{t('lackOfBike')}</Radio>
              </Row>
              <Row>
                <Radio value={RejectReason.BAD_BIKE_QUALITY}>{t('badBike')}</Radio>
              </Row>
              <Row>
                <Radio value={RejectReason.OTHER}>{t('otherReason')}</Radio>
              </Row>
            </Radio.Group>
          </Row>
        </>
      </Modal>
      <Modal
        visible={filterModal}
        title={<FilterTitle>{t('filter')}</FilterTitle>}
        onCancel={() => setFilterModal(false)}
        footer={[
          <Button key="back" onClick={resetFilters}>
            {t('reset')}
          </Button>,
          <Button key="submit" type="primary" onClick={setFilters}>
            {t('apply')}
          </Button>,
        ]}
      >
        <Row>
          <FilterCol xs={{ span: 24 }} md={{ span: 12 }}>
            <Row>
              <FilterHeading>{t('productType')}</FilterHeading>
            </Row>
            <Row>
              <SelectFullWidth value={productType} onChange={value => setProductType(value)}>
                {bikeTypes.map((type: OptionInterface) => (
                  <Select.Option key={type.id} value={type.id}>
                    {i18n.language === 'en' ? type.name : type.name_fi}
                  </Select.Option>
                ))}
              </SelectFullWidth>
            </Row>
            <Row>
              <FilterHeading>{t('year')}</FilterHeading>
            </Row>
            <Row>
              <Input value={year} onChange={e => setYear(e.target.value)} />
            </Row>
            <Row>
              <FilterHeading>{t('brand')}</FilterHeading>
            </Row>
            <Row>
              <SelectFullWidth value={brandId} onChange={value => setBrandId(value)}>
                {brands.map((brand: OptionInterface) => (
                  <Select.Option key={brand.id} value={brand.id}>
                    {i18n.language === 'en' ? brand.name : brand.name_fi}
                  </Select.Option>
                ))}
              </SelectFullWidth>
            </Row>
          </FilterCol>
          <FilterCol xs={{ span: 24 }} md={{ span: 12 }}>
            <Row>
              <FilterHeading>{t('condition')}</FilterHeading>
            </Row>
            <Row>
              <SelectFullWidth value={condition} onChange={value => setCondition(value)}>
                {conditions.map((type: OptionInterface) => (
                  <Select.Option key={type.id} value={type.id}>
                    {i18n.language === 'en' ? type.name : type.name_fi}
                  </Select.Option>
                ))}
              </SelectFullWidth>
            </Row>
            <Row>
              <FilterHeading>{t('price')}</FilterHeading>
            </Row>
            <Row>
              <Input value={price} onChange={e => setPrice(e.target.value)} />
            </Row>
          </FilterCol>
        </Row>
        <Row>
          <FilterHeading>{t('status')}</FilterHeading>
        </Row>
        <Row>
          <Radio.Group onChange={e => setStatus(e?.target?.value)} value={status}>
            <Radio value={null}>{t('all')}</Radio>
            <Radio value={BicycleStatus.ACTIVE}>{t('active')}</Radio>
            <Radio value={BicycleStatus.INACTIVE}>{t('inactive')}</Radio>
            <Radio value={BicycleStatus.PENDING}>{t('pending')}</Radio>
          </Radio.Group>
        </Row>
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 24px;
`
const ActionBar = styled.div`
  padding: 16px 24px;
  background: ${colors.WHITE};
`
const ColorButton = styled(Button)<{ color: string }>`
  color: ${props => props.color};
`
const FilterTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.BLACK_TR};
`
const FilterHeading = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.BLACK_TR};
  margin: 24px 0 8px 0;
`
const FilterCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`
const SelectFullWidth = styled(Select)`
  width: 100%;
`
