import { LoginVM, UserJwtControllerService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AuthResponse } from '.'

export const loginRequestAsync: AsyncThunk<AuthResponse, LoginVM, any> = createAsyncThunk(
  'auth/loginRequest',
  async (body: LoginVM, { rejectWithValue }) => {
    try {
      return await UserJwtControllerService.authorizeUsingPost(body)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
