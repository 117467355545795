import { combineReducers } from '@reduxjs/toolkit'
import getAllBicyclesReducer from './getAll'
import changeStatusReducer from './status'
import getBicycleReducer from './get'
import createBicycleReducer from './create'

export const bicycleReducers = combineReducers({
  getAll: getAllBicyclesReducer,
  get: getBicycleReducer,
  status: changeStatusReducer,
  create: createBicycleReducer,
})
