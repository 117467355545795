import { createSlice } from '@reduxjs/toolkit'
import { ErrorResponse } from 'interfaces/Error'
import { RootState } from 'store'
import { cityRequestAsync } from './asyncActions'

export interface CityResponse {
  id: number
  name: string
}

export interface CityState {
  is_fetching: boolean
  data: CityResponse[]
  errors: ErrorResponse
}

const initialState: CityState = {
  is_fetching: false,
  data: null,
  errors: null,
}

export const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(cityRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(cityRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload
      })
      .addCase(cityRequestAsync.rejected, (state, { payload }) => {
        state.data = null
        state.is_fetching = false
        state.errors = payload
      })
  },
})

export const selectCity = (state: RootState): CityState => state.auth.city

export default citySlice.reducer
