import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { OwnBicycleRequest } from '.'
import { AppBicycleService } from 'api/main'
import { BICYCLE_OWN_SORT_QUERY } from 'constants/index'

export const getOwnBicyclesRequestAsync: AsyncThunk<any, any, any> = createAsyncThunk(
  'bicycles/getOwn',
  async (requestBody: OwnBicycleRequest, { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleList(requestBody.page - 1, requestBody.size, BICYCLE_OWN_SORT_QUERY)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
