import { useMemo } from 'react'
import { useLocation } from 'react-router'
import qs from 'querystring'
type useSetQueryType = (paramsObj: Record<string, any>) => string

export const useQuery = <T extends Record<string, any>>(): Record<string, any> => {
  const location = useLocation()

  return useMemo<T>(() => {
    const item = qs.parse(location.search.slice(1)) as Record<any, any>

    for (const key in item) {
      if (/^\d+$/.test(item[key])) {
        item[key] = +item[key]
      }
    }

    return item
  }, [location.search])
}

export const useSetQuery = (): useSetQueryType => {
  return (paramsObj: Record<string, any>): string => {
    const filteredObj = Object.entries<[]>(paramsObj).reduce<Record<string, any>>((acc, [key, value]) => {
      if (value && value.length > 0) {
        acc[key] = value
      }
      return acc
    }, {})
    return qs.stringify(filteredObj)
  }
}
