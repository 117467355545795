import { Button, Checkbox, Input, message, Pagination, Row, Space, Switch, Table, Tooltip } from 'antd'
import { Breakpoint } from 'antd/lib/_util/responsiveObserve'
import { colors } from 'constants/colors'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AdminPageTitle } from '../Shared/Components/Components'
import { EyeOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ADMIN_BICYCLES_PAGE_SIZE } from 'constants/index'
import { Indicator } from 'Components/UiKit/Indicator'
import { GetALlUsersAdminRequest, Users } from 'store/admin/user/Interfaces'
import { selectAdminUsers } from 'store/admin/user/getAll'
import { getAllUsersRequestAsync } from 'store/admin/user/getAll/asyncActions'
import { AuthRole } from 'store/auth/login'
import { UserChangeStatus } from 'store/admin/user/update/Status.interface'
import { changeUserStatusRequestAsync } from 'store/admin/user/update/asyncActions'
import { resetError, resetState, SelectChangeStatusUser } from 'store/admin/user/update'
import { FieldError } from 'interfaces/Error'
import { useHistory } from 'react-router'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'
import { useWindowDimensions } from 'utils/hooks/useWindowDimensions'

export const UsersList: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [pageNumber, setPageNumber] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const { data, is_fetching, totalElements } = useAppSelector(selectAdminUsers)
  const { is_fetching: userUpdateIsFetching, done, errors } = useAppSelector(SelectChangeStatusUser)
  const { width } = useWindowDimensions()
  const isMobile = width <= 576

  const changePage = (page: number) => setPageNumber(page)
  const makeUserAdmin = useCallback(
    (userId: string, checked: any) => {
      const request: UserChangeStatus = {
        userId,
        requestBody: {
          role: checked ? AuthRole.ROLE_ADMIN : AuthRole.ROLE_USER,
        },
      }
      dispatch(changeUserStatusRequestAsync(request))
    },
    [dispatch]
  )

  const showPosts = useCallback(
    (record: Users) => {
      history.push('/admin/bicycles?userId=' + record.id)
    },
    [history]
  )

  const toggleActiveUser = useCallback(
    (userId: string, checked: any) => {
      const request: UserChangeStatus = {
        userId,
        requestBody: {
          enabled: checked,
        },
      }
      dispatch(changeUserStatusRequestAsync(request))
    },
    [dispatch]
  )

  useEffect(() => {
    if (errors) {
      errors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }

    return () => {
      dispatch(resetError())
    }
  }, [errors, dispatch])

  useEffect(() => {
    if (done) {
      message.success(t('userUpdated'))
    }

    return () => {
      dispatch(resetState())
    }
  }, [done, dispatch, t])

  const columns = useMemo(
    () => [
      {
        title: t('user'),
        key: 'user',
        render: (_: any, record: Users) => record.firstName + ' ' + record.lastName,
      },
      {
        title: t('email'),
        dataIndex: 'email',
        key: 'email',
        ellipsis: isMobile,
        render: (_: any, record: Users) =>
          isMobile ? (
            <Tooltip placement="topLeft" title={record.email}>
              {record.email}
            </Tooltip>
          ) : (
            record.email
          ),
      },
      {
        title: t('phone'),
        dataIndex: 'mobile',
        key: 'phone',
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: t('admin'),
        dataIndex: 'admin',
        key: 'admin',
        render: (text: string, record: Users) => (
          <Checkbox
            onChange={checked => makeUserAdmin(record.id.toString(), checked.target.checked)}
            defaultChecked={record?.role === AuthRole.ROLE_ADMIN || record?.role === AuthRole.ROLE_SUPER_ADMIN}
            disabled={record?.role === AuthRole.ROLE_SUPER_ADMIN}
          />
        ),
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: t('location'),
        dataIndex: 'location',
        key: 'location',
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: 'active',
        key: 'active',
        dataIndex: 'active',
        render: (active: any, record: Users) => (
          <Switch
            defaultChecked={record.enabled}
            onChange={checked => toggleActiveUser(record.id.toString(), checked)}
          />
        ),
        responsive: ['md', 'lg', 'xl', 'xxl'] as Breakpoint[],
      },
      {
        title: 'Action',
        key: 'action',
        render: (record: Users) => (
          <Space size="middle">
            <ColorButton type="text" color={colors.BRAND_GREEN} onClick={() => showPosts(record)}>
              <EyeOutlined />
            </ColorButton>
          </Space>
        ),
        responsive: ['sm', 'xs'] as Breakpoint[],
      },
    ],
    [t, makeUserAdmin, toggleActiveUser, showPosts, isMobile]
  )
  useEffect(() => {
    const request: GetALlUsersAdminRequest = {
      page: pageNumber,
      size: ADMIN_BICYCLES_PAGE_SIZE,
      keyword: searchQuery,
    }
    dispatch(getAllUsersRequestAsync(request))
  }, [pageNumber, searchQuery, dispatch])
  const onSearch = (query: string) => {
    setSearchQuery(query)
  }

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'users' }} />
      <ActionBar>
        <Row justify="space-between">
          <AdminPageTitle>{t('users')}</AdminPageTitle>
          <Space>
            <Input.Search
              placeholder={t('searchByUserOrModel')}
              onSearch={onSearch}
              className={'three-hundred-px-width'}
            />
          </Space>
        </Row>
      </ActionBar>
      {(is_fetching || userUpdateIsFetching) && <Indicator />}
      <Table columns={columns} dataSource={data} pagination={false} />
      <Row justify="end">
        <Pagination defaultCurrent={1} onChange={changePage} current={pageNumber} total={totalElements} />
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 24px;
`
const ActionBar = styled.div`
  padding: 16px 24px;
  background: ${colors.WHITE};
`
const ColorButton = styled(Button)<{ color: string }>`
  color: ${props => props.color};
`
