import { createSlice } from '@reduxjs/toolkit'
import { getBrandsRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { BrandResponse } from 'interfaces/Brands'

export interface BrandsState {
  is_fetching: boolean
  data: BrandResponse[]
}

const initialState: BrandsState = {
  is_fetching: false,
  data: null,
}

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getBrandsRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(getBrandsRequestAsync.fulfilled, (state, action) => {
        state.is_fetching = false
        state.data = action.payload
      })
      .addCase(getBrandsRequestAsync.rejected, state => {
        state.is_fetching = false
      })
  },
})

export const selectBrands = (state: RootState): any => state.brands.get

export default brandsSlice.reducer
