/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseChangeBicycleStatusVM } from '../models/BaseChangeBicycleStatusVM';
import type { BicycleStatus } from '../models/BicycleStatus';
import type { PostType } from '../models/PostType';
import type { RejectBicycleVM } from '../models/RejectBicycleVM';
import type { RequestReviewBicycleVM } from '../models/RequestReviewBicycleVM';
import type { SaveBicycleVMSaveFromAdminView } from '../models/SaveBicycleVMSaveFromAdminView';
import { request as __request } from '../core/request';

export class AdminBicycleService {

    /**
     * Create bicycle
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static async adminBicycleCreate(
        requestBody?: SaveBicycleVMSaveFromAdminView,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/administration/bicycle`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * List all bicycles
     * @param postType
     * @param status
     * @param bicycleTypeId
     * @param brandId
     * @param price
     * @param year
     * @param userId userId
     * @param keyword keyword
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns any OK
     * @throws ApiError
     */
    public static async adminBicycleList(
        postType?: PostType,
        status?: BicycleStatus,
        bicycleTypeId?: number,
        brandId?: number,
        price?: string,
        year?: number,
        userId?: string,
        keyword?: string,
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/bicycles`,
            query: {
                'postType': postType,
                'status': status,
                'bicycleTypeId': bicycleTypeId,
                'brandId': brandId,
                'price': price,
                'year': year,
                'userId': userId,
                'keyword': keyword,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Show bicycle
     * @param bicycleId bicycleId
     * @returns any OK
     * @throws ApiError
     */
    public static async adminBicycleShow(
        bicycleId: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/bicycles/${bicycleId}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Update bicycle
     * @param bicycleId bicycleId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async adminBicycleUpdate(
        bicycleId: number,
        requestBody?: SaveBicycleVMSaveFromAdminView,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/administration/bicycles/${bicycleId}`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Update bicycle status
     * @param bicycleId bicycleId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async adminBicycleStatusUpdate(
        bicycleId: number,
        requestBody?: (RequestReviewBicycleVM | RejectBicycleVM | BaseChangeBicycleStatusVM),
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/administration/bicycles/${bicycleId}/status`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}