import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useForm, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContentDetailsView, SaveContentVM } from '../../../../api/main'
import { FormEvent, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { EditContentInterface } from '../../../../store/admin/contents/interfaces'
import { adminEditContentRequest } from '../../../../store/admin/contents/edit/asyncActions'
import { resetState, selectAdminEditContent } from '../../../../store/admin/contents/edit'
import { useHistory } from 'react-router-dom'
import { ContentPosition } from '../../../../store/contents/interfaces'

export function useContentForm(content: ContentDetailsView): {
  handleSubmit: (e?: FormEvent<HTMLFormElement>) => Promise<void>
  register: UseFormRegister<ContentDetailsView>
  errors: any
  submitting: boolean
  setValue: UseFormSetValue<ContentDetailsView>
  getValues: UseFormGetValues<ContentDetailsView>
} {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { is_fetching, errors: updateApiErrors, done } = useAppSelector(selectAdminEditContent)
  const history = useHistory()

  const schema = Yup.object().shape({
    body: Yup.string().required(t('required')),
    finnishBody: Yup.string().required(t('required')),
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => content, [content]),
  })

  useEffect(() => {
    reset({
      body: content?.body,
      finnishBody: content?.finnishBody,
    })
  }, [reset, content])

  const handleContentSubmit = handleSubmit((data: SaveContentVM) => {
    const request: EditContentInterface = {
      body: data.body,
      finnishBody: data.finnishBody,
      contentPosition: content.contentPosition as ContentPosition,
    }

    dispatch(adminEditContentRequest(request))
  })

  useEffect(() => {
    if (!updateApiErrors && done && !is_fetching) {
      dispatch(resetState())
      history.push('/admin/content')
    }
  }, [is_fetching, updateApiErrors, history, done, dispatch])

  return { register, errors, handleSubmit: handleContentSubmit, submitting: is_fetching, setValue, getValues }
}
