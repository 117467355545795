import { AdminBannerService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const adminDeleteBannerRequest: AsyncThunk<any, number, any> = createAsyncThunk(
  'admin/banner/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      return await AdminBannerService.adminBannerDelete(id)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
