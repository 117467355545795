const fi = Object.freeze({
  hello: 'Hei',
  login: 'Kirjaudu',
  required: 'Pakollinen',
  invalidEmail: 'Väärä sähköposti',
  enterBasedOnPattern: 'Vain pienet kirjaimet, numerot ja väliviiva ovat sallittuja',
  tooShort: 'Liian lyhyt!',
  tooLong: 'Liian pitkä!',
  submit: 'Lähetä',
  powers: 'Power',
  email: 'Sähköposti',
  password: 'Salasana',
  forgotPassword: 'Unohtuiko salasana?',
  or: 'tai',
  mainMediaId: 'Kansikuva',
  whatsappNumber: 'WhatsApp',
  loginWith: 'Kirjaudu',
  signUp: 'Rekisteröidy',
  newHere: 'Uusi NettiFillarissa?',
  admin: 'Ylläpitäjä',
  user: 'Käyttäjä',
  firstName: 'Etunimi',
  lastName: 'Sukunimi',
  signUpWith: 'Rekisteröidy',
  PleaseEnterYourRegisteredEmailSoWeCanSendYouACodeToResetYourPassword:
    'Syötä tiliisi liitetty sähköpostiosoite ja lähetämme sinulle salasanan palautuskoodin.',
  resetLinkSent: 'Salasanan palautuspyyntö on lähetetty. Katso sähköpostisi.',
  resetPassword: 'Palauta Salasana',
  pleaseEnterAndConfirmYourNewPassword: 'Syötä ja vahvista uusi salasanasi.',
  newPassword: 'Uusi salasana',
  confirmPassword: 'Vahvista salasana',
  confirm: 'Vahvista',
  invalidToken: 'Please put the new translations in this column',
  welcomeToNettiFillari: 'Tervetuloa NettiFillariin!',
  slogan: 'Pyörän myynti-ilmoituksen tekeminen ja pyörien etsiminen on sinulle maksutonta',
  passwordMatch: 'Salasanat eivät täsmää.',
  invalidCredentials: 'Väärä käyttäjänimi tai salasana, kokeile uudestaan tai palauta unohtunut salasana. ',
  brands: 'Pyörämerkit',
  sellOnNet: 'Myy NettiFillarissa',
  blog: 'Blogi',
  backToBrands: 'Takaisin pyörämerkkeihin',
  sortBy: 'Järjestä',
  filters: 'Suodattimet',
  men: 'Miehet',
  women: 'Naiset',
  unisex: 'Unisex',
  kids: 'Lapset ja nuoret',
  postedOn: 'Julkaisupäivä',
  noBic: 'Pyörää ei löydetty',
  productType: 'Pyörän tyyppi',
  city: 'Kaupunki',
  hybrid: 'Hybridipyörät',
  mountain: 'Maastopyörät',
  readAndGravel: 'Maantiepyörät ja gravel-pyörät',
  ebike: 'Sähköpyörät',
  kidsAndJuniors: 'Lapset ja nuoret',
  other: 'Muut',
  bsa: 'Please put the new translations in this column',
  bsaLadyBird: 'Please put the new translations in this column',
  hercules: 'Please put the new translations in this column',
  machCity: 'Kaupunki',
  montra: 'Please put the new translations in this column',
  juniors: 'Lapset ja nuoret',
  xs14: 'XS (14‴)',
  s16: 'S (16‴)',
  m18: 'M (18‴)',
  aluminum: 'Alumiinirunko',
  carbon: 'Hiilikuiturunko',
  steel: 'Teräsrunko',
  foot: 'Jalkajarrut',
  footAndFront: 'Jalka- ja etujarrut',
  disc: 'Levyjarrut',
  vBrak: 'V-jarrut',
  red: 'Punainen',
  golden: 'Kultainen',
  blue: 'Sininen',
  purple: 'Lila/violetti',
  loadMore: 'Lataa lisää',
  BecomeSellerOnNettiFillari: 'Ryhdy myyjäksi NettiFillarissa',
  startSelling: 'ALOITA MYYNTI',
  SellerTopQuestion: 'Haluatko myydä pyöräsi nopeammin ja saada lisää näkyvyyttä ilmoituksellesi?',
  whoCanUse: 'Mitä pyöriä voit ilmoittaa NettiFillarissa?',
  youCanSearch: 'Voit etsiä varastettua pyörääsi NettiFillarin kautta!',
  longTextSell: `Mikäli pyöräsi on varastettu, voit ilmoittaa sen kuvineen ja tietoineen NettiFillarin avoimessa "varastetut pyörät" osiossa. Täältä osiosta käytettyjen pyörien ostajat voivat tarkistaa, ettei pyörää ole ilmoitettu varastetuksi. Ilmoitus tehdään kuten myynti-ilmoituksetkin, mutta ilmoituksen tulee sisältää paikkakuntatiedot. Sen sijaan hintatietoja ei lisätä.`,
  weOfferNoCommission:
    'Palvelumme käyttö on maksutonta, ellet osta lisänäkyvyyttä ilmoituksellesi. Voit halutessasi antaa NettiFillarille tippiä kaupoista.',
  termsAndConditions: 'Käyttöehdot',
  privacyAndPolicy: 'Tietosuojaseloste',
  contactUs: 'Ota yhteyttä',
  noPostFound: 'Ilmoitusta ei löydetty',
  verification: 'Vahvistus',
  privacyPolicy: 'Tietosuojaseloste',
  termsAndConditionsBody: `Käyttöehdot

  Yleistä
  Nämä Käyttöehdot koskevat NettiFillari Oy:n (jäljempänä ”Palveluntarjoaja”) ylläpitämää verkkopalvelua www.nettifillari.fi  (jäljempänä ”Palvelu”). Näitä ehtoja sovelletaan kaikkiin NettiFillari Oy:n ylläpitämiin Palveluihin. Verkkopalvelua käyttävän (jäljempänä ”Käyttäjä”) on hyväksyttävä nämä Käyttöehdot itseään sitoviksi, käyttääkseen Palvelua, rekisteröitynä tai rekisteröimättä.
  Käyttämällä Palvelua Käyttäjä hyväksyy Käyttöehdot ja vakuuttaa toimivansa näiden Käyttöehtojen, kulloinkin sovellettavan lain ja hyvän tavan mukaisesti. Käyttöehdot sitovat Käyttäjää niin kauan, kun Käyttäjä käyttää Palvelua. Käyttäjä vakuuttaa olevansa vähintään 18-vuotias tai että hänellä on huoltajansa suostumus Palvelun käyttöön ja että hän on oikeustoimikelpoinen.
  Palvelu toimii verkkopalveluna, jossa Palveluun rekisteröityneet myyjät ja ostajat voivat ilmoittaa tuotteitaan (jäljempänä ”Kohde”) myyntiin Palvelun välityksellä ja tehdä ostotarjouksia myynnissä olevista Kohteista. Kaupanteko tapahtuu Käyttäjien kesken Käyttäjien sopimien ehtojen mukaisesti, eikä Palveluntarjoaja ole kaupan osapuoli missään suhteessa.
    •	Palveluntarjoajan oikeudet ja velvollisuudet
  Palveluntarjoajan tarjoama Palvelu on lähtökohtaisesti käytettävissä 24 tuntia vuorokaudessa, parhaalla mahdollisella tavalla. Palveluntarjoajalla on kuitenkin oikeus ottaa Palvelu tai sen osa tilapäisesti pois käytöstä huollon, laitteistoasennuksen, yleisen järjestyksen ja turvallisuuden, järjestelmän liiallisen kuormituksen tai muun välttämättömän syyn vuoksi.
  Palveluntarjoajalla on oikeus parantaa ja kaikin tavoin muuttaa Palvelun sisältöä, rakennetta ja ulkoasua, sen tuote- ja tietokantavalikoimaa sekä muita Palvelun osia.  Palveluntarjoajalla on oikeus muuttaa Palvelun maksuttomia osioita maksullisiksi tai lopettaa Palvelun ylläpitäminen sekä tuottaminen osittain tai kokonaisuudessaan. Palveluntarjoajalla on myös oikeus muuttaa Palvelun eri osioiden hintoja ja hinnoitteluperusteita. Palveluntarjoaja pyrkii tiedottamaan Palvelussa tapahtuvista, Käyttäjän kannalta olennaisista muutoksista sekä katkoksista Käyttäjälle etukäteen mahdollisuuksien mukaan hyvissä ajoin etukäteen Palvelun välityksellä tai muulla sopivaksi katsomallaan tavalla. Muutokset astuvat voimaan heti, kun ne on toteutettu.
  Palveluntarjoaja ei takaa suoranaisesti eikä välillisesti, että Palvelu toimii käyttökatkoitta tai täysin virheettömästi.
  Palveluntarjoaja ei vastaa Palvelun kautta saatavilla olevien sisältöjen oikeellisuudesta tai lainmukaisuudesta eikä Palvelussa olevilla keskustelu- tai muilla vastaavilla palstoilla esitetyistä näkemyksistä tai mielipiteistä. 
  Palveluntarjoajalla on oikeus, mutta ei velvollisuutta tarkastaa ja muuttaa tai poistaa Palvelussa esitettävien kirjoitusten ja julkaisujen sisältöä osittain tai kokonaan, jos Palveluntarjoaja kokee tämän tarpeelliseksi.
  Palveluntarjoajalla on oikeus poistaa Palvelusta aineistoa, jonka ilmaisemisen Palvelussa Palveluntarjoaja on kieltänyt tai joka Palveluntarjoajan näkemyksen mukaan on lain tai hyvän tavan vastaista, sopimatonta, virheellistä, haitallista tai vahingollista Palveluntarjoajalle, Käyttäjille tai kolmansille osapuolille. Palveluntarjoajalla ei ole velvollisuutta ilmoittaa aineiston poistamisesta Käyttäjille etu- eikä jälkikäteen.
  Palveluntarjoaja ei ole vastuussa mahdollisista Käyttäjien välisen viestinnän tai muun toiminnan aiheuttamista haitoista tai vahingoista, jotka kohdistuvat toisiin Käyttäjiin tai kolmansiin osapuoliin.
  Palveluntarjoaja ei vastaa Palvelussa esitettyjen tietojen oikeellisuudesta tai luotettavuudesta tai Palvelussa kaupan pidettävien tai välitettävien tuotteiden ja palvelusten virheettömyydestä tai niitä koskevien tietojen oikeellisuudesta.
  Palveluntarjoaja ei ole kaupan osapuoli. Kaupankäynti Palvelussa tapahtuu Käyttäjien kesken ja vastuu kaupasta ja sen toteutumisesta on Palvelun Käyttäjillä. Palveluntarjoaja ei vastaa oikeudettomasti tapahtuneesta myynnistä kolmannelle osapuolelle.
  Palveluntarjoaja ei vastaa mistään vahingoista tai muista haitoista, joita virheelliset tai riittämättömät Käyttäjätiedot mahdollisesti aiheuttavat Käyttäjälle tai kolmansille osapuolille.
  Palvelu on suojattu tekijänoikeudella Suomen lainsäädännön mukaisesti. Palvelu voi sisältää tekijänoikeudella, tavaramerkkioikeudella tai muulla immateriaalioikeudella suojattua aineistoa. Käyttäjällä ei ole ilman Palveluntarjoajan tai muun oikeudenhaltijan nimenomaista kirjallista lupaa levittää, julkaista, kopioida, saattaa yleisön saataville tai muuten kaupallisesti hyödyntää suojattua materiaalia taikka muodostaa Palvelusta tai sen osasta toista teosta, tietokantaa tai palvelua.
  Palveluntarjoaja on vastuussa ainoastaan Palvelua varten tuottamansa aineiston sisällöstä, tekijänoikeuksista ja muista immateriaalioikeuksista. Palveluntarjoaja vastaa ainoastaan itse tuottamiensa Palvelussa olevien tietojen tarkkuudesta, laadusta ja toimivuudesta.”
  Palveluntarjoaja ei ole missään tapauksessa vastuussa mistään suorista tai epäsuorista vahingoista, jotka liittyvät millään tavalla Palveluun tai sen käyttöön, Palvelun kautta saatavilla oleviin tietoihin tai ilmoituksiin. Palveluntarjoaja ei myöskään ole vastuussa mistään vahingoista, joita Palvelun käyttö tai sen toimintahäiriöt, tekniset viat, haittaohjelmat, linkit tai keskeytykset Palvelussa aiheuttavat Käyttäjälle tai kolmansille osapuolille.
  
  
    •	Käyttäjän oikeudet ja velvollisuudet
  Käyttäjän luoma käyttäjätunnus sekä salasana ovat henkilökohtaisia. Käyttäjä saa oikeuden käyttää Palvelua näiden Käyttöehtojen, lainsäädännön ja muiden Palvelua tai sen yksittäisiä osia koskevien ehtojen mukaisesti. Käyttäjä sitoutuu käyttäessään Palvelua toimimaan kaikkien näiden ehtojen, lainsäädännön sekä hyvän tavan mukaisesti. Käyttäjä sitoutuu niin ikään noudattamaan muualla Palvelussa asetettuja sääntöjä ja ohjeita.
  Käyttäjä ymmärtää, että Palveluntarjoaja ei ole velvollinen, mutta sillä on kuitenkin oikeus valvoa, miten Käyttäjä käyttää Palvelua, mikäli Palveluntarjoaja katsoo tämän tarpeelliseksi.
  Palvelussa ei saa esittää lain tai hyvän tavan vastaista materiaalia. Käyttäjä vastaa siitä, että hänellä on kaikki tarvittavat tekijän- ja muut oikeudet ja/tai oikeudenhaltijoiden suostumukset Palvelussa julkaisemaansa materiaaliin sekä muuhun aineistoon, jonka hän lähettää, välittää tai tallentaa Palveluun. Palvelussa ei saa myydä tuoteväärennöksiä tai piraattituotteita. Käyttäjä vastaa kaikista toiminnallaan aiheuttamistaan vahingoista. 
  Käyttäjä vastaa kaikista Palvelun käytöstä itselleen aiheutuvista välittömistä ja välillisistä kuluista.
  Käyttäjä sitoutuu Kohteen ilmoittaessaan kuvaamaan sen totuudenmukaisesti ja riittävän laajasti. Käyttäjä vakuuttaa ja vastaa siitä, että hänellä on oikeus myydä ilmoittamansa Kohteet, joita hän tarjoaa myytäväksi Palvelussa. Palveluntarjoaja ei vastaa oikeudettomasti tapahtuneesta myynnistä kolmansille osapuolille.
  Mikäli ilmenee, että Käyttäjä rikkoo näitä Käyttöehtoja käyttäessään Palvelua, taikka mikäli Palveluntarjoajalla on perusteltu syy olettaa tällaisen rikkomuksen tapahtuneen, Palveluntarjoaja pidättää oikeuden käyttäjätilin välittömään sulkemiseen sekä käyttäjätilille ja/tai Palveluun kirjautumisen väliaikaiseen rajoittamiseen ilman erillistä ennakkovaroitusta.
    •	Käyttäjätiedot
  Palvelun käyttö voi edellyttää rekisteröitymistä. Käyttäjän tulee antaa Palvelun tarjoamista varten tarvittavat tiedot tätä varten. Käyttäjä vakuuttaa tietojen olevan oikein ja ajantasaisia. Käyttäjä vastaa virheistä tai viivästyksistä, jotka johtuvat siitä, että Käyttäjän antamat tiedot ovat puutteellisia tai vanhentuneita.
  Käyttäjätunnus ja salasana ovat käyttäjäkohtaisia, eikä niitä saa luovuttaa kolmansille osapuolille. Käyttäjä vastaa kaikesta Käyttäjätunnuksellaan tapahtuneesta Palvelun käytöstä. Salasanan tai Käyttäjätunnuksen joutuessa ulkopuolisen tietoon, Käyttäjän tulee ilmoittaa tästä viipymättä Palveluntarjoajalle. Saatuaan Palveluntarjoajan kuittauksen ilmoituksestaan Käyttäjän vastuu Palvelun käytöstä lakkaa kyseisten tunnusten osalta.
  
  Käyttäjä voi saada kadonneen käyttäjätunnuksen ja salasanan tilalle uudet niin halutessaan. Käyttäjän oikeudet käyttää Palvelua voidaan tällöin siirtää uudelle tunnukselle ja salasanalle, samoin kuin maksullisen Palvelun käyttämättä oleva osa.
  Käyttäjätunnus, salasana tai muut Palvelun käytön mahdolliset edellyttämät tunnukset voidaan tarvittaessa muuttaa Palveluntarjoajan toimesta, jos tekniset tai muut hyväksyttävät syyt sitä edellyttävät.
    •	Maksulliset palvelut ja laskutus
  Palvelu saattaa sisältää sekä maksuttomia että maksullisia osia. Maksullisten osien hinnat ja maksuehdot ilmoitetaan kunkin osan kohdalla erikseen. Lisäksi Palvelun kautta voi olla tilattavissa erilaisia maksullisia tuotteita ja palveluita.
  Palveluntarjoaja voi muuttaa Palvelun hinnoittelua tai laskutusperusteita oman harkintansa mukaan ilmoittamalla siitä Palvelussa tai muulla sopivaksi katsotulla tavalla hyvissä ajoin etukäteen. Jos arvonlisävero tai jokin muu Palveluun liittyvä vero tai julkisoikeudellinen maksu tai niitä koskeva viranomaisten soveltamiskäytäntö muuttuu, Palveluntarjoajalla on oikeus välittömästi muuttaa hintoja vastaavasti.
  Käyttäjällä ei ole Palvelun kautta hankittujen maksullisten tuotteiden tai palvelujen peruuttamisoikeutta sen jälkeen, kun kyseessä oleva palvelu tai tuote on Käyttäjän tilauksen perusteella sähköisesti suoritettu.
  Mikäli Käyttäjälle toimitetun maksullisen palvelun käyttö estyy Palveluntarjoajan vaikutuspiirissä ja vastuulla olevasta Palvelun häiriöstä johtuen kokonaan tai oleellisin osin, Käyttäjällä on hyvityksenä oikeus maksullisen palvelun käyttöoikeuden pidennykseen keskeytystä vastaavalla ajalla.
    •	Immateriaalioikeudet
  Palveluntarjoaja omistavaa kaikki oikeudet Palveluun, mukaan lukien rajoituksetta kaikki Palveluun ja sen sisältämään materiaaliin ja aineistoon liittyvät tavaramerkit, tekijänoikeudet sekä muut immateriaalioikeudet Palveluun sekä teksteihin, kuviin, videoihin, musiikkiin, muotoiluihin, malleihin sekä kaikkeen muuhun materiaaliin ja aineistoon, joka on Palvelussa, lukuun ottamatta Käyttäjän tuottamaa sisältöä.
  Palveluntarjoaja myöntää Käyttäjälle oikeuden käyttää Palvelua Käyttöehtojen mukaisesti. Käyttäjällä on oikeus käyttää Palvelun aineistoa ainoastaan sellaisiin tarkoituksiin, jotka kuuluvat Palvelun tavanomaiseen käyttöön. Käyttäjällä ei ole oikeutta siirtää kolmansille osapuolille Palveluntarjoajalta saamaansa käyttöoikeutta Palveluun.
  Palveluntarjoajalla on oikeus käyttää Käyttäjän tuottamaa sisältöä vapaasti markkinoinnissa. Käyttäjä luopuu kaikista korvausvaatimuksista, jotka koskevat Käyttäjän tuottaman sisällön käyttöä Palveluntarjoajan toimesta.
  Käyttäjän tulee varmistaa, että Käyttäjän tuottama sisältö ei loukkaa mitään kolmannen osapuolen oikeuksia, lakeja tai hyvän tavan mukaisia käytäntöjä.
  Käyttäjä vakuuttaa Palvelua käyttäessään ymmärtävänsä oikeutensa Palvelun aineiston ja Käyttäjän tuottaman sisällön käytöstä Palvelussa. 
  Palveluntarjoajalla on oikeus muokata, kopioida, tallentaa, säilyttää sekä muuten käyttää Käyttäjän tuottamaa sisältöä. Palveluntarjoajalla on oikeus luovuttaa näitä oikeuksia edelleen mahdollisille yhteistyökumppaneilleen.
    •	Käyttöoikeuden siirtäminen ja lakkaaminen
  Käyttäjällä ei ole oikeutta luovuttaa tai siirtää Palvelun käyttöoikeutta kolmannelle henkilölle. 
  Palvelun käytön lakkaaminen ei vapauta Käyttäjää palveluiden tai suoritusten maksuista, joiden peruste on syntynyt ennen, kun käyttöoikeus on lakkautettu. 
  Palveluntarjoajalla on oikeus olla antamatta Käyttäjälle pääsyä Palveluun, jos sopimus päättyy Käyttäjän sopimusrikkomuksen johdosta. 
    •	Ylivoimainen este
  Osapuolet vapautuvat sopimuksen mukaisista velvoitteistaan siksi ajaksi ja siinä laajuudessa, kun tämä johtuu ylivoimaisesta esteestä. Perusteena ylivoimaiselle esteelle pidetään sellaista sopijapuolista riippumatonta asiaa tai tapahtumaa, jonka vaikutusta ei voida kohtuudella välttää. Tällaisia tapahtumia voivat olla mm. sota, tulipalo, lakko tai muu työnseisaus, tulva tai muu luonnonilmiö tai muu vaikutuksiltaan vastaava ja epätavallinen Käyttäjästä tai Palveluntarjoajasta riippumaton syy.
    •	Henkilötietojen käsittely ja evästeet
  Käyttäjien henkilötietoja käsitellään luottamuksellisesti tietosuojaselosteen ja yksityisyydensuojaa kuvaavan lainsäädännön mukaan. Palvelussa käytetään evästeitä tietosuojaselosteessa kerrotuin tavoin. Tietosuojaseloste:
  (tähän voidaan myös tehdä evästeille oma kohta, jos tietosuojaselosteessa ei ole mainintaa)
    •	Käyttöehtojen muuttaminen
  Palveluntarjoajalla on oikeus muuttaa Käyttöehtoja, jos kokee sen tarpeelliseksi. Käyttöehtoja voidaan muuttaa sekä täydentää esim. tarjonnan tai sisällön muuttuessa, lainsäädännön tai viranomaiskäytännön muutoksista johtuen tai ennakoimattomien olosuhteiden vuoksi. Palveluntarjoaja pyrkii ilmoittamaan keskeisistä muutoksista Käyttäjälle mahdollisimman nopeasti sähköpostitse tai Palvelun välityksellä.
    •	Muut ehdot
  Käyttäjä sitoutuu myös noudattamaan mahdollisia Palveluntarjoajan erikseen määrittämiä tai sen sopimuskumppanin luomia ohjeita. Kaikki voimassa olevat käyttöehdot ovat Käyttäjän luettavissa.
  Palvelu voi sisältää linkkejä ja yhteyksiä kolmannen osapuolen verkkosivustoihin ja palveluihin. Näihin kolmannen osapuolen tarjoamiin palveluihin sovelletaan kyseisten osapuolien käyttö- sekä muita ehtoja, eikä Palveluntarjoaja vastaa sivustojen yksityisyydensuojakäytännöistä, sisällöstä, toimivuudesta tai soveltuvuudesta Käyttäjälle.
  Palvelun kautta yksityishenkilöltä ostettuihin tuotteisiin tai palveluihin ei sovelleta kuluttajansuojalain mukaista palautusoikeutta. Jos käytetyt tavarat on ostettu yksityishenkilöltä, kauppaan ei sovelleta kuluttajansuojalakia vaan kauppalakia, jonka säännökset vastaavat suurelta osin kuluttajansuojalakia virheen määritelmän osalta. Jos tuote tai palvelu ei vastaa myyjän antamaa kuvausta, ostajan on otettava yhteyttä myyjään asian selvittämiseksi. Jos asiaa ei saada ratkaistua toivotulla tavalla, esim. kaupan purkamisella, ostaja voi viedä riidan käräjäoikeuteen.
  Yksityishenkilöiden lisäksi Palvelussa toimii myyjinä myös yrityksiä, ja heiltä hankittuihin tuotteisiin ja palveluihin kuluttajalla on kuluttajansuojalain mukainen suoja ja palautusoikeus. Palveluntarjoaja ei ole kuitenkaan vastuussa, jos yrityksen ja yksityisen henkilön välisessä kaupassa kuluttajansuojalain mukainen oikeus ei toteudu, vaan tällöin vastuussa on kuluttajansuojalakia noudattamatta jättänyt yritys.
    •	Sovellettava laki ja riitojen ratkaisu
  Näihin käyttöehtoihin sekä Palveluun sovelletaan Suomen lakia, pois lukien lainvalintaa koskevat säännökset.
  Palveluntarjoajan ja Käyttäjän väliset mahdolliset erimielisyydet pyritään ratkaisemaan osapuolten välisillä neuvotteluilla. Mikäli asiasta ei päästä sovintoon, riita ratkaistaan Suomen yleisissä tuomioistuimissa.
  `,
  privacyPolicyBody: `
   <p>
    <strong>1 Yleistä</strong>
   </p>
<p>
    NettiFillari Oy on sitoutunut huolehtimaan sen hallussa olevien
    henkilötietojen luottamuksellisuudesta ja tietosuojasta. Tämä
    tietosuojaseloste soveltuu henkilötietoihin, joita keräämme verkkosivujen
    vierailijoista. Lisätietoja henkilötietojen käsittelystä saat:
    asiakaspalvelu@nettifillari.fi
</p>
<p>
    <strong>2 Rekisterinpitäjä</strong>
</p>
<p>
    Nimi: NettiFillari Oy
</p>
<p>
    Osoite: c/o Angelma&amp;Mäkelä
</p>
<p>
    Eteläkatu 14 C 3, 13100 Hämeenlinna
</p>
<p>
    Y-tunnus: 3234194-2
</p>
<p>
    <strong>3 Mitä henkilötietojasi kerätään ja miten?</strong>
</p>
<p>
    Keräämme tietoja kun käytät nettifillari.fi verkkosivuja ja/tai käytät
    palveluitamme. Näihin henkilötietoihin sisältyvät tiedot koskien
    verkkosivuston hyödyntämistä ja sen sisältöä, selaimesi lähettämät tekniset
    tiedot (esim. IP-osoite, selain, selainversio, verkkosivu) sekä evästeet,
    jotka lähetetään selaimellesi ja näihin liittyvät tiedot. (ks.
    Evästeet-osio)
</p>
<ul>
    <li>Nimi</li>
    <li>Osoite</li>
    <li>Sähköpostiosoite</li>
    <li>Puhelinnumero</li>
    <li>Laskutustiedot</li>
    <li>Asiakkaan ilmoittamat asiakassuhteen hoitoon ja/tai markkinointiin
        liittyvät tiedot</li>
</ul>
<p>
    <strong>4 Henkilötietojesi käsittelyn peruste ja käyttötarkoitukset</strong>
</p>
<p>
    NettiFillari Oy voi lähettää sinulle myös sähköisiä
    suoramarkkinointiviestejä, jos olet kirjautunut uutiskirjeen tilaajaksi tai
    tilannut meiltä palveluita. Emme lähetä suoramarkkinointiviestejä muille.
    Henkilötietoja käytetään asiakassuhteen hoitamiseen ja kehittämiseen,
    laskutukseen ja maksujen perintään, markkinointiin sekä tilastollisiin
    tarkoituksiin. NettiFillari saattaa välittää asiakasrekisterin tietoja
    yhteistyökumppaneille. Asiakkaalla on oikeus kieltää tietojen välittäminen
    yhteistyökumppaneille ilmoittamalla siitä NettiFillarille.
</p>
<p>
    Käsittelemme henkilötietojasi voidaksemme:
</p>
<ul>
    <li>käsitellä pyyntöjäsi ja vastata niihin;</li>
    <li>näyttää verkkosivustonsa sisällön sopivalla tavalla laitteessasi;</li>
    <li>seurata verkkosivustonsa käyttöä ja parantaa sivustonsa toimintoja sekä
        käyttökokemusta;
    </li>
    <li>seurata markkinointikampanjoidensa tehokuutta;
    </li>
    <li>mainostaa ja tarjota palvelujaan sinulle voimassaolevan lainsäädännön
        sallimissa rajoissa; ja parantaa asiakasviestintäänsä ja tunnistaa
        mahdolliset asiakkaat.
    </li>
</ul>
<p>
    Emme käsittele henkilötietojasi muihin kuin tässä tietosuojaselosteessa
    kuvattuihin tarkoituksiin.
</p>
<p>
    <strong>5 Henkilötietojesi säännönmukaiset luovutukset ja siirrot kolmansille
        osapuolille
    </strong>
</p>
<p>
    Käsittelemme henkilötietojasi vain tässä tietosuojaselosteessa määriteltyjä
    tarkoituksia varten.
</p>
<p>
    <strong>
        6 Henkilötietojesi siirrot EU:n tai Euroopan talousalueen ulkopuolelle
    </strong>
</p>
<p>
    Pääsääntöisesti emme siirrä henkilötietojasi EU:n tai ETA:n ulkopuolelle.
    Tietoja voidaan kuitenkin siirtää GDPR asetusten sallimissa rajoissa EU:n
    tai ETA:n ulkopuolelle.
    <br/>
    <br/>
    <strong>7 Henkilötietojen säilyttämisen periaatteet</strong>
</p>
<p>
    Säilytämme henkilötietojasi tämän tietosuojaselosteen mukaisten
    käsittelytarkoitusten vaatiman ajan.
</p>
<p>
    <strong>8 Henkilötietojesi käsittelyyn liittyvät oikeudet</strong>
</p>
<p>
    Sinulla on oikeus koska tahansa vastustaa henkilötietojesi käsittelyä
    suoramarkkinointitarkoituksiin.
</p>
<p>
    Lisäksi sinulla on oikeus, soveltuvan tietosuojalainsäädännön mukaisesti,
    milloin tahansa:
</p>
<ul>
    <li>saada tieto henkilötietojesi käsittelystä;
    </li>
    <li>saada pääsy omiin tietoihisi ja tarkastaa itseäsi koskevat
        henkilötietosi;
    </li>
    <li>vaatia epätarkan ja virheellisen henkilötietosi oikaisua sekä tietojesi
        täydentämistä;
    </li>
    <li>peruuttaa suostumuksesi ja vastustaa henkilötietojesi käsittelyä siltä
        osin, kuin henkilötietojesi käsittely perustuu antamaasi suostumukseen;
    </li>
    <li>vastustaa henkilötietojesi käsittelyä henkilökohtaiseen erityiseen
        tilanteeseesi liittyvällä perusteella
    </li>
</ul>
<p>
    Sinun tulee esittää yllä mainitun oikeuden toteuttamista koskeva pyyntösi
    NettiFillari Oy:lle tämän tietosuojaselosteen mukaisesti. Voimme pyytää
    sinua tarkentamaan pyyntöäsi kirjallisesti ja varmentamaan
    henkilöllisyytesi ennen pyynnön käsittelemistä.
</p>
<p>
    <strong>9 Rekisterin suojauksen periaatteet</strong>
</p>
<p>
    Kunnioitamme henkilötietojesi luottamuksellisuutta. Digitaalisesti
    käsiteltävät henkilötiedot on suojattu ja tallennettu järjestelmiin, johon
    on rajattu pääsy vain sellaisilla henkilöillä, jotka tarvitsevat kyseisiä
    tietoja työtehtäviensä hoitamiseksi. Tietojärjestelmät ja tiedostot on
    suojattu yritystoiminnassa normaalisti käytössä olevilla teknisillä
    suojausmenetelmillä. Rekisterin käyttöoikeus edellyttää henkilökohtaista
    käyttäjätunnusta ja salasanaa, jotka myönnetään vain rekisterinpitäjän
    henkilökuntaan kuuluvalle, jonka asemaan ja tehtäviin mainittu käyttöoikeus
    liittyy.
</p>
<p>
    <strong>
        10 Evästeet
        <br/>
        <br/>
    </strong>
    Eväste on yleisesti käytetty pieni tekstitiedosto, jonka internetselain
    tallentaa tietokoneellesi tai muulle päätelaitteellesi, kun vierailet
    verkkosivulla. Selain lähettää tiedon vierailustasi takaisin verkkosivulle,
    kun vierailet siellä uudestaan. Kaikki nykyaikaiset verkkosivut käyttävät
    evästeitä tarjotakseen sinulle personoidumman selainkokemuksen. Evästeitä
    tarvitaan esimerkiksi siihen, kun teet ostoksia verkkokaupassa, ostoskori
    muistaa evästeiden avulla, mitä olet koriin laittanut.
    <br/>
    <br/>
</p>
<p>
    Kukin eväste on erikseen asetettu kullekin päätelaitteellesi ja evästeitä
    voi lukea ainoastaan sillä palvelimella, jolla eväste on asetettu. Koska
    eväste on sidottu selaimeen, eikä lähtökohtaisesti ole jaettavissa eri
    selainten tai laitteiden välillä (ellei jokin selain, lisäosa tai muu
    sovellus erikseen mahdollista tätä), evästeiden hallinnoimiseksi tekemät
    valintasi soveltuvat lähtökohtaisesti vain kyseiseen yksittäiseen
    selaimeen. Eväste ei voi ajaa ohjelmistoja, eikä sitä voida käyttää
    virusten tai muun haitallisen koodin toimittamiseen, eikä vahingoittamaan
    päätelaitettasi tai tiedostojasi. Yksittäistä käyttäjää ei voida tunnistaa
    pelkästään evästeen tai vastaavien teknologioiden käytön kautta.
    <br/>
    <br/>
</p>
<p>
    Sivustollamme käytetään Google Analytics -palvelua, joka on Googlen
    verkkoanalyysipalvelu. Google Analytics käyttää evästeitä
    tekstitiedoissaan. Tämä eväste tallennetaan tietokoneellesi, mikä
    mahdollistaa sivuston käyttöanalyysin. Evästeen antama tieto sivuston
    käytöstä (vain IP-osoite, joka on tehty tunnistamattomaksi) lähetetään ja
    tallennetaan Googlen palvelimelle Yhdysvaltoihin. Niissä tapauksissa,
    joissa Google siirtää henkilötietoja EU:n ja ETA-alueen ulkopuolelle,
    tietoja käsitellään Standard contractual clauses (SCCs) asetusten
    mukaisilla tavoilla. Jos haluat poistaa Google Analyticsin käytöstä, voit
    tehdä sen asentamalla Google Analytics Opt-Out Browser -lisäosan tai
    lopettaa tämän sivuston käytön. Google käyttää näitä tietoja arvioidakseen
    sivuston käyttöä, raportoimalla käyttötiheydestä ja internetin käyttöön
    liittyvistä palveluista. Voit lukea tarkemmin Google Analyticsin
    tietosuojapolitiikasta
    <a href="https://support.google.com/analytics/answer/6004245?hl=fi">
        tästä linkistä.
    </a>
</p>
<p>
    <strong>DoubleClick</strong>
</p>
<p>
    Käytämme (Googlen) DoubleClick-alustaa mainontaan ulkoisessa
    verkkomediassa.
    <br/>
    DoubleClick automatisoi mainosten ostamisprosessin ja määrittelemien
    kriteerien mukaisen mainostilan. Ulkoinen mainonta on yksilöllistä ja
    suunnattu tietyille kävijöille.
    <a href="https://support.google.com/faqs/answer/2727482?hl=en">
        Lue lisää.
    </a>
</p>
<p>
    <br/>
    DoubleClick-evästeet tallennetaan kävijän laitteeseen, kun kävijä tulee
    jollekin sivulle, jolla käytetään DoubleClick-alustaa tai (Googlen)
    AdWordsin tai YouTuben kaltaista sisältöä, joka asettaa evästeitä
    DoubleClickin puolesta.
    <br/>
    Kävijä ei näe DoubleClick-alustaa millään tavalla. Se toimii taustalla ja
    eväste asetetaan laitteeseen.
    <br/>
    DoubleClickin keräämät tiedot lähetetään Googlelle. Google voi käyttää
    näitä tietoja kaikessa kävijälle suunnatussa verkkomainonnassa.
</p>
<p>
    <strong>11 Yhteydenotot</strong>
</p>
<p>
    Kaikki yllä mainittujen oikeuksiesi käyttämistä koskevat pyyntösi,
    kysymyksesi tästä tietosuojaselosteesta ja muut yhteydenottosi tulee tehdä
    sähköpostitse
    <a href="mailto:asiakaspalvelu@nettifillari.fi">
        asiakaspalvelu@nettifillari.fi
    </a>
</p>
  `,
  phone: 'Puhelinnumero',
  buyANotice: 'Osta maksettu ilmoitus',
  EUR: '€',
  aDay: 'päivässä',
  aWeek: 'viikossa',
  alreadyAUser: 'Oletko jo käyttäjä?',
  userCreated: 'Rekisteröiminen onnistui. Lähetimme sinulle sähköpostin, jotta voit vahvistaa sähköpostiosoitteesi',
  verificationCode: 'Vahvistuskoodi',
  userActivated: 'Tilisi on aktivoitu.',
  enterVerificationCode: 'Syötä alle vahvistuskoodi, joka lähetettiin sähköpostiisi.',
  resendCode: 'Lähetä koodi uudestaan',
  newPasswordSet: 'Salasanasi on vaihdettu',
  newBicycle: 'Uusi pyöräilmoitus',
  public: 'Julkinen',
  private: 'Yksityinen',
  condition: 'Kunto',
  tyreSize: 'Rengaskoko',
  description: 'Kuvaus',
  gender: 'Käyttäjä',
  deliveryInstruction: 'Toimitustiedot',
  frameSize: 'Rungon koko',
  urban: 'Urbaanit',
  gears: 'Vaihteet',
  featureIn: 'Näytä',
  request: 'Lähetä',
  top5Euro: 'Top5 (+5€)',
  top10Euro: 'Top10 (+3€)',
  brand: 'Merkki',
  price: 'Hinta',
  year: 'Vuosi',
  model: 'Malli',
  colors: 'Värit',
  brakes: 'Jarrut',
  frameMaterial: 'Runko',
  myProfile: 'Profiilini',
  dashboard: 'Ohjausnäkymä',
  signOut: 'Kirjaudu ulos',
  myPosts: 'Ilmoitukseni',
  profile: 'Profiili',
  category: 'Kategoria',
  new: 'Uusi',
  secondHand: 'Käytetty',
  stolen: 'Varastettu',
  leasing: 'Leasing',
  renting: 'Vuokra',
  batteryLocation: 'Akun sijainti',
  reaBattery: 'Tavaratelinteessä',
  hubBattery: 'Napa-akku',
  frameBattery: 'Runkoakku',
  intubeBattery: 'Alaputkessa',
  motors: 'Moottori',
  users: 'Käyttäjät',
  bicycles: 'Pyörät',
  banner: 'Banneri',
  newsletter: 'Uutiskirje',
  settings: 'Asetukset',
  searchByUserOrModel: 'Etsi käyttäjän tai mallin perusteella',
  filter: 'Filtteröi',
  active: 'Aktiivinen',
  bicycleIsCreated: 'Pyöräilmoitus on luotu',
  bicycleApproved: 'Pyöräilmoitus on hyväksytty',
  chooseYourFavorite: 'Käytettyjen ja uusien polkupyörien kauppapaikka netissä – valitse suosikkisi!',
  SearchPlaceHolder: 'Etsi pyörää avainsanalla',
  all: 'KAIKKI',
  todaysHighlight: 'NettiFillarin siisteimmät nostot tänään',
  contactSeller: 'Ota yhteyttä myyjään',
  characteristics: 'Ominaisuudet',
  bikType: 'Pyörän tyyppi',
  frame: 'Runko',
  shareOn: 'Jaa',
  userUpdated: 'Käyttäjä on päivitetty',
  LEASING: 'Leasing',
  NEW: 'Uusi',
  RENTING: 'Vuokraus',
  SECOND_HAND: 'Käytetyt',
  STOLEN: 'Varastetut',
  rejectReason: 'Hylkäämisen syy',
  pleaseChooseReason: 'Valitse hylkäämisen syy',
  poorQuality: 'Huonolaatuinen kuva',
  lackOfBike: 'Puutteelliset tiedot tai kuvaus',
  badBike: 'Huonolaatuinen pyörä',
  otherReason: 'Muut syyt: ota yhteys NettiFillariin',
  accept: 'Hyväksy',
  reject: 'Hylkää',
  close: 'Sulje',
  bicycleRejected: 'Pyöräilmoitus on hylätty',
  reviewBicycle: 'Näytä pyöräilmoitus',
  edit: 'Muokkaa',
  size: 'Koko',
  youMightAlsoLike: 'Saatat pitää myös näistä',
  title: 'Otsikko',
  date: 'Päivämäärä',
  searchByTitleOrModel: 'Etsi ilmoituksen otsikon tai avainsanan perusteella',
  searchByTitle: 'Etsi ilmoituksen otsikolla',
  newBlogPost: 'Uusi blogijulkaisu',
  id: 'Ilmoituksen tunnus',
  position: 'Asettelu',
  photos: 'Kuvat',
  hoverText: 'Teksti kuvan päällä',
  url: 'URL',
  startDate: 'Aloituspäivä',
  endDate: 'Lopetuspäivä',
  newBannerPost: 'Uusi bannerijulkaisu',
  receiversEmail: 'Vastaanottajien sähköpostiosoitteet',
  attachedFile: 'Liitä tiedosto',
  newEmail: 'Uusi sähköposti',
  profileInfo: 'Profiilin tiedot',
  uploadPhoto: 'Lataa kuvat',
  phoneNumber: 'Puhelinnumero',
  save: 'Tallenna',
  changePassowrd: 'Vaihda salasana',
  currentPassword: 'Nykyinen salasana',
  passwordIsChanged: 'Salasana on vaihdettu',
  profileIsUpdated: 'Profiili on päivitetty',
  bicycleIsUpdated: 'Pyöräilmoitus on päivitetty',
  markAsSold: 'Merkitse myydyksi',
  markAsFound: 'Merkitse löydetyksi',
  markAsLeased: 'Merkitse leasatuksi',
  markAsRented: 'Merkitse vuokratuksi',
  english: 'Englanti',
  suomi: 'Suomi',
  slug: 'Slug (URLin loppuosa)',
  metaTag: 'Metatiedot',
  blogPostBody: 'Tekstikenttä',
  post: 'Julkaise',
  finnishTitle: 'Otsikko suomeksi',
  finnishBody: 'Tekstikenttä suomeksi',
  finnishMetaTag: 'Metatiedot suomeksi',
  postCreated: 'Julkaisu on luotu',
  inactive: 'Ei aktiivinen',
  postIsUpdated: 'Julkaisu on päivitetty',
  update: 'Päivitä',
  postUpdated: 'Julkaisu on päivitetty',
  confirmDeletePost: 'Haluatko varmasti poistaa julkaisun?',
  yes: 'Kyllä',
  no: 'Ei',
  postIsDeleted: 'Julkaisu on poistettu',
  editBicycle: 'Muokkaa pyöräilmoitusta',
  blogInnerPage: 'Blogisivu',
  home: 'Etusivu',
  status: 'Status',
  apply: 'Käytä',
  reset: 'Resetoi',
  selectRejectionReason: 'Syötä hylkäämisen syy',
  newsletterCreated: 'Sähköposti luotu',
  send: 'Lähetä',
  pleaseUploadFile: 'Lataa tiedosto',
  newBanner: 'Uusi banneri',
  enterCorrectUrl: 'Syötä oikea url',
  paymentSuccessFul: 'Maksu onnistui!',
  yourPaymentWasSuc: 'Maksusi onnistui! Voit nyt jatkaa NettiFillarin käyttöä.',
  gotoProfile: 'Siirry profiiliin.',
  yourPaymentFailed: 'Maksu epäonnistui! Näyttää siltä, että emme saaneet maksua.',
  paymentFailed: 'Maksu epäonnistui',
  bannerCreated: 'Banneri on luotu',
  bannerUpdated: 'Banneri on päivitetty',
  confirmDeleteBanner: 'Vahvista bannerin poisto',
  bannerIsDelete: 'Banneri on poistettu',
  pending: 'Odottaa',
  pleaseUploadImage: 'Lisää kuvia pyöräilmoitukseesi',
  ascending: 'Nouseva',
  pleaseFillCityAndPhone: 'Lisää kaupunki ja puhelinnumero',
  editBanner: 'Muokkaa banneria',
  bicyclesList: 'Pyörälistaus',
  editPost: 'Muokkaa julkaisua',
  done: 'Valmis',
  BAD_BIKE_QUALITY: 'Huono pyöränlaatu',
  NOT_ENOUGH_INFORMATION: 'Ei tarpeeksi tietoja',
  OTHER: 'Muut syyt',
  POOR_PICTURE_QUALITY: 'Huonolaatuinen kuva',
  incorrectPassword: 'Väärä salasana.',
  pleaseFillPhone: 'Lisääthän puhelinnumeron profiiliisi',
  pleaseFillCity: 'Lisääthän paikkakunnan profiiliisi',
  bannerSizeHint: 'Bannerin suositeltu koko: 850x417, 630x417, 410x417, tiedostokoko ei saa ylittää 2 MB',
  bicycleSizeHint: 'Suositeltu koko 290x170. Tiedostokoko ei saa ylittää  2 MB',
  postHint: 'Suositeltu koko 850x420. Tiedostokoko ei saa ylittää 2 MB',
  emailHint: 'Tiedosto tulee olla PDF tai word formaatissa ja tiedoston enimmäiskoko on 2 MB',
  mainPageTitle: 'Monipuolinen pyörien kauppapaikka netissä.',
  mainPageContent: `Haaveiletko käytetystä täysjousto maastopyörästä tai onko hankintalistalla ensimmäinen lasten polkupyörä? NettiFillarista löydät niin miesten, naisten, nuorten kuin lasten polkupyörät monenlaiseen ajoon: maastoon, kaupunkiin tai maantielle! Valikoimassamme on useita pyörämerkkejä aina laadukkaista maantiepyöristä trendikkäisiin sähköpolkupyöriin. Etsi juuri sinun tarpeisiisi sopiva pyörä käyttämällä laajaa pyörähakuamme, ja huomaat kuinka helppoa ja nopeaa polkupyörän ostaminen verkosta voikaan olla. Tutustu myös blogiimme, josta löydät vinkkejä muun muassa oikean kokoisen polkupyörän ostoon.`,
  sellOnNetTitle: 'Myy käytetty polkupyöräsi helposti netissä',
  sellOnNetH2: 'Ohjeet polkupyörän myyntiin',
  SellerH1: 'Myy käytetty polkupyöräsi helposti NettiFillarissa',
  sellerP:
    'Myyminen NettiFillarissa on helppoa! Voit ilmoittaa pyöräsi myytäväksi  rekisteröidyttyäsi NettiFillarin käyttäjäksi. Panostathan pyörän myynti-ilmoitukseen ja ota selkeät kuvat myytävästä pyörästä. Pyörien ostamisesta kiinnostuneet NettiFillarin käyttäjät etsivät käytettyjä ja uusia polkupyöriä pyörähaun (hakukenttä) tai merkkihaun kautta. Siksi on tärkeää, että myynti-ilmoituksessa on mainittu pyörän perustiedot, merkki ja mahdolliset lisäkuvaukset oikein. Osa myynti-ilmoituksen kentistä on pakollisia, jotta pyörän myyminen ja ostaminen olisi mahdollisimman helppoa. Myös myynti-ilmoituksen kuva on pakollinen.',
  blogH1: 'NettiFillarin pyöräilyblogi',
  blogH2: 'Vinkkejä pyöräilyyn ja neuvoja pyörän valintaan',
  blogTopContent:
    'Polkupyöräily on tehokasta liikuntaa, ekologinen ja kätevä kulkumuoto ja ennen kaikkea loistava harrastus. Lue blogistamme, miten saat pyöräilystä kaiken ilon irti: mikä pyörän runkokoko on oikea valinta? Mitä eroa on erilaisilla jousituksilla? Mitä kannattaa ottaa huomioon pyörää valitessa? Ajankohtaista asiaa ja käytännön tietoa pyöräilystä tarjoavat NettiFillarin pyöräilyasiantuntijat, harrastajat ja vieraskirjoittajat!',
  blogTitle: 'NettiFillarin pyöräilyblogi',
  blogMeta:
    'NettiFillarissa myyt ja ostat pyörät helposti. Pyöräilyblogimme antaa vinkkejä ja ajankohtaista tietoa pyöristä ja pyöräilystä. Lue lisää nyt!',
  sellMeta:
    'NettiFillarissa myyt ja ostat käytetyt polkupyörät helposti netissä. Ilmoita pyöräsi myytäväksi maksutta. Siirry pyöräkaupoille nyt!',
  phonePrefix: '+358',
  FAQTitle: 'Myy ja osta fillari, usein kysyttyä | NettiFillari.fi',
  FAQdesc:
    'Myy ja osta fillari helposti NettiFillarista. Olemme pyrkineet tekemään käytettyjen pyörien myymisestä ja ostamisesta mahdollisimman helppoa. Tutustu ohjeisiin ja usein kysyttyihin kysymyksiin!',
  FAQPolicy: 'NettiFillarin ohjeet ja usein kysyttyä ',
  FAQh2: 'Vastaamme fillarin myyjän ja ostajan kysymyksiin!',
  FAQBody: `Olemme pyrkineet tekemään käytettyjen pyörien myymisestä ja ostamisesta mahdollisimman helppoa. NettiFillari-sivustomme on suunniteltu tarjoamaan perinteisen nettikaupan miellyttävä asiakaskokemus, jota tukee selkeä tuotelistaus, navigointi sekä tyylikäs ulkoasu. Käytettyjenkin fillareiden myyminen ja ostaminen voi olla helppoa! Tällä sivulla vastaamme usein kysyttyihin kysymyksiin. Kehitämme sivustoa jatkuvasti palautteiden ja kysymysten perusteella. Asiakaspalvelumme palvelee sähköpostitse osoitteella <a href="mailto:&#097;&#115;&#105;&#097;&#107;&#097;&#115;&#112;&#097;&#108;&#118;&#101;&#108;&#117;&#064;&#110;&#101;&#116;&#116;&#105;&#102;&#105;&#108;&#108;&#097;&#114;&#105;&#046;&#102;&#105;">Email</a>.




  Mikä on NettiFillari?
  
  Tavoitteemme on tarjota helppokäyttöinen käytettyjen ja uusien fillarien kauppapaikka netissä kaikille pyöräilystä kiinnostuneille. Olemme kategorisoineet pyörät muun muassa pyörämerkkien, pyörän koon, pyörän tyypin ja käyttötarkoituksen mukaan, jotta pyörien etsiminen olisi sujuvaa. NettiFillari-sivustolla pyörän myynti-ilmoituksen teko vaatii rekisteröitymisen ja kirjautumisen sivuston käyttäjäksi. Pyörän ostajan ei tarvitse rekisteröityä tai kirjautua sivustolle. Pyöräkaupat tehdään suoraan myyjän ja ostajan välillä. 
  
  
  
  
  Maksaako NettiFillari-sivuston käyttö? 
  
  Sivustolle rekisteröityminen, pyörien etsiminen sekä myynti-ilmoituksen tekeminen on käyttäjälle täysin maksutonta. Rekisteröityneenä käyttäjänä voit ostaa maksullisia lisäpalveluita, kuten fillarin myynti-ilmoituksen mainostusta ja ilmoituksen uusimisen.
  
  
  
  
  Voinko palauttaa ostamani fillarin? 
  
  NettiFillari on käytettyjen ja uusien pyörien kauppapaikka, jossa sivuston käyttäjät voivat myydä ja ostaa pyöriä. NettiFillari Oy ei siis myy polkupyöriä kuluttajille, eikä täten voi ottaa vastaan tuotteiden palautuksia. Tutustuthan myynti-ilmoituksiin huolellisesti ja kysy tarvittaessa myyjältä lisätietoja tai lisäkuvia myytävästä fillarista ennen sen ostoa.`,
  faq: 'FAQ',
  tireSize: 'Rengas koko',
  Gender: 'Käyttäjä',
  frames: 'Runko',
  color: 'Värit',
  suspension: 'Jousitus',
  upload: 'Lataa kuva',
  reason: 'Syy',
  REJECTED: 'Hylätty',
  RENTED: 'Vuokrattu',
  SOLD: 'Myyty',
  FOUND: 'Löytynyt',
  LEASED: 'Vuokrattu',
  INACTIVE: 'Ei aktiivinen ',
  PENDING: 'Odottaa ',
  ACTIVE: 'Aktiivinen',
  motor: 'Moottori',
  location: 'sijainti',
  body: 'Tekstikenttä',
  search: 'Etsi',
  contents: 'Sisällys',
  editContent: 'Muokkaa sisältöä',
  contentPosition: 'Sisällön sijainti',
  action: 'Toiminta',
  BLOG_HEADING: 'Blogin otsikko',
  BRANDS_HEADING: 'Tuotemerkit otsikko',
  HOME_HEADING: 'Etusivu Otsikko',
  SELL_HEADING: 'Myy otsikko',
  framesMaterial: 'Rungon materiaali',
})

export default fi
