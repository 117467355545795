import { createSlice } from '@reduxjs/toolkit'
import { getTopBicyclesRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { ErrorResponse } from 'interfaces/Error'
import { BicycleAppListView, FeatureType } from 'api/main'

export interface BicycleState {
  is_fetching: boolean
  top5: BicycleAppListView[]
  top10: BicycleAppListView[]
  errors: ErrorResponse
  page: number
}

const initialState: BicycleState = {
  is_fetching: false,
  top5: [],
  top10: [],
  errors: null,
  page: -1,
}

export const bicyclesSlice = createSlice({
  name: 'bicycles',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getTopBicyclesRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(getTopBicyclesRequestAsync.fulfilled, (state, action) => {
        state.is_fetching = false
        if (action?.meta?.arg === FeatureType.TOP_TEN) {
          state.top10 = action?.payload?.content
        } else {
          state.top5 = action.payload?.content
        }
      })
      .addCase(getTopBicyclesRequestAsync.rejected, (state, action: any) => {
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
      })
  },
})

export const selectTopBicycles = (state: RootState): any => state.bicycles.top

export default bicyclesSlice.reducer
