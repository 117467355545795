import { Pagination, Row } from 'antd'
import { USER_OWN_POSTS_PAGE_SIZE } from 'constants/index'
import { Bicycle } from 'interfaces/Bicycles'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { selectOwnBicycles } from 'store/bicycle/getown'
import { getOwnBicyclesRequestAsync } from 'store/bicycle/getown/asyncActions'
import { SelectOwnChangeStatusBicycle } from 'store/bicycle/status'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import styled from 'styled-components'
import { BicyclePost } from '../BicyclePost/BicyclePost'
import { resetState } from 'store/bicycle/status/'
import { Indicator } from 'Components/UiKit/Indicator'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'

export const BicycleList: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const { data, totalElements, is_fetching } = useAppSelector(selectOwnBicycles)
  const { done } = useAppSelector(SelectOwnChangeStatusBicycle)

  useEffect(() => {
    dispatch(getOwnBicyclesRequestAsync({ page, size: USER_OWN_POSTS_PAGE_SIZE }))
    return () => {
      dispatch(resetState())
    }
  }, [dispatch, page, done])

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'bicycle' }} />
      {is_fetching && <Indicator />}
      {data?.map((bike: Bicycle) => (
        <BicyclePost key={bike.id} bicycle={bike} />
      ))}
      <Row justify="end">
        <Pagination
          defaultCurrent={1}
          onChange={setPage}
          current={page}
          defaultPageSize={USER_OWN_POSTS_PAGE_SIZE}
          total={totalElements}
        />
      </Row>
    </Container>
  )
}
const Container = styled.div``
