import { Card, Row, Image } from 'antd'
import { fallBackImage } from 'constants/index'
import { colors } from 'constants/colors'
import { FunctionComponent, useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Padding } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { BicycleAppListView, BicycleDetailsView, MediaNestedView } from 'api/main'
import { useTranslation } from 'react-i18next'

interface BicycleProps {
  bicycle: BicycleDetailsView
}

export const BicycleItem: FunctionComponent<BicycleProps> = ({ bicycle }) => {
  const { i18n, t } = useTranslation<string>()
  const handleOther = (str: string) => {
    if (i18n.language === 'en') {
      return str
    } else if (i18n.language === 'fi') {
      if (str === 'Other') {
        return t(str.toLowerCase())
      } else {
        return str
      }
    }
  }
  const getMediaNestedView = useCallback((bike: BicycleAppListView & { mainMedia?: MediaNestedView }) => {
    return bike.mainMedia ?? bike.medias[0]
  }, [])

  return (
    <>
      <Link to={'/bicycle/' + bicycle.id}>
        <BicycleWrapper
          bordered={false}
          hoverable
          cover={
            <BicycleThumbnail
              alt={bicycle.brand?.name}
              fallback={fallBackImage}
              src={Array.isArray(bicycle.medias) ? getMediaNestedView(bicycle)?.url : ''}
            />
          }
        >
          <Padding padding="10px">
            <Row>{handleOther(bicycle.brand?.name)}</Row>
            <Row>
              {i18n.language === 'fi'
                ? handleOther(bicycle.bicycleType?.finnishName)
                : handleOther(bicycle.bicycleType?.name)}
            </Row>
            <Row>
              <PriceTag>{bicycle.price}</PriceTag>
            </Row>
          </Padding>
        </BicycleWrapper>
      </Link>
    </>
  )
}

const BicycleWrapper = styled(Card)`
  width: 230px;
  height: 100%;
  background-color: ${colors.SELL_BACKGROUND};

  & .ant-card-body {
    background: ${colors.SELL_BACKGROUND};
    padding-left: 0;
    padding-bottom: 0;
  }

  margin: 20px 30px 0px 0px;
`
const BicycleThumbnail = styled(Image)`
  width: 230px;
  background: ${colors.WHITE};
  padding: 30px 5px 30px 5px;
`
const PriceTag = styled.p`
  font-weight: bold;

  &:before {
    content: '€ ';
  }
`
