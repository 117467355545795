import styled from 'styled-components'
import { Button, Row } from 'antd'
import { colors } from 'constants/colors'
import { ButtonProps } from 'Components/UiKit/Buttons/ButtonText'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { FunctionComponent } from 'react'

interface PaddingProps {
  padding?: string
}
interface ButtonType {
  htmlType?: string
  notBlockOnMobile?: boolean
}
interface FavButtonType {
  icon?: React.ReactNode
  onClick?: any
}

export const NetButton = styled(Button)<ButtonProps & ButtonType>`
  background: ${(props): colors => (props.primary ? colors.PRIMARY : colors.WHITE)};
  color: ${(props): colors => (props.primary ? colors.WHITE : colors.PRIMARY)};
  height: 45px;
  border-radius: 4px;
  padding: 9px 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${colors.TEXT_WHITE};
  @media (max-width: 768px) {
    width: ${props => (props.notBlockOnMobile ? 'fit-content' : '100%')};
  }
`
export const Padding = styled.div<PaddingProps>`
  padding: ${(props: PaddingProps) => props.padding || '42px'};
`
export const RowFullHeight = styled(Row)`
  height: 100%;
`
export const AuthForm = styled.form`
  margin: auto;
  width: 368px;
`
export const InfoTitle = styled.h2`
  padding: 96px 0 0 52px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
`
export const InfoBody = styled.p`
  padding: 0 52px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
`

export const Background = styled.div<{ image: string }>`
  height: 100vh;
  width: 100%;
  background-image: url(${props => props.image || 0});
  background-repeat: no-repeat;
`
export const RowWMargin = styled(Row)`
  margin: 16px 0;
`
export const Error = styled.div`
  color: ${colors.RED};
`
export const TextButton = styled(Link)`
  color: ${props => props.color || colors.PRIMARY};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`
export const TextAnchor = styled.a`
  color: ${props => props.color || colors.PRIMARY};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`
export const LargetTextButton = styled(TextButton)`
  font-size: 16px;
  line-height: 24px;
`
export const SignUpText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.BLACK};
  margin-right: 4px;
`
const GrayButton = styled(Button)`
  background: ${colors.LIGHT_GRAY_2};
`
export const FavButton: FunctionComponent<FavButtonType> = ({ icon, onClick }) => (
  <GrayButton onClick={onClick} shape="circle" icon={icon} />
)
export const BackButton: FunctionComponent<FavButtonType> = ({ onClick }) => (
  <FavButton onClick={onClick} icon={<ArrowLeftOutlinedWhite />} />
)
export const BlackButton = styled(Button)<{ block?: boolean }>`
  background: ${colors.BLACK};
  color: ${colors.WHITE};
  width: ${props => (props.block ? '100%' : 'auto')};
`
export const WhiteButton = styled(Button)<{ block?: boolean }>`
  background: ${colors.WHITE};
  color: ${colors.BLACK};
  width: ${props => (props.block ? '100%' : 'auto')};
`
export const ClearGhostBtn = styled(Button)`
  border: none;
`
export const RowFullWidth = styled(Row)`
  width: 90vw;
`
export const H1 = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
`
const ArrowLeftOutlinedWhite = styled(ArrowLeftOutlined)`
  color: ${colors.WHITE};
`
export const IconWrapper = styled.div`
  margin: 13px 8px 13px 8px;
  color: ${colors.LIGHT_GRAY_3};
`
