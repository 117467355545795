import React, { ReactElement, useEffect, useState } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap-grid.css'
import { selectAuthStatus, setLoggedIn } from './store/auth/login'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { apiConfig } from 'api/config'
import { Router } from 'react-router'
import { browserHistory } from './routes/historyWrapper'
import Routes from './routes'
import { getToken } from 'utils/storage'
import { Indicator } from 'Components/UiKit/Indicator'
import { colors } from 'constants/colors'
import styled from 'styled-components'
import { ScrollToTop } from './Components/Shared/ScrollToTop'
import { HelmetWrapper } from './Components/UiKit/HelmetWrapper/HelmetWrapper'

function App(): ReactElement {
  const [authCheckDone, setAuthCheckDone] = useState(false)
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectAuthStatus)

  useEffect(() => {
    apiConfig()
  }, [dispatch])

  useEffect(() => {
    const token = getToken()
    if (token) {
      dispatch(setLoggedIn({ token }))
      setTimeout(() => {
        setAuthCheckDone(true)
      }, 0)
    } else {
      setAuthCheckDone(true)
    }
  }, [dispatch, status])

  return authCheckDone ? (
    <BackgroundLayout>
      <HelmetWrapper parentData={{}} />
      <Router history={browserHistory}>
        <ScrollToTop />
        <Routes />
      </Router>
    </BackgroundLayout>
  ) : (
    <Indicator />
  )
}

const BackgroundLayout = styled.div`
  & .ant-layout {
    background-color: ${colors.BG};
  }
`
export default App
