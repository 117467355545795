import { FunctionComponent, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ObjectShape } from 'yup/lib/object'
import { Col, Input, message } from 'antd'
import { AuthForm, RowWMargin, NetButton, BackButton, IconWrapper } from '../Shared/TinyComponents/TinyComponents'
import { MailOutlined } from '@ant-design/icons'
import { AuthWrapper } from '../Shared/Wrapper/AuthWrapper'
import { FormInput } from '../Shared/InputArea'
import { resetPasswordRequestAsync } from 'store/auth/reset-password/asyncActions'
import { deleteStatus, ResetPasswordRequest, ResetPasswordStatus } from 'store/auth/reset-password'
import { Indicator } from 'Components/UiKit/Indicator'
import { useHistory } from 'react-router-dom'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

const resetPasswordSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
  Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
  })

export const ResetPassword: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPasswordRequest>({ defaultValues: { email: '' }, resolver: yupResolver(resetPasswordSchema(t)) })
  const { is_fetching, status, errors: backendErrors } = useAppSelector(state => state.auth.resetPassword)

  useEffect(() => {
    if (backendErrors) {
      backendErrors.fieldErrors.forEach(err => message.error(err.message))
    }
  }, [backendErrors])

  useEffect(() => {
    if (status === ResetPasswordStatus.requestSuccess) {
      dispatch(deleteStatus())
      message.success(t('resetLinkSent'))
      history.push('home')
    }
  }, [dispatch, t, status, history])

  const onSubmit = handleSubmit((data: ResetPasswordRequest) => {
    dispatch(resetPasswordRequestAsync(data))
  })

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <AuthWrapper>
      <HelmetWrapper parentData={{ titleKey: 'resetPassword' }} />
      {is_fetching && <Indicator />}
      <AuthForm onSubmit={onSubmit}>
        <RowWMargin>
          <BackButton onClick={goBack} />
        </RowWMargin>
        <RowWMargin>
          <h2>{t('forgotPassword')}</h2>
        </RowWMargin>
        <RowWMargin>{t('PleaseEnterYourRegisteredEmailSoWeCanSendYouACodeToResetYourPassword')}</RowWMargin>
        <Col>
          <Input.Group>
            <RowWMargin>
              <FormInput
                name="email"
                prefix={
                  <IconWrapper>
                    <MailOutlined />
                  </IconWrapper>
                }
                title={t('email')}
                control={control}
                error={errors.email}
              />
            </RowWMargin>
            <RowWMargin justify={'space-between'} align={'middle'}>
              <NetButton primary htmlType="submit">
                {t('submit')}
              </NetButton>
            </RowWMargin>
          </Input.Group>
        </Col>
      </AuthForm>
    </AuthWrapper>
  )
}
