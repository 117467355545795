import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

interface NotFoundProps {
  isRejected?: boolean
}

export const NotFound: FunctionComponent<NotFoundProps> = ({ isRejected = true }) => {
  if (isRejected) {
    return (
      <StyledRow justify={'center'} align={'middle'}>
        <HelmetWrapper parentData={{ noIndex: true }} />
        <Col>
          <H1>404: Page Not Found.</H1>
          <StyledLink to="/">Redirect To Home Page</StyledLink>
        </Col>
      </StyledRow>
    )
  } else {
    return null
  }
}

export const StyledRow = styled(Row)`
  height: 100vh;
  max-height: 100%;
  background-color: #ececec;
  font-weight: normal;
`
export const H1 = styled.h1`
  font-size: 30px;
  font-weight: normal;
`
export const StyledLink = styled(Link)`
  font-size: 25px;
  font-weight: normal;
`
