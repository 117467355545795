import { createSlice } from '@reduxjs/toolkit'
import { ErrorResponse } from 'interfaces/Error'
import { registerRequestAsync } from './asyncActions'

export interface RegisterState {
  is_fetching: boolean
  isDone: boolean
  errors: ErrorResponse
}

const initialState: RegisterState = {
  is_fetching: false,
  isDone: false,
  errors: null,
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    deleteStatus: state => {
      state.isDone = false
    },
  },

  extraReducers: builder => {
    builder
      .addCase(registerRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(registerRequestAsync.fulfilled, state => {
        state.is_fetching = false
        state.isDone = true
      })
      .addCase(registerRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.isDone = false
        state.is_fetching = false
      })
  },
})

export const { deleteStatus } = registerSlice.actions

export default registerSlice.reducer
