import { Route, Switch } from 'react-router-dom'

import { FunctionComponent } from 'react'
import { Layout } from 'antd'
import { RouteProps } from 'react-router'
import { Footer } from 'Components/Shared/Footer/Footer'
import { AdminLayout } from 'Components/Admin/Shared/Layout/Layout'
import { BicyclesList } from 'Components/Admin/Bicycles/Bicycles'
import { UsersList } from 'Components/Admin/Users/Users'
import { SingleBicycle } from 'Components/Admin/SingleBicycle/SingleBicycle'
import { BlogList } from 'Components/Admin/Blog/Blog'
import { BannerList } from 'Components/Admin/Banner/Banner'
import { NewsletterList } from 'Components/Admin/Newsletter/Newsletter'
import { AdminNewBicycle } from 'Components/Admin/Bicycles/NewBicycle/NewBicycle'
import { NewPost } from 'Components/Admin/Blog/NewPost/NewPost'
import { EditPost } from 'Components/Admin/Blog/EditPost/EditPost'
import { NewEmail } from 'Components/Admin/Newsletter/NewEmail/NewEmail'
import { NewBanner } from 'Components/Admin/Banner/NewBanner/NewBanner'
import { EditBanner } from 'Components/Admin/Banner/EditBanner/EditBanner'
import { Content } from 'Components/Admin/Content/Content'
import styled from 'styled-components'
import { EditContent } from '../../Components/Admin/Content/EditContent/EditContent'
import { EditBicycle } from '../../Components/Profile/Bicyles/EditBicycle/EditBicycle'

const adminPageRoutes: RouteProps[] = [
  {
    exact: true,
    path: '/admin/bicycles',
    children: <BicyclesList />,
  },
  {
    exact: true,
    path: '/admin/users',
    children: <UsersList />,
  },
  {
    exact: true,
    path: '/admin/bicycle/:id',
    children: <SingleBicycle />,
  },
  {
    exact: true,
    path: '/admin/bicycle/edit/:id',
    children: <EditBicycle />,
  },
  {
    exact: true,
    path: '/admin/blog',
    children: <BlogList />,
  },
  {
    exact: true,
    path: '/admin/banner',
    children: <BannerList />,
  },
  {
    exact: true,
    path: '/admin/newsletter',
    children: <NewsletterList />,
  },
  {
    exact: true,
    path: '/admin/new-bicycle',
    children: <AdminNewBicycle />,
  },
  {
    exact: true,
    path: '/admin/new-post',
    children: <NewPost />,
  },
  {
    exact: true,
    path: '/admin/post-edit/:id',
    children: <EditPost />,
  },
  {
    exact: true,
    path: '/admin/new-email',
    children: <NewEmail />,
  },
  {
    exact: true,
    path: '/admin/new-banner',
    children: <NewBanner />,
  },
  {
    exact: true,
    path: '/admin/update-banner/:id',
    children: <EditBanner />,
  },
  {
    exact: true,
    path: '/admin/content',
    children: <Content />,
  },
  {
    exact: true,
    path: '/admin/content-edit/:contentPosition',
    children: <EditContent />,
  },
]

export const paths = [adminPageRoutes.map(route => route.path)].flat().filter(path => path) as string[]

const MainRoutes: FunctionComponent = () => {
  return (
    <Layout className={'full-screen-min-height'}>
      <MainLayout className="site-layout-background">
        <AdminLayout>
          <Switch>
            {adminPageRoutes.map(route => (
              <Route key={route.path.toString()} {...route} />
            ))}
          </Switch>
        </AdminLayout>
        <Footer />
      </MainLayout>
    </Layout>
  )
}
const MainLayout = styled(Layout)`
  overflow: hidden;
`
export default MainRoutes
