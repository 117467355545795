import { FunctionComponent, useEffect } from 'react'
import { useHistory } from 'react-router'
import { setLoggedIn } from 'store/auth/login'
import { useAppDispatch, useQueryParams } from 'store/hooks'
import { setToken } from 'utils/storage'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

export const OathDone: FunctionComponent = () => {
  const { token } = useQueryParams()
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(setLoggedIn({ token }))
    setToken(token)
    history.push('/')
  }, [dispatch, history, token])

  return (
    <>
      <HelmetWrapper parentData={{ titleKey: 'done' }} />
      Loading...
    </>
  )
}
