import { yupResolver } from '@hookform/resolvers/yup'
import { Row } from 'antd'
import { ChangePasswordVM } from 'api/main'
import React, { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { ProfileInput, SaveButton, SaveContainer } from '../Components/Components'
import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { ObjectShape } from 'yup/lib/object'
import { useAppDispatch } from 'store/hooks'
import { changePasswordRequestAsync } from 'store/auth/change-password/asyncActions'
import { useTranslation } from 'react-i18next'

export const ChangePassword: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation<string>()

  const changePasswordSchema = (t: TFunction): Yup.ObjectSchema<ObjectShape> =>
    Yup.object().shape({
      newPassword: Yup.string().min(8, t('tooShort')).max(50, t('tooLong')).required(t('required')),
      oldPassword: Yup.string().min(8, t('tooShort')).max(50, t('tooLong')).required(t('required')),
    })
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ChangePasswordVM>({
    resolver: yupResolver(changePasswordSchema(t)),
  })

  const onSubmit = handleSubmit((data: ChangePasswordVM) => {
    dispatch(changePasswordRequestAsync(data))
  })

  return (
    <form onSubmit={onSubmit}>
      <Row>
        <ProfileInput
          name="oldPassword"
          type="password"
          title={t('currentPassword')}
          control={control}
          error={errors.oldPassword}
        />
        <ProfileInput
          type="password"
          name="newPassword"
          title={t('newPassword')}
          control={control}
          error={errors.newPassword}
        />
      </Row>
      <Row justify="end">
        <SaveContainer xs={{ span: 24 }} md={{ span: 12 }}>
          <SaveButton type="primary" htmlType="submit">
            {t('save')}
          </SaveButton>
        </SaveContainer>
      </Row>
    </form>
  )
}
