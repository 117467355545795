import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorResponse } from 'interfaces/Error'
import { RootState } from 'store'
import { meRequestAsync } from './asyncActions'

export interface MeResponse {
  city: City
  email: string
  enabled: boolean
  firstName: string
  id: number
  lastName: string
  mobile: string
  photo: Photo
  role: string
}

interface Photo {
  cloudId: string
  format: string
  id: number
  type: string
  url: string
}

interface City {
  id: number
  name: string
}

export interface MeState {
  is_fetching: boolean
  data: MeResponse
  errors: ErrorResponse
}

const initialState: MeState = {
  is_fetching: false,
  data: null,
  errors: null,
}

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    updateCityIdAndPhone: (state, action: PayloadAction<{ id: number }>) => {
      state.data.city = { ...state.data.city, id: action.payload.id }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(meRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(meRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload
      })
      .addCase(meRequestAsync.rejected, (state, { payload }) => {
        state.data = null
        state.is_fetching = false
        state.errors = payload
      })
  },
})

export const selectMe = (state: RootState): MeState => state.auth.me
export const { updateCityIdAndPhone } = meSlice.actions
export default meSlice.reducer
