import { Button, Col, message, Row, Space, Switch, Tabs } from 'antd'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { SaveBlogVM } from 'api/main'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { MediaResponse, selectCreatedMedia } from 'store/bicycle/media'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'
import {
  BICYCLE_MAIN_MEDIA_LABEL,
  BLOG_MEDIA_LABEL,
  CLOUDINARY_ID,
  CLOUDINARY_UPLOAD_PRESET,
  CREATE_MEDIA_MEDIA_TYPE_IMAGE,
  SLUG_PATTERN,
} from 'constants/index'
import { CloudinaryResponse } from 'interfaces/Cloudinary'
import { createMediaRequest } from 'store/bicycle/media/asyncActions'
import { UploadImage } from 'Components/Profile/Bicyles/UploadImage/UploadImage'
import { EditPostInterface, resetState, selectAdminEditPost } from 'store/admin/blog/edit'
import { UrlParamsWithId } from 'interfaces/General'
import { getPostsRequestAsync } from 'store/admin/blog/get/asyncActions'
import { selectAdminPost } from 'store/admin/blog/get'
import { Indicator } from 'Components/UiKit/Indicator'
import { FieldError } from 'interfaces/Error'
import { adminEditPostRequest } from 'store/admin/blog/edit/asyncActions'
import { resetState as resetMediaState } from 'store/bicycle/media'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'
import { SelectBicycleInput, SelectBicycleWrapper } from '../../../Shared/Components/Components'
import { NotFound } from '../../../Shared/NotFound/NotFound'

const { TabPane } = Tabs

export const EditPost: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const [mediaIds, setMediaIds] = useState<string[]>([])
  const dispatch = useAppDispatch()
  const { id } = useParams<UrlParamsWithId>()
  const modules = useMemo(() => {
    return {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image'],
        ['clean'],
        [{ color: [] }],
      ],
    }
  }, [])
  const { quill, quillRef } = useQuill({ modules, placeholder: t('blogPostBody') })
  const { quill: quillFinish, quillRef: quillFinishRef } = useQuill({ modules, placeholder: t('blogPostBody') })
  const { data: uploadedMediaIds, errors: UploadErrors } = useAppSelector(selectCreatedMedia)
  const { data, errors: PostErrors, is_fetching } = useAppSelector(selectAdminPost)
  const { done, errors: updateErrors, is_fetching: updateFetching } = useAppSelector(selectAdminEditPost)

  useEffect(() => {
    if (id) {
      dispatch(getPostsRequestAsync(+id))
    }

    return () => {
      dispatch(resetState())
      dispatch(resetMediaState())
    }
  }, [id, dispatch])

  const newPostSchema = (t: TFunction): Yup.ObjectSchema<ObjectShape> =>
    Yup.object().shape({
      slug: Yup.string().required(t('required')).matches(SLUG_PATTERN, t('enterBasedOnPattern')),
      title: Yup.string().required(t('required')),
      body: Yup.string().required(t('required')),
      metaTag: Yup.string().required(t('required')),
      finnishTitle: Yup.string(),
      finnishBody: Yup.string(),
      finnishMetaTag: Yup.string(),
      enabled: Yup.boolean().required(t('required')),
      photoId: Yup.number(),
    })

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<SaveBlogVM>({
    defaultValues: {},
    resolver: yupResolver(newPostSchema(t)),
  })

  useEffect(() => {
    if (data) {
      reset({
        enabled: data?.enabled,
        body: data?.body || '',
        finnishBody: data?.finnishBody || '',
        finnishMetaTag: data?.finnishMetaTag || '',
        finnishTitle: data?.finnishTitle || '',
        metaTag: data?.metaTag || '',
        photoId: data?.photo?.id,
        slug: data?.slug || '',
        title: data?.title || '',
      })
    }
  }, [data, reset])

  const goBack = () => history.goBack()

  const onSubmit = handleSubmit((requestBody: SaveBlogVM) => {
    if (UploadErrors) return
    if (uploadedMediaIds)
      requestBody.bodyPhotoIds = uploadedMediaIds
        .filter((img: MediaResponse) => mediaIds.indexOf(img.cloudId) > -1)
        .map((img: MediaResponse) => img.id)
    const bodyImage = uploadedMediaIds.filter((img: MediaResponse) => mediaIds.indexOf(img.cloudId) === -1)
    if (bodyImage.length > 0) requestBody.photoId = bodyImage[bodyImage.length - 1]?.id
    else requestBody.photoId = data?.photo?.id
    const request: EditPostInterface = {
      id: +id,
      ...requestBody,
    }
    dispatch(adminEditPostRequest(request))
  })

  const insertToEditor = useCallback(
    (url: string) => {
      const range = quill.getSelection()
      quill.insertEmbed(range.index, 'image', url)
    },
    [quill]
  )

  useEffect(() => {
    if (UploadErrors) {
      UploadErrors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }
  }, [t, UploadErrors, dispatch])

  useEffect(() => {
    if (Array.isArray(PostErrors?.fieldErrors)) {
      PostErrors?.fieldErrors?.forEach((err: FieldError) => message.error(err.message))
    }
    if (Array.isArray(updateErrors?.fieldErrors)) {
      updateErrors?.fieldErrors?.forEach((err: FieldError) => message.error(err.message))
    }
    if (!PostErrors && done) {
      message.success(t('postUpdated'))
      history.push('/admin/blog')
    }
    return () => {
      dispatch(resetState())
    }
  }, [t, PostErrors, updateErrors, done, history, dispatch])

  const saveToServer = useCallback(
    async (file: any) => {
      const data = new FormData()
      data.append('file', file)
      data.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      data.append('cloud_name', 'breellz')
      fetch('https://api.cloudinary.com/v1_1/' + CLOUDINARY_ID + '/image/upload', {
        method: 'post',
        body: data,
      })
        .then(resp => resp.json())
        .then((response: CloudinaryResponse) => {
          dispatch(
            createMediaRequest({
              cloudId: response.public_id,
              mediaType: CREATE_MEDIA_MEDIA_TYPE_IMAGE,
              targetType: BLOG_MEDIA_LABEL,
            })
          )
          setMediaIds([...mediaIds, response.public_id])
          insertToEditor(response.url)
        })
    },
    [insertToEditor, dispatch, mediaIds]
  )

  const selectLocalImage = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = () => {
      const file = input.files[0]
      saveToServer(file)
    }
  }, [saveToServer])

  useEffect(() => {
    if (quill) {
      quill.getModule('toolbar').addHandler('image', selectLocalImage)
      if (data?.body) quill.clipboard.dangerouslyPasteHTML(data?.body)
      quill.on('text-change', () => {
        setValue('body', quillRef.current.firstChild.innerHTML)
      })
    }
  }, [quill, selectLocalImage, quillRef, setValue, data])

  useEffect(() => {
    if (quillFinish) {
      quillFinish.getModule('toolbar').addHandler('image', selectLocalImage)
      if (data?.finnishBody) quillFinish.clipboard.dangerouslyPasteHTML(data?.finnishBody)
      quillFinish.on('text-change', () => {
        setValue('finnishBody', quillFinishRef.current.firstChild.innerHTML)
      })
    }
  }, [quillFinish, selectLocalImage, quillFinishRef, setValue, data])

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'editPost' }} />
      {(is_fetching || updateFetching) && <Indicator />}
      {data ? (
        <form onSubmit={onSubmit}>
          <Topbar justify="space-between">
            <Space>
              <Button type="text" onClick={goBack} icon={<ArrowLeftOutlined />} />
              {t('newBlogPost')}
            </Space>
            <Controller
              control={control}
              name={'enabled'}
              render={({ field: { onChange } }) => (
                <GreenSwitch
                  checkedChildren={t('active')}
                  unCheckedChildren={t('inactive')}
                  defaultChecked={true}
                  onChange={onChange}
                />
              )}
            />
          </Topbar>
          <TabsList defaultActiveKey="english">
            <TabPane tab={t('english')} key="english" forceRender>
              <RowPadding justify="space-between" gutter={20}>
                {data && (
                  <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
                    <SelectBicycleInput
                      value={data?.slug}
                      label={t('slug')}
                      name={'slug'}
                      control={control}
                      error={errors.slug}
                    />
                    <SelectBicycleInput
                      value={data?.title}
                      label={t('title')}
                      name={'title'}
                      control={control}
                      error={errors.title}
                    />
                    <UploadImage
                      hint={t('postHint')}
                      singleMode
                      targetType={BICYCLE_MAIN_MEDIA_LABEL}
                      images={[
                        {
                          uid: data?.photo?.id,
                          url: data?.photo?.url,
                        },
                      ]}
                    />
                  </InputCol>
                )}
                <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
                  {data && (
                    <SelectBicycleInput
                      value={data?.metaTag}
                      label={t('metaTag')}
                      name={'metaTag'}
                      control={control}
                      error={errors.metaTag}
                    />
                  )}
                  <SelectBicycleWrapper label={t('body')} name={'body'} control={control} error={errors.body}>
                    <Quill ref={quillRef} />
                  </SelectBicycleWrapper>
                </InputCol>
              </RowPadding>
            </TabPane>
            <TabPane tab={t('suomi')} key="suomi" forceRender>
              <RowPadding justify="space-between" gutter={20}>
                {data && (
                  <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
                    <SelectBicycleInput
                      label={t('finnishTitle')}
                      value={data?.finnishTitle}
                      name={'finnishTitle'}
                      control={control}
                      error={errors.finnishTitle}
                    />
                  </InputCol>
                )}
                <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
                  {data && (
                    <SelectBicycleInput
                      label={t('finnishMetaTag')}
                      name={'finnishMetaTag'}
                      value={data?.finnishMetaTag}
                      control={control}
                      error={errors.finnishMetaTag}
                    />
                  )}
                  <SelectBicycleWrapper
                    label={t('finnishBody')}
                    name={'finnishBody'}
                    control={control}
                    error={errors.finnishBody}
                  >
                    <Quill ref={quillFinishRef} />
                  </SelectBicycleWrapper>
                </InputCol>
              </RowPadding>
            </TabPane>
          </TabsList>
          <Row justify="end">
            <SubmitButton htmlType="submit">{t('update')}</SubmitButton>
          </Row>
        </form>
      ) : (
        <NotFound />
      )}
    </Container>
  )
}
const Quill = styled.div`
  height: 85%;
`
const Container = styled.div`
  margin: 16px 24px;
  background-color: ${colors.WHITE};
  font-family: 'Roboto';
  font-style: normal;
  color: ${colors.BLACK_TR};
`
const Topbar = styled(Row)`
  padding: 16px 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 1px solid ${colors.HEADER_BORDER};
`
const TabsList = styled(Tabs)`
  margin: 16px 24px;
`
const InputCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const SubmitButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  background: ${colors.BLUE};
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: ${colors.WHITE};
`
const RowPadding = styled(Row)`
  padding: 0 0 50px 0;
`
const GreenSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${colors.BRAND_GREEN};
  }
`
