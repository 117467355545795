import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AppBlogService } from 'api/main'

export const getOnePostsRequestAsync: AsyncThunk<any, string, any> = createAsyncThunk(
  'blog/getOne',
  async (id: string, { rejectWithValue }) => {
    try {
      return AppBlogService.appBlogShowBySlug(id)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
