import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { getAppBannerRequestAsync } from './asyncActions'
import { ErrorResponse } from 'interfaces/Error'
import { BannerListView, BannerPosition } from 'api/main'

export interface BannersGetAllState {
  is_fetching: boolean
  home: BannerListView[]
  blog: BannerListView[]
  bicycle: BannerListView[]
  errors: ErrorResponse
}

const initialState: BannersGetAllState = {
  is_fetching: false,
  home: null,
  blog: null,
  bicycle: null,
  errors: null,
}

export const getAllBannersSlice = createSlice({
  name: 'AllBanners',
  initialState,
  reducers: {
    resetState: state => {
      state.is_fetching = false
      state.blog = null
      state.bicycle = null
      state.errors = null
      state.errors = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAppBannerRequestAsync.pending, state => {
        state.blog = null
        state.bicycle = null
        state.errors = null
        state.is_fetching = true
      })
      .addCase(getAppBannerRequestAsync.fulfilled, (state, action) => {
        state.is_fetching = false
        switch (action?.meta?.arg) {
          case BannerPosition.HOME:
            state.home = action?.payload?.content
            break
          case BannerPosition.BLOG:
            state.blog = action?.payload?.content
            break
          case BannerPosition.BICYCLE:
            state.bicycle = action?.payload?.content
            break
        }
      })
      .addCase(getAppBannerRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.blog = null
        state.bicycle = null
        state.errors = null
        state.is_fetching = false
      })
  },
})

export const selectBanners = (state: RootState): BannersGetAllState => state.banner.getAll
export const { resetState, resetError } = getAllBannersSlice.actions

export default getAllBannersSlice.reducer
