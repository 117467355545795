import { AdminUserService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { UserChangeStatus } from './Status.interface'

export const changeUserStatusRequestAsync: AsyncThunk<any, UserChangeStatus, any> = createAsyncThunk(
  'admin/user/status',
  async (requestBody: UserChangeStatus, { rejectWithValue }) => {
    try {
      return await AdminUserService.adminUserUpdate(requestBody.userId, requestBody.requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
