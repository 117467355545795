import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { getAllContentRequestAsync } from './asyncActions'
import { ErrorResponse } from 'interfaces/Error'
import { ContentPageResponseView } from 'api/main'

export type AdminContentsGetAllState = ContentPageResponseView & {
  isFetching: boolean
  errors: ErrorResponse
}

const initialState: AdminContentsGetAllState = {
  isFetching: false,
  content: [],
  currentPageNumber: null,
  isLast: false,
  pageSize: null,
  totalElements: null,
  errors: null,
}

export const getAllContentSlice = createSlice({
  name: 'adminAllContents',
  initialState,
  reducers: {
    resetState: state => {
      state.isFetching = false
      state.content = []
      state.errors = null
      state.currentPageNumber = null
      state.isLast = false
      state.pageSize = null
      state.totalElements = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAllContentRequestAsync.pending, state => {
        state.content = []
        state.isFetching = true
      })
      .addCase(getAllContentRequestAsync.fulfilled, (state, { payload }) => {
        state.isFetching = false
        state.content = payload?.content
        state.currentPageNumber = payload?.currentPageNumber
        state.isLast = payload?.isLast
        state.pageSize = payload?.pageSize
        state.totalElements = payload?.totalElements
      })
      .addCase(getAllContentRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.content = null
        state.currentPageNumber = null
        state.isLast = false
        state.pageSize = null
        state.totalElements = null
        state.totalElements = null
        state.isFetching = false
      })
  },
})

export const selectAdminContents = (state: RootState): AdminContentsGetAllState => state.admin.contents.getAll
export const { resetState, resetError } = getAllContentSlice.actions

export default getAllContentSlice.reducer
