/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordVM } from '../models/ChangePasswordVM';
import type { UpdateLanguageVM } from '../models/UpdateLanguageVM';
import type { UpdateProfileVM } from '../models/UpdateProfileVM';
import { request as __request } from '../core/request';

export class AppUserService {

    /**
     * Show profile
     * @returns any OK
     * @throws ApiError
     */
    public static async appProfileShow(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/user/me`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Update profile
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static async appProfileUpdate(
        requestBody?: UpdateProfileVM,
    ): Promise<void> {
        const result = await __request({
            method: 'PUT',
            path: `/api/app/user/me`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Change password
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static async appProfilePasswordChange(
        requestBody?: ChangePasswordVM,
    ): Promise<void> {
        const result = await __request({
            method: 'PUT',
            path: `/api/app/user/me/password`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Update language
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static async appLanguageUpdate(
        requestBody?: UpdateLanguageVM,
    ): Promise<void> {
        const result = await __request({
            method: 'PUT',
            path: `/api/app/user/me/language`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}