import { createSlice } from '@reduxjs/toolkit'
import { adminCreatePostRequest } from './asyncActions'

import { RootState } from '../../../index'
import { ErrorResponse } from 'interfaces/Error'

export interface AdminCreatePostState {
  is_fetching: boolean
  done: boolean
  errors: ErrorResponse
}

const initialState: AdminCreatePostState = {
  is_fetching: false,
  done: false,
  errors: null,
}

export const adminCreatePostSlice = createSlice({
  name: 'adminCreatePost',
  initialState,
  reducers: {
    resetState: state => {
      state.is_fetching = false
      state.done = false
      state.errors = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(adminCreatePostRequest.pending, state => {
        state.is_fetching = true
      })
      .addCase(adminCreatePostRequest.fulfilled, state => {
        state.is_fetching = false
        state.done = true
      })
      .addCase(adminCreatePostRequest.rejected, (state, action: any) => {
        state.is_fetching = false
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = null
        }
      })
  },
})

export const selectAdminCreatedPost = (state: RootState): any => state.admin.blog.create
export const { resetState, resetError } = adminCreatePostSlice.actions

export default adminCreatePostSlice.reducer
