import { STORAGE_PENDING_EMAIL_KEY_NAME, STORAGE_TOKEN_KEY_NAME } from 'constants/index'

export const getToken = (): string | null => {
  return localStorage.getItem(STORAGE_TOKEN_KEY_NAME)
}

export const removeToken = (): void => {
  localStorage.removeItem(STORAGE_TOKEN_KEY_NAME)
}

export const setToken = (token: string): void => {
  localStorage.setItem(STORAGE_TOKEN_KEY_NAME, token)
}

export const getPendingEmail = (): string => {
  return localStorage.getItem(STORAGE_PENDING_EMAIL_KEY_NAME)
}

export const setPendingEmail = (email: string): void => {
  localStorage.setItem(STORAGE_PENDING_EMAIL_KEY_NAME, email)
}

export const removePendingEmail = (): void => {
  localStorage.removeItem(STORAGE_PENDING_EMAIL_KEY_NAME)
}
