/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class AppCityService {

    /**
     * Get list of cities
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns any OK
     * @throws ApiError
     */
    public static async appCityList(
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/cities`,
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}