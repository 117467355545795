/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewsletterDetailsView } from '../models/NewsletterDetailsView';
import type { NewsletterPageResponseView } from '../models/NewsletterPageResponseView';
import type { SaveNewsletterVM } from '../models/SaveNewsletterVM';
import { request as __request } from '../core/request';

export class AdminNewsletterService {

    /**
     * Get list of newsletters
     * @param keyword keyword
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns NewsletterPageResponseView OK
     * @throws ApiError
     */
    public static async adminNewsletterList(
        keyword?: string,
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<NewsletterPageResponseView> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/newsletters`,
            query: {
                'keyword': keyword,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
        return result.body;
    }

    /**
     * Create Newsletter By Admin
     * @param requestBody
     * @returns NewsletterDetailsView OK
     * @throws ApiError
     */
    public static async adminNewsletterCreate(
        requestBody?: SaveNewsletterVM,
    ): Promise<NewsletterDetailsView> {
        const result = await __request({
            method: 'POST',
            path: `/api/administration/newsletters`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
        return result.body;
    }

}