import { AdminBicycleService, SaveBicycleVMSaveFromAdminView } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const editBicycleRequest: AsyncThunk<SaveBicycleVMSaveFromAdminView, any, any> = createAsyncThunk(
  'bicycle/edit',
  async (requestBody: SaveBicycleVMSaveFromAdminView & { id: number }, { rejectWithValue }) => {
    try {
      return await AdminBicycleService.adminBicycleUpdate(requestBody.id, requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
