import { AdminContentService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { EditContentInterface } from '../interfaces'

export const adminEditContentRequest: AsyncThunk<any, EditContentInterface, any> = createAsyncThunk(
  'admin/blog/edit',
  async (requestBody: EditContentInterface, { rejectWithValue }) => {
    try {
      return await AdminContentService.adminContentUpdate(requestBody.contentPosition, requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
