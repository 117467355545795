/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlogDetailsView } from '../models/BlogDetailsView';
import type { BlogPageResponseView } from '../models/BlogPageResponseView';
import type { SaveBlogVM } from '../models/SaveBlogVM';
import { request as __request } from '../core/request';

export class AdminBlogService {

    /**
     * Get list of blogs
     * @param keyword keyword
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns BlogPageResponseView OK
     * @throws ApiError
     */
    public static async adminBlogList(
        keyword?: string,
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<BlogPageResponseView> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/blogs`,
            query: {
                'keyword': keyword,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
        return result.body;
    }

    /**
     * Create Blog By Admin
     * @param requestBody
     * @returns BlogDetailsView OK
     * @throws ApiError
     */
    public static async adminBlogCreate(
        requestBody?: SaveBlogVM,
    ): Promise<BlogDetailsView> {
        const result = await __request({
            method: 'POST',
            path: `/api/administration/blogs`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
        return result.body;
    }

    /**
     * Show blog
     * @param blogId blogId
     * @returns BlogDetailsView OK
     * @throws ApiError
     */
    public static async adminBlogShow(
        blogId: number,
    ): Promise<BlogDetailsView> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/blogs/${blogId}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Delete blog
     * @param blogId blogId
     * @returns void
     * @throws ApiError
     */
    public static async adminBlogDelete(
        blogId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/administration/blogs/${blogId}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Update blog
     * @param blogId blogId
     * @param requestBody
     * @returns BlogDetailsView OK
     * @throws ApiError
     */
    public static async adminBlogUpdate(
        blogId: number,
        requestBody?: SaveBlogVM,
    ): Promise<BlogDetailsView> {
        const result = await __request({
            method: 'PUT',
            path: `/api/administration/blogs/${blogId}`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}