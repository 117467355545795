import { Col, Row } from 'antd'
import { NetButton, Padding } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { colors } from 'constants/colors'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import sellImageTop from 'assets/images/sell-top-image.png'
import topFrame1 from 'assets/images/TopImage1.png'
import topFrame2 from 'assets/images/TopImage2.png'
import topFrame3 from 'assets/images/TopImage3.png'
import whoCanUseNet from 'assets/images/who-can-use.png'
import enFloatCard from 'assets/images/float-card-en.png'
import fiFloatCard from 'assets/images/float-card-fi.png'
import whiteBicycle from 'assets/images/bic.png'
import mountainBicycle from 'assets/images/bic2.png'
import redBicycle from 'assets/images/redbic.png'
import { SellCol } from './SellCol/SellCol'
import { useHistory } from 'react-router'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { selectAuthStatus } from 'store/auth/login'
import { AuthStatus } from 'constants/index'
import { getContentRequestAsync } from '../../store/contents/get/asyncActions'
import { selectAppContent } from '../../store/contents/get'
import { HelmetWrapper } from '../UiKit/HelmetWrapper/HelmetWrapper'

export const SellOnNett: FunctionComponent = () => {
  const { t, i18n } = useTranslation<string>()
  const history = useHistory()
  const authStatus = useAppSelector(selectAuthStatus)
  const isLoggedIn = authStatus === AuthStatus.loggedIn
  const dispatch = useAppDispatch()

  const gotoStartSelling = useCallback(() => {
    if (isLoggedIn) history.push('/account/myposts')
    else history.push('/login')
  }, [history, isLoggedIn])

  useEffect(() => {
    dispatch(getContentRequestAsync('SELL_HEADING'))
  }, [dispatch])

  const { data } = useAppSelector(selectAppContent)

  return (
    <>
      <HelmetWrapper parentData={{ descriptionKey: 'sellMeta', titleKey: 'sellOnNetTitle' }} />
      <BannerWrapper>
        <Banner justify="center">
          <BannerCol lg={{ span: 7 }} xs={{ span: 24 }}>
            <BannerTitle>{t('BecomeSellerOnNettiFillari')}</BannerTitle>
            <SellButton notBlockOnMobile primary onClick={gotoStartSelling}>
              {t('startSelling')}
            </SellButton>
          </BannerCol>
          <Col lg={{ span: 9 }} xs={{ span: 24 }}>
            <ImgMaxWidth alt={t('BecomeSellerOnNettiFillari')} src={sellImageTop} />
          </Col>
        </Banner>
        <WHBackground>
          {data && (
            <SellerInformation
              dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? data.body : data.finnishBody }}
            />
          )}
          <TopQuestion>{t('SellerTopQuestion')}</TopQuestion>
          <ImageFrame>
            <Row justify="center" gutter={[10, 20]}>
              <SellCol priceForDay="4" priceForWeek="20" image={topFrame1} />
              <SellCol priceForDay="3" priceForWeek="15" image={topFrame2} />
              <SellCol priceForDay="2" priceForWeek="10" image={topFrame3} />
            </Row>
          </ImageFrame>
        </WHBackground>
        <Padding padding="96px 0">
          <Row justify="center">
            <Row>
              <H2>{t('whoCanUse')}</H2>
            </Row>
            <RowRelative>
              <WhoCanUseImg alt="who can use nettifillari" src={whoCanUseNet} />
              <FloatCardImage alt="who can use nettfillari" src={i18n.language === 'en' ? enFloatCard : fiFloatCard} />
            </RowRelative>
          </Row>
        </Padding>
        <TopQuestionLongMargin>{t('youCanSearch')}</TopQuestionLongMargin>
        <RowMinHeight justify="center">
          <ColWithMargin lg={{ span: 7, push: 1 }} xs={{ span: 24 }}>
            <p>{t('longTextSell')}</p>
          </ColWithMargin>
          <ColRelative lg={{ span: 12, push: 2 }} xs={{ span: 24 }}>
            <FloatingImage1 alt="white bicycles" src={whiteBicycle} />
            <FloatingImage2 alt="mountain bicycles" src={mountainBicycle} />
            <FloatingImage3 alt="red bicycles" src={redBicycle} />
          </ColRelative>
        </RowMinHeight>
      </BannerWrapper>
      <LastQuestion>
        <Centered>{t('weOfferNoCommission')}</Centered>
      </LastQuestion>
      <Padding padding="0 0 32px 0">
        <Row justify="center">
          <SellButton notBlockOnMobile primary onClick={gotoStartSelling}>
            {t('startSelling')}
          </SellButton>
        </Row>
      </Padding>
    </>
  )
}

const BannerWrapper = styled.div`
  background: ${colors.SELL_BACKGROUND};
  min-height: fit-content;
  padding: 66px 10px;
`
const BannerTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 86px;
  @media (max-width: 768px) {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
`
const H2 = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
`
const ImgMaxWidth = styled.img`
  max-width: 100%;
`
const TopQuestion = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 45px auto;
  width: 50vw;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const SellerInformation = styled.div`
  font-style: normal;
  margin: 45px auto;
  width: 50vw;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const TopQuestionLongMargin = styled(TopQuestion)`
  width: fit-content;
  margin: 74px auto;
  color: ${colors.BLACK};
`
const ImageFrame = styled.div`
  margin: 10px auto;
  text-align: center;
`
const FloatCardImage = styled.img`
  width: 100%;
  position: absolute;
  width: 400px;
  right: 160px;
  top: 240px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 80%;
  }
`
const RowRelative = styled(Row)`
  position: relative;
  @media (max-width: 768px) {
    row-gap: 0px;
    display: flex;
    justify-content: start;
    overflow: hidden;
  }
`
const RowMinHeight = styled(Row)`
  min-height: 730px;
`
const ColRelative = styled(Col)`
  position: relative;
  min-height: 760px;
  min-width: 800px;
  overflow: auto;
  @media (max-width: 768px) {
    min-height: 550px;
    min-width: 400px;
  }
  overflow: hidden;
`
const FloatingImage = styled(ImgMaxWidth)`
  position: absolute;
  z-index: 1;
  @media (max-width: 768px) {
    width: 45vw;
  }
`
const FloatingImage1 = styled(FloatingImage)`
  top: 90px;
  left: 70px;
  @media (max-width: 768px) {
    left: 0;
  }
`
const FloatingImage2 = styled(FloatingImage)`
  left: 400px;
  @media (max-width: 768px) {
    left: initial;
    right: 0;
    top: 50px;
  }
`
const FloatingImage3 = styled(FloatingImage)`
  z-index: 2;
  position: absolute;
  left: 240px;
  top: 330px;
  @media (max-width: 768px) {
    left: 80px;
    top: 220px;
  }
`
const Banner = styled(Row)`
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`
const BannerCol = styled(Col)`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const SellButton = styled(NetButton)`
  width: 300px;
  height: 70px;
  font-size: 24px;
`
const ColWithMargin = styled(Col)`
  @media (min-width: 768px) {
    margin-top: 90px;
  }
`
const LastQuestion = styled(TopQuestion)`
  font-size: 36px;
  width: auto;
  margin: 32px auto;
`
const Centered = styled.div`
  margin: auto;
`
const WHBackground = styled.div`
  padding-top: 45px;
  padding-bottom: 96px;
  background: white;
`

const WhoCanUseImg = styled.img`
  width: 100%;
`
