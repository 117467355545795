import { AppBannerService, BannerPageResponseView, BannerPosition } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const getAppBannerRequestAsync: AsyncThunk<BannerPageResponseView, BannerPosition, any> = createAsyncThunk(
  'banner/getAll',
  async (bannerPosition: BannerPosition, { rejectWithValue }) => {
    try {
      return await AppBannerService.appBannerList(bannerPosition)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
