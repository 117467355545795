import { Row } from 'antd'
import { H1 } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { colors } from 'constants/colors'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

export const FAQPage: FunctionComponent = () => {
  const { t } = useTranslation<string>()

  return (
    <GrayBackground>
      <HelmetWrapper parentData={{ descriptionKey: 'FAQdesc', titleKey: 'FAQTitle' }} />
      <ContentCenter>
        <Row>
          <H1>{t('FAQPolicy')}</H1>
          <h2>{t('FAQh2')}</h2>
        </Row>
        <ContentBody dangerouslySetInnerHTML={{ __html: t('FAQBody') }}></ContentBody>
      </ContentCenter>
    </GrayBackground>
  )
}

const GrayBackground = styled.div`
  background-color: ${colors.LIGHT_GRAY};
`
const ContentCenter = styled.div`
  margin: auto;
  max-width: 850px;
  padding: 110px 0;
`
const ContentBody = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
`
