import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { authReducers } from './auth'
import { bicyclesReducers } from './bicycle'
import { blogReducers } from './blog'
import { brandsReducers } from './brands'
import { adminReducers } from './admin'
import { bannerReducers } from './banners'
import { contentReducers } from './contents'

export const store = configureStore({
  reducer: {
    auth: authReducers,
    brands: brandsReducers,
    bicycles: bicyclesReducers,
    blog: blogReducers,
    admin: adminReducers,
    banner: bannerReducers,
    content: contentReducers,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
