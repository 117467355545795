import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AppBicycleService, FeatureType } from 'api/main'

export const getTopBicyclesRequestAsync: AsyncThunk<any, any, any> = createAsyncThunk(
  'bicycles/getTop',
  async (query: FeatureType, { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleSearch(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        query,
        null,
        0,
        10
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
