export enum colors {
  WHITE = 'white',
  NAVY_BLUE = '#000080',
  BG = '#FFFFFF',
  PRIMARY = '#1FC987',
  GRAY = '#636363',
  TEXT_WHITE = '#F8F8F8',
  BLACK = '#000000',
  LIGHT_GRAY_2 = '#C9C9C9',
  RED = 'red',
  DARK_GRAY = '#0A0A0A',
  BRAND_GREEN = '#1FC987',
  LIGHT_GRAY = '#ECECEC',
  LIGHT_GRAY_3 = '#AEAEAE',
  SELL_BACKGROUND = '#FBFBFB',
  GRAY_TEXT = '#19191d',
  BLUE = '#2f5af3',
  BLACK_TR = '#000000d9',
  HEADER_BORDER = '#E0E0E0',
  DANGER_RED = '#FF4D4F',
  TOP_PANEL_OVERLAY = '#0000008c',
  POST_TYPE_BUTTON = '#f2f2f2',
  BOX_BORDER = '#e7e7e7',
  POLAR_GREEN = '#52C41A',
  EXPIRED_GRAY = '#8C8C8C',
  VOLCANO = '#FA541C',
  REJECTED_RED = '#E01541',
  SOLD_GRAY = '#BFBFBF',
}
