import { Row, Space } from 'antd'
import { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled-base'
import { colors } from 'constants/colors'
import { menuItems } from 'constants/menu.items'
import { HeaderMenuItem } from 'interfaces/General'
import { useTranslation } from 'react-i18next'

export const MenuBar: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  return (
    <Row justify="center">
      <Space direction="horizontal" size={42}>
        {menuItems.map((menuItem: HeaderMenuItem) => (
          <MenuLink activeClassName="active" key={menuItem.link} to={menuItem.link}>
            {t(menuItem.title)}
          </MenuLink>
        ))}
      </Space>
    </Row>
  )
}

const MenuLink = styled(NavLink)`
  & {
    color: ${colors.DARK_GRAY};
    position: relative;
  }
  &.active {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${colors.BRAND_GREEN};
      height: 3px;
      border-radius: 2px;
    }
  }
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`
