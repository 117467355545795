import { Col, Row } from 'antd'
import { BlogListView } from 'api/main'
import { H1 } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { colors } from 'constants/colors'
import { ellipsis, formatDate } from 'constants/functions'
import { maxPostContentPreview } from 'constants/index'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  post: BlogListView
}

export const TopPost: FunctionComponent<Props> = ({ post }) => {
  const { t, i18n } = useTranslation()
  const isFin = i18n.language === 'fi'

  return (
    <RowFit justify="center">
      <ColWidth xs={{ span: 24 }} md={{ span: 12 }}>
        <Link to={'/blog/' + post.slug}>
          <Img alt={isFin ? post.finnishTitle : post.title} src={post.photo?.url} />
        </Link>
      </ColWidth>
      <ColWidth xs={{ span: 24 }} md={{ span: 12 }}>
        <Link to={'/blog/' + post.slug}>
          <H1>{isFin ? post.finnishTitle : post.title}</H1>
        </Link>
        <BlogBody
          dangerouslySetInnerHTML={{ __html: ellipsis(isFin ? post.finnishBody : post.body, maxPostContentPreview) }}
        ></BlogBody>
        <p>
          <PostDate>{t('postedOn')}</PostDate> <DateFormat>{formatDate(post.createdAt)}</DateFormat>
        </p>
      </ColWidth>
    </RowFit>
  )
}

const Img = styled.img`
  max-width: 100%;
`
const PostDate = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.GRAY};
`

const DateFormat = styled(PostDate)`
  color: ${colors.BRAND_GREEN};
`
const RowFit = styled(Row)`
  margin: 0 auto;
`

const ColWidth = styled(Col)<{ width?: string }>`
  max-width: ${props => props.width || 'auto'};
  padding: 15px;
`
const BlogBody = styled.p`
  & a {
    color: ${colors.BRAND_GREEN} !important;
  }
`
