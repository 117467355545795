import { AdminContentService, ContentPageResponseView } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export interface GetALlContentsAdminRequest {
  page?: number
  size?: number
  sort?: string
}

export const getAllContentRequestAsync: AsyncThunk<ContentPageResponseView, GetALlContentsAdminRequest, any> =
  createAsyncThunk('admin/content/getAll', async (request: GetALlContentsAdminRequest, { rejectWithValue }) => {
    try {
      return await AdminContentService.adminContentList(request.page - 1, request.size, request.sort)
    } catch (error) {
      return rejectWithValue(error)
    }
  })
