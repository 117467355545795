import { Dropdown, Menu, Row, Space } from 'antd'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import { UserOutlined, DownOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import { useTranslation } from 'react-i18next'
import { removeToken } from 'utils/storage'
import { setStatus } from 'store/auth/login'
import { AuthStatus } from 'constants/index'
import { useAppDispatch } from 'store/hooks'
import styled from 'styled-components'

export const AdminHeader: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const signOut = useCallback(() => {
    removeToken()
    dispatch(setStatus({ status: AuthStatus.none }))
    history.push('/')
  }, [history, dispatch])

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item color={colors.RED} onClick={signOut}>
          {t('signOut')}
        </Menu.Item>
      </Menu>
    ),
    [signOut, t]
  )
  return (
    <HeaderContainer>
      <Row justify="space-between">
        <Link to="/">
          <img src={logo} alt="logo" width="61" />
        </Link>
        <Space>
          <UserOutlined /> {'admin'}
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <DownOutlined />
          </Dropdown>
        </Space>
      </Row>
    </HeaderContainer>
  )
}
const HeaderContainer = styled.div`
  padding: 10px 17px;
  border-bottom: 1px solid ${colors.HEADER_BORDER};
`
