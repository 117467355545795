import { combineReducers } from '@reduxjs/toolkit'
import getAllBannerReducer from './getAll'
import createBicycleReducer from './create'
import updateBicycleReducer from './edit'
import deleteBannerReducer from './delete'
import currentBannerReducer from './get'

export const bannerReducers = combineReducers({
  getAll: getAllBannerReducer,
  create: createBicycleReducer,
  edit: updateBicycleReducer,
  delete: deleteBannerReducer,
  current: currentBannerReducer,
})
