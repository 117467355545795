import { createSlice } from '@reduxjs/toolkit'
import { ErrorResponse } from 'interfaces/Error'
import { RootState } from 'store'
import { SingleBicycleType } from '../Interfaces'
import { getAdminBicyclesRequestAsync } from './asyncActions'

export interface AdminBicyclesGetAllState {
  is_fetching: boolean
  data: SingleBicycleType
  errors: ErrorResponse
}

const initialState: AdminBicyclesGetAllState = {
  is_fetching: false,
  data: null,
  errors: null,
}

export const getBicyclesSlice = createSlice({
  name: 'adminBicycles',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getAdminBicyclesRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(getAdminBicyclesRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload
      })
      .addCase(getAdminBicyclesRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.is_fetching = false
        state.data = null
      })
  },
})

export const selectAdminSingleBicycle = (state: RootState): AdminBicyclesGetAllState => state.admin.bicycles.get

export default getBicyclesSlice.reducer
