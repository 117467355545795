import { Col, Drawer, Row } from 'antd'
import { FunctionComponent, useState } from 'react'
import logo from 'assets/images/logo.png'
import { ClearGhostBtn, Padding, RowFullWidth } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { MenuBar } from './Menu/Menu'
import { UserBar } from './UserBar/UserBar'
import { Link } from 'react-router-dom'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import styled from '@emotion/styled-base'
import { SideBar } from './Sidebar/Sidebar'

export const Header: FunctionComponent = () => {
  const [visible, setVisible] = useState(false)

  return (
    <HeaderBackground padding="1vh 2vw">
      <Row justify="space-between" align="middle" gutter={[10, 10]}>
        <Col xs={8}>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </Col>
        <Col xs={0} sm={8}>
          <MenuBar />
        </Col>
        <Col xs={0} sm={8}>
          <UserBar />
        </Col>
        <Col xs={4} sm={0}>
          <ClearGhostBtn type="ghost" onClick={() => setVisible(true)}>
            <MenuIcon />
          </ClearGhostBtn>
          <Drawer
            title={
              <RowFullWidth justify="space-between" align="middle">
                <Link to="/">
                  <img height={70} src={logo} alt="logo" />
                </Link>
                <ClearGhostBtn onClick={() => setVisible(false)}>
                  <CloseOutlinedBold />
                </ClearGhostBtn>
              </RowFullWidth>
            }
            closable={false}
            placement="left"
            width="100vw"
            onClose={() => setVisible(false)}
            visible={visible}
          >
            <SideBar onClose={() => setVisible(false)} />
            <UserBar sidebar onClose={() => setVisible(false)} />
          </Drawer>
        </Col>
      </Row>
    </HeaderBackground>
  )
}

const HeaderBackground = styled(Padding)`
  background: white;
`
const MenuIcon = styled(MenuOutlined)`
  font-size: 1.5rem;
  font-weight: bold;
`
const CloseOutlinedBold = styled(CloseOutlined)`
  font-size: 2em;
  font-weight: bold;
`
