import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { getAllBicyclesRequestAsync } from './asyncActions'
import { Bicycles } from '../Interfaces'
import { ErrorResponse } from 'interfaces/Error'

export interface AdminBicyclesGetAllState {
  is_fetching: boolean
  data: Bicycles[]
  currentPageNumber: number
  isLast?: boolean
  pageSize: number
  totalElements?: number
  errors: ErrorResponse
}

const initialState: AdminBicyclesGetAllState = {
  is_fetching: false,
  data: null,
  currentPageNumber: null,
  isLast: false,
  pageSize: null,
  totalElements: null,
  errors: null,
}

export const getAllBicyclesSlice = createSlice({
  name: 'adminAllBicycles',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getAllBicyclesRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(getAllBicyclesRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload?.content
        state.currentPageNumber = payload?.currentPageNumber
        state.isLast = payload?.isLast
        state.pageSize = payload?.pageSize
        state.totalElements = payload?.totalElements
      })
      .addCase(getAllBicyclesRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.data = null
        state.currentPageNumber = null
        state.isLast = false
        state.pageSize = null
        state.totalElements = null
        state.totalElements = null
        state.is_fetching = false
      })
  },
})

export const selectAdminBicycles = (state: RootState): AdminBicyclesGetAllState => state.admin.bicycles.getAll

export default getAllBicyclesSlice.reducer
