import { Button, Col, Divider, message, Modal, Radio, RadioChangeEvent, Row, Space, Switch } from 'antd'
import { colors } from 'constants/colors'
import React, { useEffect, useMemo, useState } from 'react'
import { FunctionComponent, useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ArrowLeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Indicator } from 'Components/UiKit/Indicator'
import { resetError, resetState, SelectChangeStatusBicycle } from 'store/admin/bicycles/status'
import { AdminBicyclesGetAllState, selectAdminSingleBicycle } from 'store/admin/bicycles/get'
import { ShowValue } from '../Shared/Components/Components'
import { FieldError } from 'interfaces/Error'
import { useTranslation } from 'react-i18next'
import { getAdminBicyclesRequestAsync } from 'store/admin/bicycles/get/asyncActions'
import { BicycleChangeStatus } from 'store/admin/bicycles/status/Status.interface'
import { RejectReason, BicycleTransition, BicycleStatus, FeatureType } from 'api/main'
import { changeStatusRequestAsync } from 'store/admin/bicycles/status/asyncActions'
import { UploadImage } from 'Components/Profile/Bicyles/UploadImage/UploadImage'
import { BICYCLE_MEDIA_LABEL } from 'constants/index'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'
import { NotFound } from '../../Shared/NotFound/NotFound'

interface UrlParams {
  id: string
}

export const SingleBicycle: FunctionComponent = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const { id: idString } = useParams<UrlParams>()
  const id = +idString
  const [rejectionReason, setRejectionReason] = useState<RejectReason>(null)
  const [rejectModal, setRejectModal] = useState(false)

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const { errors: updateError, done, is_fetching } = useAppSelector(SelectChangeStatusBicycle)
  const { data: bicycle, errors }: AdminBicyclesGetAllState = useAppSelector(selectAdminSingleBicycle)
  const isPublic = bicycle?.status === BicycleStatus.ACTIVE
  const underReview = bicycle?.status !== BicycleStatus.ACTIVE && bicycle?.status !== BicycleStatus.INACTIVE

  useEffect(() => {
    if (Number.isNaN(id)) {
      history.goBack()
    }
    dispatch(getAdminBicyclesRequestAsync(id))
  }, [dispatch, id, history])

  useEffect(() => {
    if (errors) {
      errors.fieldErrors.forEach(err => message.error(err.message))
      dispatch(resetError())
    }
  }, [errors, dispatch])

  useEffect(() => {
    if (updateError) {
      updateError.fieldErrors.forEach((err: FieldError) => message.error(err.message))
      dispatch(resetError())
    }
  }, [updateError, dispatch])

  const translationMapValue = useCallback(
    (val: any) => {
      return i18n.language === 'en' ? val?.name : val?.finnishName
    },
    [i18n]
  )

  const rejectBicycle = useCallback(() => {
    if (!rejectionReason) {
      message.error(t('selectRejectionReason'))
      return
    }
    const request: BicycleChangeStatus = {
      bicycleId: id,
      requestBody: {
        transition: BicycleTransition.REJECT,
        rejectReason: rejectionReason,
      },
    }
    dispatch(changeStatusRequestAsync(request))
  }, [dispatch, id, rejectionReason, t])

  const approveBicycle = useCallback(() => {
    const request: BicycleChangeStatus = {
      bicycleId: id,
      requestBody: {
        transition: BicycleTransition.APPROVE,
      },
    }
    dispatch(changeStatusRequestAsync(request))
  }, [dispatch, id])

  useEffect(() => {
    if (done) {
      rejectionReason ? message.success('bicycleRejected') : message.success(t('bicycleApproved'))
      history.goBack()
    }
    if (errors) {
      errors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }

    return () => {
      dispatch(resetState())
    }
  }, [dispatch, errors, done, history, rejectionReason, t])

  const markAsPublicPrivate = useCallback(
    (toPublic: boolean) => {
      const request: BicycleChangeStatus = {
        bicycleId: bicycle?.id,
        requestBody: {
          transition: toPublic ? BicycleTransition.ACTIVATE : BicycleTransition.DEACTIVATE,
        },
      }
      dispatch(changeStatusRequestAsync(request))
    },
    [bicycle, dispatch]
  )
  const imageSrc = useMemo(() => {
    return bicycle?.mainMedia?.url
  }, [bicycle])

  return (
    <Background>
      <HelmetWrapper parentData={{ titleKey: 'bicycle' }} />
      {is_fetching && <Indicator />}
      {bicycle ? (
        <Container>
          <Row justify="space-between">
            <Col>
              <BackButton onClick={goBack} icon={<ArrowLeftOutlined />} />
              <H1>
                {t('reviewBicycle')} : {bicycle.id}
              </H1>
            </Col>
            <GreenSwitch
              disabled={underReview}
              checkedChildren={t('public')}
              unCheckedChildren={t('private')}
              defaultChecked={isPublic}
              onChange={markAsPublicPrivate}
            />
          </Row>
          <Divider />
          <Row justify="space-between" gutter={20}>
            <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
              <ShowValue label={t('condition')} value={bicycle?.postType} />
              <ShowValue isTextArea label={t('description')} value={bicycle?.description} />
              <ShowValue isTextArea label={t('deliveryInstruction')} value={bicycle?.deliveryInstructions} />
              <Radio.Group value={bicycle?.featureType}>
                <Radio value={FeatureType.TOP_FIVE}>{t('top5Euro')}</Radio>
                <Radio value={FeatureType.TOP_TEN}>{t('top10Euro')}</Radio>
              </Radio.Group>
              <UploadImage targetType={BICYCLE_MEDIA_LABEL} images={bicycle.medias} viewMode={true} />
              <ShowValue label={t('mainMediaId')} value={bicycle.mainMedia?.url.split('/').pop()} />
              {imageSrc && <MainImage alt="" src={imageSrc} />}
            </InputCol>
            <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
              <ShowValue
                label={t('productType')}
                value={i18n.language === 'en' ? bicycle?.bicycleType.name : bicycle?.bicycleType.finnishName}
              />
              <ShowValue
                label={t('category')}
                value={i18n.language === 'en' ? bicycle?.category?.name : bicycle?.category?.finnishName}
              />
              {bicycle?.bicycleType.id === 4 && (
                <>
                  <ShowValue label={t('postType')} value={bicycle?.batteryLocation} />
                  <ShowValue label={t('motor')} value={bicycle?.motor} />
                  <ShowValue label={t('powers')} value={bicycle?.powers} />
                </>
              )}
              <ShowValue label={t('gender')} value={translationMapValue(bicycle?.gender)} />
              <ShowValue label={t('brand')} value={translationMapValue(bicycle?.brand)} />
              <ShowValue label={t('model')} value={bicycle?.model} />
              <ShowValue label={t('year')} value={bicycle?.year?.toString()} />
              <ShowValue label={t('price')} value={bicycle?.price} />
            </InputCol>
            <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
              <ShowValue label={t('tireSize')} value={translationMapValue(bicycle?.tire)} />
              <ShowValue label={t('frameSize')} value={translationMapValue(bicycle?.frameSize)} />
              <ShowValue label={t('gears')} value={translationMapValue(bicycle?.gear)} />
              <ShowValue label={t('framesMaterial')} value={translationMapValue(bicycle?.frameMaterial)} />
              <ShowValue label={t('brakes')} value={translationMapValue(bicycle?.brake)} />
              <ShowValue label={t('color')} value={translationMapValue(bicycle?.color)} />
              <ShowValue label={t('suspension')} value={translationMapValue(bicycle?.suspension)} />
              <ShowValue label={t('whatsappNumber')} value={bicycle?.whatsappNumber} />
            </InputCol>
          </Row>
          {bicycle.status === BicycleStatus.PENDING && (
            <Row justify="end">
              <Space>
                <RequestButton type="primary" danger onClick={() => setRejectModal(true)}>
                  {t('reject')}
                </RequestButton>
                <RequestButton type="primary" onClick={() => approveBicycle()}>
                  {t('accept')}
                </RequestButton>
              </Space>
            </Row>
          )}
          <Modal
            title={<Row>{t('rejectReason')}</Row>}
            visible={rejectModal}
            onOk={() => rejectBicycle()}
            onCancel={() => {
              setRejectionReason(null)
              setRejectModal(false)
            }}
          >
            <>
              <Row>{t('pleaseChooseReason')}</Row>
              <Row>
                <Radio.Group
                  onChange={(value: RadioChangeEvent) => setRejectionReason(value.target.value)}
                  name="radiogroup"
                >
                  <Row>
                    <Radio value={RejectReason.POOR_PICTURE_QUALITY}>{t('poorQuality')}</Radio>
                  </Row>
                  <Row>
                    <Radio value={RejectReason.NOT_ENOUGH_INFORMATION}>{t('lackOfBike')}</Radio>
                  </Row>
                  <Row>
                    <Radio value={RejectReason.BAD_BIKE_QUALITY}>{t('badBike')}</Radio>
                  </Row>
                  <Row>
                    <Radio value={RejectReason.OTHER}>{t('otherReason')}</Radio>
                  </Row>
                </Radio.Group>
              </Row>
            </>
          </Modal>
        </Container>
      ) : (
        <NotFound />
      )}
    </Background>
  )
}
const MainImage = styled.img`
  border: 3px dashed gray;
`
const Background = styled.div``
const Container = styled.div`
  font-family: 'Roboto', 'Poppins';
  line-height: 28px;
  font-style: normal;
  background-color: ${colors.WHITE};
  padding: 16px;
`
const GreenSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${colors.BRAND_GREEN};
  }
`
const InputCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const RequestButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
`
const H1 = styled.h1`
  font-weight: bold;
  font-size: 20px;
  color: ${colors.BLACK_TR};
  display: inline-block;
`
const BackButton = styled(Button)`
  background: ${colors.WHITE};
  border: none;
`
