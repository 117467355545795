import { DatePicker, Input, Select } from 'antd'
import { colors } from 'constants/colors'
import React, { ReactNode, useMemo } from 'react'
import { FunctionComponent } from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OptionInterface } from '../../Profile/Bicyles/EditBicycle/Options'
import { Moment } from 'moment'
import moment from 'moment'
import { BACKEND_DATE_FORMAT } from '../../../constants'
import TextArea from 'antd/lib/input/TextArea'

const { Option } = Select

interface SelectBicycle {
  label: string
  options?: OptionInterface[]
  control: Control<any>
  prefix?: ReactNode
  name: string
  error?: FieldError
  isMulti?: boolean
  required?: boolean
  value?: number | string
}

interface FormDatePickerInterface {
  label: string
  control?: Control<any>
  name: string
  error?: FieldError
  required?: boolean
  value?: string
}

interface SelectBicycleWrapperInterface {
  label: string
  children: React.ReactElement
  control?: Control<any>
  name: string
  error?: FieldError
  required?: boolean
}

export const SelectBicycleWrapper: FunctionComponent<SelectBicycleWrapperInterface> = ({
  label,
  children,
  control,
  name,
  error,
  required = false,
}) => {
  return (
    <SelectBicycleMargin>
      <Label>
        {label} {required ? ' * ' : null}
      </Label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => React.cloneElement(children, { onBlur, onChange, value })}
      />
      {error && <Error>{error.message}</Error>}
    </SelectBicycleMargin>
  )
}

export const SelectBicycleItems: FunctionComponent<SelectBicycle> = ({
  label,
  options: conditions,
  control,
  name,
  error,
  isMulti = false,
  required = false,
}) => {
  const { i18n } = useTranslation<string>()
  const currentLangues = i18n.language

  return (
    <SelectBicycleWrapper label={label} control={control} name={name} error={error} required={required}>
      <SelectFullWidth mode={isMulti ? 'multiple' : null}>
        {conditions.map(condition => (
          <Option key={condition.id} value={condition.id}>
            {currentLangues === 'en' ? condition.name : condition.name_fi}
          </Option>
        ))}
      </SelectFullWidth>
    </SelectBicycleWrapper>
  )
}

export const SelectBicycleInput: FunctionComponent<SelectBicycle> = ({
  label,
  control,
  name,
  error,
  prefix,
  required = false,
}) => {
  return (
    <SelectBicycleWrapper label={label} control={control} name={name} error={error} required={required}>
      <InputFullWidth name={name} prefix={prefix} />
    </SelectBicycleWrapper>
  )
}

export const FormDatePicker: FunctionComponent<FormDatePickerInterface> = ({
  label,
  control,
  name,
  error,
  required = false,
}) => {
  const tomorrow = useMemo(() => moment().add(1, 'days'), [])

  return (
    <SelectBicycleMargin>
      <Label>
        {label} {required ? ' * ' : null}
      </Label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <DatePicker
            defaultValue={value ? moment(value) : tomorrow}
            onBlur={onBlur}
            onChange={(value: Moment) => onChange(value.format(BACKEND_DATE_FORMAT))}
          />
        )}
      />
      {error && <Error>{error.message}</Error>}
    </SelectBicycleMargin>
  )
}
export const SelectFullWidth = styled(Select)`
  width: 100%;

  & .ant-select-selector {
    height: 50px !important;
    border-radius: 8px !important;
  }
`
export const InputFullWidth = styled(Input)`
  width: 100%;
  height: 50px !important;
  border-radius: 8px !important;
`
export const Error = styled.div`
  color: ${colors.RED};
`
export const Label = styled.div`
  font-weight: 200;
  font-size: 14px;
  color: ${colors.BLACK_TR};
`
export const SelectBicycleMargin = styled.div`
  margin: 15px 0;
`
export const CustomTextArea = styled(TextArea)`
  width: 100%;
  height: 50px !important;
  border-radius: 8px !important;
`
