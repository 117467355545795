import { Col, Input, Row } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Control, Controller, ControllerFieldState, ControllerRenderProps, FieldError } from 'react-hook-form'
import { colors } from 'constants/colors'
import { PHONE_ALLOWED_CHARACTERS } from 'constants/index'

type InputType =
  | 'date'
  | 'email'
  | 'hidden'
  | 'image'
  | 'number'
  | 'password'
  | 'range'
  | 'reset'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'week'

type onChangeType = (...event: any[]) => void
type onBlurType = () => void

interface renderType {
  field: { onChange: onChangeType; onBlur: onBlurType }
}

type RenderFunctionType = ({
  field,
  fieldState,
}: {
  field: ControllerRenderProps
  fieldState: ControllerFieldState
}) => React.ReactElement

export interface InputAreaInterface {
  title: string
  name: string
  isOptional?: boolean
  control?: Control<any>
  error?: FieldError
  render?: RenderFunctionType
  defaultValue?: string | number
  prefix?: React.ReactElement
  suffix?: React.ReactElement
  type?: InputType
  addonBefore?: string
}

export const InputArea: FunctionComponent<InputAreaInterface> = ({
  name,
  control,
  error,
  render,
}: InputAreaInterface) => {
  return (
    <Col span={24}>
      <RowBody>
        <Controller control={control} name={name} render={render} />
        {error && <Error>{error.message}</Error>}
      </RowBody>
    </Col>
  )
}

export const FormInput: FunctionComponent<InputAreaInterface> = ({
  title,
  isOptional = false,
  name,
  control,
  error,
  defaultValue,
  prefix,
  suffix,
  type = 'text',
  addonBefore,
}: InputAreaInterface) => {
  const { t } = useTranslation()
  const [phoneValue, setPhoneValue] = useState(defaultValue)

  const phoneInput = useCallback((value: string, onChange: onChangeType) => {
    const filteredValue = value
      .split('')
      .filter((i: string) => PHONE_ALLOWED_CHARACTERS.indexOf(i) > -1)
      .join('')
    setPhoneValue(filteredValue)
    onChange(filteredValue)
  }, [])

  return (
    <MarginedInputArea
      title={title}
      isOptional={isOptional}
      control={control}
      name={name}
      error={error}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur } }: renderType) =>
        type === 'tel' ? (
          <NetInput
            prefix={prefix}
            type={type}
            placeholder={t(title)}
            onBlur={onBlur}
            onChange={e => phoneInput(e.target.value, onChange)}
            defaultValue={defaultValue}
            value={phoneValue}
            addonBefore={addonBefore}
            suffix={suffix}
          />
        ) : (
          <NetInput
            prefix={prefix}
            type={type}
            placeholder={t(title)}
            onBlur={onBlur}
            onChange={onChange}
            defaultValue={defaultValue}
            addonBefore={addonBefore}
            suffix={suffix}
          />
        )
      }
    />
  )
}

const RowBody = styled(Row)`
  > * {
    width: 100%;
  }
`
const MarginedInputArea = styled(InputArea)`
  margin: 24px 0;
`
const Error = styled.div`
  color: ${colors.RED};
`
const NetInput = styled(Input)`
  height: 50px;
  border-radius: 8px;

  & .ant-input-wrapper {
    height: 100%;
  }

  & .ant-input-wrapper > * {
    height: 100%;
  }

  & input {
    border-radius: 8px;
  }
`
