import { FunctionComponent } from 'react'
import PaymentBack from 'assets/images/PaymentBack.jpg'
import styled from 'styled-components'
import { Button, Card, Row } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import { useTranslation } from 'react-i18next'
import { CheckCircleOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'

interface PaymentComponentInterface {
  isSuccessful: boolean
}
export const PaymentComponent: FunctionComponent<PaymentComponentInterface> = ({ isSuccessful }) => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const gotoProfile = () => history.push('/account')

  return (
    <Background>
      <LayoutCard>
        <Row justify="center">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </Row>
        <Row justify="center">
          <Message>{isSuccessful ? t('paymentSuccessFul') : t('paymentFailed')}</Message>
        </Row>
        <Row justify="center">
          <CheckCircleOutlinedGreen width={'150px'} />
        </Row>
        <Row justify="center">
          <MiddleMessage>{t('yourPaymentWasSuc')}</MiddleMessage>
        </Row>
        <Row justify="center">
          <ButtonColord type="primary" onClick={gotoProfile}>
            <ButtonCaption>{t('gotoProfile')}</ButtonCaption>
          </ButtonColord>
        </Row>
      </LayoutCard>
    </Background>
  )
}

const Background = styled.div`
  background-image: url(${PaymentBack});
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LayoutCard = styled(Card)`
  max-width: 500px;
  max-height: 500px;
`
const Message = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  padding: 40px 50px;
`
const CheckCircleOutlinedGreen = styled(CheckCircleOutlined)`
  color: ${colors.BRAND_GREEN};
  font-size: 50px;
`
const MiddleMessage = styled.div`
  margin: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`
const ButtonColord = styled(Button)`
  color: ${colors.BLUE};
`
const ButtonCaption = styled.div`
  color: ${colors.WHITE};
`
