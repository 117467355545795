import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ObjectShape } from 'yup/lib/object'
import { Col, Input, message } from 'antd'
import { AuthForm, RowWMargin, NetButton, IconWrapper } from '../Shared/TinyComponents/TinyComponents'
import { MailOutlined } from '@ant-design/icons'
import { AuthWrapper } from '../Shared/Wrapper/AuthWrapper'
import { FormInput } from '../Shared/InputArea'
import { Indicator } from 'Components/UiKit/Indicator'
import { useHistory } from 'react-router-dom'
import { setPasswordRequestAsync } from 'store/auth/set-password/asyncActions'
import { ResetPasswordVM } from 'api/main'
import { deleteStatus, selectPassword, SetPasswordStatus } from 'store/auth/set-password'
import { useQuery } from 'utils/useQuery'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

interface QueryProps {
  token: string
}

const setPasswordSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
  Yup.object().shape({
    newPassword: Yup.string().min(8, t('tooShort')).max(50, t('tooLong')).required(t('required')),
    confirmPassword: Yup.string()
      .min(8, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('required'))
      .oneOf([Yup.ref('newPassword'), null], t('passwordMatch')),
  })

export const SetPassword: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const queryObject = useQuery<QueryProps>()
  const token = queryObject?.key
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPasswordVM & { confirmPassword: string }>({
    defaultValues: { resetKey: '', newPassword: '' },
    resolver: yupResolver(setPasswordSchema(t)),
  })
  const { is_fetching, status, errors: backendErrors } = useAppSelector(selectPassword)

  const onSubmit = handleSubmit((data: ResetPasswordVM) => {
    data.resetKey = token
    dispatch(setPasswordRequestAsync(data))
  })

  useEffect(() => {
    if (!token) {
      message.error(t('invalidToken'))
      history.push('/home')
    }
  }, [t, token, history])

  useEffect(() => {
    if (status === SetPasswordStatus.requestSuccess) {
      dispatch(deleteStatus())
      message.success(t('newPasswordSet'))
      history.push('/home')
    }
  }, [dispatch, t, status, history])

  useEffect(() => {
    if (status === SetPasswordStatus.requestFailed && backendErrors) {
      if (backendErrors) {
        backendErrors.fieldErrors.forEach(err => message.error(err.message))
      }
    }
  }, [backendErrors, status])

  return (
    <AuthWrapper>
      <HelmetWrapper parentData={{ titleKey: 'resetPassword' }} />
      {is_fetching && <Indicator />}
      <AuthForm onSubmit={onSubmit}>
        <RowWMargin>
          <h2>{t('resetPassword')}</h2>
        </RowWMargin>
        <RowWMargin>{t('pleaseEnterAndConfirmYourNewPassword')}</RowWMargin>
        <Col>
          <Input.Group>
            <RowWMargin>
              <FormInput
                name="newPassword"
                prefix={
                  <IconWrapper>
                    <MailOutlined />
                  </IconWrapper>
                }
                type="password"
                title={t('newPassword')}
                control={control}
                error={errors.newPassword}
              />
            </RowWMargin>
            <RowWMargin>
              <FormInput
                name="confirmPassword"
                prefix={
                  <IconWrapper>
                    <MailOutlined />
                  </IconWrapper>
                }
                type="password"
                title={t('confirmPassword')}
                control={control}
                error={errors.confirmPassword}
              />
            </RowWMargin>
            <RowWMargin justify={'space-between'} align={'middle'}>
              <NetButton primary htmlType="submit">
                {t('confirm')}
              </NetButton>
            </RowWMargin>
          </Input.Group>
        </Col>
      </AuthForm>
    </AuthWrapper>
  )
}
