import { AdminNewsletterService, SaveNewsletterVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const adminCreateNewsletterRequest: AsyncThunk<any, SaveNewsletterVM, any> = createAsyncThunk(
  'admin/newsletter/create',
  async (requestBody: SaveNewsletterVM, { rejectWithValue }) => {
    try {
      return await AdminNewsletterService.adminNewsletterCreate(requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
