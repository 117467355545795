import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ObjectShape } from 'yup/lib/object'
import { Col, Divider, Input, message, Row, Space } from 'antd'
import { FormInput } from 'Components/Auth/Shared/InputArea'
import {
  AuthForm,
  RowWMargin,
  NetButton,
  TextButton,
  IconWrapper,
  SignUpText,
  TextAnchor,
} from '../Shared/TinyComponents/TinyComponents'
import { MailOutlined, KeyOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons'
import { AuthWrapper } from '../Shared/Wrapper/AuthWrapper'
import { registerRequestAsync } from 'store/auth/register/asyncActions'
import { useHistory } from 'react-router'
import { Indicator } from 'Components/UiKit/Indicator'
import { deleteStatus } from 'store/auth/register'
import styled from 'styled-components'
import { RegisterUserVM } from 'api/main'
import { setPendingEmail } from 'utils/storage'
import { GOOGLE_LOGIN_ADDRESS, RECAPTCHA_KEY } from 'constants/index'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'
import ReCAPTCHA from 'react-google-recaptcha'

const registerSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
  Yup.object().shape({
    lastName: Yup.string().min(2, t('tooShort')).max(50, t('tooLong')).required(t('required')),
    password: Yup.string().min(8, t('tooShort')).max(50, t('tooLong')).required(t('required')),
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
    mobile: Yup.string()
      .min(7, t('invalidPhone'))
      .required(t('required'))
      .matches(/^[1-9][0-9]*$/, t('invalidPhone')),
  })

export const Register: FunctionComponent = () => {
  const { t, i18n } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const reRef = useRef<ReCAPTCHA>()
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<RegisterUserVM>({
    defaultValues: { password: '', email: '' },
    resolver: yupResolver(registerSchema(t)),
  })

  const onSubmit = handleSubmit(async (data: RegisterUserVM) => {
    data.langKey = i18n.language
    if (data.mobile) data.mobile = '+358' + data.mobile
    data.captchaResponse = await reRef.current.executeAsync()
    reRef.current.reset()
    dispatch(registerRequestAsync(data))
  })

  const { is_fetching, isDone, errors: backendErrors } = useAppSelector(state => state.auth.register)

  useEffect(() => {
    if (isDone) {
      dispatch(deleteStatus())
      message.success(t('userCreated'))
      setPendingEmail(getValues('email'))
      history.push('/verification')
    }
  }, [dispatch, t, isDone, history, getValues])

  useEffect(() => {
    if (!isDone && backendErrors) {
      if (backendErrors) {
        backendErrors.fieldErrors?.forEach(err => message.error(err.message))
      }
    }
  }, [backendErrors, isDone])

  const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
  const toggleVisiblePassword = () => setVisiblePassword(!visiblePassword)

  return (
    <AuthWrapper>
      <ReCAPTCHA sitekey={RECAPTCHA_KEY} size="invisible" ref={reRef} />
      <HelmetWrapper parentData={{ titleKey: 'register' }} />
      {is_fetching && <Indicator />}
      <AuthForm onSubmit={onSubmit}>
        <Col>
          <Input.Group>
            <RowWMargin>
              <FormInput
                name="firstName"
                prefix={
                  <IconWrapper>
                    <UserOutlined />
                  </IconWrapper>
                }
                title={'firstName'}
                control={control}
                error={errors.firstName}
              />
            </RowWMargin>
            <RowWMargin>
              <FormInput
                name="lastName"
                prefix={
                  <IconWrapper>
                    <UserOutlined />
                  </IconWrapper>
                }
                title={t('lastName')}
                control={control}
                error={errors.lastName}
              />
            </RowWMargin>
            <RowWMargin>
              <FormInput
                name="email"
                prefix={
                  <IconWrapper>
                    <MailOutlined />
                  </IconWrapper>
                }
                title={t('email')}
                control={control}
                error={errors.email}
              />
            </RowWMargin>
            <RowWMargin>
              <FormInput
                name="mobile"
                type="tel"
                prefix={
                  <IconWrapper>
                    <PhoneOutlined />
                    {t('phonePrefix')}
                  </IconWrapper>
                }
                title={t('phone')}
                control={control}
                error={errors.mobile}
              />
            </RowWMargin>
            <RowWMargin>
              <FormInput
                name="password"
                prefix={
                  <IconWrapper>
                    <KeyOutlined />
                  </IconWrapper>
                }
                title={t('password')}
                control={control}
                error={errors.password}
                type={visiblePassword === true ? 'text' : 'password'}
                suffix={
                  visiblePassword === true ? (
                    <EyeTwoTone onClick={toggleVisiblePassword} />
                  ) : (
                    <EyeInvisibleOutlined onClick={toggleVisiblePassword} />
                  )
                }
              />
            </RowWMargin>
            <RowWMargin justify={'space-between'} align={'middle'}>
              <NetButton primary htmlType="submit">
                {t('signUp')}
              </NetButton>
            </RowWMargin>
            <Divider plain={false}>{t('or')}</Divider>
            <RowWMargin justify={'space-between'} align={'middle'}>
              <Row>{t('signUpWith')}</Row>
              <Space>
                <TextAnchor href={GOOGLE_LOGIN_ADDRESS} target="_blank">
                  {t('google')}
                </TextAnchor>
                <TextButton to={'/forgot-password'}>{t('facebook')}</TextButton>
                <TextButton to={'/forgot-password'}>{t('twitter')}</TextButton>
              </Space>
            </RowWMargin>
            <RowWMargin justify={'start'}>
              <SignUpText>{t('alreadyAUser')}</SignUpText>
              <LineHeight>
                <TextButton to={'/login'}>{t('login')}</TextButton>
              </LineHeight>
            </RowWMargin>
          </Input.Group>
        </Col>
      </AuthForm>
    </AuthWrapper>
  )
}
const LineHeight = styled.div`
  line-height: 24px;
`
