import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AppBicycleService } from 'api/main'

export const getSingleBicycleRequestAsync: AsyncThunk<any, number, any> = createAsyncThunk(
  'bicycles/get/single',
  async (bicycleId: number, { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleShow(bicycleId)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
