import { Button, Col, Row, Tabs } from 'antd'
import { colors } from 'constants/colors'
import React, { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { BicycleList } from './Bicyles/BicycleList/BicycleList'
import { Profile } from './Profile/Profile'
import { HelmetWrapper } from '../UiKit/HelmetWrapper/HelmetWrapper'

const { TabPane } = Tabs

export const Account: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const { tab = 'profile' } = useParams<{ tab: string }>()

  const gotoNewBicycle = useCallback(() => {
    history.push('/account/new-bicycle')
  }, [history])

  const changeTab = (key: string) => {
    history.push('/account/' + key)
  }

  return (
    <Background>
      <HelmetWrapper parentData={{ titleKey: 'account' }} />
      <Container>
        <Switch>
          <StyledTabs
            defaultActiveKey={tab}
            tabBarExtraContent={
              <Row>
                <Col xs={{ push: 0, pull: 4 }}>
                  <ProfileBtn onClick={gotoNewBicycle}>{t('newBicycle')}</ProfileBtn>
                </Col>
              </Row>
            }
            onChange={changeTab}
          >
            <TabPane tab={<TabTitle>{t('profile')}</TabTitle>} key="profile">
              <Profile />
            </TabPane>
            <TabPane tab={<TabTitle>{t('myPosts')}</TabTitle>} key="myposts">
              <BicycleList />
            </TabPane>
          </StyledTabs>
        </Switch>
      </Container>
    </Background>
  )
}
const Background = styled.div`
  background: ${colors.SELL_BACKGROUND};
  min-height: 80vh;
  display: flex;
  flex-direction: row;
`

const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav {
    flex-wrap: wrap-reverse;
  }

  & .ant-tabs-extra-content {
    padding-left: 25px;
  }
`
const Container = styled.div`
  width: 100%;
  background: ${colors.WHITE};
  min-height: 100%;
`
const TabTitle = styled.div`
  padding: 10px 30px;

  &.ant-tabs-tab-active {
    color: red;
  }
`
const ProfileBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${colors.PRIMARY};
  border-radius: 2px;
  color: ${colors.WHITE};

  padding: 8px 16px;
  width: 150px;
  height: 40px;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 4%);
`
