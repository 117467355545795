import { FunctionComponent, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { loginRequestAsync } from 'store/auth/login/asyncActions'
import { ObjectShape } from 'yup/lib/object'
import { Col, Divider, Input, message, Row, Space } from 'antd'
import {
  AuthForm,
  IconWrapper,
  NetButton,
  RowWMargin,
  SignUpText,
  TextAnchor,
  TextButton,
} from '../Shared/TinyComponents/TinyComponents'
import { KeyOutlined, MailOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import { LoginVM, UpdateLanguageVM } from 'api/main'
import { AuthWrapper } from '../Shared/Wrapper/AuthWrapper'
import { FormInput } from '../Shared/InputArea'
import { AuthStatus, FACEBOOK_LOGIN_ADDRESS, GOOGLE_LOGIN_ADDRESS, RECAPTCHA_KEY } from 'constants/index'
import { Indicator } from 'Components/UiKit/Indicator'
import { deleteStatus } from 'store/auth/login'
import styled from 'styled-components'
import { languageChangeRequestAsync } from 'store/auth/language/asyncActions'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'
import ReCAPTCHA from 'react-google-recaptcha'

const loginSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
  Yup.object().shape({
    password: Yup.string().min(8, t('tooShort')).max(50, t('tooLong')).required(t('required')),
    username: Yup.string().email(t('invalidEmail')).required(t('required')),
  })

export const Login: FunctionComponent = () => {
  const { t, i18n } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const reRef = useRef<ReCAPTCHA>()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginVM>({ defaultValues: { username: '', password: '' }, resolver: yupResolver(loginSchema(t)) })
  const { data, is_fetching, status, errors: authErrors } = useAppSelector(state => state.auth.auth)

  useEffect(() => {
    return () => {
      dispatch(deleteStatus())
    }
  }, [dispatch])

  useEffect(() => {
    if (status === AuthStatus.rejected) {
      if (authErrors) {
        authErrors.fieldErrors.forEach(err => message.error(err.message))
      } else {
        message.error(t('invalidCredentials'))
      }
    }
  }, [data, is_fetching, status, t, authErrors])

  const onSubmit = handleSubmit(async (data: LoginVM) => {
    data.captchaResponse = await reRef.current.executeAsync()
    reRef.current.reset()
    await dispatch(loginRequestAsync(data))
    dispatch(
      languageChangeRequestAsync({
        langKey: i18n.language === 'en' ? UpdateLanguageVM.langKey.EN : UpdateLanguageVM.langKey.FI,
      })
    )
  })

  useEffect(() => {
    setTimeout(() => {
      const script = document.createElement('script')
      script.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());  gtag('config', 'UA-227398303-1');
      `
      document.getElementById('script-wrapper').appendChild(script)
    }, 1000)
  }, [])

  return (
    <AuthWrapper>
      <ReCAPTCHA sitekey={RECAPTCHA_KEY} size="invisible" ref={reRef} />
      <HelmetWrapper parentData={{ titleKey: 'login' }} />
      {is_fetching && <Indicator />}
      <AuthForm onSubmit={onSubmit}>
        <Col>
          <Input.Group>
            <RowWMargin>
              <FormInput
                name="username"
                prefix={
                  <IconWrapper>
                    <MailOutlined />
                  </IconWrapper>
                }
                title={t('email')}
                control={control}
                error={errors.username}
              />
            </RowWMargin>
            <RowWMargin>
              <FormInput
                name="password"
                prefix={
                  <IconWrapper>
                    <KeyOutlined />
                  </IconWrapper>
                }
                title={t('password')}
                control={control}
                error={errors.password}
                type="password"
              />
            </RowWMargin>
            <RowWMargin justify={'space-between'} align={'middle'} gutter={[0, 40]}>
              <NetButton primary htmlType="submit">
                {t('login')}
              </NetButton>
              <TextButton color={colors.GRAY} to={'/forgot-password'}>
                {t('forgotPassword')}
              </TextButton>
            </RowWMargin>
            <Divider plain={false}>{t('or')}</Divider>
            <RowWMargin justify={'space-between'} align={'middle'}>
              <Row>{t('loginWith')}</Row>
              <Space>
                <TextAnchor href={GOOGLE_LOGIN_ADDRESS} target="_blank">
                  {t('google')}
                </TextAnchor>
                <TextAnchor href={FACEBOOK_LOGIN_ADDRESS} target="_blank">
                  {t('facebook')}
                </TextAnchor>
              </Space>
            </RowWMargin>
            <RowWMargin justify={'start'}>
              <SignUpText>{t('newHere')}</SignUpText>
              <LineHeight>
                <TextButton to={'/register'}>{t('signUp')}</TextButton>
              </LineHeight>
            </RowWMargin>
          </Input.Group>
        </Col>
      </AuthForm>
      <div id="script-wrapper" />
    </AuthWrapper>
  )
}
const LineHeight = styled.div`
  line-height: 24px;
`
