import { Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { colors } from 'constants/colors'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

export const AdminPageTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.BLACK_TR};
`
interface ShowValueInterface {
  label: string
  value: string
  isTextArea?: boolean
  styled?: boolean
}

export const ShowValue: FunctionComponent<ShowValueInterface> = ({
  label,
  value,
  isTextArea = false,
  styled = false,
}) => {
  return (
    <SelectBicycleMargin noMargin={styled}>
      <Label>{label}</Label>
      {isTextArea ? (
        <TextArea disabled value={value} />
      ) : styled ? (
        <InputStyled disabled value={value} />
      ) : (
        <InputFullWidth disabled value={value} />
      )}
    </SelectBicycleMargin>
  )
}
const SelectBicycleMargin = styled.div<{ noMargin: boolean }>`
  margin: ${props => (props.noMargin ? '0' : '15px 0')};
`
const Label = styled.div`
  font-weight: 200;
  font-size: 14px;
  color: ${colors.BLACK_TR};
`
const InputFullWidth = styled(Input)`
  width: 100%;
`
const InputStyled = styled(InputFullWidth)`
  height: 50px;
  border-radius: 8px;
`
