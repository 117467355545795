import { Col, Row } from 'antd/lib/grid'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import filterIcon from '../../../assets/images/filter.png'
import sortIcon from '../../../assets/images/sort.png'
import { useDispatch } from 'react-redux'
import { getBicyclesRequestAsync } from 'store/bicycle/get/asyncActions'
import { BICYCLES_RESULT_PER_PAGE, POST_TYPES_LIST } from 'constants/index'
import { useHistory, useParams } from 'react-router'
import { UrlParamsWithId } from 'interfaces/General'
import { useTranslation } from 'react-i18next'
import { Checkbox, Collapse, Radio } from 'antd'
import styled from 'styled-components'
import { useAppSelector } from 'store/hooks'
import { BicycleRequest, selectBicycles } from 'store/bicycle/get'
import { Indicator } from 'Components/UiKit/Indicator'
import { BicycleItem } from './BicycleItem/BicycleItem'
import { ClearGhostBtn, NetButton } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { useQuery, useSetQuery } from 'utils/useQuery'
import { colors } from 'constants/colors'
import {
  allCategories,
  bikeTypes,
  brakes,
  colorsBikes,
  frameSizes,
  framesMaterial,
  gears,
  genders,
  OptionInterface,
  tireSizes,
} from 'Components/Profile/Bicyles/NewBicycle/Options'
import { BicycleDetailsView, PostType } from 'api/main'
import { selectBanners } from 'store/banners/getAll'
import { batteryLocation, motors, powers } from '../../Profile/Bicyles/EditBicycle/Options'

const { Panel } = Collapse

interface BicyclePageProps {
  searchQuery?: string
}

const prepareValues = (params: any, key: string): any => {
  if (params[key]) {
    let value = params[key]
    if (!Number.isNaN(+value)) value = +value
    if (Array.isArray(params[key])) return params[key]
    return [params[key]]
  } else {
    return []
  }
}

export const InnerPageBrand: FunctionComponent<BicyclePageProps> = ({ searchQuery }) => {
  const { id } = useParams<UrlParamsWithId>()

  const [page, setPage] = useState(1)
  const params = useQuery()
  const [brand, setBrand] = useState<number[]>(prepareValues(params, 'brand'))
  const [bicycleType, setBicycleType] = useState(prepareValues(params, 'bicycleType'))
  const [tireSize, setTireSize] = useState(prepareValues(params, 'tireSize'))
  const [category, setCategory] = useState(prepareValues(params, 'category'))
  const [frameSize, setFrameSize] = useState(prepareValues(params, 'frameSize'))
  const [gearType, setGearType] = useState(prepareValues(params, 'gearType'))
  const [frameType, setFrameType] = useState(prepareValues(params, 'frameType'))
  const [brake, setBrake] = useState(prepareValues(params, 'brake'))
  const [color, setColor] = useState(prepareValues(params, 'color'))
  const [motor, setMotor] = useState(prepareValues(params, 'motor'))
  const [battery, setBattery] = useState(prepareValues(params, 'battery'))
  const [power, setPower] = useState(prepareValues(params, 'power'))
  const [gender, setGender] = useState(prepareValues(params, 'gender'))
  const [postType, setPostType] = useState<PostType>(null)
  const setParam = useSetQuery()
  const [queryFilter, setQueryFilter] = useState('')
  const [sort, setSort] = useState('id')
  const [isAscending, setIsAscending] = useState(false)
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const { data: bicycles, is_fetching, isLast } = useAppSelector(selectBicycles)
  const { home: homeBanner } = useAppSelector(selectBanners)

  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      setBrand([+id])
    }
  }, [dispatch, id])

  useEffect(() => {
    const filterQuery = setParam({
      gender,
      brand,
      bicycleType,
      category,
      tireSize,
      frameSize,
      gearType,
      frameType,
      brake,
      color,
      motor,
      battery,
      power,
    })
    if (filterQuery !== queryFilter) {
      history.push('?' + filterQuery)
      setQueryFilter(filterQuery)
    }
  }, [
    setParam,
    brand,
    bicycleType,
    tireSize,
    frameSize,
    gearType,
    frameType,
    category,
    brake,
    color,
    history,
    gender,
    queryFilter,
    power,
    motor,
    battery,
  ])

  useEffect(() => {
    const query: BicycleRequest = {
      bicycleTypeIds: bicycleType,
      brakeIds: brake,
      brandIds: brand,
      colorIds: color,
      batteryLocationIds: battery,
      powerIds: power,
      motorIds: motor,
      categoryIds: category,
      frameMaterialIds: frameType,
      frameSizeIds: frameSize,
      gearIds: gearType,
      genderIds: gender,
      keyword: searchQuery,
      page: page,
      pageSize: BICYCLES_RESULT_PER_PAGE,
      postTypes: postType ? [postType] : [],
      sort: sort + ',' + (isAscending ? 'asc' : 'desc'),
      tireIds: tireSize,
    }
    dispatch(getBicyclesRequestAsync(query))
  }, [
    dispatch,
    page,
    sort,
    brand,
    bicycleType,
    category,
    tireSize,
    frameSize,
    gearType,
    frameType,
    brake,
    color,
    gender,
    postType,
    searchQuery,
    isAscending,
    id,
    power,
    motor,
    battery,
  ])

  const addTranslationToValue = (options: OptionInterface[]) =>
    options.map(opt => {
      return { label: i18n.language === 'en' ? opt.name : opt.name_fi, value: opt.id }
    })

  const goToBrands = useCallback(() => {
    history.push('/brands')
  }, [history])

  const bicyclesAndBanners = useMemo(() => {
    const result = bicycles?.map((bicycle: BicycleDetailsView) => <BicycleItem key={bicycle.id} bicycle={bicycle} />)
    if (homeBanner && homeBanner.length > 0) {
      const HomeBannerComponent = (
        <HomeBanner>
          <a href={homeBanner[0].url}>
            <Img alt={homeBanner[0].title} src={homeBanner[0].photo.url} />
          </a>
        </HomeBanner>
      )
      if (result.length > 6) {
        result.splice(6, 0, HomeBannerComponent)
      } else {
        result.push(HomeBannerComponent)
      }
    }
    return result
  }, [bicycles, homeBanner])

  return (
    <>
      <Row justify="center">
        {is_fetching && <Indicator />}
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Row justify="center">
            <PaddingHorizontal>
              {id && (
                <ClearGhostBtn onClick={goToBrands} icon={<ArrowLeftOutlined />}>
                  {t('backToBrands')}
                </ClearGhostBtn>
              )}
            </PaddingHorizontal>
            <CollapseFullWidth
              className={'zero-margin'}
              defaultActiveKey={window.innerWidth < 768 ? [] : ['1']}
              ghost
              expandIconPosition="right"
            >
              <Panel
                header={
                  <SortTitle>
                    <IconImage src={sortIcon} alt="sort" />
                    {t('sortBy')}
                  </SortTitle>
                }
                key="1"
              >
                <Radio.Group value={sort} onChange={e => setSort(e.target.value)}>
                  <Row>
                    <Radio value={'price'}>{t('price')}</Radio>
                  </Row>
                  <Row>
                    <Radio value={'id'}>{t('date')}</Radio>
                  </Row>
                </Radio.Group>
                <Row>
                  <Checkbox onChange={() => setIsAscending(!isAscending)}>{t('ascending')}</Checkbox>
                </Row>
              </Panel>
            </CollapseFullWidth>
            <CollapseFullWidth defaultActiveKey={window.innerWidth < 768 ? [] : ['1']} ghost expandIconPosition="right">
              <Panel
                header={
                  <SortTitle>
                    <IconImage src={filterIcon} alt="sort" />
                    {t('filters')}
                  </SortTitle>
                }
                key="1"
              >
                <CollapseFullWidth
                  defaultActiveKey={['2', '3', '4', '5', '6', '7', '8', '9', '10', '11']}
                  ghost
                  expandIconPosition="right"
                >
                  <PanelNoPadding header={t('Gender')} key="2">
                    <Items
                      options={addTranslationToValue(genders)}
                      value={gender}
                      onChange={checked => setGender(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('productType')} key="3">
                    <Items
                      options={addTranslationToValue(bikeTypes)}
                      value={bicycleType}
                      onChange={checked => setBicycleType(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('category')} key="4">
                    <Items
                      options={addTranslationToValue(allCategories)}
                      value={category}
                      onChange={checked => setCategory(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('tireSize')} key="5">
                    <Items
                      options={addTranslationToValue(tireSizes)}
                      value={tireSize}
                      onChange={checked => setTireSize(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('frameSize')} key="6">
                    <Items
                      options={addTranslationToValue(frameSizes)}
                      value={frameSize}
                      onChange={checked => setFrameSize(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('gears')} key="7">
                    <Items
                      options={addTranslationToValue(gears)}
                      value={gearType}
                      onChange={checked => setGearType(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('frames')} key="8">
                    <Items
                      options={addTranslationToValue(framesMaterial)}
                      value={frameType}
                      onChange={checked => setFrameType(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('brakes')} key="9">
                    <Items
                      options={addTranslationToValue(brakes)}
                      value={brake}
                      onChange={checked => setBrake(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('color')} key="10">
                    <Items
                      options={addTranslationToValue(colorsBikes)}
                      value={color}
                      onChange={checked => setColor(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('motor')} key="11">
                    <Items
                      options={addTranslationToValue(motors)}
                      value={motor}
                      onChange={checked => setMotor(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('batteryLocation')} key="12">
                    <Items
                      options={addTranslationToValue(batteryLocation)}
                      value={battery}
                      onChange={checked => setBattery(checked)}
                    />
                  </PanelNoPadding>
                  <PanelNoPadding header={t('powers')} key="13">
                    <Items
                      options={addTranslationToValue(powers)}
                      value={power}
                      onChange={checked => setPower(checked)}
                    />
                  </PanelNoPadding>
                </CollapseFullWidth>
              </Panel>
            </CollapseFullWidth>
          </Row>
        </Col>
        <Col xs={{ span: 20 }} md={{ span: 18, push: 1 }}>
          <PostTypeComponent value={postType} onChange={value => setPostType(value.target.value)}>
            <PostTypeButton value={null} checked={!postType}>
              {t('all')}
            </PostTypeButton>
            {POST_TYPES_LIST.map(type => (
              <PostTypeButton key={type} value={type}>
                {t(type)}
              </PostTypeButton>
            ))}
          </PostTypeComponent>
          {bicycles.length > 0 ? (
            <>
              <Row wrap justify="space-between" gutter={[0, 10]}>
                {bicyclesAndBanners}
              </Row>
              <Row justify="center">
                {!isLast && (
                  <NetButton primary htmlType="submit" onClick={() => setPage(page + 1)}>
                    {t('loadMore')}
                  </NetButton>
                )}
              </Row>
            </>
          ) : (
            <BicyclesCol>
              <Row>{t('noBic')}</Row>
              <Row>
                {!isLast && (
                  <NetButton primary htmlType="submit" onClick={() => setPage(page + 1)}>
                    {t('loadMore')}
                  </NetButton>
                )}
              </Row>
            </BicyclesCol>
          )}
        </Col>
      </Row>
      <Row>
        <Col offset={6}></Col>
      </Row>
    </>
  )
}
const PostTypeComponent = styled(Radio.Group)`
  display: flex;
  justify-content: start;
  gap: 24px;
  overflow: hidden;
  flex-wrap: wrap;

  & .ant-radio-button-wrapper {
    background: ${colors.POST_TYPE_BUTTON};
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${colors.GRAY};
    border: none;
    padding: 24px 30px;
  }

  & .ant-radio-button-wrapper-checked {
    color: ${colors.WHITE};
    background: ${colors.BRAND_GREEN};
  }
`
const PaddingHorizontal = styled.div`
  padding: 20px 0;
`
const CollapseFullWidth = styled(Collapse)<any>`
  width: 100%;
  margin: 16px 0;
  background: ${colors.TEXT_WHITE};

  & .ant-collapse-header {
    min-height: 64px;
    display: flex;
    align-items: center;
  }
`
const PanelNoPadding = styled(Panel)`
  & div.ant-collapse-header {
    padding: 0 !important;
  }
`
const Items = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
`
const BicyclesCol = styled(Col)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SortTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${colors.BLACK};
`
const PostTypeButton = styled(Radio.Button)``
const HomeBanner = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const Img = styled.img`
  max-width: 100%;
`

const IconImage = styled.img`
  width: 28px;
  height: 28px;
`
