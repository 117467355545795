import { Login } from 'Components/Auth/Login/Login'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { FunctionComponent } from 'react'
import { Register } from 'Components/Auth/Register/Register'
import { ResetPassword } from 'Components/Auth/ResetPassword/ResetPassword'
import { SetPassword } from 'Components/Auth/SetPassword/SetPassword'
import { Verification } from 'Components/Auth/Register/Activation/Verification'
import { OathDone } from 'Components/Auth/Oath/OathDone'

const authPageRoutes: RouteProps[] = [
  {
    exact: true,
    path: '/login',
    children: <Login />,
  },
  {
    exact: true,
    path: '/register',
    children: <Register />,
  },
  {
    exact: true,
    path: '/forgot-password',
    children: <ResetPassword />,
  },
  {
    exact: true,
    path: '/reset/finish',
    children: <SetPassword />,
  },
  {
    exact: true,
    path: '/verification',
    children: <Verification />,
  },
  {
    exact: true,
    path: '/oauth2/done/',
    children: <OathDone />,
  },
]

export const paths = [authPageRoutes.map(route => route.path)].flat().filter(path => path) as string[]

const AuthRoutes: FunctionComponent = () => {
  return (
    <Switch>
      {authPageRoutes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Switch>
  )
}
export default AuthRoutes
