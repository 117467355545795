import { createSlice } from '@reduxjs/toolkit'
import { getBlogPostsRequestAsync } from './asyncActions'

import { RootState } from 'store'
import { BlogListView } from 'api/main'

export interface PostState {
  is_fetching: boolean
  data: BlogListView[]
}

export interface BlogListRequest {
  page?: number
  size?: number
  keyword?: string
}

const initialState: PostState = {
  is_fetching: false,
  data: [],
}

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getBlogPostsRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(getBlogPostsRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload?.content
      })
      .addCase(getBlogPostsRequestAsync.rejected, state => {
        state.is_fetching = false
      })
  },
})

export const selectPosts = (state: RootState): any => state.blog.get

export default blogSlice.reducer
