import { createSlice } from '@reduxjs/toolkit'
import { ErrorResponse } from 'interfaces/Error'
import { RootState } from 'store'
import { Users } from '../Interfaces'
import { getAllUsersRequestAsync } from './asyncActions'

export interface AdminUsersGetAllState {
  is_fetching: boolean
  data: Users[]
  currentPageNumber: number
  isLast?: boolean
  pageSize: number
  totalElements?: number
  errors: ErrorResponse
}

const initialState: AdminUsersGetAllState = {
  is_fetching: false,
  data: null,
  currentPageNumber: null,
  isLast: false,
  pageSize: null,
  totalElements: null,
  errors: null,
}

export const getAllUsersSlice = createSlice({
  name: 'adminAllUsers',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getAllUsersRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(getAllUsersRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload?.content
        state.currentPageNumber = payload?.currentPageNumber
        state.isLast = payload?.isLast
        state.pageSize = payload?.pageSize
        state.totalElements = payload?.totalElements
      })
      .addCase(getAllUsersRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.data = null
        state.currentPageNumber = null
        state.isLast = false
        state.pageSize = null
        state.totalElements = null
        state.totalElements = null
        state.is_fetching = false
      })
  },
})

export const selectAdminUsers = (state: RootState): AdminUsersGetAllState => state.admin.users.getAll

export default getAllUsersSlice.reducer
