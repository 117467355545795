/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentDetailsView } from '../models/ContentDetailsView';
import type { ContentPageResponseView } from '../models/ContentPageResponseView';
import type { SaveContentVM } from '../models/SaveContentVM';
import { request as __request } from '../core/request';

export class AdminContentService {

    /**
     * Get list of contents
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sort by a field
     * @returns ContentPageResponseView OK
     * @throws ApiError
     */
    public static async adminContentList(
        page?: number,
        size?: number,
        sort?: string,
    ): Promise<ContentPageResponseView> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/contents`,
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
        return result.body;
    }

    /**
     * Show content
     * @param contentPosition contentPosition
     * @returns ContentDetailsView OK
     * @throws ApiError
     */
    public static async adminContentShow(
        contentPosition: 'HOME_HEADING' | 'BLOG_HEADING' | 'BRANDS_HEADING' | 'SELL_HEADING',
    ): Promise<ContentDetailsView> {
        const result = await __request({
            method: 'GET',
            path: `/api/administration/contents/${contentPosition}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Update content
     * @param contentPosition contentPosition
     * @param requestBody
     * @returns ContentDetailsView OK
     * @throws ApiError
     */
    public static async adminContentUpdate(
        contentPosition: 'HOME_HEADING' | 'BLOG_HEADING' | 'BRANDS_HEADING' | 'SELL_HEADING',
        requestBody?: SaveContentVM,
    ): Promise<ContentDetailsView> {
        const result = await __request({
            method: 'PUT',
            path: `/api/administration/contents/${contentPosition}`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}