import { Button, Input, message, Pagination, Popconfirm, Row, Space, Switch, Table } from 'antd'
import { colors } from 'constants/colors'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AdminPageTitle } from 'Components/Admin/Shared/Components/Components'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Indicator } from 'Components/UiKit/Indicator'
import { FieldError } from 'interfaces/Error'
import { ellipsis, formatDate } from 'constants/functions'
import { useHistory } from 'react-router'
import { getAllPostsRequestAsync } from 'store/admin/blog/getAll/asyncActions'
import { GetALlPostsAdminRequest } from 'store/admin/blog/Interfaces'
import { BLOG_ADMIN_LIST_SIZE } from 'constants/index'
import { selectAdminPosts } from 'store/admin/blog/getAll'
import { BlogListView } from 'api/main'
import { EditPostInterface, resetError, selectAdminEditPost } from 'store/admin/blog/edit'
import { adminEditPostRequest } from 'store/admin/blog/edit/asyncActions'
import { resetError as resetActivateErrors, resetState as resetEditState } from 'store/admin/blog/edit'
import {
  resetError as resetDeleteError,
  selectAdminDeletePost,
  resetState as resetDeleteState,
} from 'store/admin/blog/delete'
import { adminDeletePostRequest } from 'store/admin/blog/delete/asyncActions'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

export const BlogList: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const [pageNumber, setPageNumber] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const history = useHistory()
  const { is_fetching, data, totalElements, errors } = useAppSelector(selectAdminPosts)
  const { is_fetching: EditFetching, done, errors: ActivateError } = useAppSelector(selectAdminEditPost)
  const { is_fetching: DeleteFetching, done: deleteDone, errors: deleteError } = useAppSelector(selectAdminDeletePost)

  const changePage = (page: number) => setPageNumber(page)

  useEffect(() => {
    if (pageNumber === -1) return
    const request: GetALlPostsAdminRequest = {
      keyword: searchQuery,
      page: pageNumber,
      size: BLOG_ADMIN_LIST_SIZE,
    }
    dispatch(getAllPostsRequestAsync(request))
  }, [searchQuery, pageNumber, dispatch])

  useEffect(() => {
    if (done) {
      message.success(t('postIsUpdated'))
    }
    if (deleteDone) {
      message.success(t('postIsDeleted'))
      setPageNumber(-1)
      setPageNumber(0)
    }
    if (errors) {
      errors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
      dispatch(resetActivateErrors())
    }
    if (ActivateError) {
      ActivateError.fieldErrors.forEach((err: FieldError) => message.error(err.message))
      dispatch(resetError())
    }
    if (deleteError) {
      deleteError.fieldErrors.forEach((err: FieldError) => message.error(err.message))
      dispatch(resetDeleteError())
    }
    return () => {
      dispatch(resetEditState())
      dispatch(resetDeleteState())
    }
  }, [errors, dispatch, ActivateError, done, t, deleteDone, deleteError])

  const toggleActive = useCallback(
    (checked: boolean, record: BlogListView) => {
      const request: EditPostInterface = {
        ...record,
        enabled: checked,
        photoId: record.photo.id,
      }
      dispatch(adminEditPostRequest(request))
    },
    [dispatch]
  )

  const gotoEdit = useCallback(
    (id: number) => {
      history.push('/admin/post-edit/' + id)
    },
    [history]
  )

  const deletePost = useCallback(
    (id: number) => {
      dispatch(adminDeletePostRequest(id))
    },
    [dispatch]
  )

  const columns = useMemo(
    () => [
      {
        title: t('title'),
        dataIndex: 'title',
        width: 300,
      },
      {
        title: t('description'),
        dataIndex: 'body',
        render: (content: string) => <div dangerouslySetInnerHTML={{ __html: ellipsis(content, 300) }}></div>,
        width: 500,
      },
      {
        title: t('date'),
        dataIndex: 'createdAt',
        render: (date: string) => formatDate(date),
      },
      {
        title: 'active',
        key: 'active',
        dataIndex: 'active',
        render: (active: any, record: BlogListView) => (
          <Switch defaultChecked={record.enabled} onChange={(checked: boolean) => toggleActive(checked, record)} />
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (record: BlogListView) => (
          <Space size="middle">
            <ColorButton type="text" color={colors.BLUE} onClick={() => gotoEdit(record.id)}>
              <EditOutlined />
            </ColorButton>
            <ColorButton type="text" color={colors.DANGER_RED}>
              <Popconfirm
                title={t('confirmDeletePost')}
                onConfirm={() => deletePost(record.id)}
                okText={t('yes')}
                cancelText={'no'}
              >
                <DeleteOutlined />
              </Popconfirm>
            </ColorButton>
          </Space>
        ),
      },
    ],
    [t, toggleActive, gotoEdit, deletePost]
  )

  const onSearch = (query: string) => {
    setSearchQuery(query)
  }

  const gotoNewPost = () => history.push('/admin/new-post')

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'blog' }} />
      <ActionBar>
        <Row justify="space-between">
          <AdminPageTitle>{t('blog')}</AdminPageTitle>
          <Space>
            <Input.Search
              placeholder={t('searchByTitleOrModel')}
              onSearch={onSearch}
              className={'three-hundred-px-width'}
            />
            <Button icon={<PlusOutlined />} type="primary" onClick={gotoNewPost}>
              {t('newBlogPost')}
            </Button>
          </Space>
        </Row>
      </ActionBar>
      {(is_fetching || EditFetching || DeleteFetching) && <Indicator />}
      <Table columns={columns} dataSource={data} pagination={false} />
      <Row justify="end">
        <Pagination defaultCurrent={1} onChange={changePage} current={pageNumber} total={totalElements} />
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 24px;
  background-color: ${colors.WHITE};
`
const ActionBar = styled.div`
  padding: 16px 24px;
  background: ${colors.WHITE};
`
const ColorButton = styled(Button)<{ color: string }>`
  color: ${props => props.color};
`
