/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateUserVM } from '../models/ActivateUserVM';
import type { ForgetPasswordVM } from '../models/ForgetPasswordVM';
import type { RegisterUserVM } from '../models/RegisterUserVM';
import type { ResendCodeVM } from '../models/ResendCodeVM';
import type { ResetPasswordVM } from '../models/ResetPasswordVM';
import { request as __request } from '../core/request';

export class AppAuthService {

    /**
     * Activate a recently registered user
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async appAuthActivate(
        requestBody?: ActivateUserVM,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/auth/activate`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Request for reset forgotten password
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async appAuthForgetPassword(
        requestBody?: ForgetPasswordVM,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/auth/password/forget`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Request for resend verification code
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async appAuthResendCode(
        requestBody?: ResendCodeVM,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/auth/code/resend`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Register User
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static async appAuthRegister(
        requestBody?: RegisterUserVM,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/auth/register`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Reset password
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async appAuthResetPassword(
        requestBody?: ResetPasswordVM,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/auth/password/reset`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}