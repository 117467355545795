import { HeaderMenuItem } from 'interfaces/General'
export const menuItems: Array<HeaderMenuItem> = [
  {
    title: 'sellOnNet',
    link: '/sell-on-nettifillari',
  },
  {
    title: 'blog',
    link: '/blog',
  },
]
