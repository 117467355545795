import { Row, Col, Divider, Button, Space, Dropdown, Menu } from 'antd'
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { UserOutlined, DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { colors } from 'constants/colors'
import { BlackButton, Padding, RowFullWidth, WhiteButton } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import styled from 'styled-components'
import { AuthRole, selectAuthDetails, selectAuthStatus, setStatus } from 'store/auth/login'
import { AuthStatus } from 'constants/index'
import { removeToken } from 'utils/storage'
import { selectMe } from 'store/auth/me'
import { meRequestAsync } from 'store/auth/me/asyncActions'
import { UpdateLanguageVM } from 'api/main'
import { languageChangeRequestAsync } from 'store/auth/language/asyncActions'
import globeImage from '../../../../assets/images/globe.png'

interface Props {
  sidebar?: boolean
  noAuth?: boolean
  showDropDown?: boolean
  onClose?: () => void
}

export const UserBar: FunctionComponent<Props> = ({ sidebar, noAuth, onClose = () => null, showDropDown = true }) => {
  const { t, i18n } = useTranslation<string>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const authStatus = useAppSelector(selectAuthStatus)
  const { data } = useAppSelector(selectMe)
  const { data: authData } = useAppSelector(selectAuthDetails)
  const isAdmin = authData?.role === AuthRole.ROLE_ADMIN || authData?.role === AuthRole.ROLE_SUPER_ADMIN
  const isLoggedIn = authStatus === AuthStatus.loggedIn

  const gotoLogin = useCallback(() => {
    onClose()
    history.push('/login')
  }, [history, onClose])

  useEffect(() => {
    if (isLoggedIn) dispatch(meRequestAsync())
  }, [dispatch, isLoggedIn])

  const gotoRegister = useCallback(() => {
    onClose()
    history.push('/register')
  }, [history, onClose])

  const changeLang = (lang: 'en' | 'fi') => {
    onClose()
    i18n.changeLanguage(lang)
    const languageChangeRequest: UpdateLanguageVM = {
      langKey: lang === 'en' ? UpdateLanguageVM.langKey.EN : UpdateLanguageVM.langKey.FI,
    }
    if (isLoggedIn) dispatch(languageChangeRequestAsync(languageChangeRequest))
  }

  const gotoProfile = useCallback(() => {
    onClose()
    history.push('/account/profile')
  }, [history, onClose])
  const gotoDashboard = useCallback(() => {
    onClose()
    history.push('/admin')
  }, [history, onClose])
  const signOut = useCallback(() => {
    onClose()
    removeToken()
    dispatch(setStatus({ status: AuthStatus.none }))
    history.push('/')
  }, [onClose, dispatch, history])

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item onClick={gotoProfile}>{t('myProfile')}</Menu.Item>
        {isAdmin && <Menu.Item onClick={gotoDashboard}>{t('dashboard')}</Menu.Item>}
        <Menu.Item color={colors.RED} onClick={signOut}>
          {t('signOut')}
        </Menu.Item>
      </Menu>
    ),
    [gotoDashboard, gotoProfile, signOut, t, isAdmin]
  )

  if (sidebar)
    return (
      <ColFullHeight>
        {isLoggedIn && showDropDown && (
          <Space>
            <UserOutlined />
            {data?.firstName}
            <Dropdown overlay={menu} placement="bottomRight" arrow>
              <DownOutlined />
            </Dropdown>
          </Space>
        )}
        {!(noAuth || isLoggedIn) && (
          <RowFullWidthFitHeight justify="start" gutter={[0, 12]}>
            <BlackButton block color={colors.BLACK} onClick={gotoLogin}>
              {t('login')}
            </BlackButton>
            <WhiteButton block onClick={gotoRegister}>
              {t('signUp')}
            </WhiteButton>
          </RowFullWidthFitHeight>
        )}
        <Row className={'fit-content-height'} justify="center" align="middle">
          <img className={'footer-image'} src={globeImage} alt={''} />
          <ButtonColored color={noAuth ? colors.WHITE : null} type="text" onClick={() => changeLang('en')}>
            {t('en')}
          </ButtonColored>
          <Divider type="vertical" />
          <ButtonColored color={noAuth ? colors.WHITE : null} type="text" onClick={() => changeLang('fi')}>
            {t('fi')}
          </ButtonColored>
        </Row>
      </ColFullHeight>
    )

  return (
    <Row justify="center">
      <Row align="middle">
        <Padding padding="0 42px">
          <img className={'footer-image'} src={globeImage} alt={''} />
          <ButtonColoredNoSpace
            color={noAuth ? colors.WHITE : i18n.language === 'en' ? colors.BLACK : colors.LIGHT_GRAY_3}
            type="text"
            onClick={() => changeLang('en')}
          >
            {t('en')}
          </ButtonColoredNoSpace>
          <LanguageDivider type="vertical" />
          <ButtonColoredNoSpace
            color={noAuth ? colors.WHITE : i18n.language === 'fi' ? colors.BLACK : colors.LIGHT_GRAY_3}
            type="text"
            onClick={() => changeLang('fi')}
          >
            {t('fi')}
          </ButtonColoredNoSpace>
        </Padding>
        {isLoggedIn && showDropDown && (
          <Space>
            <UserOutlined />
            {data?.firstName}
            <Dropdown overlay={menu} placement="bottomRight" arrow>
              <DownOutlined />
            </Dropdown>
          </Space>
        )}
        {!(noAuth || isLoggedIn) && (
          <Space>
            <BlackButton color={colors.BLACK} onClick={gotoLogin}>
              {t('login')}
            </BlackButton>
            <Button onClick={gotoRegister}>{t('signUp')}</Button>
          </Space>
        )}
      </Row>
    </Row>
  )
}

const RowFullWidthFitHeight = styled(RowFullWidth)`
  height: fit-content;
`
const ColFullHeight = styled(Col)`
  padding-top: 20px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const ButtonColored = styled(Button)<{ color?: string }>`
  color: ${props => props.color || null};
`
const ButtonColoredNoSpace = styled(ButtonColored)`
  padding: 0;
`
const LanguageDivider = styled(Divider)`
  margin: 0 12px;
`
