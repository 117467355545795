import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { BicycleRequest } from '.'
import { AppBicycleService } from 'api/main'

export const getBicyclesRequestAsync: AsyncThunk<any, any, any> = createAsyncThunk(
  'bicycles/get',
  async (query: BicycleRequest, { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleSearch(
        query.bicycleTypeIds,
        query.brakeIds,
        query.brandIds,
        query.colorIds,
        query.frameMaterialIds,
        query.postTypes || [],
        query.frameSizeIds,
        query.gearIds,
        query.genderIds,
        query.categoryIds,
        query.batteryLocationIds,
        query.powerIds,
        query.motorIds,
        null,
        query.keyword,
        query.page - 1,
        query.size,
        query.sort
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
