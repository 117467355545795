import { Route, Switch } from 'react-router-dom'

import { FunctionComponent } from 'react'
import { Layout } from 'antd'
import { RouteProps } from 'react-router'
import { Header } from 'Components/Shared/Header/Header'
import Home from 'Components/Home/Home'
import { InnerPageBrand } from 'Components/Brands/InnerPage/InnerPage'
import { Blog } from 'Components/Blog/Blog'
import { PostComponent } from 'Components/Blog/Post/Post'
import { SellOnNett } from 'Components/Sell/Sell'
import { Footer } from 'Components/Shared/Footer/Footer'
import { PrivacyPage } from 'Components/Home/Policy/Policy'
import { TermsPage } from 'Components/Home/Terms/Terms'
import { NewBicycle } from 'Components/Profile/Bicyles/NewBicycle/NewBicycle'
import { Account } from 'Components/Profile/Account'
import { BicycleComponent } from 'Components/Bicycle/Bicycle'
import { EditBicycle } from 'Components/Profile/Bicyles/EditBicycle/EditBicycle'
import styled from 'styled-components'
import { FAQPage } from 'Components/Home/FAQ/FAQ'

const mainPageRoutes: RouteProps[] = [
  {
    exact: true,
    path: '/home',
    children: <Home />,
  },
  {
    exact: true,
    path: '/brands/:id',
    children: <InnerPageBrand />,
  },
  {
    exact: true,
    path: '/blog',
    children: <Blog />,
  },
  {
    exact: true,
    path: '/blog/:id',
    children: <PostComponent />,
  },
  {
    exact: true,
    path: '/sell-on-nettifillari',
    children: <SellOnNett />,
  },
  {
    exact: true,
    path: '/privacy-and-policy',
    children: <PrivacyPage />,
  },
  {
    exact: true,
    path: '/terms-and-conditions',
    children: <TermsPage />,
  },
  {
    exact: true,
    path: '/account/new-bicycle',
    children: <NewBicycle />,
  },
  {
    exact: true,
    path: '/account/:tab?',
    children: <Account />,
  },
  {
    exact: true,
    path: '/bicycle/:id?',
    children: <BicycleComponent />,
  },
  {
    exact: true,
    path: '/bicycle/edit/:id',
    children: <EditBicycle />,
  },
  {
    exact: true,
    path: '/faq',
    children: <FAQPage />,
  },
]
const { Content } = Layout

export const paths = [mainPageRoutes.map(route => route.path)].flat().filter(path => path) as string[]

const MainRoutes: FunctionComponent = () => {
  return (
    <Layout className={'full-screen-min-height'}>
      <MainLayout className="site-layout-background">
        <Header />
        <Content className={'two-hundreds-min-height'}>
          <Switch>
            {mainPageRoutes.map(route => (
              <Route key={route.path.toString()} {...route} />
            ))}
          </Switch>
        </Content>
        <Footer />
      </MainLayout>
    </Layout>
  )
}
const MainLayout = styled(Layout)`
  overflow-x: hidden;
`
export default MainRoutes
