import { createSlice } from '@reduxjs/toolkit'
import { ErrorResponse } from 'interfaces/Error'
import { RootState } from 'store'
import { setPasswordRequestAsync } from './asyncActions'

export enum SetPasswordStatus {
  requestSuccess,
  requestFailed,
  none,
}
export interface SetPasswordState {
  is_fetching: boolean
  status: SetPasswordStatus
  errors: ErrorResponse
}

const initialState: SetPasswordState = {
  is_fetching: false,
  status: SetPasswordStatus.none,
  errors: null,
}

export const setPasswordSlice = createSlice({
  name: 'setPassword',
  initialState,
  reducers: {
    deleteStatus: state => {
      state.status = SetPasswordStatus.none
    },
  },

  extraReducers: builder => {
    builder
      .addCase(setPasswordRequestAsync.pending, state => {
        state.status = SetPasswordStatus.none
        state.is_fetching = true
      })
      .addCase(setPasswordRequestAsync.fulfilled, state => {
        state.is_fetching = false
        state.status = SetPasswordStatus.requestSuccess
      })
      .addCase(setPasswordRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.status = SetPasswordStatus.requestFailed
        state.is_fetching = false
      })
  },
})

export const { deleteStatus } = setPasswordSlice.actions

export const selectPassword = (state: RootState): SetPasswordState => state.auth.setPassword

export default setPasswordSlice.reducer
