import { combineReducers } from '@reduxjs/toolkit'
import getAllContentReducer from './getAll'
import getContentReducer from './get'
import editContentReducer from './edit'

export const contentReducers = combineReducers({
  getAll: getAllContentReducer,
  get: getContentReducer,
  edit: editContentReducer,
})
