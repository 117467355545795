import { AppBicycleService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { BrandResponse } from 'interfaces/Brands'

export const getOneBrandRequestAsync: AsyncThunk<BrandResponse, number, any> = createAsyncThunk(
  'brands/getOne',
  async (id: number, { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleShow(id)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
