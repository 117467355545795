import { FC, useCallback } from 'react'
import { AdminPageTitle } from '../Shared/Components/Components'
import styled from 'styled-components'
import { colors } from '../../../constants/colors'
import { Button, Pagination, Row, Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useContentDataTable } from './useContentDataTable'
import { ContentListView } from '../../../api/main'
import { EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

export const Content: FC = () => {
  const { t } = useTranslation()
  const { columns, data, changePage, currentPage, totalElements, pageSize } = useContentDataTable(record => (
    <Action content={record} />
  ))

  return (
    <div>
      <Container>
        <ActionBar>
          <Row justify="space-between">
            <AdminPageTitle>{t('contents')}</AdminPageTitle>
          </Row>
        </ActionBar>
        <Table columns={columns} dataSource={data} pagination={false} />
        <Row justify="end">
          <Pagination
            defaultCurrent={1}
            pageSize={pageSize}
            onChange={changePage}
            current={currentPage}
            total={totalElements}
          />
        </Row>
      </Container>
    </div>
  )
}

const Action: FC<{ content: ContentListView }> = ({ content }) => {
  const history = useHistory()
  const gotoEdit = useCallback(() => {
    history.push('/admin/content-edit/' + content.contentPosition)
  }, [history, content.contentPosition])

  return (
    <Space size="middle">
      <ColorButton type="text" color={colors.BLUE} onClick={gotoEdit}>
        <EditOutlined />
      </ColorButton>
    </Space>
  )
}

const Container = styled.div`
  margin: 16px 24px;
  background-color: ${colors.WHITE};
`
const ActionBar = styled.div`
  padding: 16px 24px;
  background: ${colors.WHITE};
`

const ColorButton = styled(Button)<{ color: string }>`
  color: ${props => props.color};
`
