import { AppBicycleService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { BicycleChangeStatus } from './Status.interface'

export const changeOwnStatusRequestAsync: AsyncThunk<any, BicycleChangeStatus, any> = createAsyncThunk(
  'bicycle/status',
  async (requestBody: BicycleChangeStatus, { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleStatusUpdate(requestBody.bicycleId, requestBody.requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
