import { Row } from 'antd'
import { H1 } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { Indicator } from 'Components/UiKit/Indicator'
import { colors } from 'constants/colors'
import { formatDate } from 'constants/functions'
import { UrlParamsWithId } from 'interfaces/General'
import { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { selectPosts } from 'store/blog/get'
import { getBlogPostsRequestAsync } from 'store/blog/get/asyncActions'
import { selectPost } from 'store/blog/getOne'
import { getOnePostsRequestAsync } from 'store/blog/getOne/asyncActions'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import styled from 'styled-components'
import { ColPost } from '../ColPost/ColPost'
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'
import { NotFound } from '../../Shared/NotFound/NotFound'

export const PostComponent: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { is_fetching } = useAppSelector(selectPost)
  const { id } = useParams<UrlParamsWithId>()
  const { t, i18n } = useTranslation<string>()
  const { data: posts, errors } = useAppSelector(selectPosts)
  const { data: post } = useAppSelector(selectPost)
  const isFin = i18n.language === 'fi'

  useEffect(() => {
    if (!posts && !errors) dispatch(getBlogPostsRequestAsync({ keyword: '', page: 1, size: 3 }))
  }, [dispatch, posts, errors])

  useEffect(() => {
    dispatch(getOnePostsRequestAsync(id))
  }, [dispatch, id])

  if (is_fetching) return <Indicator />
  if (post)
    return (
      <BlogWrapper>
        <HelmetWrapper
          parentData={{
            ...post,
            description: post.metaTag,
            finnishDescription: post.finnishMetaTag,
            keywords: post.slug,
            isArticle: true,
            image: post.photo?.url,
          }}
        />
        <Row>
          <H1Center>{isFin ? post.finnishTitle : post.title}</H1Center>
        </Row>
        <Row justify="center">
          <p>
            <PostDate>{t('postedOn')}</PostDate> <DateFormat>{formatDate(post.createdAt)}</DateFormat>
          </p>
        </Row>
        <Row justify="center">
          <img alt={isFin ? post.finnishTitle : post.title} src={post.photo?.url} />
        </Row>
        <Row>
          <BlogBody dangerouslySetInnerHTML={{ __html: isFin ? post.finnishBody : post.body }}></BlogBody>
        </Row>
        <Row>
          <a
            href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookOutlined />
          </a>
          <a
            href={'https://twitter.com/intent/tweet?text=' + window.location.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterOutlined />
          </a>
        </Row>
        <Row>
          <Recommended>{t('Recommended')}</Recommended>
        </Row>
        <MiddlePostsWrapper>
          {posts[1] && <ColPost md={{ span: 8 }} xs={{ span: 20 }} post={posts[1]}></ColPost>}
          {posts[2] && <ColPost md={{ span: 8 }} xs={{ span: 20 }} post={posts[2]}></ColPost>}
          {posts[3] && <ColPost md={{ span: 8 }} xs={{ span: 20 }} post={posts[3]}></ColPost>}
        </MiddlePostsWrapper>
      </BlogWrapper>
    )
  return <NotFound />
}

const Recommended = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: ${colors.BLACK};
`
const MiddlePostsWrapper = styled(Row)`
  margin: 0 auto;
  max-width: fit-content;
`
const BlogBody = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.GRAY_TEXT};

  & a {
    color: ${colors.BRAND_GREEN} !important;
  }
`
const H1Center = styled(H1)`
  text-align: center;
`
const PostDate = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.GRAY};
`

const DateFormat = styled(PostDate)`
  color: ${colors.BRAND_GREEN};
`

const BlogWrapper = styled.div`
  padding-top: 10px;
  margin: auto;
  width: 100%;
  max-width: 1100px;
`
