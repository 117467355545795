import { Col, ColProps, Row } from 'antd'
import { BlogListView } from 'api/main'
import { H1 } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { colors } from 'constants/colors'
import { formatDate } from 'constants/functions'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props extends ColProps {
  post: BlogListView
}

export const ColPost: FunctionComponent<Props> = ({ post, xs, md, lg, span }) => {
  const { t, i18n } = useTranslation<string>()
  const isFin = i18n.language === 'fi'

  return (
    <MiddlePost xs={xs} md={md} lg={lg} span={span}>
      <RowFitMaxWidth justify="center">
        <Link to={'/blog/' + post.slug}>
          <Img alt={isFin ? post.finnishTitle : post.title} src={post.photo?.url} />
        </Link>
      </RowFitMaxWidth>
      <RowFitMaxWidth>
        <Link to={'/blog/' + post.slug}>
          <H2>{isFin ? post.finnishTitle : post.title}</H2>
        </Link>
      </RowFitMaxWidth>
      <RowFitMaxWidth>
        <p>
          <PostDate>{t('postedOn')}</PostDate> <DateFormat>{formatDate(post.createdAt)}</DateFormat>
        </p>
      </RowFitMaxWidth>
    </MiddlePost>
  )
}
const Img = styled.img`
  max-width: 100%;
`
const PostDate = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.GRAY};
`
const DateFormat = styled(PostDate)`
  color: ${colors.BRAND_GREEN};
`
const RowFitMaxWidth = styled(Row)``
const H2 = styled(H1)`
  font-size: 24px;
  line-height: 36px;
`
const MiddlePost = styled(Col)`
  padding: 15px;
  &,
  & {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: ${colors.GRAY_TEXT};
  }
`
