import { FunctionComponent } from 'react'
import { Col, Row } from 'antd'
import styled from '@emotion/styled-base'
import { menuItems } from 'constants/menu.items'
import { HeaderMenuItem, voidCallBack } from 'interfaces/General'
import { NavLink } from 'react-router-dom'
import { colors } from 'constants/colors'
import { useTranslation } from 'react-i18next'

interface Props {
  onClose?: voidCallBack
}

export const SideBar: FunctionComponent<Props> = ({ onClose }) => {
  const { t } = useTranslation<string>()

  return (
    <Col>
      {menuItems.map((menuItem: HeaderMenuItem) => (
        <Row key={menuItem.link}>
          <MenuLink activeClassName="active" to={menuItem.link} onClick={() => onClose()}>
            {t(menuItem.title)}
          </MenuLink>
        </Row>
      ))}
    </Col>
  )
}

const MenuLink = styled(NavLink)`
  & {
    color: ${colors.DARK_GRAY};
    position: relative;
  }
  &.active {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${colors.BRAND_GREEN};
      height: 3px;
      border-radius: 2px;
    }
  }
`
