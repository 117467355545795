import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { RefObject, useEffect } from 'react'
import { useQuill } from 'react-quilljs'
import { ContentListView } from '../../../../api/main'

export function useQuillField(
  setValue: UseFormSetValue<any>,
  getValues: UseFormGetValues<any>,
  fieldName: string,
  content: ContentListView
): RefObject<any> {
  const { quill, quillRef } = useQuill()

  useEffect(() => {
    if (quill && content) {
      quill.clipboard.dangerouslyPasteHTML(getValues(fieldName))
    }
  }, [quill, getValues, fieldName, content])

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setValue(fieldName, quillRef.current.firstChild.innerHTML)
      })
    }
  }, [quillRef, setValue, fieldName, quill])

  return quillRef
}
