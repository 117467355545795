/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Bicycle Reject Reason
 */
export enum RejectReason {
    BAD_BIKE_QUALITY = 'BAD_BIKE_QUALITY',
    NOT_ENOUGH_INFORMATION = 'NOT_ENOUGH_INFORMATION',
    OTHER = 'OTHER',
    POOR_PICTURE_QUALITY = 'POOR_PICTURE_QUALITY',
}