import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { getContentRequestAsync } from './asyncActions'
import { ErrorResponse } from 'interfaces/Error'
import { ContentDetailsView } from 'api/main'

export interface AdminContentGetState {
  is_fetching: boolean
  data: ContentDetailsView
  errors: ErrorResponse
}

const initialState: AdminContentGetState = {
  is_fetching: false,
  data: null,
  errors: null,
}

export const getContentSlice = createSlice({
  name: 'adminContent',
  initialState,
  reducers: {
    resetState: state => {
      state.is_fetching = false
      state.data = null
      state.errors = null
    },
    resetError: state => {
      state.errors = null
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getContentRequestAsync.pending, state => {
        state.data = null
        state.is_fetching = true
      })
      .addCase(getContentRequestAsync.fulfilled, (state, { payload }) => {
        state.is_fetching = false
        state.data = payload
      })
      .addCase(getContentRequestAsync.rejected, (state, action: any) => {
        try {
          state.errors = JSON.parse(action.payload?.body)
          if (action.payload.status === 401) {
            state.errors.fieldErrors = [
              {
                message: state.errors.detail,
                field: state.errors.detail,
                objectName: state.errors.detail,
              },
            ]
          }
        } catch (e) {
          state.errors = {}
        }
        state.data = null
        state.is_fetching = false
      })
  },
})

export const selectAdminContent = (state: RootState): AdminContentGetState => state.admin.contents.get
export const { resetState, resetError } = getContentSlice.actions

export default getContentSlice.reducer
