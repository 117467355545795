import { Button, Col, message, Row, Space } from 'antd'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { SaveBannerVM } from 'api/main'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { selectCreatedMedia } from 'store/bicycle/media'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import 'quill/dist/quill.snow.css'
import { BANNER_MEDIA_LABEL, URL_REGEX } from 'constants/index'
import { UploadImage } from 'Components/Profile/Bicyles/UploadImage/UploadImage'
import { resetState } from 'store/admin/banners/edit'
import { resetState as resetMediaState } from 'store/bicycle/media'
import { Indicator } from 'Components/UiKit/Indicator'
import { selectAdminUpdatedBanner } from 'store/admin/banners/edit'
import { getBannerRequestAsync } from 'store/admin/banners/get/asyncActions'
import { selectAdminCurrentBanner } from 'store/admin/banners/get'
import { adminUpdateBannerRequest } from 'store/admin/banners/edit/asyncActions'
import { UpdateBannerInterface } from 'store/admin/banners/Interfaces'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'
import { FormDatePicker, SelectBicycleInput, SelectBicycleItems } from '../../../Shared/Components/Components'
import { NotFound } from '../../../Shared/NotFound/NotFound'

export const EditBanner: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { done, errors: UpdateErrors, is_fetching } = useAppSelector(selectAdminUpdatedBanner)
  const { data: uploadedMediaIds, errors: UploadErrors } = useAppSelector(selectCreatedMedia)
  const {
    data: currentBanner,
    errors: fetchError,
    is_fetching: currentFetching,
  } = useAppSelector(selectAdminCurrentBanner)
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    dispatch(getBannerRequestAsync(+id))
  }, [dispatch, id])

  useEffect(() => {
    if (fetchError) {
      history.push('banners')
    }
  }, [fetchError, history])

  const BannerPositions = useMemo(
    () => [
      { id: 'HOME', name: t('home'), name_fi: t('home') },
      { id: 'BLOG', name: t('blog'), name_fi: t('blog') },
      { id: 'BICYCLE', name: t('bicycle'), name_fi: t('bicycle') },
    ],
    [t]
  )

  const newBannerSchema = (t: TFunction): Yup.ObjectSchema<ObjectShape> =>
    Yup.object().shape({
      position: Yup.string().required(t('required')),
      title: Yup.string().required(t('required')),
      url: Yup.string().matches(URL_REGEX, t('enterCorrectUrl')).required(t('required')),
      startDate: Yup.string().required(t('required')),
      endDate: Yup.string().required(t('required')),
    })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<SaveBannerVM>({
    defaultValues: {},
    resolver: yupResolver(newBannerSchema(t)),
  })

  useEffect(() => {
    if (currentBanner)
      reset({
        position: currentBanner.position,
        title: currentBanner.title,
        url: currentBanner.url,
        active: currentBanner.active,
        startDate: currentBanner.startDate,
        endDate: currentBanner.endDate,
        photoId: currentBanner.photo.id,
      })
  }, [currentBanner, reset])

  const goBack = () => history.goBack()

  const onSubmit = handleSubmit((requestBody: SaveBannerVM) => {
    let photoId = currentBanner.photo.id
    if (uploadedMediaIds && uploadedMediaIds.length > 0) {
      photoId = uploadedMediaIds[uploadedMediaIds?.length - 1]?.id
    }
    const request: UpdateBannerInterface = {
      id: +id,
      ...requestBody,
      photoId: photoId,
    }
    dispatch(adminUpdateBannerRequest(request))
  })

  useEffect(() => {
    if (UploadErrors) {
      UploadErrors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }
  }, [t, UploadErrors, dispatch])

  useEffect(() => {
    if (Array.isArray(UpdateErrors?.fieldErrors)) {
      UpdateErrors?.fieldErrors?.forEach((err: FieldError) => message.error(err.message))
    }
    if (!UpdateErrors && done) {
      message.success(t('bannerUpdated'))
      history.push('/admin/banner')
    }
    return () => {
      dispatch(resetState())
    }
  }, [t, UpdateErrors, done, history, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetState())
      dispatch(resetMediaState())
    }
  }, [dispatch])

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'editBanner' }} />
      {(is_fetching || currentFetching || UpdateErrors) && <Indicator />}
      {currentBanner ? (
        <form onSubmit={onSubmit}>
          <Topbar justify="space-between">
            <Space>
              <Button type="text" onClick={goBack} icon={<ArrowLeftOutlined />} />
              {t('newBanner')}
            </Space>
          </Topbar>
          <RowPadding justify="space-between" gutter={20}>
            <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
              <SelectBicycleItems
                control={control}
                required
                label={t('position')}
                options={BannerPositions}
                error={errors.position}
                name={'position'}
                value={currentBanner.position}
              />
              <Row>
                <FormDatePicker
                  label={t('startDate')}
                  required
                  name={'startDate'}
                  control={control}
                  error={errors.startDate}
                  value={currentBanner.startDate}
                />
                <UploadImage
                  hint={t('bannerSizeHint')}
                  singleMode
                  targetType={BANNER_MEDIA_LABEL}
                  images={[currentBanner.photo]}
                />
              </Row>
            </InputCol>
            <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
              <SelectBicycleInput
                value={currentBanner.title}
                label={t('title')}
                required
                name={'title'}
                control={control}
                error={errors.title}
              />
              <FormDatePicker
                value={currentBanner.endDate}
                label={t('endDate')}
                required
                name={'endDate'}
                control={control}
                error={errors.endDate}
              />
            </InputCol>
            <InputCol xs={{ span: 24 }} md={{ span: 8 }}>
              <SelectBicycleInput
                value={currentBanner.url}
                label={t('url')}
                required
                name={'url'}
                control={control}
                error={errors.url}
              />
            </InputCol>
          </RowPadding>
          <Row justify="end">
            <SubmitButton htmlType="submit">{t('send')}</SubmitButton>
          </Row>
        </form>
      ) : (
        <NotFound />
      )}
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 24px;
  background-color: ${colors.WHITE};
  font-family: 'Roboto';
  font-style: normal;
  color: ${colors.BLACK_TR};
`
const Topbar = styled(Row)`
  padding: 16px 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 1px solid ${colors.HEADER_BORDER};
`
const InputCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const SubmitButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  background: ${colors.BLUE};
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: ${colors.WHITE};
`
const RowPadding = styled(Row)`
  padding: 0 0 50px 0;
`
