import { AdminBannerService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { UpdateBannerInterface } from '../Interfaces'

export const adminUpdateBannerRequest: AsyncThunk<any, UpdateBannerInterface, any> = createAsyncThunk(
  'admin/banner/update',
  async (requestBody: UpdateBannerInterface, { rejectWithValue }) => {
    try {
      return await AdminBannerService.adminBannerUpdate(requestBody.id, requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
