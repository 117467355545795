import { AdminBannerService, SaveBannerVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const adminCreateBannerRequest: AsyncThunk<any, SaveBannerVM, any> = createAsyncThunk(
  'admin/banner/create',
  async (requestBody: SaveBannerVM, { rejectWithValue }) => {
    try {
      return await AdminBannerService.adminBannerCreate(requestBody)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
