import { Carousel, Col, message, Row, Tabs } from 'antd'
import { Indicator } from 'Components/UiKit/Indicator'
import { UrlParamsWithId } from 'interfaces/General'
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { BicycleState, resetState, selectCurrentBicycle } from 'store/bicycle/getSingle'
import { getSingleBicycleRequestAsync } from 'store/bicycle/getSingle/asyncActions'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import styled from 'styled-components'
import { Image } from 'antd'
import { colors } from 'constants/colors'
import { useTranslation } from 'react-i18next'
import {
  PhoneOutlined,
  WhatsAppOutlined,
  MailOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from '@ant-design/icons'
import { mapColor } from 'constants/functions'
import { BannerPosition, BicycleAppListView, MediaNestedView } from 'api/main'
import { getAppBannerRequestAsync } from 'store/banners/getAll/asyncActions'
import { selectBanners } from 'store/banners/getAll'
import { YouMightLike } from './YouMightLike/YouMightLike'
import { HelmetWrapper } from '../UiKit/HelmetWrapper/HelmetWrapper'
import { fallBackImage } from '../../constants'
import { CarouselRef } from 'antd/lib/carousel'
import { NotFound } from '../Shared/NotFound/NotFound'
import Linkify from 'react-linkify'

interface ValueWithLang {
  name: string
  finnishName: string
}

export const BicycleComponent: FunctionComponent = ({}) => {
  const { id } = useParams<UrlParamsWithId>()
  const dispatch = useAppDispatch()
  const {
    data: bicycle,
    is_fetching,
    errors,
    rejected: isRejected,
  } = useAppSelector<BicycleState>(selectCurrentBicycle)
  const history = useHistory()
  const { t, i18n } = useTranslation<string>()
  const { bicycle: bicycleBanner } = useAppSelector(selectBanners)
  useEffect(() => {
    const pressKeyCallback = (e: KeyboardEvent) => {
      switch (e.code) {
        case 'ArrowLeft':
          carouselRef.current.prev()
          break
        case 'ArrowRight':
          carouselRef.current.next()
          break
      }
    }
    document.addEventListener('keydown', pressKeyCallback)
    return () => {
      document.removeEventListener('keydown', pressKeyCallback)
    }
  }, [])

  useEffect(() => {
    dispatch(getAppBannerRequestAsync(BannerPosition.BICYCLE))
  }, [dispatch])

  const [medias, setMedias] = useState([])

  useEffect(() => {
    if (bicycle) {
      const tempMedias = bicycle?.medias.filter(media => media.id !== bicycle?.mainMedia?.id)
      const main = bicycle?.medias.find(media => media.id === bicycle?.mainMedia?.id)
      if (main) {
        tempMedias.splice(0, 0, main)
      }
      setMedias(tempMedias)
    }
  }, [bicycle])

  useEffect(() => {
    dispatch(getSingleBicycleRequestAsync(+id))
  }, [dispatch, id])

  useEffect(() => {
    if (errors) {
      errors.fieldErrors?.forEach(err => message.error(err.message))
      history.goBack()
    }
    return () => {
      dispatch(resetState())
    }
  }, [dispatch, errors, history])

  const translationMapValue = useCallback(
    (val: ValueWithLang) => {
      return i18n.language === 'en' ? val?.name : val?.finnishName
    },
    [i18n]
  )

  const carouselRef = useRef<CarouselRef>(null)
  const getMediaNestedView = useCallback((bike: BicycleAppListView & { mainMedia?: MediaNestedView }) => {
    return bike.mainMedia ?? bike.medias[0]
  }, [])
  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'bicycle' }}>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: bicycle?.brand?.name,
            image: [Array.isArray(bicycle?.medias) ? getMediaNestedView(bicycle)?.url : ''],
            description: bicycle?.description,
            brand: {
              '@type': 'Brand',
              name: bicycle?.brand?.name,
            },
            offers: {
              '@type': 'Offer',
              url: 'https://example.com/anvil',
              priceCurrency: 'EUR',
              price: bicycle?.price,
            },
          })}
        </script>
      </HelmetWrapper>
      {is_fetching && <Indicator />}
      {bicycle ? (
        <>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Row justify="center">
                <CarouselHolder>
                  <Carousel ref={carouselRef} dotPosition={'bottom'}>
                    {Array.isArray(medias) ? (
                      medias.map((photo: MediaNestedView) => (
                        <Thumbnail preview={false} fallback={fallBackImage} key={photo?.id} src={photo?.url} />
                      ))
                    ) : (
                      <Thumbnail fallback={fallBackImage} src={'ss'} />
                    )}
                  </Carousel>
                </CarouselHolder>
                <Status color={mapColor(bicycle.status)}>{t(bicycle.status)}</Status>
              </Row>
            </Col>
            <ColBreak xs={{ span: 24 }} md={{ span: 12, push: 1 }}>
              <BikeTitle>{bicycle.description}</BikeTitle>
              <Price>€ {bicycle.price}</Price>
              <Row>{t('color')}</Row>
              <RowCentered>
                <ColorBox color={bicycle.color.name} />
                {i18n.language === 'en' ? bicycle.color.name : bicycle.color.finnishName}
              </RowCentered>
              <Row>{t('size')}</Row>
              <RowCentered>
                <FrameBox color={bicycle.color.name}>
                  {i18n.language === 'en' ? bicycle.frameSize.name : bicycle.frameSize.finnishName}
                </FrameBox>
              </RowCentered>
              <Row>{t('contactSeller')}</Row>
              <Row>
                <ContactBox>
                  {bicycle.owner.mobile && (
                    <RowCentered>
                      <PhoneOutlined />
                      <a href={'tel:' + bicycle.owner.mobile}>{bicycle.owner.mobile}</a>
                    </RowCentered>
                  )}
                  {bicycle.owner.mobile && (
                    <RowCentered>
                      <WhatsAppOutlined />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={'https://wa.me/' + bicycle.whatsappNumber ? bicycle.whatsappNumber : bicycle.owner.mobile}
                      >
                        {bicycle.whatsappNumber ? bicycle.whatsappNumber : bicycle.owner.mobile}
                      </a>
                    </RowCentered>
                  )}
                  {bicycle.owner.email && (
                    <RowCentered>
                      <MailOutlined />
                      <a target="_blank" rel="noreferrer" href={'mailto:' + bicycle.owner.email}>
                        {bicycle.owner.email}
                      </a>
                    </RowCentered>
                  )}
                </ContactBox>
              </Row>
              <Row>
                <Tabs defaultActiveKey="1" moreIcon={<></>}>
                  <Tabs.TabPane tab={t('description')} key="1">
                    <Linkify>{bicycle.description}</Linkify>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t('characteristics')} key="2">
                    <Row>
                      {t('gender')}: {translationMapValue(bicycle.gender)}
                    </Row>
                    <Row>
                      {t('bikType')}: {translationMapValue(bicycle.bicycleType)}
                    </Row>
                    <Row>
                      {t('brand')}: {bicycle.brand.name}
                    </Row>
                    <Row>
                      {t('tireSize')}: {translationMapValue(bicycle.tire)}
                    </Row>
                    <Row>
                      {t('frameSize')}: {translationMapValue(bicycle.frameSize)}
                    </Row>
                    <Row>
                      {t('gears')}: {translationMapValue(bicycle.gear)}
                    </Row>
                    <Row>
                      {t('frame')}: {translationMapValue(bicycle.frameMaterial)}
                    </Row>
                    <Row>
                      {t('brakes')}: {translationMapValue(bicycle.brake)}
                    </Row>
                    <Row>
                      {t('color')}: {translationMapValue(bicycle.color)}
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t('deliveryInstruction')} key="3">
                    <Linkify>{bicycle.deliveryInstructions}</Linkify>
                  </Tabs.TabPane>
                </Tabs>
              </Row>
              <RowCentered>
                <ShareTitle>{t('shareOn')}:</ShareTitle>
                <ColoredIcon>
                  <a
                    href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookOutlined />
                  </a>
                  <a
                    href={'https://www.instagram.com/?url=' + window.location.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramOutlined />
                  </a>
                  <a
                    href={'https://twitter.com/intent/tweet?text=' + window.location.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterOutlined />
                  </a>
                </ColoredIcon>
              </RowCentered>
            </ColBreak>
          </Row>
          <YouMightLike id={bicycle?.bicycleType?.id} />
          {bicycleBanner && bicycleBanner.length > 0 && (
            <BicycleBanner>
              <a href={bicycleBanner[0].url}>
                <Img alt={bicycleBanner[0]?.title} src={bicycleBanner[0]?.photo?.url} />
              </a>
            </BicycleBanner>
          )}
        </>
      ) : (
        <NotFound isRejected={isRejected} />
      )}
    </Container>
  )
}
const ShareTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.GRAY_TEXT};
`
const ColoredIcon = styled.div`
  display: flex;
  gap: 12px;
  color: ${colors.BRAND_GREEN};
`
const Container = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  max-width: 80%;
  margin: auto;
  max-width: 1200px;
`
const Thumbnail = styled(Image)`
  width: 100%;
  cursor: pointer;
`
const ColBreak = styled(Col)`
  word-break: break-word;

  & > * {
    margin: 10px 0;
  }
`
const BikeTitle = styled(Row)`
  color: ${colors.GRAY_TEXT};
  word-wrap: break-word;
`
const Price = styled(Row)`
  font-weight: bold;
  color: ${colors.GRAY_TEXT};
`
const ColorBox = styled.div<{ color: string }>`
  background: ${props => props.color};
  width: 10px;
  height: 10px;
`
const RowCentered = styled(Row)`
  display: flex;
  align-items: center;
  gap: 12px;
`
const ContactBox = styled.div`
  border: 1px solid ${colors.BOX_BORDER};
  box-sizing: border-box;
  border-radius: 1px;
  min-width: 400px;
  padding: 19px;

  & .ant-row {
    gap: 15px;
  }

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.GRAY_TEXT};
`

const FrameBox = styled.div`
  border: 2px solid ${colors.BLACK};
  border-radius: 0.5px;
  padding: 12px 5px;
  font-size: 12px;
`

const CarouselHolder = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  border: 1px solid #eeeeee;
  padding: 10px;
  box-shadow: inset 0px 0px 10px 1px lightgrey;

  & .ant-carousel {
    width: 100%;
    height: 100%;
  }

  & .slick-current {
    display: flex !important;
    justify-content: center !important;
  }

  & .slick-dots-bottom {
    background-color: rgba(0, 128, 0, 0.24);
    border-radius: 10px;
    padding: 10px;
  }
`
const Status = styled.div<{ color?: string }>`
  position: absolute;
  right: 0px;
  top: 20px;
  background: ${props => (props.color ? props.color : colors.BRAND_GREEN)};
  color: ${colors.WHITE};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 7px 16px;
`
const BicycleBanner = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const Img = styled.img`
  max-width: 100%;
`
