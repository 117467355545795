import { Button, DatePicker, message, Modal, Pagination, Popconfirm, Radio, Row, Space, Table } from 'antd'
import { colors } from 'constants/colors'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AdminPageTitle } from 'Components/Admin/Shared/Components/Components'
import { EditOutlined, DeleteOutlined, PlusOutlined, FilterOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Indicator } from 'Components/UiKit/Indicator'
import { FieldError } from 'interfaces/Error'
import { ellipsis, formatDate } from 'constants/functions'
import { selectAdminBanners } from 'store/admin/banners/getAll'
import { getAllBannerRequestAsync } from 'store/admin/banners/getAll/asyncActions'
import { GetALlBannersAdminRequest } from 'store/admin/banners/Interfaces'
import { ADMIN_BANNERS_PAGE_SIZE, BACKEND_DATE_FORMAT, DATE_FORMAT } from 'constants/index'
import { BannerListView, BannerPosition } from 'api/main'
import { useHistory } from 'react-router'
import { resetError } from 'store/admin/banners/getAll'
import { resetState as resetDeleteState } from 'store/admin/banners/delete'
import { adminDeleteBannerRequest } from 'store/admin/banners/delete/asyncActions'
import { selectAdminDeletedBanner } from 'store/admin/banners/delete'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

enum BicycleStatusEnum {
  ALL,
  ACTIVE,
  INACTIVE,
}

export const BannerList: FunctionComponent = () => {
  const { t } = useTranslation<string>()

  const dispatch = useAppDispatch()
  const [pageNumber, setPageNumber] = useState(1)
  const { is_fetching, data, errors, totalElements } = useAppSelector(selectAdminBanners)
  const { deleteFetching, done, errors: deleteErrors } = useAppSelector(selectAdminDeletedBanner)
  const [filterModal, setFilterModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [bicycleStatus, setBicycleStatus] = useState(BicycleStatusEnum.ALL)
  const [position, setPosition] = useState(null)
  const [filterQuery, setFilterQuery] = useState(null)
  const history = useHistory()

  const changePage = (page: number) => setPageNumber(page)
  const changeBicyclePosition = useCallback((value: BannerPosition) => {
    setPosition(value)
  }, [])

  useEffect(() => {
    if (done) {
      message.success(t('bannerIsDelete'))
      setPageNumber(0)
      setPageNumber(1)
      dispatch(resetDeleteState())
    }
    if (deleteErrors) {
      deleteErrors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }
  }, [deleteErrors, dispatch, done, t])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.fieldErrors) {
        errors?.fieldErrors.forEach((err: FieldError) => message.error(err.message))
      } else {
        errors?.violations.forEach((err: FieldError) => message.error(err.message))
      }
    }

    return () => {
      dispatch(resetError())
    }
  }, [errors, dispatch])

  const gotoEdit = useCallback((id: number) => history.push('/admin/update-banner/' + id), [history])

  const deleteBanner = useCallback(
    (id: number) => {
      dispatch(adminDeleteBannerRequest(id))
    },
    [dispatch]
  )

  const columns = useMemo(
    () => [
      {
        title: t('id'),
        dataIndex: 'id',
      },
      {
        title: t('position'),
        dataIndex: 'position',
      },
      {
        title: t('photos'),
        dataIndex: 'photos',
        render: (photos: string) => <img alt={photos} src={photos} />,
      },
      {
        title: 'title',
        key: 'title',
        dataIndex: 'title',
        render: (content: string) => ellipsis(content, 300),
        width: 300,
      },
      {
        title: t('url'),
        dataIndex: 'url',
        render: (link: string) => <a href={'https://' + link}>{link}</a>,
      },
      {
        title: t('startDate'),
        dataIndex: 'startDate',
        render: (date: string) => formatDate(date),
      },
      {
        title: t('endDate'),
        dataIndex: 'endDate',
        render: (date: string) => formatDate(date),
      },

      {
        title: 'Action',
        key: 'action',
        render: (_: any, record: BannerListView) => (
          <Space size="middle">
            <ColorButton type="text" color={colors.BLUE} onClick={() => gotoEdit(record.id)}>
              <EditOutlined />
            </ColorButton>
            <ColorButton type="text" color={colors.DANGER_RED}>
              <Popconfirm
                title={t('confirmDeleteBanner')}
                onConfirm={() => deleteBanner(record.id)}
                okText={t('yes')}
                cancelText={'no'}
              >
                <DeleteOutlined />
              </Popconfirm>
            </ColorButton>
          </Space>
        ),
      },
    ],
    [t, gotoEdit, deleteBanner]
  )

  const resetFilters = useCallback(() => {
    setStartDate(null)
    setEndDate(null)
    setBicycleStatus(BicycleStatusEnum.ALL)
    setPosition(null)
    setFilterQuery(null)
    setFilterModal(false)
  }, [])

  const changeBicycleStatus = useCallback((value: BicycleStatusEnum) => {
    setBicycleStatus(value)
  }, [])

  const setFilters = useCallback(() => {
    const filterQuery: GetALlBannersAdminRequest = {}
    if (startDate) {
      filterQuery.startDateFrom = startDate[0]?.format(BACKEND_DATE_FORMAT)
      filterQuery.startDateTo = startDate[1]?.format(BACKEND_DATE_FORMAT)
    }
    if (endDate) {
      filterQuery.endDateFrom = endDate[0]?.format(BACKEND_DATE_FORMAT)
      filterQuery.endDateFrom = endDate[1]?.format(BACKEND_DATE_FORMAT)
    }
    if (bicycleStatus !== BicycleStatusEnum.ALL) {
      filterQuery.active = bicycleStatus === BicycleStatusEnum.ACTIVE
    }
    if (position) {
      filterQuery.position = position
    }
    setFilterQuery(filterQuery)
    setFilterModal(false)
  }, [bicycleStatus, endDate, position, startDate])

  useEffect(() => {
    if (!pageNumber) return
    const request: GetALlBannersAdminRequest = {
      ...filterQuery,
      page: pageNumber,
      size: ADMIN_BANNERS_PAGE_SIZE,
    }
    dispatch(getAllBannerRequestAsync(request))
  }, [pageNumber, dispatch, filterQuery, done])

  const gotoNewBanner = () => history.push('/admin/new-banner')

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'banner' }} />
      <ActionBar>
        <Row justify="space-between">
          <AdminPageTitle>{t('Banner')}</AdminPageTitle>
          <Space>
            <Button icon={<FilterOutlined />} onClick={() => setFilterModal(true)}>
              {t('filter')}
            </Button>
            <Button icon={<PlusOutlined />} type="primary" onClick={gotoNewBanner}>
              {t('newBannerPost')}
            </Button>
          </Space>
        </Row>
      </ActionBar>
      {(is_fetching || deleteFetching) && <Indicator />}
      <Table columns={columns} dataSource={data} pagination={false} />
      <Row justify="end">
        <Pagination defaultCurrent={1} onChange={changePage} current={pageNumber} total={totalElements} />
      </Row>
      <Modal
        visible={filterModal}
        title={<FilterTitle>{t('filter')}</FilterTitle>}
        onCancel={() => setFilterModal(false)}
        footer={[
          <Button key="back" onClick={resetFilters}>
            {t('reset')}
          </Button>,
          <Button key="submit" type="primary" onClick={setFilters}>
            {t('apply')}
          </Button>,
        ]}
      >
        <Row>
          <FilterHeading>{t('startDate')}</FilterHeading>
        </Row>
        <Row>
          <DatePicker.RangePicker format={DATE_FORMAT} value={startDate} onChange={value => setStartDate(value)} />
        </Row>
        <Row>
          <FilterHeading>{t('endDate')}</FilterHeading>
        </Row>
        <Row>
          <DatePicker.RangePicker format={DATE_FORMAT} value={endDate} onChange={value => setEndDate(value)} />
        </Row>
        <Row>
          <FilterHeading>{t('status')}</FilterHeading>
        </Row>
        <Row>
          <Radio.Group onChange={e => changeBicycleStatus(e?.target?.value)} value={bicycleStatus}>
            <Radio value={BicycleStatusEnum.ALL}>{t('all')}</Radio>
            <Radio value={BicycleStatusEnum.ACTIVE}>{t('active')}</Radio>
            <Radio value={BicycleStatusEnum.INACTIVE}>{t('inactive')}</Radio>
          </Radio.Group>
        </Row>
        <Row>
          <FilterHeading>{t('position')}</FilterHeading>
        </Row>
        <Row>
          <Radio.Group onChange={e => changeBicyclePosition(e?.target?.value)} value={position}>
            <Radio value={null}>{t('all')}</Radio>
            <Radio value={BannerPosition.HOME}>{t('home')}</Radio>
            <Radio value={BannerPosition.BLOG}>{t('blog')}</Radio>
            <Radio value={BannerPosition.BICYCLE}>{t('bicycle')}</Radio>
          </Radio.Group>
        </Row>
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 24px;
`
const ActionBar = styled.div`
  padding: 16px 24px;
  background: ${colors.WHITE};
`
const ColorButton = styled(Button)<{ color: string }>`
  color: ${props => props.color};
`
const FilterTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.BLACK_TR};
`
const FilterHeading = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.BLACK_TR};
  margin: 24px 0 8px 0;
`
