import { AppBicycleService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { BrandResponse } from 'interfaces/Brands'

export const getBrandsRequestAsync: AsyncThunk<BrandResponse[], void, any> = createAsyncThunk(
  'brands/get',
  async (_, { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleList()
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
