import { Card, Row } from 'antd'
import { FunctionComponent, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { colors } from 'constants/colors'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { BicycleAppListView, ContentDetailsView, FeatureType, MediaNestedView } from 'api/main'
import { Link } from 'react-router-dom'
import { selectTopBicycles } from 'store/bicycle/getTop'
import { getTopBicyclesRequestAsync } from 'store/bicycle/getTop/asyncActions'
import { HelmetWrapper } from '../../UiKit/HelmetWrapper/HelmetWrapper'

interface HighlightsInterface {
  title?: string
  autoLoad?: boolean
  content: ContentDetailsView
}

export const Highlights: FunctionComponent<HighlightsInterface> = ({ title, autoLoad = false, content }) => {
  const { t, i18n } = useTranslation<string>()
  const { top5: bicycles } = useAppSelector(selectTopBicycles)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getTopBicyclesRequestAsync(FeatureType.TOP_FIVE))
  }, [autoLoad, dispatch])

  const getMediaNestedView = useCallback((bike: BicycleAppListView & { mainMedia?: MediaNestedView }) => {
    return bike.mainMedia ?? bike.medias[0]
  }, [])

  return (
    <Container>
      <HelmetWrapper parentData={{}}>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: bicycles.map((bicycle: any, i: number) => {
              return {
                '@type': 'ListItem',
                position: (i + 1).toString(),
                item: {
                  '@context': 'https://schema.org/',
                  '@type': 'Product',
                  name: bicycle.brand?.name,
                  image: [Array.isArray(bicycle.medias) ? getMediaNestedView(bicycle)?.url : ''],
                  description: bicycle.description,
                  brand: {
                    '@type': 'Brand',
                    name: bicycle.brand?.name,
                  },
                  offers: {
                    '@type': 'Offer',
                    url: 'https://example.com/anvil',
                    priceCurrency: 'EUR',
                    price: bicycle.price,
                  },
                },
              }
            }),
          })}
        </script>
      </HelmetWrapper>
      <RowWithPadding>
        {content && (
          <div dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? content.body : content.finnishBody }} />
        )}
      </RowWithPadding>
      <RowWithPaddingCentered>
        <HighlightTitle>{title ? t(title) : t('todaysHighlight')}</HighlightTitle>
      </RowWithPaddingCentered>
      <RowWithGap justify="start" wrap={false}>
        {bicycles.map((bike: BicycleAppListView) => (
          <Link key={bike.id} to={'/bicycle/' + bike.id}>
            <CardMargin
              hoverable
              className={'three-hundred-px-max-width'}
              cover={
                <ImageHolder>
                  <Img alt={bike.description} src={bike.medias[0]?.url} />
                </ImageHolder>
              }
            >
              <Card.Meta title={<Title>{bike.brand.name}</Title>} description={<Price>€ {bike.price}</Price>} />
            </CardMargin>
          </Link>
        ))}
      </RowWithGap>
    </Container>
  )
}
const RowWithGap = styled(Row)`
  gap: 12px;
  padding: 20px 0;
`
const Container = styled.div`
  margin: 20px auto;
  width: auto;
  overflow: hidden;
`
const Title = styled(Row)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #${colors.GRAY_TEXT};
`
const Price = styled(Row)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${colors.BLACK_TR};
`
const CardMargin = styled(Card)`
  padding: 20px;

  & .ant-row {
    white-space: normal;
  }

  & .ant-card-cover {
    height: 75%;
  }

  width: 238px;
  height: 279px;
`
const ImageHolder = styled.div`
  max-width: 300px;
  max-height: 350px;
  background: ${colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
`
const HighlightTitle = styled(Row)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  color: ${colors.BLACK};
  margin-bottom: 20px;
`
const Img = styled.img`
  max-width: 100%;
`
const RowWithPadding = styled(Row)`
  padding: 0 20px;
`
const RowWithPaddingCentered = styled(Row)`
  text-align: center;
`
