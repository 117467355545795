import { combineReducers } from '@reduxjs/toolkit'
import getAllPostsReducer from './getAll'
import createPostReducer from './create'
import editPostReducer from './edit'
import deletePostReducer from './delete'
import getPostReducer from './get'

export const blogReducers = combineReducers({
  getAll: getAllPostsReducer,
  create: createPostReducer,
  edit: editPostReducer,
  delete: deletePostReducer,
  get: getPostReducer,
})
