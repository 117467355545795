import { Button, Col, message, Row, Space } from 'antd'
import { FunctionComponent, useEffect } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { SaveNewsletterVM } from 'api/main'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { selectCreatedMedia } from 'store/bicycle/media'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import 'quill/dist/quill.snow.css'
import { NEWSLETTER_MEDIA_LABEL } from 'constants/index'
import { UploadImage } from 'Components/Profile/Bicyles/UploadImage/UploadImage'
import { resetState } from 'store/admin/newsletter/create'
import { resetState as resetMediaState } from 'store/bicycle/media'
import { Indicator } from 'Components/UiKit/Indicator'
import { selectAdminCreatedNewsletter } from 'store/admin/newsletter/create'
import { adminCreateNewsletterRequest } from 'store/admin/newsletter/create/asyncActions'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'
import { CustomTextArea, SelectBicycleInput, SelectBicycleWrapper } from '../../../Shared/Components/Components'

interface SaveNewsletterVMCorrected extends Omit<SaveNewsletterVM, 'emails'> {
  emails: string
}

export const NewEmail: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { done, errors: CreateErrors, is_fetching } = useAppSelector(selectAdminCreatedNewsletter)
  const { data: uploadedMediaIds, errors: UploadErrors } = useAppSelector(selectCreatedMedia)

  const newEmailSchema = (t: TFunction): Yup.ObjectSchema<ObjectShape> =>
    Yup.object().shape({
      title: Yup.string().required(t('required')),
      emails: Yup.string().required(t('required')),
    })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SaveNewsletterVMCorrected>({
    defaultValues: {},
    resolver: yupResolver(newEmailSchema(t)),
  })

  const goBack = () => history.goBack()

  const onSubmit = handleSubmit((requestBody: SaveNewsletterVMCorrected) => {
    if (uploadedMediaIds.length === 0) {
      message.error(t('pleaseUploadFile'))
      return
    }
    const photoId = uploadedMediaIds[uploadedMediaIds?.length - 1]?.id
    const request: SaveNewsletterVM = {
      ...requestBody,
      emails: requestBody?.emails.split('\n'),
      mediaId: photoId,
    }
    dispatch(adminCreateNewsletterRequest(request))
  })

  useEffect(() => {
    if (UploadErrors) {
      UploadErrors.fieldErrors.forEach((err: FieldError) => message.error(err.message))
    }
  }, [t, UploadErrors, dispatch])

  useEffect(() => {
    if (Array.isArray(CreateErrors?.fieldErrors)) {
      CreateErrors?.fieldErrors?.forEach((err: FieldError) => message.error(err.message))
    }
    if (!CreateErrors && done) {
      message.success(t('newsletterCreated'))
      history.push('/admin/newsletter')
    }
    return () => {
      dispatch(resetState())
      dispatch(resetMediaState())
    }
  }, [t, CreateErrors, done, history, dispatch])

  return (
    <Container>
      <HelmetWrapper parentData={{ titleKey: 'newEmail' }} />
      {is_fetching && <Indicator />}
      <form onSubmit={onSubmit}>
        <Topbar justify="space-between">
          <Space>
            <Button type="text" onClick={goBack} icon={<ArrowLeftOutlined />} />
            {t('newEmail')}
          </Space>
        </Topbar>
        <RowPadding justify="space-between" gutter={20}>
          <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
            <SelectBicycleInput label={t('title')} name={'title'} control={control} error={errors.title} />
          </InputCol>
          <InputCol xs={{ span: 24 }} md={{ span: 12 }}>
            <SelectBicycleWrapper label={t('emails')} required name={'emails'} control={control} error={errors.emails}>
              <CustomTextArea />
            </SelectBicycleWrapper>
            <UploadImage hint={t('emailHint')} singleMode targetType={NEWSLETTER_MEDIA_LABEL} images={[]} />
          </InputCol>
        </RowPadding>
        <Row justify="end">
          <SubmitButton htmlType="submit">{t('send')}</SubmitButton>
        </Row>
      </form>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 24px;
  background-color: ${colors.WHITE};
  font-family: 'Roboto';
  font-style: normal;
  color: ${colors.BLACK_TR};
`
const Topbar = styled(Row)`
  padding: 16px 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 1px solid ${colors.HEADER_BORDER};
`
const InputCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const SubmitButton = styled(Button)`
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  background: ${colors.BLUE};
  border: 1px solid ${colors.BLUE};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: ${colors.WHITE};
`
const RowPadding = styled(Row)`
  padding: 0 0 50px 0;
`
