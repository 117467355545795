import { FunctionComponent, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ObjectShape } from 'yup/lib/object'
import { Button, Col, Input, message } from 'antd'
import { Indicator } from 'Components/UiKit/Indicator'
import { useHistory } from 'react-router-dom'
import { AuthWrapper } from 'Components/Auth/Shared/Wrapper/AuthWrapper'
import { AuthForm, BackButton, NetButton, RowWMargin } from 'Components/Auth/Shared/TinyComponents/TinyComponents'
import { FormInput } from 'Components/Auth/Shared/InputArea'
import { activateUserRequestAsync } from 'store/auth/activate/asyncActions'
import { ActivateUserVM } from 'api/main'
import { deleteStatus } from 'store/auth/activate'
import { getPendingEmail, removePendingEmail } from 'utils/storage'
import { resendRequestAsync } from 'store/auth/resend/asyncActions'
import { HelmetWrapper } from '../../../UiKit/HelmetWrapper/HelmetWrapper'

const activateUserSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
  Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
    activationCode: Yup.string().min(6).required(t('required')),
  })

export const Verification: FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const dispatch = useAppDispatch()
  const pendingEmail = getPendingEmail()
  const history = useHistory()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ActivateUserVM>({ defaultValues: { email: pendingEmail }, resolver: yupResolver(activateUserSchema(t)) })
  const { is_fetching, done, errors: backendErrors } = useAppSelector(state => state.auth.activateUser)

  useEffect(() => {
    if (backendErrors) {
      backendErrors.fieldErrors.forEach(err => message.error(err.message))
    }
  }, [backendErrors])

  useEffect(() => {
    if (done) {
      dispatch(deleteStatus())
      message.success(t('userActivated'))
      removePendingEmail()
      history.push('home')
    }
  }, [dispatch, t, done, history])

  const onSubmit = handleSubmit((data: ActivateUserVM) => {
    dispatch(activateUserRequestAsync(data))
  })

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const resendCode = useCallback(() => {
    dispatch(resendRequestAsync({ email: pendingEmail }))
  }, [dispatch, pendingEmail])

  return (
    <AuthWrapper>
      <HelmetWrapper parentData={{ titleKey: 'verification' }} />
      {is_fetching && <Indicator />}
      <AuthForm onSubmit={onSubmit}>
        <RowWMargin>
          <BackButton onClick={goBack} />
        </RowWMargin>
        <RowWMargin>
          <h2>{t('verification')}</h2>
        </RowWMargin>
        <RowWMargin>{t('enterVerificationCode')}</RowWMargin>
        <Col>
          <Input.Group>
            <RowWMargin>
              <FormInput
                name="activationCode"
                title={t('verificationCode')}
                control={control}
                error={errors.activationCode}
              />
            </RowWMargin>
            <RowWMargin justify={'space-between'} align={'middle'}>
              <NetButton primary htmlType="submit">
                {t('submit')}
              </NetButton>
              <Button type="link" onClick={resendCode}>
                {t('resendCode')}
              </Button>
            </RowWMargin>
          </Input.Group>
        </Col>
      </AuthForm>
    </AuthWrapper>
  )
}
