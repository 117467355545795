import { createSlice } from '@reduxjs/toolkit'
import { getOneBrandRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { BrandResponse } from 'interfaces/Brands'

export interface BrandState {
  is_fetching: boolean
  data: BrandResponse
}

const initialState: BrandState = {
  is_fetching: false,
  data: null,
}

export const brandSlice = createSlice({
  name: 'currentBrand',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getOneBrandRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(getOneBrandRequestAsync.fulfilled, (state, action) => {
        state.is_fetching = false
        state.data = action.payload
      })
      .addCase(getOneBrandRequestAsync.rejected, state => {
        state.is_fetching = false
      })
  },
})

export const selectBrand = (state: RootState): any => state.brands.current

export default brandSlice.reducer
