import { AppUserService, ChangePasswordVM } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const changePasswordRequestAsync: AsyncThunk<any, ChangePasswordVM, any> = createAsyncThunk(
  'auth/changePassword',
  async (request: ChangePasswordVM, { rejectWithValue }) => {
    try {
      return await AppUserService.appProfilePasswordChange(request)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
