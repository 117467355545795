import { BicycleStatus } from 'api/main'
import { colors } from './colors'

export const formatDate = (date: string): string => {
  const parsedDate = new Date(date)
  return parsedDate.toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' })
}
export const ellipsis = (text: string, limit: number): string => {
  if (text?.length > limit) {
    for (let i = limit; i > 0; i--) {
      if (text.charAt(i) === ' ' && (text.charAt(i - 1) != ',' || text.charAt(i - 1) != '.')) {
        return text.substring(0, i) + '...'
      }
    }
    return text.substring(0, limit) + '...'
  } else return text
}
export const mapColor = (status: string): string => {
  if (status === BicycleStatus.ACTIVE) return colors.BRAND_GREEN
  if (status === BicycleStatus.INACTIVE) return colors.SOLD_GRAY
  if (status === BicycleStatus.PENDING) return colors.VOLCANO
  if (status === BicycleStatus.EXPIRED) return colors.EXPIRED_GRAY
  if (status === BicycleStatus.SOLD) return colors.SOLD_GRAY
  if (status === BicycleStatus.REJECTED) return colors.REJECTED_RED
  if (status === BicycleStatus.FOUND) return colors.GRAY_TEXT
  if (status === BicycleStatus.RENTED) return colors.SOLD_GRAY
  if (status === BicycleStatus.LEASED) return colors.SOLD_GRAY
}
