import { AdminBlogService } from 'api/main'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

export const getPostsRequestAsync: AsyncThunk<any, number, any> = createAsyncThunk(
  'admin/bicycles/get',
  async (id: number, { rejectWithValue }) => {
    try {
      return await AdminBlogService.adminBlogShow(id)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
