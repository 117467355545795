import { Col } from 'antd'
import { FunctionComponent } from 'react'
import { HeaderComponent } from '../Header/Header'
import { Background, InfoBody, InfoTitle, RowFullHeight } from '../TinyComponents/TinyComponents'
import background from 'assets/images/login-bg.jpg'
import { useTranslation } from 'react-i18next'

interface AuthWrapperInterface {
  children: React.ReactNode
}

export const AuthWrapper: FunctionComponent<AuthWrapperInterface> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <RowFullHeight>
      <Col xs={24} xl={12}>
        <HeaderComponent />
        {children}
      </Col>
      <Col xs={0} xl={12}>
        <Background image={background}>
          <InfoTitle>{t('welcomeToNettiFillari')}</InfoTitle>
          <InfoBody>{t('slogan')}</InfoBody>
        </Background>
      </Col>
    </RowFullHeight>
  )
}
