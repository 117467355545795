import { createSlice } from '@reduxjs/toolkit'
import { getOwnBicyclesRequestAsync } from './asyncActions'

import { RootState } from '../../index'
import { Bicycle } from 'interfaces/Bicycles'
import { ErrorResponse } from 'interfaces/Error'

export interface OwnBicycleRequest {
  offset?: number
  page?: number
  pageNumber?: number
  pageSize?: number
  paged?: boolean
  size?: number
  sort?: Array<string>
  sortSorted?: boolean
  sortUnsorted?: boolean
  unpaged?: boolean
}

export interface OwnBicycleState {
  is_fetching: boolean
  data: Bicycle[]
  errors: ErrorResponse
  totalElements: number
}

const initialState: OwnBicycleState = {
  is_fetching: false,
  data: null,
  errors: null,
  totalElements: 0,
}

export const ownBicyclesSlice = createSlice({
  name: 'owenBicycles',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getOwnBicyclesRequestAsync.pending, state => {
        state.is_fetching = true
      })
      .addCase(getOwnBicyclesRequestAsync.fulfilled, (state, action) => {
        state.is_fetching = false
        state.data = action.payload?.content
        state.totalElements = action.payload?.totalElements
      })
      .addCase(getOwnBicyclesRequestAsync.rejected, state => {
        state.is_fetching = false
        state.data = null
      })
  },
})

export const selectOwnBicycles = (state: RootState): any => state.bicycles.own

export default ownBicyclesSlice.reducer
