import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { AppBicycleService } from 'api/main'

export const getCapacityRequestAsync: AsyncThunk<any, any, any> = createAsyncThunk(
  'bicycles/capacity',
  async (type: 'TOP_TEN' | 'TOP_FIVE', { rejectWithValue }) => {
    try {
      return await AppBicycleService.appBicycleFeatureCapacity(type)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
